module.exports = {
  keyList: {
    projectName: window._env_.REACT_APP_projectName,
    projectUrl: window._env_.REACT_APP_projectUrl,
    apiURL: window._env_.REACT_APP_apiURL,
    grpcURL: window._env_.REACT_APP_grpcURL,
    webReleaseVersion: window._env_.REACT_APP_webReleaseVersion,
    demoWorkspace: window._env_.REACT_APP_demoWorkspace,
    communityServiceOrgId: window._env_.REACT_APP_communityServiceOrgId,
    userNameExt: window._env_.REACT_APP_userNameExt,
    reducerKey: window._env_.REACT_APP_reducerKey,
    localStorage: window._env_.REACT_APP_localStorage,
  },
};
