module.exports = {
    companySetDomain: "company",
    calendly: "calendly",
    workspace: "workspace",
    subjectSetDomain: "subject",
    classSetDomain: "class",
    educationDayEntrySetDomain: "educationDayEntry",
    educationTaskSetDomain: "educationTask",
    projectSetDomain: "project",
    studyMaterialSetDomain: "studyMaterial",
    teamSetDomain: "team",
    clientSetDomain: "client",
    uiSetDomain: "ui",
    dayEntrySetDomain: "dayEntry",
    dailyReport: "dailyReport",
    taskSetDomain: "task",
    meetingsSetDomain: "meetings",
    chatSetDomain: "chat",
    textSearch: "textSearch",
    pollSetDomain: "poll",
    boardsSetDomain: "boards",
    educationBoardsSetDomain: "educationBoards",
    feedbackSetDomain: "feedback",
    teqreqSetDomain: "teqreq",
    pollTestSetDomain: "pollTest",
    userSetDomain: "user",
    appsSetDomain: "apps",
    educationExpenseCategorySetDomain: "educationExpenseCategory",
    educationExpensesSetDomain: "educationExpenses",
    streamSetDomain: "stream",
    vsCodeSetDomain: "vsCode",
    adminSetDomain: "admin",
    adminUserReqSetDomain: "adminUserReq",
    manageSetDomain: "manage",
    accumulatorSetDomain: "accumulator",
    reportingSetDomain: "reporting",
    organizationfeedbackSetDomain: "organizationfeedback",
    qatReportSetDomain: "qatReport",
    csvManagerSetDomain: "csvManager",
    adminUserManageSetDomain: "adminUserManage",
    adminPagesSetDomain: "adminPages",
    adminRoleTypeSetDomain: "adminRoleType",
    expensesSetDomain: "expenses",
    expenseCategorySetDomain: "expenseCategory",
    csServiceType: "cs-serviceType",
    csQuestion: "cs-question",
    membership: "membership",
    buildMonitor: "buildMonitor",
    contactSetDomain: "contact",
    survivorSetDomain: "survivor",
    accumulationSetDomain: "accumulation",
    csEventSetDomain: "cs-event",
    csSubServiceType: "cs-subServiceType",
    teqitTv: "teqitTv",
    donation: "donation",
    donationInventory: "donationInventory",
    agentSetDomain: "agent",
    healthcareService: "healthcareService",
    csEncareSetDomain:"cs-encare"
};