import storage from 'redux-persist/lib/storage'
import {createStore, applyMiddleware} from 'redux';
import {persistStore, persistReducer} from 'redux-persist';
import thunk from 'redux-thunk';

import rootReducer from '../reducers/index';
import MyConstant from '../../config/MyConstant';

const persistConfig = {
  key: MyConstant.keyList.reducerKey,
  storage: storage,
  whitelist: ['authReducer'],
  blacklist: [],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, applyMiddleware(thunk));

const persistor = persistStore(store);

export {store, persistor};