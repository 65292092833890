import React, { useEffect, useState } from "react";
import { getCaseId } from "../../config/Common";
import Modal from "../../components/Modal";
import DropDown from "../../components/DropDown";
import GlobalMsgCons from "../../config/GlobalMsgCons";
import GlobalDomainCons from '../../config/GlobalDomainCons';
import GlobalEventCons from '../../config/GlobalEventCons';
import { ZiFetchRequest } from '../../protobuf/generated/ZiFetchService_grpc_web_pb';
import { getGrpcClient } from '../../config/Common';
import { getUuidv4 } from '../../config/Common';
import { useSelector } from 'react-redux';
import CustomEditor from '../../components/CustomEditor';

const statusUpdateValue = {
    "pending": "pending",
    "cboAccepted": "CBO Accepted",
    "shareWithIretiResource": "Shared with Ireti Resource",
    "receivedRequest": "Received Request",
    "declined": "declined",
    "lnscAccepted": "lnscAccepted",
    "adminAccepted": "Admin Accepted",
}


export default function UpdateStatus(props) {

    const auth = useSelector(state => state.authReducer);

    let cbo = { label: "CBO Accepted", value: statusUpdateValue["cboAccepted"], isDisabled: false, }
    if (auth.activeUserType === GlobalMsgCons.roleTypeLNSC) {
        cbo = { label: "LNSC Accepted", value: statusUpdateValue["lnscAccepted"], isDisabled: false, }
    } else if (auth.activeUserType === GlobalMsgCons.roleTypeAdmin || auth.activeUserType === GlobalMsgCons.roleTypeCommunityServiceAdmin) {
        cbo = { label: "Admin Accepted", value: statusUpdateValue["adminAccepted"], isDisabled: false, }
    }


    let statusOptionList = [
        { label: "Pending", value: statusUpdateValue["pending"], isDisabled: false, },
        cbo,
        { label: "Shared with Ireti Resource", value: statusUpdateValue["shareWithIretiResource"], isDisabled: false, },
        { label: "Received Request", value: statusUpdateValue["receivedRequest"], isDisabled: false, },
        { label: "Declined", value: statusUpdateValue["declined"], isDisabled: false, },
    ]

    let { csEventData, sendCommunityServiceEvent } = props;
    let { eventCaseNo, status, lnscStatus } = csEventData;

    let [updateModal, setUpdateModal] = useState(false);
    let [updateStatus, setUpdateStatus] = useState("");
    let [contactData, setContactData] = useState([]);
    let [selectContact, setSelectContact] = useState("");
    let [previewContact, setPreviewContact] = useState({});
    let [summaryContent, setSummaryContent] = useState("");
    let [statusOption, setStatusOption] = useState(statusOptionList);

    let userId = auth.loginId;
    let companyId = auth.activeCompany

    useEffect(() => {
        let getStatus = status
        if (auth.activeUserType === GlobalMsgCons.roleTypeLNSC) {
            getStatus = lnscStatus
        }
        setUpdateStatus(getStatus)
    }, [])



    function save() {
        let data = { ...csEventData }
        let messageType = GlobalMsgCons.messageTypeCSEventResponse;

        if (auth.activeUserType === GlobalMsgCons.roleTypeLNSC) {
            data.lnscStatus = updateStatus
            messageType = GlobalMsgCons.messageTypeCSEventLnscResponse;
        } else {
            data.status = updateStatus
        }

        let dataValue = data.data

        if (selectContact !== "") {
            dataValue.contactPersonInfo = previewContact
        } else {
            delete dataValue.contactPersonInfo

        }
        if (summaryContent) {
            dataValue.summary = summaryContent
        } else {
            delete dataValue.summary
        }

        data.data = { ...dataValue }

        sendCommunityServiceEvent(data, messageType);
        setUpdateModal(false)
        setSummaryContent("")
    }

    function getContactList() {
        let postJson = { reqId: getUuidv4(), userId, orgId: companyId }
        //console.log("-->", postJson)
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.contactSetDomain);
        request.setEvent(GlobalEventCons.getContactListSetEvent);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                // console.log("responseData", responseData)
                if (responseData && Array.isArray(responseData.result)) {
                    let optionResult = responseData.result.map(e => { return { label: e.email, value: e.id, details: e } })
                    setContactData(optionResult)
                } else {
                    setContactData([])
                }

            }
        });
    }
    function statusListChange(updatestatus) {
        updatestatus = updatestatus ? updatestatus : status;
        if (updatestatus === statusUpdateValue["cboAccepted"]) {
            statusOption = statusOption.map((e) => {
                e.isDisabled = [statusUpdateValue["pending"], statusUpdateValue["receivedRequest"], statusUpdateValue["cboAccepted"]].includes(e.value)
                return e
            })
        } else if (updatestatus === statusUpdateValue["shareWithIretiResource"]) {
            statusOption = statusOption.map((e) => {
                e.isDisabled = [statusUpdateValue["pending"], statusUpdateValue["receivedRequest"], statusUpdateValue["cboAccepted"]].includes(e.value)
                return e
            })
        }
        else if (updatestatus === statusUpdateValue["receivedRequest"] || updatestatus === statusUpdateValue["declined"]) {
            statusOption = statusOption.map((e) => {
                e.isDisabled = [statusUpdateValue["pending"]].includes(e.value)
                return e
            })
        }
        else if (updatestatus === statusUpdateValue["pending"]) {
            statusOption = statusOption.map((e) => {
                e.isDisabled = [""].includes(e.value)
                return e
            })
        }


        setStatusOption(statusOption)
    }

    return <React.Fragment>
        <div className="mt-2">
            <span className="update-status" onClick={() => {
                getContactList()
                statusListChange()
                setUpdateModal(true)
            }}>
                Update Status
            </span>
        </div>
        <div style={{ flex: "none" }}>
            {
                updateModal && <Modal
                    visible={updateModal}
                    parentClass={"add-category"}
                    closeModal={() => {
                        setUpdateModal(false)
                        setPreviewContact({})
                        setSelectContact("")
                        setUpdateStatus(status)
                        setSummaryContent("")
                    }}
                    heading={`Update Status - ` + getCaseId(eventCaseNo)}
                    size={"modal-lg"}
                    body={<React.Fragment>
                        <div className="row" >
                            <div className="col-4">
                                <div className="form-group">
                                    <label data-tip={"Status"}>{"Status"}</label>
                                    <DropDown
                                        value={statusOption.filter(option => option.value === updateStatus)}
                                        onChange={(e) => {
                                            setUpdateStatus(e.value)
                                            // statusListChange(e.value)
                                        }}
                                        options={statusOption}
                                        isDisabled={false}
                                        isMulti={false}
                                        placeholder={"Select Status"}
                                    />
                                </div>
                            </div>
                            {auth.activeUserType !== GlobalMsgCons.roleTypeLNSC && <div className="col-4">
                                <div className="form-group">
                                    <label data-tip={"Contact"}>{"Contact"}</label>
                                    <DropDown
                                        value={contactData.filter(option => option.value === selectContact)}
                                        onChange={(e) => {
                                            setSelectContact(e.value)
                                            setPreviewContact(e.details)
                                        }}
                                        options={contactData}
                                        isDisabled={false}
                                        isMulti={false}
                                        placeholder={"Select Contact"}
                                    />
                                </div>
                            </div>}

                        </div>

                        {Object.keys(previewContact).length > 0 ? <>
                            <label className="mb-2">
                                Contact Details
                            </label>
                            <div className="row" >
                                <div className="col-4">
                                    <div className="form-group">
                                        <label data-tip={"Name"}>{"Name"}</label>
                                        <div>{previewContact.name}</div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-group">
                                        <label data-tip={"Email"}>{"Email"}</label>
                                        <div>{previewContact.email}</div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-group">
                                        <label data-tip={"Address"}>{"Address"}</label>
                                        <div>{previewContact.address}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="row" >
                                <div className="col-4">
                                    <div className="form-group">
                                        <label data-tip={"Phone"}>{"Phone"}</label>
                                        <div>{previewContact.phone}</div>
                                    </div>
                                </div>

                            </div>
                        </> : null}
                        {auth.activeUserType !== GlobalMsgCons.roleTypeLNSC && <div className="row">
                            <div className="col-8">
                                <div className="form-group">
                                    <label>{"Summary"}</label>
                                    <CustomEditor
                                        title={"Summary"}
                                        text={summaryContent}
                                        textOnchange={(e) => {
                                            setSummaryContent(e)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>}


                    </React.Fragment>
                    }
                    footer={<React.Fragment>
                        <button className="btn btn-sm btn-primary" onClick={() => save()}>Save</button>
                    </React.Fragment>
                    }
                />
            }
        </div>
    </React.Fragment>;
}

