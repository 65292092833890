import React, { useState } from "react";
import { getBase64, getUuidv4 } from "../config/Common";
import Dragplaceholder from '../assets/Dragfile.png';
import { toast, ToastContainer } from "react-toastify";
import Dropzone from 'react-dropzone';
import { setProjectData } from "../redux/actions";
import { PDFViewer } from "react-view-pdf";
import Modal from "./Modal";
import MyConfig from "../config/MyConfig";
import HelpAudio from "./HelpAudio";

let videos = ["mp4", "3gp", "ogg", "mov"]
export default function FileUpload(props) {
    let { documentFiles, documentJson, setDocumentFiles, setDocumentJson, label, dragFilesTxt, supportFileTxt, processType, tempLocal, helpAudio } = props;

    let [previewData, setPreviewData] = useState("");
    let [previewModal, setPreviewModal] = useState(false);

    function documentOnDrop(newFiles) {
        let cloneFiles = [...documentFiles];
        let cloneJson = [...documentJson];
        for (var i = 0; i < newFiles.length; i++) {
            let file = newFiles[i];
            let filename = file.name;
            let ext = filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
            let uid = getUuidv4();

            cloneFiles.push(newFiles[i]);
            cloneJson.push({
                name: "",
                path: newFiles[i],
                status: 'active',
                progress: '0',
                origFileName: filename,
                fileName: uid + "." + ext,
                docsId: uid,
                processType,
                fileType: ext
            })

            if (tempLocal) {
                setStoreLocal(newFiles[i], uid)
            }
        }

        setDocumentFiles(cloneFiles);
        setDocumentJson(cloneJson)
    }

    async function setStoreLocal(file, name) {
        let base64 = await getBase64(file)
        localStorage.setItem(MyConfig.project_LocalStorage + "tempFile_" + name, base64);
    }

    function documentOnRemove(loopIndex) {
        let cloneFiles = [...documentFiles];
        let cloneJson = [...documentJson];

        let { docsId } = cloneJson[loopIndex]
        localStorage.removeItem(MyConfig.project_LocalStorage + "tempFile_" + docsId);

        cloneJson.splice(loopIndex, 1);
        cloneFiles.splice(loopIndex, 1);

        setDocumentFiles(cloneFiles);
        setDocumentJson(cloneJson)
    }

    function filePreview() {
        let returnModelBody = ""
        if (previewData.fileType) {
            if (videos.includes(previewData.fileType.toLowerCase())) {
                returnModelBody = <>
                    <video width="400" controls>
                        <source src={previewData.filePath} />
                    </video>
                </>
            }
            else if (previewData.fileType === "pdf") {
                returnModelBody = <PDFViewer url={previewData.filePath} />

            }
            else {
                returnModelBody = <>
                    <img src={previewData.filePath} className={"img-thumbnail"} style={{ height: 400 }} />
                </>
            }
        }

        return returnModelBody
    }
    return <React.Fragment>
        <ToastContainer />
        <label className='form-group'>{label}</label>
        <div className="dropback">
            <Dropzone accept=".png, .jpg, .mp4, .jpeg, .3gp, .ogg, .mov, .pdf, .txt" multiple={true}
                onDrop={(acceptedFiles) => {
                    documentOnDrop(acceptedFiles)
                }} onDropRejected={(reject) => {
                    toast.error("Please Choose Image / Video / Pdf / Txt File.", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                }}  >
                {({ getRootProps, getInputProps }) => (
                    <section>
                        <div {...getRootProps({ className: 'dropzone' })}>
                            <input {...getInputProps()} />
                            <div className="row">
                                <div className="col-3 px-0 my-auto">
                                    <img src={Dragplaceholder} className='dragimgph' />
                                </div>
                                <div className="col-9">
                                    
                                    <p className='dragtext'>
                                        {dragFilesTxt} { helpAudio && <HelpAudio parentClass={""} audio={"51"} /> }
                                        <br />
                                        <small>{supportFileTxt}</small>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                )}
            </Dropzone>
        </div>

        <div className='row my-2'>
            {
                documentFiles.map((f, loopIndex) => {
                    let filePath = URL.createObjectURL(f)
                    let fileName = f.name
                    let fileType = fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2);
                    let previewData = { filePath, fileType }
                    let returnData = "";

                    if (videos.includes(fileType.toLowerCase())) {
                        returnData = <i className="fa fa-video" aria-hidden="true"
                            style={{ paddingRight: 5, fontSize: 80, cursor: "pointer" }}
                            onClick={() => {
                                setPreviewModal(true);
                                setPreviewData(previewData)
                            }}
                        ></i>
                    }
                    else if (fileType === "pdf") {
                        returnData = <i className="fa fa-file-pdf" aria-hidden="true"

                            style={{ paddingRight: 5, fontSize: 80, cursor: "pointer" }}
                            onClick={() => {
                                setPreviewModal(true)
                                setPreviewData(previewData)
                            }}
                        ></i>
                    } else {
                        returnData = <img src={filePath} alt="no img" style={{ width: 80, height: 80, cursor: "pointer", maxWidth: "100%" }}
                            onClick={() => {
                                setPreviewModal(true)
                                setPreviewData(previewData)
                            }}
                        />

                    }
                    return <div className='col-sm-4 col-md-3 file-box' key={loopIndex}>
                        {returnData}
                        <i className="fa fa-trash delete-upload-file" aria-hidden="true"
                            onClick={() => documentOnRemove(loopIndex)}

                        ></i>
                    </div>
                })
            }
        </div>

        {
            previewModal && <Modal
                visible={previewModal}
                size={"modal-xl"}
                closeModal={() => {
                    setPreviewModal(false)
                    setPreviewData("")
                }}
                heading={"File View"}
                parentClass={"text-center"}
                body={<React.Fragment>
                    {filePreview()}
                </React.Fragment>}
                bodyClassRemove={false}
            />
        }
    </React.Fragment>;
}
