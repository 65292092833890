export const SET_AUTH = 'SET_AUTH';
export const SET_MULTIPLE_AUTH = 'SET_MULTIPLE_AUTH';
export const RESET_AUTH = 'RESET_AUTH';


//Login
export const SET_LOGIN = 'SET_LOGIN';
export const RESET_LOGIN = 'RESET_LOGIN';

//REGISTER
export const SET_REGISTER = 'SET_REGISTER';
export const SET_MULTIPLE_REGISTER = 'SET_MULTIPLE_REGISTER';
export const RESET_REGISTER = 'RESET_REGISTER';

//FORGOT_PASSWORD
export const SET_FORGOT_PASSWORD = 'SET_FORGOT_PASSWORD';
export const SET_MULTIPLE_FORGOT_PASSWORD = 'SET_MULTIPLE_FORGOT_PASSWORD';
export const RESET_FORGOT_PASSWORD = 'RESET_FORGOT_PASSWORD';

//CHAT
export const SET_CHAT = 'SET_CHAT';
export const SET_MULTIPLE_CHAT = 'SET_MULTIPLE_CHAT';
export const RESET_CHAT = 'RESET_CHAT';

//CHAT
export const SET_CHAT_CHANNEL = 'SET_CHAT_CHANNEL';
export const SET_MULTIPLE_CHAT_CHANNEL = 'SET_MULTIPLE_CHAT_CHANNEL';
export const RESET_CHAT_CHANNEL = 'RESET_CHAT_CHANNEL';

//CHAT
export const SET_THREAD_CHAT = 'SET_THREAD_CHAT';
export const SET_MULTIPLE_THREAD_CHAT = 'SET_MULTIPLE_THREAD_CHAT';
export const RESET_THREAD_CHAT = 'RESET_THREAD_CHAT';


//MY_PROFILE
export const SET_MY_PROFILE = 'SET_MY_PROFILE';
export const SET_MULTIPLE_MY_PROFILE = 'SET_MULTIPLE_MY_PROFILE';
export const RESET_MY_PROFILE = 'RESET_MY_PROFILE';

//REPORT_HERE
export const SET_REPORT_HERE = 'SET_REPORT_HERE';
export const SET_MULTIPLE_REPORT_HERE = 'SET_MULTIPLE_REPORT_HERE';
export const RESET_REPORT_HERE = 'RESET_REPORT_HERE';
