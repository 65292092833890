import { SET_REPORT_HERE, SET_MULTIPLE_REPORT_HERE, RESET_REPORT_HERE } from '../constants/actionTypes';

const initialState = {
  requesterReached: "",
  generatedPin: ""
};

const reportHereReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_REPORT_HERE: {
      return {
        ...state,
        [action.key]: action.value,
      };
    }
    case SET_MULTIPLE_REPORT_HERE: {
      return {
        ...state,
        ...action.data,
      };
    }
    case RESET_REPORT_HERE: {
      return {
        requesterReached: "",
        generatedPin: ""
      };
    }
    default: {
      return state;
    }
  }
};

export default reportHereReducer;
