import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GlobalEventCons from "../../../../config/GlobalEventCons";
import GlobalDomainCons from "../../../../config/GlobalDomainCons";
import { ZiFetchRequest } from "../../../../protobuf/generated/ZiFetchService_grpc_web_pb";
import { getAppInfo, getGrpcClient, getUuidv4, getValidLabels } from "../../../../config/Common";
import GlobalMsgCons from "../../../../config/GlobalMsgCons";
import MyConfig from "../../../../config/MyConfig";
import { setMultipleAuthData } from "../../../../redux/actions";
import { ToastContainer, toast } from 'react-toastify';
import HelpAudio from "../../../../components/HelpAudio";


let yesOrNo = [
    { value: "Yes", label: "Yes", help: <HelpAudio parentClass={""} audio={"17"} /> },
    { value: "No", label: "No", help: <HelpAudio parentClass={""} audio={"18"} /> },
]

export default function PINVerify(props) {
    let { pageText, focusPhone, setFocusPhone, setShortName, shortNameAlert } = props;

    let [existUser, setExistUser] = useState("Yes")
    let [username, setUsername] = useState("")
    let [pin, setPin] = useState("")
    let [errors, setErrors] = useState({})
    let [generatedPin, setGeneratedPin] = useState("")
    let [submitLoader, setSubmitLoader] = useState(false)

    const auth = useSelector(state => state.authReducer);
    const dispatch = useDispatch();
    let { loginId, firstName } = auth

    const inputRef = useRef(null);

    useEffect(() => {
        if (focusPhone) {
            if (inputRef.current) {
                inputRef.current.focus();
            }
            setTimeout(() => {
                setFocusPhone(false)
            }, 2500)
        }
    }, [focusPhone])

    useEffect(() => {
        if(existUser === "Yes") {
            setShortName(username.trim())
        } else {
            let errors = {};
            if(isUserNameValid(username)) {
                setShortName(username.trim())
            } else if(username.trim()){
                errors["username"] = "Please enter a valid name. Special characters and spaces are not allowed.";
                setShortName("")
            }
            setErrors(errors)
        }
                                    
    }, [username, existUser])

    function isUserNameValid(username) {
        /* 
          Usernames can only have: 
          - Capital Letters (A-Z) 
          - Lowercase Letters (a-z) 
          - Numbers (0-9)
          - Dots (.)
          - Underscores (_)
        */
        const res = /^[A-Za-z0-9_\.]+$/.exec(username);
        const valid = !!res;
        return valid;
    }

    function loginCheck() {
        let errors = {};
        if (username.trim() === '') {
            errors["username"] = "Please enter name.";
        } else if (!isUserNameValid(username)) {
            // errors["username"] = "Please enter a valid name. Special characters and spaces are not allowed.";
        }

        if (pin.trim() === '') {
            errors["pin"] = "Please enter pin.";
        }

        setErrors(errors)

        if (Object.keys(errors).length === 0) {
            setSubmitLoader(true)
            username = username.toLowerCase() + MyConfig.userNameExt
            let deviceInfo = getAppInfo()
            let postJson = {
                reqId: getUuidv4(),
                userName: username.trim(),
                password: pin,
                deviceInfo
            };

            const request = new ZiFetchRequest();
            request.setDomain(GlobalDomainCons.manageSetDomain);
            request.setEvent(GlobalEventCons.userSignInViaPin);
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
                if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                    console.warn('Invalid Request. Please try again later');
                } else {
                    let responseData = JSON.parse(response.getMessage());
                    var response = responseData.response;
                    setSubmitLoader(false)
                    if (response === GlobalMsgCons.success) {

                        dispatch(setMultipleAuthData({
                            isLoggedIn: '1',
                            loginId: responseData.id,
                            firstName: responseData.firstName,
                            lastName: responseData.lastName,
                            profilePhoto: "",
                            sessionId: responseData.sessionId,
                            loggedEmail: "",
                            userThemeColor: "",
                            languagePreference: response.languagePreference ? response.languagePreference : "eng",
                            activeStatus: responseData.status,
                            hoursSelected: responseData.hourFormat ? responseData.hourFormat : 12,
                            phone: responseData.phone ? responseData.phone : ""
                        }))


                        localStorage.setItem(MyConfig.project_LocalStorage + "languagePreference", response.languagePreference ? response.languagePreference : "eng");
                        localStorage.setItem(MyConfig.project_LocalStorage + "_userLogin", true);
                        setUserTimeLocation(responseData.id)
                    } else if (response === GlobalMsgCons.passwordMismatch) {
                        toast.error("Username/Password does not match.", {
                            position: "top-center",
                            autoClose: 1500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else if (response === GlobalMsgCons.notExist) {
                        toast.error("Username not exist.", {
                            position: "top-center",
                            autoClose: 1500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                    else if (response === GlobalMsgCons.inActiveAccount) {
                        toast.error("Account has been deactivated, please contact admin.", {
                            position: "top-center",
                            autoClose: 1500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                }
            });
        }
    }

    function registerCheck() {
        let errors = {};
        if (username.trim() === '') {
            errors["username"] = "Please enter username.";
        } else if (!isUserNameValid(username)) {
            errors["username"] = "Please enter a valid username. Special characters and spaces are not allowed.";
        }

        setErrors(errors)
        if (Object.keys(errors).length === 0) {
            setSubmitLoader(true)
            let postJson = {
                reqId: getUuidv4(),
                orgId: MyConfig.communityServiceOrgId,
                roleType: GlobalMsgCons.roleTypeSurvivor,
                firstName: username.toLowerCase(),
                lastName: "",
                email: "",
                password: "",
                country: "NG",
                phone: "",
                signUpType: "custompin",
                requestFrom: MyConfig.requestFrom,
                loginId:username.toLowerCase() + MyConfig.userNameExt,
                platformFrom:"web"
            };


            console.log("postJson", postJson)
            const request = new ZiFetchRequest();
            request.setDomain(GlobalDomainCons.manageSetDomain);
            request.setEvent(GlobalEventCons.userSignUpViaEncareFlow);
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
                if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                    console.warn('Invalid Request. Please try again later');
                } else {
                    let responseData = JSON.parse(response.getMessage());
                    var response = responseData.response;
                    setSubmitLoader(false)
                    if (response === GlobalMsgCons.success) {
                        setGeneratedPin(responseData.userPin)
                        dispatch(setMultipleAuthData({
                            isLoggedIn: '1',
                            loginId: responseData.userId,
                            firstName: responseData.firstName,
                            lastName: responseData.lastName,
                            profilePhoto: "",
                            sessionId: responseData.sessionId,
                            loggedEmail: "",
                            userThemeColor: "",
                            languagePreference: response.languagePreference ? response.languagePreference : "eng",
                            activeStatus: responseData.status,
                            hoursSelected: responseData.hourFormat ? responseData.hourFormat : 12,
                            phone: responseData.phone ? responseData.phone : ""
                        }))


                        localStorage.setItem(MyConfig.project_LocalStorage + "languagePreference", response.languagePreference ? response.languagePreference : "eng");
                        localStorage.setItem(MyConfig.project_LocalStorage + "_userLogin", true);
                        setUserTimeLocation(responseData.id)
                    } else if (response === GlobalMsgCons.alreadyExist) {
                        toast.error("Username already exist.", {
                            position: "top-right",
                            autoClose: 1500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true
                        });
                    }
                }
            });
        }
    };

    function setUserTimeLocation(userId) {
        if (userId) {
            //userId: String, timezoneInfo: String,timezoneType:String
            let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
            let postJson = {
                reqId: getUuidv4(),
                userId: userId,
                timezoneInfo: timeZone,
                timezoneType: "dynamic",
                isForced: false
            };
            // console.log("postJson", postJson)
            const request = new ZiFetchRequest();
            request.setDomain(GlobalDomainCons.userSetDomain);
            request.setEvent(GlobalEventCons.setUserTimeZoneInfoSetEvent);
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
                if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                    console.warn('Invalid Request. Please try again later');
                } else {
                    // let responseData = JSON.parse(response.getMessage());
                    // console.log("setUserZone!", responseData)
                }
            });
        }
    }


    return <div className="pt-2">
        {
            loginId ? <div className="mx-2">
                {
                    generatedPin ? <React.Fragment>
                        <div className="display-pin mx-auto">{generatedPin}</div>
                        <div className="text-center my-2">Please save this pin code to complete and manage this report. <HelpAudio parentClass={""} audio={"93"} /></div>
                    </React.Fragment> : <div className="already-login">Thanks you are logged in, {firstName}. <HelpAudio parentClass={""} audio={"55"} /></div>
                }
            </div> : <React.Fragment>
                <ToastContainer />
                <div className="row mx-0">
                    <div className="col-sm-4">
                        <div className="form-group">
                            <label>{"Do you have a pin number?"} <span className='required'>*</span> <HelpAudio parentClass={""} audio={"16"} /></label>
                            <div>
                                <div className="btn-group">
                                    {
                                        yesOrNo.map((value, index) => {
                                            let { help } = value
                                            return <React.Fragment key={index}>
                                                <input type="radio" className="btn-check" name="btnradio" id={"existuser" + index} autoComplete="off" checked={existUser === value.value} onChange={() => {
                                                    setExistUser(value.value)
                                                }} />
                                                <label className={`btn btn${existUser === value.value ? "" : "-outline"}-primary`}>
                                                    <label className="mb-0" htmlFor={"existuser" + index}>{value.label}</label>
                                                    {help && <span className="px-2">{help}</span>}
                                                </label>
                                            </React.Fragment>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="form-group">
                            <label>{existUser === "Yes" ? "Name" : "Enter a short name that you can remember"} <span className='required'>*</span> <HelpAudio parentClass={""} audio={"119"} /></label>
                            <div className={`${focusPhone ? "error-shake" : ""}`}>
                                <input type="text" ref={inputRef} value={username} className={`form-control ${(focusPhone || errors["username"]) ? "is-invalid" : ""}`} placeholder={"Enter Name"} onChange={(e) => {
                                    let value = e.target.value.toLowerCase()
                                    setUsername(value)
                                }} />
                                {
                                    focusPhone ? <div className="invalid-feedback">{shortNameAlert  === "exist" ? "Name already exist" : "Please enter name"}</div> : <React.Fragment>
                                        {
                                            errors["username"] && <div className="invalid-feedback">{errors["username"]}</div>
                                        }
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                    </div>
                    {
                        existUser === "Yes" && <div className="col-sm-4">
                            <div className="form-group">
                                <label>{"PIN"} <span className='required'>*</span> <HelpAudio parentClass={""} audio={"20"} /></label>
                                <input type="number" value={pin} className={`form-control ${errors["pin"] ? "is-invalid" : ""}`} placeholder={"Enter PIN"} onChange={(e) => setPin(e.target.value)} />
                                {
                                    errors["pin"] && <div className="invalid-feedback">{errors["pin"]}</div>
                                }
                            </div>
                        </div>
                    }
                </div>
                {
                    existUser === "Yes" && <div className="text-center">
                        <button className="btn btn-primary" onClick={() => {
                            if (existUser === "Yes") {
                                loginCheck()
                            } else {
                                registerCheck()
                            }
                        }} disabled={submitLoader}>
                            {submitLoader && <i className="fa fa-spinner fa-spin"></i>}
                            <span className={`${submitLoader && ("pl-1")}`}>{existUser === "Yes" ? "Verify PIN" : "Generate PIN"}</span>
                            <span className="px-2"><HelpAudio parentClass={""} audio={"21"} /></span>
                        </button>
                    </div>
                }
                
            </React.Fragment>
        }
    </div>;
}
