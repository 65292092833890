import icon from '../assets/helpAudio/icon.png';
import record_1_22_23 from '../assets/helpAudio/1_22_23.mp3';
import record_2_7_13 from '../assets/helpAudio/2_7_13.mp3';
import record_4 from '../assets/helpAudio/4.mp3';
import record_5_12 from '../assets/helpAudio/5_12.mp3';
import record_6 from '../assets/helpAudio/6.mp3';
import record_8 from '../assets/helpAudio/8.mp3';
import record_9_11 from '../assets/helpAudio/9_11.mp3';
import record_10 from '../assets/helpAudio/10.mp3';
import record_14 from '../assets/helpAudio/14.mp3';
import record_15 from '../assets/helpAudio/15.mp3';
import record_16 from '../assets/helpAudio/16.mp3';
import record_17 from '../assets/helpAudio/17.mp3';
import record_18 from '../assets/helpAudio/18.mp3';
import record_19 from '../assets/helpAudio/19.mp3';
import record_20 from '../assets/helpAudio/20.mp3';
import record_21 from '../assets/helpAudio/21.mp3';
import record_24 from '../assets/helpAudio/24.mp3';
import record_25 from '../assets/helpAudio/25.mp3';
import record_26 from '../assets/helpAudio/26.mp3';
import record_27 from '../assets/helpAudio/27.mp3';
import record_28 from '../assets/helpAudio/28.mp3';
import record_29 from '../assets/helpAudio/29.mp3';
import record_30 from '../assets/helpAudio/30.mp3';
import record_31 from '../assets/helpAudio/31.mp3';
import record_33 from '../assets/helpAudio/33.mp3';
import record_36 from '../assets/helpAudio/36.mp3';
import record_36_re from '../assets/helpAudio/36_re.mp3';
import record_37 from '../assets/helpAudio/37.mp3';
import record_38 from '../assets/helpAudio/38.mp3';
import record_39 from '../assets/helpAudio/39.mp3';
import record_40 from '../assets/helpAudio/40.mp3';
import record_41 from '../assets/helpAudio/41.mp3';
import record_42 from '../assets/helpAudio/42.mp3';
import record_43 from '../assets/helpAudio/43.mp3';
import record_44 from '../assets/helpAudio/44.mp3';
import record_45 from '../assets/helpAudio/45.mp3';
import record_46 from '../assets/helpAudio/46.mp3';
import record_47 from '../assets/helpAudio/47.mp3';
import record_48 from '../assets/helpAudio/48.mp3';
import record_49 from '../assets/helpAudio/49.mp3';
import record_50 from '../assets/helpAudio/50.mp3';
import record_51 from '../assets/helpAudio/51.mp3';
import record_52 from '../assets/helpAudio/52.mp3';
import record_53 from '../assets/helpAudio/53.mp3';
import record_54 from '../assets/helpAudio/54.mp3';
import record_55 from '../assets/helpAudio/55.mp3';
import record_66 from '../assets/helpAudio/66.mp3';
import record_67 from '../assets/helpAudio/67.mp3';
import record_69_72 from '../assets/helpAudio/69_72.mp3';
import record_70 from '../assets/helpAudio/70.mp3';
import record_71 from '../assets/helpAudio/71.mp3';
import record_73 from '../assets/helpAudio/73.mp3';
import record_93 from '../assets/helpAudio/93.mp3';
import record_75 from '../assets/helpAudio/75.mp3';
import record_76 from '../assets/helpAudio/76.mp3';
import record_77 from '../assets/helpAudio/77.mp3';
import record_78 from '../assets/helpAudio/78.mp3';
import record_79 from '../assets/helpAudio/79.mp3';
import record_80 from '../assets/helpAudio/80.mp3';
import record_82 from '../assets/helpAudio/82.mp3';
import record_83 from '../assets/helpAudio/83.mp3';
import record_84 from '../assets/helpAudio/84.mp3';
import record_85 from '../assets/helpAudio/85.mp3';
import record_85_mismatch from '../assets/helpAudio/85_mismatch.mp3';
import record_86 from '../assets/helpAudio/86.mp3';
import record_87 from '../assets/helpAudio/87.mp3';
import record_88 from '../assets/helpAudio/88.mp3';
import record_95 from '../assets/helpAudio/95.mp3';
import record_96 from '../assets/helpAudio/96.mp3';
import record_97 from '../assets/helpAudio/97.mp3';
import record_98 from '../assets/helpAudio/98.mp3';
import record_99 from '../assets/helpAudio/99.mp3';
import record_100 from '../assets/helpAudio/100.mp3';
import record_102 from '../assets/helpAudio/102.mp3';
import record_103 from '../assets/helpAudio/103.mp3';
import record_104 from '../assets/helpAudio/104.mp3';
import record_105 from '../assets/helpAudio/105.mp3';
import record_106 from '../assets/helpAudio/106.mp3';
import record_107 from '../assets/helpAudio/107.mp3';
import record_108 from '../assets/helpAudio/108.mp3';
import record_109 from '../assets/helpAudio/109.mp3';
import record_110 from '../assets/helpAudio/110.mp3';
import record_111 from '../assets/helpAudio/111.mp3';
import record_112 from '../assets/helpAudio/112.mp3';
import record_113 from '../assets/helpAudio/113.mp3';
import record_114 from '../assets/helpAudio/114.mp3';
import record_115 from '../assets/helpAudio/115.mp3';
import record_116 from '../assets/helpAudio/116.mp3';
import record_117 from '../assets/helpAudio/117.mp3';
import record_118 from '../assets/helpAudio/118.mp3';
import record_119 from '../assets/helpAudio/119.mp3';
import record_120 from '../assets/helpAudio/120.mp3';
import record_121 from '../assets/helpAudio/121.mp3';
import record_122 from '../assets/helpAudio/122.mp3';
import record_123 from '../assets/helpAudio/123.mp3';

let currentAudio;
export default function HelpAudio(props) {
    let { audio, parentClass} = props;

    function playAudio() {
        let sound = "";

        switch (audio) {
            case "1_22_23":
                sound = record_1_22_23
                break;
            case "2_7_13":
                sound = record_2_7_13
                break;
            case "4":
                sound = record_4
                break;
            case "5_12":
                sound = record_5_12
                break;
            case "6":
                sound = record_6
                break;
            case "8":
                sound = record_8
                break;
            case "9_11":
                sound = record_9_11
                break;
            case "10":
                sound = record_10
                break;
            case "14":
                sound = record_14
                break;
            case "15":
                sound = record_15
                break;
            case "16":
                sound = record_16
                break;
            case "17":
                sound = record_17
                break;
            case "18":
                sound = record_18
                break;
            case "19":
                sound = record_19
                break;
            case "20":
                sound = record_20
                break;
            case "21":
                sound = record_21
                break;
            case "24":
                sound = record_24
                break;
            case "25":
                sound = record_25
                break;
            case "26":
                sound = record_26
                break;
            case "27":
                sound = record_27
                break;
            case "28":
                sound = record_28
                break;
            case "29":
                sound = record_29
                break;
            case "30":
                sound = record_30
                break;
            case "31":
                sound = record_31
                break;
            case "33":
                sound = record_33
                break;
            case "36":
                sound = record_36
                break;
            case "36_re":
                sound = record_36_re
                break;
            case "37":
                sound = record_37
                break;
            case "38":
                sound = record_38
                break;
            case "39":
                sound = record_39
                break;
            case "40":
                sound = record_40
                break;
            case "41":
                sound = record_41
                break;
            case "42":
                sound = record_42
                break;
            case "43":
                sound = record_43
                break;
            case "44":
                sound = record_44
                break;
            case "45":
                sound = record_45
                break;
            case "46":
                sound = record_46
                break;
            case "47":
                sound = record_47
                break;
            case "48":
                sound = record_48
                break;
            case "49":
                sound = record_49
                break;
            case "50":
                sound = record_50
                break;
            case "51":
                sound = record_51
                break;
            case "52":
                sound = record_52
                break;
            case "53":
                sound = record_53
                break;
            case "54":
                sound = record_54
                break;
            case "55":
                sound = record_55
                break;
            case "66":
                sound = record_66
                break;
            case "67":
                sound = record_67
                break;
            case "69_72":
                sound = record_69_72
                break;
            case "70":
                sound = record_70
                break;
            case "71":
                sound = record_71
                break;
            case "73":
                sound = record_73
                break;
            case "93":
                sound = record_93
                break;
            case "75":
                sound = record_75
                break;
            case "76":
                sound = record_76
                break;
            case "77":
                sound = record_77
                break;
            case "78":
                sound = record_78
                break;
            case "79":
                sound = record_79
                break;
            case "80":
                sound = record_80
                break;
            case "82":
                sound = record_82
                break;
            case "83":
                sound = record_83
                break;
            case "84":
                sound = record_84
                break;
            case "85":
                sound = record_85
                break;
            case "85_mismatch":
                sound = record_85_mismatch
                break;
            case "86":
                sound = record_86
                break;
            case "87":
                sound = record_87
                break;
            case "88":
                sound = record_88
                break;
            case "95":
                sound = record_95
                break;
            case "96":
                sound = record_96
                break;
            case "97":
                sound = record_97
                break;
            case "98":
                sound = record_98
                break;
            case "99":
                sound = record_99
                break;
            case "100":
                sound = record_100
                break;
            case "102":
                sound = record_102
                break;
            case "103":
                sound = record_103
                break;
            case "104":
                sound = record_104
                break;
            case "105":
                sound = record_105
                break;
            case "106":
                sound = record_106
                break;
            case "107":
                sound = record_107
                break;
            case "108":
                sound = record_108
                break;
            case "109":
                sound = record_109
                break;
            case "110":
                sound = record_110
                break;
            case "111":
                sound = record_111
                break;
            case "112":
                sound = record_112
                break;
            case "113":
                sound = record_113
                break;
            case "114":
                sound = record_114
                break;
            case "115":
                sound = record_115
                break;
            case "116":
                sound = record_116
                break;
            case "117":
                sound = record_117
                break;
            case "118":
                sound = record_118
                break;
            case "119":
                sound = record_119
                break;
            case "120":
                sound = record_120
                break;
            case "121":
                sound = record_121
                break;
            case "122":
                sound = record_122
                break;
            case "123":
                sound = record_123
                break;
        }
        if (sound) {
            if(currentAudio) {
                currentAudio.pause()
            }
            currentAudio = new Audio(sound);
            currentAudio.play();
        }
    }

    return <div className={`help-audio-circle ${parentClass}`} onClick={(e) => {
        e.stopPropagation()
        playAudio()
    }}>
        <img src={icon} className='img-fluid'/>
    </div>;
}
