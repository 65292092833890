import React, { useEffect, useRef, useState } from "react";
import ReactTooltip from 'react-tooltip';
import { ToastContainer, toast } from 'react-toastify';
import DropDown from "../../../../components/DropDown";
import MyConfig from "../../../../config/MyConfig";
import { useDispatch, useSelector } from "react-redux";
import GlobalMsgCons from "../../../../config/GlobalMsgCons";
import { getGrpcClient, getUuidv4, getPageText, getValidLabels, getDefaultValidLabels, redEscape, getFile, getPageEventName, accumulation, encareStoreData, readEncareStoreData, checkMobileView } from "../../../../config/Common";
import { ZiFetchRequest } from "../../../../protobuf/generated/ZiFetchService_grpc_web_pb";
import GlobalDomainCons from "../../../../config/GlobalDomainCons";
import GlobalEventCons from "../../../../config/GlobalEventCons";
import Recorder from "../../../../components/Recorder";
import MyConstant from "../../../../config/MyConstant";
import FileUpload from "../../../../components/FileUpload";
import Modal from "../../../../components/Modal";
import logoImg from '../../../../assets/logo-transparent.png'
import PINVerify from "./PINVerify";
import HelpAudio from "../../../../components/HelpAudio";
import { setMultipleAuthData, setMultipleReportHereData } from "../../../../redux/actions";

let relationShipOption = MyConfig.communityServiceRelationShip;
let communityMemberDistanceOption = MyConfig.communityMemberDistance;
let formOption = MyConfig.communityServiceFormOption;
let emojiOption = MyConfig.communityServiceEmojiOption;
let genderType = MyConfig.csEventGender
let genderCategory = MyConfig.csEventGenderCategory
let ageRangeOption = MyConfig.csEventAgeRange
let yesOrNo = [
    { value: "Yes", label: "Yes", help: <HelpAudio parentClass={""} audio={"17"} /> },
    { value: "No", label: "No", help: <HelpAudio parentClass={""} audio={"18"} /> },
]
const pageNameAcc = "communityServiceEventPage"

let timeoutId;
let intervalId;
function CommunityServiceEvent(props) {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.authReducer);
    let { loginId, activeCompany, activeUserType, firstName, lastName, loggedEmail, phone } = auth;
    let { eventModal, modalClose, sendCommunityServiceEvent, fromChannelId } = props;
    let [shortName, setShortName] = useState("")
    let [shortNameAlert, setShortNameAlert] = useState("")

    let [tempSessionId, setTempSessionId] = useState("")
    let [infoMessage, setInfoMessage] = useState(true)
    let [loginMessage, setLoginMessage] = useState(false)
    let [formType, setFormType] = useState("form")
    let [emoji, setEmoji] = useState("")
    let [recordingFile, setRecordingFile] = useState({})
    let [preDefinedMessage, setPreDefinedMessage] = useState("")
    let [serviceType, setServiceType] = useState([])
    let [selectedService, setSelectedService] = useState("")
    let [reportName, setReportName] = useState("")
    let [focusPhone, setFocusPhone] = useState(false)
    let [sendChannelId, setSendChannelId] = useState("")
    let [ageRange, setAgeRange] = useState([])

    let [levelOfUrgency, setLevelOfUrgency] = useState(5)
    let [requesterName, setRequesterName] = useState("")
    let [requesterPhone, setRequesterPhone] = useState("")
    let [requesterPhoneCode, setRequesterPhoneCode] = useState(234)
    let [requesterCountryCode, setRequesterCountryCode] = useState("NG")
    let [requesterEmail, setRequesterEmail] = useState("")
    let [requesterReached, setRequesterReached] = useState("")
    let [requesterGender, setRequesterGender] = useState("Male")
    let [requesterGenderCategory, setRequesterGenderCategory] = useState("")
    let [requesterAgeRange, setRequesterAgeRange] = useState("")
    let [requesterLatitude, setRequesterLatitude] = useState(0)
    let [requesterLongitude, setRequesterLongitude] = useState(0)
    let [requesterAddress, setRequesterAddress] = useState("")
    let [communityMemberName, setCommunityMemberName] = useState("")
    let [communityMemberAddress, setCommunityMemberAddress] = useState("")
    let [communityMemberContactAddress, setCommunityMemberContactAddress] = useState("")
    let [communityMemberReached, setCommunityMemberReached] = useState("")

    let [communityMemberPhoneCode, setCommunityMemberPhoneCode] = useState(234)
    let [communityMemberCountryCode, setCommunityMemberCountryCode] = useState("NG")
    let [communityMemberEmail, setCommunityMemberEmail] = useState("")

    let [relationshipToSurvivor, setRelationshipToSurvivor] = useState("")
    let [communityMemberDistance, setCommunityMemberDistance] = useState("")
    let [submitLoader, setSubmitLoader] = useState(false)
    let [questionList, setQuestionList] = useState([])
    let [answer, setAnswer] = useState({})
    let [errors, setErrors] = useState({})
    let [serviceSubType, setSubServiceType] = useState([])
    let [selectedSubService, setSubSelectedService] = useState("")
    let [requestPincode, setRequestPincode] = useState("")
    let [requesterCityOrState, setRequesterCityOrState] = useState("")
    let [pageText, setPageText] = useState(null)
    let [documentJson, setDocumentJson] = useState([])
    let [documentFiles, setDocumentFiles] = useState([])
    let [userType, setUserType] = useState(GlobalMsgCons.roleTypeSurvivor)

    let [inactivity, setInactivity] = useState(false)
    let [inactivityTimer, setInactivityTimer] = useState(60)
    let reportHereFormRef = useRef("");

    const preDefinedMessageOption = [
        { label: getValidLabels(pageText, 'childAbuseTxt'), value: "Child Abuse", typeOfViolence: "Child Abuse", help: <HelpAudio parentClass={""} audio={"25"} /> },
        { label: getValidLabels(pageText, 'domesticViolenceTxt'), value: "Domestic Violence", typeOfViolence: "Domestic Violence", help: <HelpAudio parentClass={""} audio={"26"} /> },
        { label: getValidLabels(pageText, 'humanTraffickingTxt'), value: "Human Trafficking", typeOfViolence: "Human Trafficking", help: <HelpAudio parentClass={""} audio={"27"} /> },
        { label: getValidLabels(pageText, 'sexualViolenceTxt'), value: "Sexual Violence", typeOfViolence: "Sexual Violence", help: <HelpAudio parentClass={""} audio={"28"} /> },
        { label: getValidLabels(pageText, 'stalkingTxt'), value: "Stalking", typeOfViolence: "Stalking", help: <HelpAudio parentClass={""} audio={"29"} /> },
        { label: getValidLabels(pageText, 'technologyAssistedAbuseTxt'), value: "Technology Assisted Abuse", typeOfViolence: "Technology Assisted Abuse", help: <HelpAudio parentClass={""} audio={"30"} /> }
    ];

    emojiOption[0].label = getValidLabels(pageText, 'childAbuseTxt')
    emojiOption[1].label = getValidLabels(pageText, 'domesticViolenceTxt')
    emojiOption[2].label = getValidLabels(pageText, 'humanTraffickingTxt')
    emojiOption[3].label = getValidLabels(pageText, 'sexualViolenceTxt')
    emojiOption[4].label = getValidLabels(pageText, 'stalkingTxt')
    emojiOption[5].label = getValidLabels(pageText, 'technologyAssistedAbuseTxt')

    formOption[0].label = "Report Here"
    formOption[1].label = getValidLabels(pageText, 'emojiLabelTxt')
    formOption[2].label = getValidLabels(pageText, 'voiceRecordLabelTxt')
    // formOption[3].label = getValidLabels(pageText, 'predefinedMessageLabelTxt')

    genderType[0].label = getValidLabels(pageText, 'maleLabelTxt')
    genderType[1].label = getValidLabels(pageText, 'femaleLabelTxt')
    // genderType[2].label = getValidLabels(pageText, 'otherLabelTxt')

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                let { coords } = position;
                let { latitude, longitude } = coords;
                setRequesterLatitude(latitude)
                setRequesterLongitude(longitude)
            });
        }

        let tempSessionId = localStorage.getItem(MyConfig.project_LocalStorage + "tempSessionId")

        if (!tempSessionId) {
            tempSessionId = getUuidv4()
            localStorage.setItem(MyConfig.project_LocalStorage + "tempSessionId", tempSessionId)
        }

        setTempSessionId(tempSessionId)
        getServiceType()
        init()

    }, [])

    useEffect(() => {
        if (inactivity) {
            intervalId = setInterval(() => {
                if (inactivityTimer === 0) {
                    closeWindow("inactivity")
                    clearInterval(intervalId)
                } else {
                    setInactivityTimer(prev => prev - 1)
                }
            }, 1000);
        } else {
            clearInterval(intervalId);
        }

        return () => clearInterval(intervalId);
    }, [inactivity, inactivityTimer])

    useEffect(() => {
        //Inactivity check
        setUpInactivity()
    }, [
        formType,
        loginId,
        selectedService,
        selectedSubService,
        userType,
        communityMemberName,
        communityMemberCountryCode,
        communityMemberPhoneCode,
        communityMemberContactAddress,
        communityMemberEmail,
        communityMemberReached,
        relationshipToSurvivor,
        communityMemberDistance,
        requesterName,
        requesterGender,
        requesterAgeRange,
        requesterCountryCode,
        requesterPhone,
        requesterReached,
        requesterAddress,
        requesterEmail,
        requestPincode,
        requesterCityOrState,
        requesterGenderCategory
    ])

    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            let errors = checkValidation();
            setErrors(errors)
        }
    }, [selectedService, communityMemberReached, requesterReached, requesterAgeRange, requesterName, requesterPhone, requesterAddress, requesterEmail, communityMemberAddress, communityMemberContactAddress, communityMemberName, communityMemberEmail, relationshipToSurvivor, communityMemberDistance])

    useEffect(() => {
        if (!fromChannelId && loginId) {
            getCompanyDetails(loginId, MyConfig.communityServiceOrgId)
        }
    }, [loginId])

    useEffect(() => {
        if(submitLoader && sendChannelId) {
            save()
        }
    }, [loginId, sendChannelId])

    useEffect(() => {
        if (loginId) {
            setRequesterName(GlobalMsgCons.roleTypeSurvivor === userType ? firstName : "")
            setCommunityMemberName(GlobalMsgCons.roleTypeCommunityMember === userType ? firstName : "")
        }
    }, [loginId, firstName, userType])

    useEffect(() => {
        let ageRange = ageRangeOption;
        let ageRangeSelected = ""
        if (requesterGenderCategory === "Minor") {
            ageRange = []
            ageRangeSelected = "Under 18"
        } else if (requesterGenderCategory === "Elder") {
            ageRange = [
                { value: "61-70", label: "61-70" },
                { value: "Above 70", label: "Above 70" }
            ]

            ageRangeSelected = ["61-70", "Above 70"].includes(requesterAgeRange) ? requesterAgeRange : ""
        } else {
            ageRangeSelected = requesterAgeRange
        }

        //setRequesterAgeRange(ageRangeSelected)
        setAgeRange(ageRange)
    }, [requesterGenderCategory])

    function setUpInactivity() {
        setInactivity(false)
        clearTimeout(timeoutId)
        if (!infoMessage && loginId && formType !== "voice-record") {
            timeoutId = setTimeout(() => {
                setInactivity(true)
                setInactivityTimer(60)
            }, 10000)
        }
    }

    function formScrollListener() {
        setUpInactivity()
    }

    async function init() {
        let pageText = await getPageText('communityServiceEvent');
        setPageText(pageText)
    }

    function getCompanyDetails(userId, organizationId) {
        let postJson = { reqId: getUuidv4(), userId, organizationId };
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.companySetDomain);
        request.setEvent(GlobalEventCons.organizationSetEvent);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                response = JSON.parse(response.getMessage());
                if (response && response.organization) {
                    let { csRequestSendChannelId } = response
                    setSendChannelId(csRequestSendChannelId)
                }
            }
        })
    }

    function getUserProfile() {
        let postJson = { reqId: getUuidv4(), sessionId: '123', userId: loginId };
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.userSetDomain);
        request.setEvent(GlobalEventCons.userProfileSetEvent);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                var response = responseData.response;
                let { address } = response;

                if (address) {
                    setRequesterAddress(address)
                }
            }
        })
    }

    function getServiceType() {
        let postJson = { reqId: getUuidv4(), userId: loginId, orgId: activeCompany };
        console.log("postJson", postJson)
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.csServiceType);
        request.setEvent(GlobalEventCons.listSetEvent);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                let result = responseData.result;

                let serviceTypeOption = []
                Object.keys(result).map((key) => {
                    let { name, levelOfPriority } = result[key];
                    if(key !== "e9aa3549d3304f2ab263c9c5e1eee76a") {
                        serviceTypeOption.push({ label: name, value: key, data: { levelOfPriority } })
                    }
                })

                setServiceType(serviceTypeOption)
            }
        });
    }
    function getQuestion(serviceTypeId) {
        let postJson = { reqId: getUuidv4(), serviceTypeId, userId: loginId, orgId: activeCompany, };
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.csQuestion);
        request.setEvent(GlobalEventCons.listSetEvent);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                let result = responseData.result;
                setQuestionList(Object.values(result))
            }
        });
    }

    async function fileUpload(i, dataJson) {
        const postFileUpload = new FormData();
        postFileUpload.append('file' + (i + 1), dataJson.file);
        postFileUpload.append('fileName' + (i + 1), dataJson.fileName);
        postFileUpload.append('processType' + (i + 1), dataJson.processType);
        postFileUpload.append('docsId' + (i + 1), dataJson.docsId);
        return await fetch(MyConstant.keyList.apiURL + "uploads/" + dataJson.processType + "/" + dataJson.docsId + "/" + loginId, {
            method: "POST",
            mode: 'cors',
            redirect: 'follow',
            body: postFileUpload,
        }).then(
            function (response) {
                return response;
            }
        ).then(json => {
            return json.response;
        }).catch(error => console.warn(error));
    }

    function checkValidation() {
        let errors = {}
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        if (selectedService.trim() === "") {
            errors['selectedService'] = "Please select Type of Violence."
        }
        // if (requesterAgeRange.trim() === "") {
        //     errors['requesterAgeRange'] = "Please select age range."
        // }
        if (requesterReached.trim() === "") {
            errors['requesterReached'] = "Please select."
        }
        if (requesterName.trim() === "") {
            // errors["requesterName"] = "Please enter name."
        }
        if (requesterPhone.trim() === "" && userType === GlobalMsgCons.roleTypeSurvivor) {
            errors["requesterPhone"] = "Please enter phone."
        }
        if (requesterAddress.trim() === "") {
            errors["requesterAddress"] = "Please enter address."
        }
        if (requesterEmail.trim() !== "" && reg.test(requesterEmail.trim()) === false) {
            // errors["requesterEmail"] = getValidLabels(pageText, 'enterValidEmailErr_Msg')
        }

        if (userType === GlobalMsgCons.roleTypeCommunityMember) {
            if (communityMemberName.trim() === "") {
                errors["communityMemberName"] = "Please enter name."
            }
            if (communityMemberReached.trim() === "") {
                errors['communityMemberReached'] = "Please select."
            }
            if (communityMemberAddress.trim() === "") {
                errors["communityMemberAddress"] = "Please enter phone."
            }
            if (communityMemberContactAddress.trim() === "") {
                errors["communityMemberContactAddress"] = "Please enter address."
            }
            if (relationshipToSurvivor.trim() === "") {
                errors["relationshipToSurvivor"] = "Please select relationship."
            }
            if (communityMemberDistance.trim() === "") {
                errors["communityMemberDistance"] = "Please select distance."
            }
            if (communityMemberEmail.trim() !== "" && reg.test(communityMemberEmail.trim()) === false) {
                errors["communityMemberEmail"] = getValidLabels(pageText, 'enterValidEmailErr_Msg')
            }
        }



        return errors
    }

    async function addTempCSEventData() {
        let csEventData = await buildEventData()
        let wrapperData = {
            eventCaseNo: "",
            data: csEventData,
            receivedAt: 0,
            status: ""
        }
        // encareStoreData(loginId, "TempCSEventData", wrapperData, MyConfig.communityServiceOrgId, tempSessionId)

        let postJson = {
            reqId: getUuidv4(),
            orgId: MyConfig.communityServiceOrgId,
            csEventData: wrapperData,
            userId: loginId,
            tempSessionId
        };

        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.csEventSetDomain);
        request.setEvent(GlobalEventCons.addTempCSEventData);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                var response = responseData.response;
                if (response === GlobalMsgCons.success) {

                }
            }
        });
    }

    async function buildEventData(sendChannelId, submitType) {
        let formValue = ""
        if (formType === "emoji") {
            formValue = emoji
        } else if (formType === "voice-record") {
            formValue = JSON.stringify(recordingFile);
            await fileUpload(0, recordingFile);
        } else if (formType === "predefined-message") {
            formValue = preDefinedMessage
        }

        let filterSubviolenceName = "";
        let filterSubviolence = serviceSubType.filter((e) => { return e.value === selectedSubService });
        if (filterSubviolence && filterSubviolence.length > 0) {
            filterSubviolenceName = filterSubviolence[0].label
        }

        let serviceTypeDetails = serviceType.filter(option => option.value === selectedService);

        let fileList = [];
        for (var i = 0; i < documentJson.length; i++) {
            await sendPostJson(documentJson[i], i);
            let fileDetails = documentJson[i];
            fileList.push(JSON.stringify({
                origFileName: fileDetails.origFileName,
                fileName: fileDetails.fileName,
                docsId: fileDetails.docsId,
                processType: fileDetails.processType,
                fileType: fileDetails.fileType
            }));
        }

        let channelId = fromChannelId ? fromChannelId : sendChannelId

        if (channelId === undefined) {
            channelId = ""
        }
        let csEventData = {
            id: "",
            serviceTypeId: selectedService,
            serviceType: serviceTypeDetails.length > 0 ? serviceTypeDetails[0].label : "",
            requestName: reportName,
            levelOfUrgency: levelOfUrgency,
            requsterInfo: {
                name: requesterName ? requesterName : shortName,
                phone: requesterPhone,
                email: requesterEmail ? requesterEmail.trim() : "",
                gender: requesterGender,
                latitude: parseFloat(requesterLatitude),
                longitude: parseFloat(requesterLongitude),
                address: requesterAddress,
                pinCode: requestPincode,
                reachedHere: requesterReached,
                phoneCode: requesterPhoneCode.toString(),
                countryCode: requesterCountryCode,
                ageRange: requesterAgeRange,
                genderCategory: requesterGenderCategory,
                cityOrState: requesterCityOrState,
            },
            requestByMemberType: userType,
            communityMemberInfo: {
                name: communityMemberName,
                contactInfo: communityMemberAddress,
                relationshipToSurvivor: relationshipToSurvivor,
                phoneCode: communityMemberPhoneCode.toString(),
                countryCode: communityMemberCountryCode,
                email: communityMemberEmail.trim(),
                address: communityMemberContactAddress,
                survivorDistance: communityMemberDistance,
                reachedHere: communityMemberReached
            },
            listOfQuestion: {
                questions: questionList,
                serviceType: selectedService
            },
            listOfQuestionsResponse: {
                answer,
                serviceType: selectedService
            },
            createdAt: new Date().getTime(),
            createdBy: loginId,
            fromChannelId: channelId,
            survivorId: loginId,
            formType,
            formValue,
            subServiceType: filterSubviolenceName,
            subServiceTypeId: selectedSubService,
            fileList,
            reportFrom: "Encare",
            submitType: submitType ? submitType : "",
            platformFrom: checkMobileView() ? "mobile web" : "web"
        }

        return csEventData;
    }

    function setUserTimeLocation(userId) {
        if (userId) {
            //userId: String, timezoneInfo: String,timezoneType:String
            let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
            let postJson = {
                reqId: getUuidv4(),
                userId: userId,
                timezoneInfo: timeZone,
                timezoneType: "dynamic",
                isForced: false
            };
            // console.log("postJson", postJson)
            const request = new ZiFetchRequest();
            request.setDomain(GlobalDomainCons.userSetDomain);
            request.setEvent(GlobalEventCons.setUserTimeZoneInfoSetEvent);
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
                if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                    console.warn('Invalid Request. Please try again later');
                } else {
                    // let responseData = JSON.parse(response.getMessage());
                    // console.log("setUserZone!", responseData)
                }
            });
        }
    }

    function registerCheck() {
        let postJson = {
            reqId: getUuidv4(),
            orgId: MyConfig.communityServiceOrgId,
            roleType: GlobalMsgCons.roleTypeSurvivor,
            firstName: shortName.toLowerCase(),
            lastName: "",
            email: "",
            password: "",
            country: "NG",
            phone: "",
            signUpType: "custompin",
            requestFrom: MyConfig.requestFrom,
            loginId: shortName.toLowerCase() + MyConfig.userNameExt,
            platformFrom: "web"
        };
        console.log("postJson", postJson)
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.manageSetDomain);
        request.setEvent(GlobalEventCons.userSignUpViaEncareFlow);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                var response = responseData.response;
                if (response === GlobalMsgCons.success) {
                    dispatch(setMultipleReportHereData({
                        generatedPin: responseData.userPin
                    }))

                    dispatch(setMultipleAuthData({
                        isLoggedIn: '1',
                        loginId: responseData.userId,
                        firstName: responseData.firstName,
                        lastName: responseData.lastName,
                        profilePhoto: "",
                        sessionId: responseData.sessionId,
                        loggedEmail: "",
                        userThemeColor: "",
                        languagePreference: response.languagePreference ? response.languagePreference : "eng",
                        activeStatus: responseData.status,
                        hoursSelected: responseData.hourFormat ? responseData.hourFormat : 12,
                        phone: responseData.phone ? responseData.phone : ""
                    }))


                    localStorage.setItem(MyConfig.project_LocalStorage + "languagePreference", response.languagePreference ? response.languagePreference : "eng");
                    localStorage.setItem(MyConfig.project_LocalStorage + "_userLogin", true);
                    setUserTimeLocation(responseData.id)
                } else if (response === GlobalMsgCons.alreadyExist) {
                    toast.error("Name already exist.", {
                        position: "top-right",
                        autoClose: 1500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                    setSubmitLoader(false)
                    focusSignup("exist")
                }

            }
        });
    };

    async function save() {
        let errors = checkValidation();
        setErrors(errors)

        if (Object.keys(errors).length === 0 && loginId) {
            setSubmitLoader(true)

            let channelId = fromChannelId ? fromChannelId : sendChannelId
            let csEventData = await buildEventData(channelId, "")
            let wrapperData = {
                eventCaseNo: "",
                data: csEventData,
                receivedAt: 0,
                status: ""
            }
            sendCommunityServiceEvent(wrapperData, GlobalMsgCons.messageTypeCSEventRequest, channelId, userType === GlobalMsgCons.roleTypeSurvivor ? requesterReached : communityMemberReached)
            modalClose(false)
        } else if (Object.keys(errors).length === 0) {
            setSubmitLoader(true)
            registerCheck()
        } else {
            toast.error("Please fill all required fields.", {
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    async function exitSave(submitType) {
        let channelId = fromChannelId ? fromChannelId : sendChannelId
        let csEventData = await buildEventData(channelId, submitType)
        let wrapperData = {
            eventCaseNo: "",
            data: csEventData,
            receivedAt: 0,
            status: ""
        }

        localStorage.setItem(MyConfig.project_LocalStorage + "tempId", loginId)
        encareStoreData(loginId, "TempCSEventData", wrapperData, MyConfig.communityServiceOrgId, tempSessionId)
        sendCommunityServiceEvent(wrapperData, GlobalMsgCons.messageTypeCSEventRequest, channelId, userType === GlobalMsgCons.roleTypeSurvivor ? requesterReached : communityMemberReached)
    }

    async function sendPostJson(documentArray, i) {
        await uploadDocumentFiles(documentArray, i);
    }

    async function uploadDocumentFiles(documentArray, i) {
        let { docsId, processType, fileName, path } = documentArray
        const postFileUpload = new FormData();
        postFileUpload.append('file' + (i + 1), path);
        postFileUpload.append('fileName' + (i + 1), fileName);
        postFileUpload.append('processType' + (i + 1), processType);
        postFileUpload.append('docsId' + (i + 1), docsId);

        let userId = loginId ? loginId : tempSessionId

        if (loginId) {
            localStorage.removeItem(MyConfig.project_LocalStorage + "tempFile_" + docsId);
        }
        let result = await fetch(MyConstant.keyList.apiURL + "uploads/" + documentArray.processType + "/" + documentArray.docsId + "/" + userId, {
            method: "POST",
            mode: 'cors',
            redirect: 'follow',
            body: postFileUpload,
        }).then(
            function (response) {
                return response.json();
            }
        ).then(json => {
            return json.response;
        }).catch(error => console.warn(error));

        return result
    }

    function getAnswerInput(data) {
        let { id, question, typeOfQuestion, optionList } = data;
        let str;

        switch (typeOfQuestion) {
            case "text":
                str = <input type="text" className={`form-control`} onChange={(e) => {
                    let cloneAnswer = { ...answer };
                    cloneAnswer[id] = e.target.value;
                    setAnswer(cloneAnswer);
                }} />
                break;
            case "single":
                str = <div>
                    <div className="btn-group">
                        {
                            optionList.map((value, index) => {
                                return <React.Fragment key={index}>
                                    <input type="radio" className="btn-check" name="btnradio" id={"src_" + id + "_" + index} autoComplete="off" checked={requesterGender === value} onChange={() => {
                                        let cloneAnswer = { ...answer };
                                        cloneAnswer[id] = value;
                                        setAnswer(cloneAnswer);
                                    }} />
                                    <label className={`btn btn${answer[id] === value ? "" : "-outline"}-primary`} htmlFor={"src_" + id + "_" + index} dangerouslySetInnerHTML={{ __html: value }}></label>
                                </React.Fragment>
                            })
                        }
                    </div>
                </div>
                break;
        }
        return str;
    }

    function setTypeOfViolence(selected) {
        let serviceTypeValue = serviceType.filter(option => option.label === selected)
        if (serviceTypeValue.length > 0) {
            setSelectedService(serviceTypeValue[0].value)
            getQuestion(serviceTypeValue[0].value)
            setLevelOfUrgency(parseInt(serviceTypeValue[0].data.levelOfPriority))
            subViolenceBasedList(serviceTypeValue[0].value)
        }
    }

    function saveAudio(type, blob) {
        let uid = getUuidv4();
        let audioFile = new File([blob], "record_" + uid + ".mp3")
        let fileUrl = URL.createObjectURL(audioFile);
        let file = audioFile;
        let filename = audioFile.name;
        let ext = filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
        let fileObj = {
            file: file,
            origFileName: filename,
            fileUrl: fileUrl,
            fileName: uid + "." + ext,
            docsId: uid,
            processType: "community-record",
            fileType: ext,
        };
        setRecordingFile(fileObj)
    }


    function subViolenceBasedList(servicetypeid) {
        let postJson = { reqId: getUuidv4(), userId: activeUserType === GlobalMsgCons.roleTypeCbo ? "" : loginId, orgId: activeCompany, serviceTypeId: servicetypeid }
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.csSubServiceType);
        request.setEvent(GlobalEventCons.listSetEvent);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                if (responseData) {
                    let data = responseData.result;
                    let optionResult = Object.keys(data).map(e => {
                        let { name, id } = data[e];
                        return { label: name, value: id, details: data[e] }
                    })
                    setSubServiceType([...optionResult])
                }
            }
        });
    }

    function getTitle() {
        let str = <span>{getValidLabels(pageText, 'reportHereHeaderTxt')} <HelpAudio parentClass={""} audio={"10"} /></span>;

        if (infoMessage) {
            str = <span>Leave this site safely <HelpAudio parentClass={""} audio={"8"} /></span>
        } else if (loginMessage) {
            str = "Login"
        }

        return str
    }

    function focusSignup(type) {
        setFocusPhone(true)
        setShortNameAlert(type ? type : "")
        reportHereFormRef.current.scrollTop = 0
    }

    function closeWindow(submitType) {
        if (loginId) {
            exitSave(submitType)
        }
        redEscape()
    }
    function getBody() {
        let str;

        if (infoMessage) {
            str = <div>
                <div className="d-flex m-2">
                    <div>
                        <button className="btn escape-button" onClick={() => {
                            accumulation(pageNameAcc, getPageEventName("communityServiceEventPage", "escapeBtnClicked"), activeCompany, loginId, props.grpcClient)
                            redEscape()
                        }}><i className="fas fa-exclamation-triangle"></i> Escape <HelpAudio parentClass={""} audio={"5_12"} /></button>
                    </div>
                    <div className="d-flex" style={{ width: 125, margin: "auto" }}>
                        <img src={logoImg} className="img-fluid" />
                        <HelpAudio parentClass={"mx-2"} audio={"2_7_13"} />
                    </div>
                </div>
                <div className="text-center my-5" style={{ fontSize: 18 }}>
                    Quickly leave this site by clicking on the red escape button. Safety first. <HelpAudio parentClass={""} audio={"6"} />
                </div>
            </div>
        } else {
            str = <div>
                {
                    inactivity && <div className="inactivity-timer-alert">
                        We close this window, if you have any activity in <span>{inactivityTimer}.</span>
                    </div>
                }

                <div className="m-2 row">
                    <div className="col-4 px-0">
                        <button className="btn escape-button" onClick={() => {
                            closeWindow("escape")
                            accumulation(pageNameAcc, getPageEventName("communityServiceEventPage", "escapeBtnClicked"), activeCompany, loginId, props.grpcClient)
                        }}><i className="fas fa-exclamation-triangle"></i> Escape <HelpAudio parentClass={""} audio={"5_12"} /></button>
                    </div>
                    <div className="col-4">
                        <div className="d-flex" style={{ width: 125, margin: "auto", maxWidth: "100%" }}>
                            <img src={logoImg} className="img-fluid" />
                            <HelpAudio parentClass={"mx-2"} audio={"2_7_13"} />
                        </div>
                    </div>
                    <div className="col-4 px-0 text-right">
                        <button type="button" className="btn btn-primary" onClick={() => {
                            accumulation(pageNameAcc, getPageEventName("communityServiceEventPage", "doneBtnClicked"), activeCompany, loginId, props.grpcClient)
                            save()
                        }} disabled={submitLoader}>
                            {submitLoader && <i className="fa fa-spinner fa-spin"></i>}
                            <span className={`${submitLoader && ("pl-1")}`}>
                                {"Done"} <HelpAudio parentClass={""} audio={"14"} />
                            </span>
                        </button>
                    </div>
                </div>
                <div className="custom-scrollbar" style={{ height: "65vh", overflowY: "auto" }} ref={reportHereFormRef} onScroll={() => formScrollListener()} onClick={() => formScrollListener()}>
                    <div className="my-3 ">
                        Safety first. You will be logged out after 60 seconds of inactivity. <HelpAudio parentClass={""} audio={"15"} />
                    </div>
                    <PINVerify shortNameAlert={shortNameAlert} focusPhone={focusPhone} setFocusPhone={setFocusPhone} setShortName={setShortName} pageText={pageText} {...props} />
                    <hr />

                    <div className="row mx-0 mb-2">
                        <div className="col-sm-12">
                            {/* <label>{getValidLabels(pageText, 'typeofFormLabelTxt')}</label> */}
                            <div>
                                <div className="btn-group flex-wrap">
                                    {
                                        formOption.map((value, index) => {
                                            let { label, img, help } = value
                                            return <React.Fragment key={index}>
                                                <input type="radio" className="btn-check" name="btnradio-formtype" id={"formtype" + index} autoComplete="off" checked={formType === value.value} onChange={() => {
                                                    accumulateEvent(value.value)
                                                    if (loginId || shortName) {
                                                        setFormType(value.value)
                                                        if (value.value !== "form") {
                                                            setLevelOfUrgency(10)
                                                        }
                                                        ReactTooltip.rebuild();
                                                    } else {
                                                        focusSignup()
                                                    }
                                                }} />
                                                <label className={`btn btn${formType === value ? "" : "-outline"}-primary d-flex align-items-center`}>
                                                    <label className="mb-0" htmlFor={"formtype" + index}>
                                                        {
                                                            img ? <img src={value.img} alt={value.label} style={{ width: 60 }} /> : label
                                                        }
                                                    </label>
                                                    {
                                                        help && <span className="px-2">{help}</span>
                                                    }
                                                </label>

                                            </React.Fragment>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        formType === "emoji" && <React.Fragment>
                            <div className="row mx-0">
                                <div className="col-sm-12">
                                    <label>{getValidLabels(pageText, 'emojiLabelTxt')}</label>
                                    <div>
                                        <div className="btn-group flex-wrap">
                                            {
                                                emojiOption.map((value, index) => {
                                                    let { help } = value
                                                    return <React.Fragment key={index}>
                                                        <input type="radio" className="btn-check" name="btnradio-emoji" id={"emoji" + index} autoComplete="off" checked={emoji === value.value} onChange={() => {
                                                            accumulateEvent(value.value)
                                                            if (loginId || shortName) {
                                                                setEmoji(value.value)
                                                                setTypeOfViolence(value.typeOfViolence)
                                                            } else {
                                                                focusSignup()
                                                            }
                                                        }} />
                                                        <label className={`btn btn${emoji === value.value ? "" : "-outline"}-primary`} data-tip={value.label}>
                                                            <label className="mb-0" htmlFor={"emoji" + index}><img src={value.img} alt={value.label} style={{ width: 80 }} /></label>
                                                            <span className="px-2">{help}</span>
                                                        </label>
                                                    </React.Fragment>
                                                })
                                            }
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {
                                emoji && <div className="row mx-0 my-2">
                                    <div className="col-sm-12">
                                        <label>{"Selected Emoji"}</label>

                                        <div>
                                            <div className="badge badge-success emoji-selected-size">{emojiOption.filter(option => option.value === emoji)[0].label}</div>
                                        </div>
                                    </div>
                                </div>
                            }

                        </React.Fragment>
                    }
                    {
                        formType === "form" && <div className="row mx-0 mb-2">
                            <div className="col-sm-12">
                                <label>{getValidLabels(pageText, 'typeofViolenceLabelTxt')}</label>

                                <span className="ml-1">
                                    <HelpAudio parentClass={""} audio={"31"} />
                                </span>
                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                    {
                                        preDefinedMessageOption.map((value, index) => {
                                            let { help } = value
                                            return <div className="mr-2" key={index} onClick={() => { accumulateEvent(value.value) }}>
                                                <input type="radio" className="btn-check" name="btnradio-predefined" id={"predefined" + index} autoComplete="off" checked={preDefinedMessage === value.value} onChange={() => {
                                                    if (loginId || shortName) {
                                                        setPreDefinedMessage(value.value)
                                                        setTypeOfViolence(value.typeOfViolence)
                                                    } else {
                                                        focusSignup()
                                                    }
                                                }} />
                                                <label className={`btn btn${preDefinedMessage === value.value ? "" : "-outline"}-primary`}>
                                                    <label className="mb-0" htmlFor={"predefined" + index}>{value.label}</label>
                                                    <span className="px-2">{help}</span>
                                                </label>
                                            </div>
                                        })
                                    }
                                </div>
                                {
                                    formType !== "voice-record" && errors["selectedService"] && <div className="invalid-feedback d-block">{errors["selectedService"]}</div>
                                }
                            </div>
                        </div>
                    }
                    {
                        formType === "voice-record" && <div>
                            <div>
                                <Recorder
                                    maxTimeText={"Maximum 5 minutes"}
                                    autoSave={true}
                                    autoStart={true}
                                    maxTime={300000}
                                    saveAudio={saveAudio}
                                    loginId={loginId}
                                    activeCompany={activeCompany}
                                    grpcClient={props.grpcClient}
                                    helpAudio={true}
                                />
                            </div>
                        </div>
                    }
                    {formType === "voice-record" && <>

                        <hr />
                        <div className="row mx-0 mb-2">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>{getValidLabels(pageText, 'typeofViolenceLabelTxt')}</label><span className='required'>*</span><span className="ml-1">
                                        <HelpAudio parentClass={""} audio={"31"} />
                                    </span>
                                    <DropDown
                                        value={serviceType.filter(option => option.value === selectedService)}
                                        onChange={(e) => {
                                            accumulation(pageNameAcc, getPageEventName("communityServiceEventPage", accumulateEvent(e.label)), activeCompany, loginId, props.grpcClient)
                                            if (loginId || shortName) {
                                                setSelectedService(e.value);
                                                getQuestion(e.value)
                                                setLevelOfUrgency(parseInt(e.data.levelOfPriority))
                                                subViolenceBasedList(e.value)
                                            } else {
                                                focusSignup()
                                            }
                                        }}
                                        options={serviceType}
                                        isDisabled={false}
                                        isMulti={false}
                                        placeholder={getValidLabels(pageText, 'selectTypeofViolencePhTxt')}
                                        error={errors['selectedService'] ? true : false}
                                    />
                                    {
                                        errors["selectedService"] && <div className="invalid-feedback d-block">{errors["selectedService"]}</div>
                                    }
                                </div>
                            </div>

                            {/* <div className="col-sm-6">
                            <div className="form-group">
                                <label>{getValidLabels(pageText, 'typeofSubViolenceLabelTxt')}</label>
                                <DropDown
                                    value={serviceSubType.filter(option => option.value === selectedSubService)}
                                    onChange={(e) => {
                                        accumulation(pageNameAcc, getPageEventName("communityServiceEventPage", "typeofSubViolenceSelected"), activeCompany, loginId, props.grpcClient)
                                        setSubSelectedService(e.value)
                                        setLevelOfUrgency(parseInt(e.details.levelOfPriority))
                                    }}
                                    options={serviceSubType}
                                    isDisabled={false}
                                    isMulti={false}
                                    placeholder={getValidLabels(pageText, 'selectTypeofSubViolencePhTxt')}
                                    error={errors['selectedSubService'] ? true : false}
                                />
                                {
                                    errors["selectedSubService"] && <div className="invalid-feedback d-block">{errors["selectedSubService"]}</div>
                                }
                            </div>
                        </div> */}
                        </div>
                    </>}


                    <hr />
                    {/* <div className="row mx-0 mb-2">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label>{"Please Select"}</label>
                                <div>
                                    <div className="btn-group">
                                        {
                                            MyConfig.communityServiceRoles.map((value, index) => {
                                                return <React.Fragment key={index}>
                                                    <input type="radio" className="btn-check" name="btnradio" id={"rolebtnradio" + index} autoComplete="off" checked={userType === value.value} onChange={() => {
                                                        accumulateEvent(value.value)
                                                        if (loginId || shortName) {
                                                            setUserType(value.value)

                                                            if (value.value === GlobalMsgCons.roleTypeSurvivor) {
                                                                if (requesterPhone.trim() === "") {
                                                                    setRequesterPhone(phone)
                                                                }

                                                                if (communityMemberAddress === phone) {
                                                                    setCommunityMemberAddress("")
                                                                }
                                                            } else if (value.value === GlobalMsgCons.roleTypeCommunityMember) {
                                                                if (communityMemberAddress.trim() === "") {
                                                                    setCommunityMemberAddress(phone)
                                                                }

                                                                if (requesterPhone === phone) {
                                                                    setRequesterPhone("")
                                                                }
                                                            }
                                                        } else {
                                                            focusSignup()
                                                        }
                                                    }} />
                                                    <label className={`btn btn${userType === value.value ? "" : "-outline"}-primary`} htmlFor={"rolebtnradio" + index}>{value.label}</label>
                                                </React.Fragment>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    {
                        (userType === GlobalMsgCons.roleTypeCommunityMember) && <React.Fragment>
                            <h6>{"Community Member Information"}</h6>
                            <div className="row mx-0 mb-2">
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label>{getValidLabels(pageText, 'nameLabelTxt')} <span className='required'>*</span></label>
                                        <input type="text" value={communityMemberName} className={`form-control ${errors["communityMemberName"] ? "is-invalid" : ""}`} placeholder={getValidLabels(pageText, 'enterNamePhTxt')} onChange={(e) => setCommunityMemberName(e.target.value)} />
                                        {
                                            errors["communityMemberName"] && <div className="invalid-feedback">{errors["communityMemberName"]}</div>
                                        }
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label>{getValidLabels(pageText, 'phoneLabelTxt')} <span className='required'>*</span></label>
                                        <div className='row mx-0'>
                                            {/* <div className='col-4 px-0'>
                                                <CountryCodeSelect
                                                    countryCode={communityMemberCountryCode}
                                                    onChange={(obj) => {
                                                        setCommunityMemberPhoneCode(obj.phoneCode)
                                                        setCommunityMemberCountryCode(obj.value)
                                                    }}
                                                    pageText={pageText}
                                                />
                                            </div> */}
                                            <div className='col-12 px-0'>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text">{communityMemberPhoneCode}</span>
                                                    </div>
                                                    <input type="number" value={communityMemberAddress} className={`form-control ${errors["communityMemberAddress"] ? "is-invalid" : ""}`} placeholder={getValidLabels(pageText, 'contactInfoLabelTxt')} onChange={(e) => setCommunityMemberAddress(e.target.value)} />
                                                    {
                                                        errors["communityMemberAddress"] && <div className="invalid-feedback">{errors["communityMemberAddress"]}</div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>{"Can you be reached here?"} <span className='required'>*</span></label>
                                        <div>
                                            <div className="btn-group">
                                                {
                                                    yesOrNo.map((value, index) => {
                                                        return <React.Fragment key={index}>
                                                            <input type="radio" className="btn-check" name="btnradio" id={"reachedcmbtnradio" + index} autoComplete="off" checked={communityMemberReached === value.value} onChange={() => {
                                                                setCommunityMemberReached(value.value)
                                                            }} />
                                                            <label className={`btn btn${communityMemberReached === value.value ? "" : "-outline"}-primary`} htmlFor={"reachedcmbtnradio" + index}>{value.label}</label>
                                                        </React.Fragment>
                                                    })
                                                }
                                            </div>
                                            {
                                                errors["communityMemberReached"] && <div className="invalid-feedback d-block">{errors["communityMemberReached"]}</div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mx-0 mb-2">
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label>{getValidLabels(pageText, 'addressLabelTxt')} <span className='required'>*</span></label>
                                        <textarea type="text" value={communityMemberContactAddress} className={`form-control ${errors["communityMemberContactAddress"] ? "is-invalid" : ""}`} placeholder={getValidLabels(pageText, 'enterAddressPhTxt')} onChange={(e) => setCommunityMemberContactAddress(e.target.value)} />
                                        {
                                            errors["communityMemberContactAddress"] && <div className="invalid-feedback">{errors["communityMemberContactAddress"]}</div>
                                        }
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label>{getValidLabels(pageText, 'emailLabelTxt')}</label>
                                        <input type="email" value={communityMemberEmail} className={`form-control ${errors["communityMemberEmail"] ? "is-invalid" : ""}`} placeholder={getValidLabels(pageText, 'enterEmailPhTxt')} onChange={(e) => setCommunityMemberEmail(e.target.value)} />
                                        {
                                            errors["communityMemberEmail"] && <div className="invalid-feedback">{errors["communityMemberEmail"]}</div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="row mx-0 mb-2">
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label>{getValidLabels(pageText, 'relationshipSurvivorLabelTxt')} <span className='required'>*</span></label>
                                        <DropDown
                                            value={relationShipOption.filter(option => option.value === relationshipToSurvivor)}
                                            onChange={(e) => {
                                                setRelationshipToSurvivor(e.value)
                                            }}
                                            options={relationShipOption}
                                            isDisabled={false}
                                            isMulti={false}
                                            placeholder={"Select Relationship"}
                                            error={errors['relationshipToSurvivor'] ? true : false}
                                        />
                                        {
                                            errors["relationshipToSurvivor"] && <div className="invalid-feedback d-block">{errors["relationshipToSurvivor"]}</div>
                                        }
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>{"How close are you to the survivor?"} <span className='required'>*</span></label>
                                        <DropDown
                                            value={communityMemberDistanceOption.filter(option => option.value === communityMemberDistance)}
                                            onChange={(e) => {
                                                setCommunityMemberDistance(e.value)
                                            }}
                                            options={communityMemberDistanceOption}
                                            isDisabled={false}
                                            isMulti={false}
                                            placeholder={"Select"}
                                            error={errors['communityMemberDistance'] ? true : false}
                                        />
                                        {
                                            errors["communityMemberDistance"] && <div className="invalid-feedback d-block">{errors["communityMemberDistance"]}</div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <hr />
                        </React.Fragment>
                    }

                    <div className="row mx-0 mb-2">
                        <div className="col-sm-4">
                            <div className="form-group">
                                <label>{"Survivor's Information"} <HelpAudio parentClass={""} audio={"36_re"} /></label>

                                {userType !== GlobalMsgCons.roleTypeSurvivor && <React.Fragment>
                                    <input type="text" value={requesterName} className={`form-control ${errors["requesterName"] ? "is-invalid" : ""}`} placeholder={getValidLabels(pageText, 'enterNamePhTxt')} onChange={(e) => {
                                        if (loginId || shortName) {
                                            setRequesterName(e.target.value)
                                        } else {
                                            focusSignup()
                                        }
                                    }} />
                                    {
                                        errors["requesterName"] && <div className="invalid-feedback">{errors["requesterName"]}</div>
                                    }
                                </React.Fragment>}

                            </div>
                        </div>
                    </div>
                    <div className="row mx-0 mb-2">
                        <div className="col-sm-12">
                            <div className="mb-3">
                                <div className="btn-group flex-wrap">
                                    {
                                        genderType.map((value, index) => {
                                            let { help } = value
                                            return <React.Fragment key={index}>
                                                <input type="radio" className="btn-check" name="btnradio" id={"srcbtnradio" + index} autoComplete="off" checked={requesterGender === value.value} onChange={() => {
                                                    accumulateEvent(value.value)
                                                    if (loginId || shortName) {
                                                        setRequesterGender(value.value)
                                                    } else {
                                                        focusSignup()
                                                    }
                                                }} />
                                                <label className={`btn btn${requesterGender === value.value ? "" : "-outline"}-primary`}>
                                                    <label className="mb-0" htmlFor={"srcbtnradio" + index}>{value.label}</label>
                                                    <span className="px-2">{help}</span>
                                                </label>
                                            </React.Fragment>
                                        })
                                    }
                                </div>
                            </div>
                            <div className="mb-2">
                                <div className="btn-group flex-wrap">
                                    {
                                        genderCategory.map((value, index) => {
                                            let { help } = value
                                            return <React.Fragment key={index}>
                                                <input type="radio" className="btn-check" name="btnradio" id={"genderCategory" + index} autoComplete="off" checked={requesterGenderCategory === value.value} onChange={() => {
                                                    accumulateEvent(value.value)
                                                    if (loginId || shortName) {
                                                        setRequesterGenderCategory(value.value)
                                                    } else {
                                                        focusSignup()
                                                    }
                                                }}
                                                    onClick={() => {
                                                        if (loginId && value.value === requesterGenderCategory) {
                                                            setRequesterGenderCategory("")
                                                        }
                                                    }} />
                                                <label className={`btn btn${requesterGenderCategory === value.value ? "" : "-outline"}-primary`}>
                                                    <label className="mb-0" htmlFor={"genderCategory" + index}>{value.label}</label>
                                                    <span className="px-2">{help}</span>
                                                </label>
                                            </React.Fragment>
                                        })
                                    }
                                </div>
                            </div>
                            {/* {
                                ageRange.length > 0 && <div className="mb-2">
                                    <div><label>{"Please select age range"} <span className='required'>*</span> <HelpAudio parentClass={""} audio={"43"} /></label></div>
                                    <div className="btn-group flex-wrap">
                                        {
                                            ageRange.map((value, index) => {
                                                let { help } = value
                                                return <React.Fragment key={index}>
                                                    <input type="radio" className="btn-check" name="AgeRange" id={"srcAgeRange" + index} autoComplete="off" checked={requesterAgeRange === value.value} onChange={() => {
                                                        accumulateEvent(value.value)
                                                        if (loginId || shortName) {
                                                            setRequesterAgeRange(value.value)
                                                        } else {
                                                            focusSignup()
                                                        }
                                                    }} />
                                                    <label className={`btn btn${requesterAgeRange === value.value ? "" : "-outline"}-primary`} >
                                                        <label className="mb-0" htmlFor={"srcAgeRange" + index}>{value.label}</label>
                                                        <span className="px-2">{help}</span>
                                                    </label>
                                                </React.Fragment>
                                            })
                                        }
                                    </div>
                                    {
                                        errors["requesterAgeRange"] && <div className="invalid-feedback d-block">{errors["requesterAgeRange"]} <span className='required'>*</span> <HelpAudio parentClass={""} audio={"43"} /></div>
                                    }
                                </div>
                            } */}
                        </div>
                    </div>
                    <div className="row mx-0 mb-2">
                        <div className="col-sm-4">
                            <div className="form-group">
                                <label>{getValidLabels(pageText, 'phoneLabelTxt')} <span className='required'>*</span> <HelpAudio parentClass={""} audio={"44"} /></label>
                                <div className='row mx-0 mb-2'>
                                    {/* <div className='col-4 px-0'>
                                        <CountryCodeSelect
                                            countryCode={requesterCountryCode}
                                            onChange={(obj) => {

                                                if (loginId || shortName) {
                                                    setRequesterPhoneCode(obj.phoneCode)
                                                    setRequesterCountryCode(obj.value)
                                                } else {
                                                    focusSignup()
                                                }
                                            }}
                                            pageText={pageText}
                                        />
                                    </div> */}
                                    <div className='col-12 px-0 mb-2'>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">{requesterPhoneCode}</span>
                                            </div>
                                            <input type="number" value={requesterPhone} className={`form-control ${errors["requesterPhone"] ? "is-invalid" : ""}`} placeholder={getValidLabels(pageText, 'phoneLabelTxt')} onChange={(e) => {
                                                if (loginId || shortName) {
                                                    setRequesterPhone(e.target.value)
                                                } else {
                                                    focusSignup()
                                                }
                                            }}

                                            />
                                            {
                                                errors["requesterPhone"] && <div className="invalid-feedback">{errors["requesterPhone"]} <HelpAudio parentClass={""} audio={"44"} /></div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label>{"Can you be reached here?"} <span className='required'>*</span> <HelpAudio parentClass={""} audio={"45"} /></label>
                                <div>
                                    <div className="btn-group">
                                        {
                                            yesOrNo.map((value, index) => {
                                                let { help } = value
                                                return <React.Fragment key={index}>
                                                    <input type="radio" className="btn-check" name="btnradio" id={"reachedbtnradio" + index} autoComplete="off" checked={requesterReached === value.value} onChange={() => {
                                                        accumulateEvent(value.value)
                                                        if (loginId || shortName) {
                                                            setRequesterReached(value.value)
                                                        } else {
                                                            focusSignup()
                                                        }
                                                    }} />
                                                    <label className={`btn btn${requesterReached === value.value ? "" : "-outline"}-primary`}>
                                                        <label className="mb-0" htmlFor={"reachedbtnradio" + index}>{value.label}</label>
                                                        <span className="px-2">{help}</span>
                                                    </label>
                                                </React.Fragment>
                                            })
                                        }
                                    </div>
                                    {
                                        errors["requesterReached"] && <div className="invalid-feedback d-block">{errors["requesterReached"]} <HelpAudio parentClass={""} audio={"45"} /></div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mx-0 mb-2">
                        <div className="col-sm-4">
                            <div className="form-group">
                                <label>{getValidLabels(pageText, 'addressLabelTxt')} <span className='required'>*</span> <HelpAudio parentClass={""} audio={"48"} /></label>
                                <textarea type="text" value={requesterAddress} className={`form-control ${errors["requesterAddress"] ? "is-invalid" : ""}`} placeholder={getValidLabels(pageText, 'enterAddressPhTxt')} onChange={(e) => {
                                    if (loginId || shortName) {
                                        setRequesterAddress(e.target.value)
                                    } else {
                                        focusSignup()
                                    }
                                }} />
                                {
                                    errors["requesterAddress"] && <div className="invalid-feedback">{errors["requesterAddress"]} <HelpAudio parentClass={""} audio={"48"} /></div>
                                }
                            </div>
                        </div>
                        <div className="col-sm-4">
                            {/* <div className="form-group">
                                <label>{getValidLabels(pageText, 'postalCodeLabelTxt')}</label>
                                <input type="number" value={requestPincode} className={`form-control ${errors["requestPincode"] ? "is-invalid" : ""}`} placeholder={getValidLabels(pageText, 'postalCodeLabelTxt')} onChange={(e) => {
                                    if (loginId || shortName) {
                                        setRequestPincode(e.target.value)
                                    } else {
                                        focusSignup()
                                    }
                                }} />
                                {
                                    errors["requestPincode"] && <div className="invalid-feedback">{errors["requestPincode"]}</div>
                                }
                            </div> */}
                            <div className="form-group">
                                <label>{"City/State"} <HelpAudio parentClass={""} audio={"49"} /></label>
                                <input type="text" value={requesterCityOrState} className={`form-control ${errors["requesterCityOrState"] ? "is-invalid" : ""}`} placeholder={"Enter City/State"} onChange={(e) => {
                                    if (loginId || shortName) {
                                        setRequesterCityOrState(e.target.value)
                                    } else {
                                        focusSignup()
                                    }
                                }} />
                                {
                                    errors["requesterCityOrState"] && <div className="invalid-feedback">{errors["requesterCityOrState"]}</div>
                                }
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="form-group">
                                <label>{getValidLabels(pageText, 'emailLabelTxt')} <HelpAudio parentClass={""} audio={"50"} /></label>
                                <input type="email" value={requesterEmail} className={`form-control ${errors["requesterEmail"] ? "is-invalid" : ""}`} placeholder={getValidLabels(pageText, 'enterEmailPhTxt')} onChange={(e) => {
                                    if (loginId || shortName) {
                                        setRequesterEmail(e.target.value)
                                    } else {
                                        focusSignup()
                                    }
                                }} />
                                {
                                    errors["requesterEmail"] && <div className="invalid-feedback">{errors["requesterEmail"]}</div>
                                }
                            </div>
                        </div>
                    </div>
                    {
                        questionList.length > 0 && <React.Fragment>
                            <div className="row mx-0 mt-2 mb-2">
                                {
                                    questionList.map((row) => {
                                        let { id, question } = row;
                                        return <div className="col-sm-12" key={id}>
                                            <div className="form-group" key={id}>
                                                <label>{question}</label>
                                                {
                                                    getAnswerInput(row)
                                                }
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </React.Fragment>
                    }
                    <hr />
                    <div className="row mx-0 mt-2 mb-2">
                        <div className="col-sm-8">
                            <FileUpload
                                helpAudio={true}
                                tempLocal={true}
                                processType={"csEventReport"}
                                documentJson={documentJson}
                                documentFiles={documentFiles}
                                setDocumentJson={setDocumentJson}
                                setDocumentFiles={setDocumentFiles}
                                // label={getDefaultValidLabels(pageText, "uploadLabel", "Add pictures, video, documents")}
                                label={""}
                                dragFilesTxt={<span style={{ fontSize: 18 }}>Click here to add pictures, videos, documents.</span>}
                                supportFileTxt={<span style={{ fontSize: 10 }}>{getDefaultValidLabels(pageText, "supportFileTxt", "Supported file formats (.png, .jpg, .jpeg, .mp4, .3gp, .ogg, .mov, .pdf)")}</span>}
                            />
                        </div>
                    </div>
                </div>
            </div>
        }

        return str
    }

    function getFooter() {
        let str;

        if (infoMessage) {
            str = <button type="button" className="btn btn-primary" onClick={() => {
                accumulation(pageNameAcc, getPageEventName("communityServiceEventPage", "gotItBtnClicked"), activeCompany, loginId, props.grpcClient)
                setInfoMessage(false)
            }}>
                Got it <HelpAudio parentClass={""} audio={"4"} />
            </button>
        } else {
            str = <React.Fragment>
                <div style={{ flex: 1 }}>
                    <button className="btn escape-button mx-2" onClick={() => {
                        closeWindow("escape")
                        accumulation(pageNameAcc, getPageEventName("communityServiceEventPage", "escapeBtnClicked"), activeCompany, loginId, props.grpcClient)
                    }}><i className="fas fa-exclamation-triangle"></i> Escape <HelpAudio parentClass={""} audio={"5_12"} /></button>
                </div>

                {/* <button className="btn btn-secondary mx-2" onClick={() => {
                    modalClose(true)
                }}>Close <HelpAudio parentClass={""} audio={"53"} /></button> */}
                <button type="button" className="btn btn-primary" onClick={() => {
                    accumulation(pageNameAcc, getPageEventName("communityServiceEventPage", "doneBtnClicked"), activeCompany, loginId, props.grpcClient)
                    if (loginId || shortName) {
                        save()
                    } else {
                        focusSignup()
                    }
                }} disabled={submitLoader}>
                    {submitLoader && <i className="fa fa-spinner fa-spin"></i>}
                    <span className={`${submitLoader && ("pl-1")}`}>
                        {"Done"} <HelpAudio parentClass={""} audio={"54"} />
                    </span>
                </button>
            </React.Fragment>
        }

        return str
    }
    function accumulateEvent(value) {
        let accumulationText = ""
        switch (value) {
            case "form":
                accumulationText = "reportHereClicked";
                break;
            case "emoji":
                accumulationText = "emojiIconClicked";
                break;
            case "voice-record":
                accumulationText = "recordingIconClicked";
                break;
            case "Child Abuse":
                accumulationText = "childAbuseMsgClicked";
                break;
            case "Domestic Violence":
                accumulationText = "domesticViolenceMsgClicked";
                break;
            case "Human Trafficking":
                accumulationText = "humanTraffickingMsgClicked";
                break;
            case "Sexual Violence":
                accumulationText = "sexualViolenceMsgClicked";
                break;
            case "Stalking":
                accumulationText = "stalkingMsgClicked";
                break;
            case "Technology Assisted Abuse":
                accumulationText = "technologyAssistedAbuseMsgClicked";
                break;
            case "communityMember":
                accumulationText = "communityMemberTabClicked";
                break;
            case "survivor":
                accumulationText = "survivorTabClicked";
                break;
            case "Under 18":
                accumulationText = "under18AgeRangeClicked";
                break;
            case "18-21":
                accumulationText = "18to21AgeRangeClicked";
                break;
            case "22-29":
                accumulationText = "22to29AgeRangeClicked";
                break;
            case "30-40":
                accumulationText = "30to40AgeRangeClicked";
                break;
            case "41-50":
                accumulationText = "41to50AgeRangeClicked";
                break;
            case "51-60":
                accumulationText = "51to60AgeRangeClicked";
                break;
            case "61-70":
                accumulationText = "61to70AgeRangeClicked";
                break;
            case "Above 70":
                accumulationText = "above70AgeRangeClicked";
                break;
            case "Yes":
                accumulationText = "canYouBeReachedYesClicked";
                break;
            case "No":
                accumulationText = "canYouBeReachedNoClicked";
                break;
            case "Male":
                accumulationText = "survivorGenderMaleClicked";
                break;
            case "Female":
                accumulationText = "survivorGenderFemaleClicked";
                break;
            case "PWD":
                accumulationText = "survivorGenderPWDClicked";
                break;
            case "Minor":
                accumulationText = "survivorGenderMinorClicked";
                break;
            case "Elder":
                accumulationText = "survivorGenderElderClicked";
                break;
            case "Prefer not to say":
                accumulationText = "survivorGenderPrefernottoSayClicked";
                break;
            case "Person with Disabilities":
                accumulationText = "survivorGenderPWDClicked";
                break;
            default:
                accumulationText = "";
        }

        if (accumulationText !== "" && accumulationText) {
            accumulation(pageNameAcc, getPageEventName("communityServiceEventPage", accumulationText), activeCompany, loginId, props.grpcClient)
        }

    }
    return <React.Fragment>
        <ToastContainer />
        <ReactTooltip place="bottom" type="dark" effect="float" html={true} />
        {
            eventModal && <Modal
                helpAudio={true}
                visible={eventModal}
                parentClass={"community-service-form"}
                closeModal={() => {
                    modalClose(true)
                    accumulation(pageNameAcc, getPageEventName("communityServiceEventPage", "closeIconClicked"), activeCompany, loginId, props.grpcClient)
                }}
                size={"modal-lg"}
                heading={getTitle()}
                body={<React.Fragment>
                    <ReactTooltip place="bottom" type="dark" effect="float" html={true} />
                    {getBody()}
                </React.Fragment>}
                footer={(!infoMessage && loginMessage) ? "" : <React.Fragment>
                    <ReactTooltip place="bottom" type="dark" effect="float" html={true} />
                    {getFooter()}
                </React.Fragment>}
            />
        }
    </React.Fragment>
}

export default CommunityServiceEvent;
