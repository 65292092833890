import React from "react";
import Modal from "../../../components/Modal";
import { getValidLabels, getValidTooltip } from "../../../config/Common";
import GlobalMsgCons from "../../../config/GlobalMsgCons";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ChatOptionsModal(props) {
    let { pageText, optionsModal, selectedChatMessage, loginId } = props;
    let { chatMessage, tempMessageStatus } = selectedChatMessage;
    let { messageType } = chatMessage

    return optionsModal && <Modal
        visible={optionsModal}
        size={"modal-xl"}
        closeModal={() => props.closeModal()}
        heading={"Message"}
        body={<div>
            <ToastContainer />
            <div className="options">
                {
                    <div className="options-container">
                        {
                            <React.Fragment>
                                <div className="option" onClick={() => props.updateEmoJiReactionForMsg(chatMessage.id, "2705")} data-tip={getValidTooltip(pageText, 'completedEmojiTxt')}>
                                    &#x2705;
                                </div>
                                <div className="option" onClick={() => props.updateEmoJiReactionForMsg(chatMessage.id, "1f64f")} data-tip={"Thanks"}>
                                    &#x1f64f;
                                </div>
                                <div className="option" onClick={() => props.updateEmoJiReactionForMsg(chatMessage.id, "1f64c")} data-tip={getValidTooltip(pageText, 'nicelyDoneEmojiTxt')}>
                                    &#x1f64c;
                                </div>
                                <div className="option" onClick={() => props.updateEmoJiReactionForMsg(chatMessage.id, "1f440")} data-tip={getValidTooltip(pageText, 'takingaLookEmojiTxt')}>
                                    &#x1f440;
                                </div>
                                {
                                    (messageType !== GlobalMsgCons.botRequest && messageType !== GlobalMsgCons.botResponse) && <React.Fragment>
                                        <div className="option" onClick={() => props.openThread(selectedChatMessage)} data-tip={getValidTooltip(pageText, 'replyinThreadTooltipTxt')}>
                                            <i className="fas fa-comment-dots"></i>
                                        </div>
                                        {/* 
                                        <div className="option" onClick={() => props.setShareMessage(selectedChatMessage)} data-tip={"Share"}>
                                            <i className="fas fa-share"></i>
                                        </div>
                                        */}
                                        {
                                            chatMessage.message && <div className="option" onClick={() => {
                                                let textMsg = chatMessage.message.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, '')
                                                navigator.clipboard.writeText(textMsg);

                                                toast(`Copied Successfully.`, {
                                                    position: "bottom-center",
                                                    autoClose: 1000,
                                                    hideProgressBar: true,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: true,
                                                    progress: undefined,
                                                    theme: "dark",
                                                });
                                            }} data-tip={"Copy"}>
                                                <i className="fas fa-copy"></i>
                                            </div>
                                        } 
                                    </React.Fragment>
                                }

                                {
                                    (chatMessage.userId === loginId && messageType !== GlobalMsgCons.botRequest && messageType !== GlobalMsgCons.botResponse) && <React.Fragment>
                                        <div className={"option"} onClick={() => {
                                            props.edit(chatMessage)

                                        }} data-tip={getValidTooltip(pageText, 'editBtnTxt')}>{getValidLabels(pageText, 'editBtnTxt')}</div>
                                        <div className="option" onClick={() => {
                                            let responseChoose = window.confirm(getValidLabels(pageText, 'deletethisAlert_Msg'))
                                            if (responseChoose) {
                                                props.deleteChatMessage(chatMessage.id, chatMessage.sendAt)
                                            }
                                        }} data-tip={getValidTooltip(pageText, 'deleteBtnTxt')}>{getValidLabels(pageText, 'deleteBtnTxt')}</div>
                                        {
                                            tempMessageStatus === "failed" && <div className="option" onClick={() => {
                                                props.retryChatMessage(selectedChatMessage)
                                            }} data-tip={"Retry"}><i className="fas fa-redo"></i></div>
                                        }
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        }
                    </div>
                }
            </div>
        </div>
        }
    />
}
export default ChatOptionsModal;