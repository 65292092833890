import React, { Component } from 'react';
import { getPageText, getValidLabels, getPageEventName, accumulation } from "../config/Common";
import HelpAudio from './HelpAudio';

const audioType = 'audio/*';
const pageNameAcc = "communityServiceEventPage"

class Recorder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: {},
      miliseconds: 0,
      recording: false,
      medianotFound: false,
      audios: [],
      audioBlob: null,
      stream: null,
      pageText: null
    };
    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
  }

  componentDidMount() {
    this.getPageText()
  }

  componentWillUnmount() {
    this.stopRecording()
  }

  handleAudioPause(e) {
    let { loginId, activeCompany, grpcClient } = this.props
    accumulation(pageNameAcc, getPageEventName("communityServiceEventPage", "recordingPauseIconClicked"), activeCompany, loginId, grpcClient)
    e.preventDefault();
    clearInterval(this.timer);
    this.mediaRecorder.pause();
    this.setState({ pauseRecord: true });
  }

  handleAudioStart(e) {
    let { loginId, activeCompany, grpcClient } = this.props
    accumulation(pageNameAcc, getPageEventName("communityServiceEventPage", "recordingunpauseIconClicked"), activeCompany, loginId, grpcClient)
    e.preventDefault();
    this.startTimer();
    this.mediaRecorder.resume();
    this.setState({ pauseRecord: false });
  }

  startTimer() {
    // if (this.timer === 0 && this.state.seconds > 0) {
    this.timer = setInterval(this.countDown, 100);
    // }
  }

  countDown() {
    // Remove one second, set state so a re-render happens.
    let { miliseconds } = this.state
    if (this.props.maxTime === undefined || this.props.maxTime >= miliseconds) {
      this.setState(prevState => {
        const miliseconds = prevState.miliseconds + 100;
        return ({ time: this.milisecondsToTime(miliseconds), miliseconds: miliseconds });
      });
    } else {
      this.stopRecording("")
    }
  }

  milisecondsToTime(milisecs) {

    let secs = milisecs / 1000;
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);


    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
      ms: milisecs
    };
    return obj;
  }

  async initRecorder() {
    navigator.getUserMedia =
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia;
    if (navigator.mediaDevices) {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      this.mediaRecorder = new MediaRecorder(stream);
      this.chunks = [];
      this.mediaRecorder.ondataavailable = e => {
        if (e.data && e.data.size > 0) {
          this.chunks.push(e.data);
        }
      };

      this.stream = stream;
    } else {
      this.setState({ medianotFound: true });
      console.log('Media Decives will work only with SSL.....');
    }
  }

  async startRecording(e) {
    if (e) {
      e.preventDefault();
    }
    // wipe old data chunks
    this.chunks = [];

    await this.initRecorder();
    // start recorder with 10ms buffer
    this.mediaRecorder.start(10);
    this.startTimer();
    // say that we're recording
    this.setState({
      audios: [],
      recording: true
    });
  }

  stopRecording(e) {
    clearInterval(this.timer);
    this.setState({
      time: {},
      miliseconds: 0
    });
    if (e) {
      e.preventDefault();
      // stop the recorder
    }

    if (this.stream && this.stream.getAudioTracks) {
      const tracks = this.stream.getAudioTracks();
      tracks.forEach((track) => {
        track.stop();
      });
    } else {
      console.log('No Tracks Found')
    }

    if (this.mediaRecorder) {
      this.mediaRecorder.stop();
      // say that we're not recording
      this.setState({ recording: false, pauseRecord: false, });
      // save the video to memory
      this.saveAudio();
    }

  }

  handleReset(e) {
    if (this.state.recording) {
      this.stopRecording(e);
    }
    this.setState({
      time: {},
      miliseconds: 0,
      recording: false,
      medianotFound: false,
      audios: [],
      audioBlob: null
    }, () => {

    });

  }

  saveAudio() {
    let { autoSave } = this.props;
    // convert saved chunks to blob
    const blob = new Blob(this.chunks, { type: audioType });
    // generate video url from blob
    const audioURL = window.URL.createObjectURL(blob);
    // append videoURL to list of saved videos for rendering
    const audios = [audioURL];
    console.log("blob", blob)
    this.setState({
      audios,
      audioBlob: blob
    }, () => {
      if (autoSave) {
        this.save()
      }
    });

  }


  save() {
    let { audioBlob } = this.state;
    this.props.saveAudio("record", audioBlob)
  }

  async getPageText() {
    let pageText = await getPageText('chat');
    this.setState({ pageText: pageText })
  }

  render() {
    const { recording, audios, time, medianotFound, pauseRecord, pageText } = this.state;
    let { maxTime, autoSave, maxTimeText } = this.props;
    let maxTimeStr = "";
    if (maxTime) {
      let maxTimeConv = this.milisecondsToTime(maxTime)
      maxTimeStr += maxTimeConv.m <= 9 ? '0' + maxTimeConv.m : maxTimeConv.m;
      maxTimeStr += ":";
      maxTimeStr += maxTimeConv.s <= 9 ? '0' + maxTimeConv.s : maxTimeConv.s;
    }
    let { loginId, activeCompany, grpcClient, helpAudio } = this.props
    return (
      <div className="audio-record text-center">

        {
          !recording ?
            (<React.Fragment>
              <p className={"help mt-2"}>{`${"Press microphone to Record"}`} { helpAudio && <HelpAudio parentClass={""} audio={"66"} />}</p>
              <div style={{position: "relative"}}>
                <button
                  onClick={(e) => {
                    accumulation(pageNameAcc, getPageEventName("communityServiceEventPage", "recordingStartIconClicked"), activeCompany, loginId, grpcClient)
                    this.startRecording(e)
                  }}
                  className={"mic_icon"}
                >
                  <i className="fa fa-microphone" /> 
                </button>
                { helpAudio && <div style={{position: "absolute", top: 0, right: 'calc(50% - 60px)'}}><HelpAudio parentClass={""} audio={"67"} /></div>}
              </div>
              {maxTime && <p>{maxTimeText ? maxTimeText : getValidLabels(pageText, 'maxLengthRecordTxt') + maxTimeStr}</p>}
            </React.Fragment>

            ) :
            (
              <div className={"record_controller"}>
                <button
                  onClick={(e) => {
                    accumulation(pageNameAcc, getPageEventName("communityServiceEventPage", "recordingStopIconClicked"), activeCompany, loginId, grpcClient)
                    this.stopRecording(e)
                  }}
                  href=' #'
                  className={`${"icons"} ${"stop"}`}
                >
                  <i className="fa fa-stop" />
                </button>
                { helpAudio && <HelpAudio parentClass={""} audio={"70"} />}
                <button
                  onClick={
                    !pauseRecord ?
                      e => this.handleAudioPause(e) :
                      e => this.handleAudioStart(e)
                  }
                  href=' #'
                  className={`${"icons"} ${"pause"}`}
                >
                  {
                    pauseRecord ?
                      <i className="fa fa-play"></i> :
                      <i className="fa fa-pause"></i>
                  }
                </button>
                { helpAudio && <HelpAudio parentClass={""} audio={"71"} />}
              </div>
            )
        }
        <div className={"duration"}>
          <span className={"mins"}>
            {
              time.m !== undefined ?
                `${time.m <= 9 ? '0' + time.m : time.m}` :
                '00'
            }
          </span>
          <span className={"divider"}>:</span>
          <span className={"secs"}>
            {
              time.s !== undefined ?
                `${time.s <= 9 ? '0' + time.s : time.s}` :
                '00'
            }
          </span>
          <HelpAudio parentClass={"recorder-help-place"} audio={"69_72"} />
        </div>


        {
          audios.length > 0 && <div className="d-flex justify-content-center align-items-center preview pt-2">
            <audio controls>
              <source src={audios[0]} type='audio/ogg' />
              <source src={audios[0]} type='audio/mpeg' />
            </audio>
            <HelpAudio parentClass={""} audio={"73"} />
            {
              autoSave !== true && <div className="my-2 text-right">
                <button className="btn btn-sm btn-success" onClick={() => this.save()}>{getValidLabels(pageText, 'attachBtnTxt')}</button>
              </div>
            }
          </div>
        }
      </div>
    );
  }
}

export default Recorder;
