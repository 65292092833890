import { withRouter } from "react-router-dom";
import { useEffect, useState, } from 'react';
import logoImg from '../../../assets/logo-transparent.png';
import {commonEventList, accumulation, getPageEventName } from "../../../config/Common";
import { useSelector } from 'react-redux';
import MyConstant from "../../../config/MyConstant";


function PrivacyPolicy(props) {
    let { path } = props
    const pageNameAcc = "privacyPolicyPage"
    const auth = useSelector(state => state.authReducer);
    let { loginId, activeCompany, } = auth;
    
    useEffect(() => {
        accumulation(pageNameAcc, commonEventList("pageInitiated"), activeCompany, loginId, props.grpcClient)
    }, [])

    return <div className="mobile-container">
        <div className="header">
            <div style={{ display: "flex", flex: 1, padding: 5, flexDirection: "row", fontSize: 18 }}>
                <div style={{ padding: "0 10px" }} onClick={() => {
                    if(loginId) {
                        accumulation(pageNameAcc, getPageEventName("privacyPolicyPage", "backAeroIconClicked"), activeCompany, loginId, props.grpcClient)
                        props.history.push(path + "/home/more")
                    } else {
                        props.history.push(MyConstant.keyList.projectUrl)
                    }
                }}>
                    <i className='fas fa-chevron-left' />
                </div>
                <div style={{ flex: 1 }}>
                    <div>{"Privacy policy"}</div>
                </div>
            </div>
        </div>
        <div className='body privacy-policy'>
            <div className="px-5" style={{ backgroundColor: "#f5f1f1" }}>
                <div className="py-2" style={{ width: 200, margin: "auto"}}>
                    <img src={logoImg} className="img-fluid" />
                </div>
                <p className="privacy-title">Privacy Policy</p>
                <p className="">Effective: 01/01/2024</p>
                <ul>
                    <p className="text-left privacy-sub-title">Who We Are</p>
                    <li className={`list-text`}>
                        We are Encare. Our website address is: <a href="https://encare.ng">https://encare.ng</a>
                    </li>
                    <li className={`list-text`}>We have created this privacy statement to inform site visitors (“you”) of our
                        data collection, use, and disclosure practices. This privacy statement applies
                        to <a href="https://encare.ng">https://encare.ng</a> and any other website that links to this privacy
                        statement (collectively, the “Site,” and the services available through our Site,
                        collectively, the “Services”). Please read this privacy statement carefully. By
                        using the Site or the Services, you agree that your personal information may
                        be collected, used, and disclosed in the ways we describe. Please do not visit
                        the Site or use the Services if you do not agree with the practices described
                        herein.</li>
                </ul>
                <ul>
                    <p className="text-left privacy-sub-title">What Personal Data We Collect
                        and Why We Collect It</p>
                    <p>When we use the term “personal data,” we mean information that can be used
                        to identify you as an individual person. We may collect and use the following
                        categories of personal data through your use of the Site or the Services:</p>
                    <li className={`list-text list-bullets`}>Contact information shown in the report form, such as the name,
                        address, phone number, and email address, which we use to respond to
                        you and answer your questions;</li>
                    <li className={`list-text list-bullets`}>The visitor’s IP address and browser user agent string to help spam
                        detection;</li>
                    <li className={`list-text list-bullets`}>Report information, including the content of any pictures, videos,
                        documents and comments you choose to share when you make a
                        report;</li>
                    <li className={`list-text list-bullets`}>Communication preferences, including contact preferences, which we
                        use to manage how we engage with you;</li>
                    <li className={`list-text list-bullets`}>Responses to surveys, which we use to inform product/service
                        development and tailor our communications to you; and</li>
                    <li className={`list-text list-bullets`}>Inquiries you make, including the content of your voice, email, text, or
                        other electronic messages, which we use to respond to your inquiries.</li>
                </ul>
                <ul>
                    <p className="text-left privacy-sub-title">Your Contact Information</p>
                    <li className={`list-text`}>We do not share the information you’ve given us with unaffiliated groups
                        without your explicit permission.</li>
                    <li className={`list-text`}>Your contact details and any information you’ve given us will only be shared
                        with your permission.</li>
                </ul>
                <ul>
                    <p className="text-left privacy-sub-title">Information Collected
                        Automatically</p>
                    <li className={`list-text`}>We use various technologies to collect other types of data that do not directly
                        reveal your identity (“Other Information”). If we combine or associate Other
                        Information with personal data we have collected from you, we will treat the
                        combined information as personal data in accordance with this privacy
                        statement.</li>
                    <p className="mt-3">These technologies include the following:</p>
                    <li className={`list-text`}>
                        Logging Functionality: As is true of most websites, we gather certain
                        information automatically and store it in log files. This information may
                        include IP addresses, browser type, internet service provider, referring/exit
                        pages, operating system, date/time stamp, page views, and/or clickstream
                        data. We generally only use this data for purposes such as tracking traffic
                        patterns on the Site, security, fraud detection, and protecting our rights.
                    </li>
                    <li className={`list-text`}>Cookies and Other Data Collection Technologies: We and our affiliated
                        companies, business partners, and service providers use cookies and similar
                        technologies to manage the Site and the Services and to collect and trackinformation about you and your activities online over time and across different
                        websites and social media channels. In addition, we use technologies that can
                        collect and store your information to help us recognize you and enhance your
                        experience on the Site and your use of the Services. For example, the
                        “remember me” function saves a cookie on your computer’s browser so that
                        you can be automatically logged in when interacting with the Site or using the
                        Services. Cookies also help us provide state- or region-specific information
                        and relevant news that may be of the most interest to you.</li>
                    <li className={`list-text`}>Most internet browsers allow you to remove, disable, or manage cookie
                        functions and adjust your privacy and security preferences. For information on
                        how to do this, access the “help” menu on your internet browser, or visit
                        <a href="http://www.aboutcookies.org/how-to-control-cookies"> http://www.aboutcookies.org/how-to-control-cookies</a>.</li>
                    <li className={`list-text`}>Please note, however, that disabling cookies on the Site may result in your
                        inability to take full advantage of all the features of the Site or of the Services.</li>
                </ul>
                <ul>
                    <p className="text-left privacy-sub-title">Additional Uses of Personal
                        Information</p>
                    <p>In addition to the uses described above, we may use your Personal
                        Information for the following purposes:</p>
                    <li className={`list-text list-bullets`}>Operating our organization;</li>
                    <li className={`list-text list-bullets`}>Contacting you to respond to your requests or inquiries;</li>
                    <li className={`list-text list-bullets`}>Providing you with newsletters, alerts, and other information tailored to
                        your interests;</li>
                    <li className={`list-text list-bullets`}>Contacting you about programs or events, that we believe may be of
                        interest to you;</li>
                    <li className={`list-text list-bullets`}>Conducting surveys, internal research, and analytics to help us
                        understand trends and improve our services;</li>
                    <li className={`list-text list-bullets`}>Preventing, investigating, or providing notice of fraud, unlawful or
                        criminal activity, or unauthorized access to or use of personal data, the
                        Site, the Services or our data systems, or to meet legal obligations;</li>
                    <li className={`list-text list-bullets`}>Investigating and resolving disputes and security issues and enforcing
                        our terms of use and other agreements;</li>
                    <li className={`list-text list-bullets`}>Complying with the law, a judicial proceeding, court order, or other legal
                        process, such as in response to a court order or a subpoena; and</li>
                    <li className={`list-text list-bullets`}>Any other lawful, legitimate business purpose.</li>
                </ul>
                <ul>
                    <p className="text-left privacy-sub-title">How We Share and Disclose
                        Personal Data</p>
                    <li className={`list-text`}>We share your personal data with third parties only in ways consistent with
                        this privacy statement.</li>
                    <li className={`list-text`}>Service Providers: We share your personal data with third-party service
                        providers who perform services on our behalf or for your benefit, such as web
                        hosting and analytics.</li>
                </ul>
                <ul>
                    <p className="text-left privacy-sub-title">Media</p>
                    <li className={`list-text`}>If you upload images to the website, you should avoid uploading images with
                        embedded location data (EXIF GPS) included. Visitors to the website can
                        download and extract any location data from images on the website.</li>
                </ul>
                <ul>
                    <p className="text-left privacy-sub-title">Third-Party Links</p>
                    <li className={`list-text`}>Our Site and Services may contain links to third-party websites. Any access to
                        and use of such linked websites is not governed by this privacy policy, but is
                        instead governed by the privacy policies of those third-party websites. We are
                        not responsible for the information practices of such third-party websites.</li>
                </ul>
                <ul>
                    <p className="text-left privacy-sub-title">Security of My Personal
                        Information</p>
                    <li className={`list-text`}>We have implemented commercially reasonable precautions to protect the
                        information we collect from loss, misuse, and unauthorized access,disclosure, alteration, and destruction. Please be aware that despite our
                        efforts, no data security measures can take 100% security.</li>
                    <li className={`list-text`}>You should take steps to protect against unauthorized access to your
                        password, phone, and computer by, among other things, signing off after
                        using a shared computer, choosing a robust password that nobody else
                        knows or can easily guess, and keeping your login and password private. We
                        are not responsible for any lost, stolen, or compromised passwords or for any
                        activity on your account via unauthorized password activity.</li>
                </ul>
                <ul>
                    <p className="text-left privacy-sub-title">What Choices do I Have Regarding
                        Use of My Personal Information?</p>
                    <li className={`list-text`}>We may send periodic promotional or informational emails to you. You may
                        opt out of such communications by following the opt-out instructions
                        contained in the email. Please note that it may take up to 10 business days for
                        us to process opt-out requests. If you opt out of receiving emails about
                        recommendations or other information we think may interest you, we may still
                        send you emails about your account or any Services you have requested or
                        received from us.</li>
                </ul>
                <ul>
                    <p className="text-left privacy-sub-title">Children’s Privacy</p>
                    <li className={`list-text`}>Our Services are not directed to and we do not intend to or knowingly collect
                        or solicit personal data online from children under the age of 13. We
                        encourage parents or guardians to participate in and monitor their children’s
                        online activity. If a child under 13 has provided personal data to us, we
                        encourage the child’s parent or guardian to contact us to request that we
                        remove the information from our systems. If you are under the age of 13, do
                        not provide us with any personal data.</li>
                </ul>
                <ul>
                    <p className="text-left privacy-sub-title">Updates to This Privacy Statement</p>
                    <li className={`list-text`}>This privacy statement is current as of the date set forth above. We may
                        change this privacy statement from time to time in our sole discretion, so
                        please be sure to check back periodically. Any and all changes will be
                        reflected on this page and we will update the effective date at the top of this
                        page.</li>
                    <li className={`list-text`}>If you have any questions about the privacy aspects of our Services or would
                        like to make a complaint, please contact us at: contact@teqbahn.com</li>
                </ul>
            </div>
        </div>
    </div>;
}

export default withRouter(PrivacyPolicy);