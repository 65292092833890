import {
    Switch,
    Route,
    withRouter,
    useLocation,
    useRouteMatch
} from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getPageText,getValidLabels,getPageEventName, accumulation,commonEventList} from "../../config/Common";
import ChatView from "./chat/ChatView";
import ReportHere from "./ReportHere";
import CommunityServiceMore from "./CommunityServiceMore";
import Emoji from "../../assets/community/request/Emoji.png";
import HelpAudio from "../../components/HelpAudio";

function Home(props) {
    let { requestSendingChannelId, pollChannelUnreadCount } = props;
    let { path } = useRouteMatch();
    const location = useLocation();
    const auth = useSelector(state => state.authReducer);
    let [pageText, setPageText] = useState({});
    let { loginId, activeCompany} = auth;
    const pageNameAcc = "homePage"

    useEffect(() => {
        accumulation(pageNameAcc, commonEventList("pageInitiated"), activeCompany, loginId, props.grpcClient)
        init()
    }, [])

    async function init() {
        let pageText = await getPageText('users');
        setPageText(pageText)
    }

    function notifyNewMessage(sound) {
        var audio = new Audio(sound);
        audio.play();
    }
    
    return <div>
        <div className="home-tab-content">
            <Switch>
                <Route exact path={`${path}/`}>
                    <ReportHere path={path} {...props} pageText={pageText}/>
                </Route>
                <Route exact path={`${path}/chat/:channelId/:feed?`}>
                    <ChatView {...props} />
                </Route>
                <Route exact path={`${path}/more`}>
                    <CommunityServiceMore {...props} pageText={pageText}/>
                </Route>
            </Switch>
        </div>
        <div className="bottom-tabs">
            <div className={`tab report-here-tab c-pointer ${location.pathname === props.path ? "active" : ""}`} onClick={() => {
                accumulation(pageNameAcc, getPageEventName("homePage", "reportHereIconClicked"),activeCompany, loginId,props.grpcClient)
                props.history.push(props.path)
            }}>
                <div className="icon">
                    <div style={{ mask: `url(${Emoji}) center/contain`, width: 27, height: 27, backgroundColor: location.pathname === props.path ? "#8e2d80" : "#000", margin: "auto"}} />
                    {/* <img className="img-fluid" src={Emoji} alt="Emoji" style={{ width: 27}}/> */}
                    <HelpAudio parentClass={"tab-help-place"} audio={"1_22_23"} />
                </div>
                <div className="name">{"Report Here"}</div>
            </div>
            <div className={`tab c-pointer ${location.pathname === props.path+"/home/chat/"+props.requestSendingChannelId+"/service" ? "active" : ""}`} onClick={() => {
                accumulation(pageNameAcc, getPageEventName("homePage", "reportLogIconClicked"),activeCompany, loginId,props.grpcClient)
                props.history.push(props.path+"/home/chat/"+props.requestSendingChannelId+"/service")
            }}>
                <div className="icon" style={{ position: "relative"}}>
                    {/* {
                        pollChannelUnreadCount && pollChannelUnreadCount.result && pollChannelUnreadCount.result[requestSendingChannelId] > 0 && <div className="count">{pollChannelUnreadCount.result[requestSendingChannelId]}</div>
                    } */}
                    <i className="fas fa-comment"></i>
                    <HelpAudio parentClass={"tab-help-place"} audio={"122"} />
                </div>
                <div className="name">{"Report Log"}</div>
            </div>
            <div className={`tab c-pointer ${location.pathname === props.path+"/home/more" ? "active" : ""}`} onClick={() => {
                accumulation(pageNameAcc, getPageEventName("homePage", "moreIconClicked"),activeCompany, loginId,props.grpcClient)
                props.history.push(props.path+"/home/more")
            }}>
                <div className="icon" style={{ position: "relative"}}>
                    <i className="fas fa-bars"></i>
                    <HelpAudio parentClass={"tab-help-place"} audio={"123"} />
                </div>
                <div className="name">{"More"}</div>
            </div>
        </div>
    </div>;
}

export default withRouter(Home);
