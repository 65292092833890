import { withRouter } from "react-router-dom";
import ResponseSecond from "./ResponseSecond";
import logoImg from '../../../assets/logo-transparent.png';
import { commonEventList, accumulation, getPageEventName } from "../../../config/Common";
import { useSelector } from 'react-redux';
import { useEffect} from 'react';


function CopingSkills(props) {
    let { path } = props
    const pageNameAcc = "copingSkillsPage"
    const auth = useSelector(state => state.authReducer);
    let { loginId, activeCompany, } = auth;

    useEffect(() => {
        accumulation(pageNameAcc, commonEventList("pageInitiated"), activeCompany, loginId, props.grpcClient)
    }, [])

    return <div className="mobile-container">
        <div className="header">
            <div style={{ display: "flex", flex: 1, padding: 5, flexDirection: "row", fontSize: 18 }}>
                <div style={{ padding: "0 10px" }} onClick={() => {
                    accumulation(pageNameAcc, getPageEventName("copingSkillsPage", "backAeroIconClicked"), activeCompany, loginId, props.grpcClient)
                    props.history.push(path + "/home/more")
                }}>
                    <i className='fas fa-chevron-left' />
                </div>
                <div style={{ flex: 1 }}>
                    <div>{"Coping Skills"}</div>
                </div>
            </div>
        </div>
        <div className="body">
            <div className="py-2" style={{ width: 200, margin: "auto" }}>
                <img src={logoImg} className="img-fluid" />
            </div>
            <ResponseSecond {...props} />
        </div>
    </div>;
}

export default withRouter(CopingSkills);