export default function Rating(props) {
    let { value, setValue } = props
    return <div className="rating">
        <input type="radio" name="rating" id="rating-1" value="1" checked={value === "1"} onChange={() => setValue("1")}/>
        <label htmlFor="rating-1">1 Star</label>
        <input type="radio" name="rating" id="rating-2" value="2" checked={value === "2"} onChange={() => setValue("2")}/>
        <label htmlFor="rating-2">2 Stars</label>
        <input type="radio" name="rating" id="rating-3" value="3" checked={value === "3"} onChange={() => setValue("3")}/>
        <label htmlFor="rating-3">3 Stars</label>
        <input type="radio" name="rating" id="rating-4" value="4" checked={value === "4"} onChange={() => setValue("4")}/>
        <label htmlFor="rating-4">4 Stars</label>
        <input type="radio" name="rating" id="rating-5" value="5" checked={value === "5"} onChange={() => setValue("5")}/>
        <label htmlFor="rating-5">5 Stars</label>
    </div>;
}
