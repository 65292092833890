import React, { useEffect, useState } from "react";
import Modal from "./Modal";
import logoImg from '../assets/logo-transparent.png'
import HelpAudio from "./HelpAudio";
import { toast } from 'react-toastify';
import { getGrpcClient, getUuidv4 } from "../config/Common";
import { useSelector } from "react-redux";
import GlobalDomainCons from "../config/GlobalDomainCons";
import { ZiFetchRequest } from "../protobuf/generated/ZiFetchService_grpc_web_pb";
import GlobalEventCons from "../config/GlobalEventCons";
import GlobalMsgCons from "../config/GlobalMsgCons";

let howToYouLearn = [
    { label: "Via Website", value: "ViaWebsite" },
    { label: "Via Newsletter", value: "ViaNewsletter" },
    { label: "Via Radio", value: "ViaRadio" },
    { label: "Via Friends", value: "ViaFriends" },
    { label: "Via Staff", value: "ViaStaff" },
];

export default function CaptureKnowApp(props) {
    const auth = useSelector(state => state.authReducer);

    let [submitLoader, setSubmitLoader] = useState(false)
    let [captureKnowAppModal, setCaptureKnowAppModal] = useState(false)
    let [howToYouLearnSelected, setHowToYouLearnSelected] = useState("");

    useEffect(() => {
        getHowLearnThisAppData()
    }, [])

    function getHowLearnThisAppData() {
        let postJson = { reqId: getUuidv4(), userId: auth.loginId, orgId: auth.activeCompany }
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.csEventSetDomain);
        request.setEvent(GlobalEventCons.getHowLearnThisAppData);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, async (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn("Invalid Request");
            } else {
                let responseMsg = JSON.parse(response.getMessage());
                if(responseMsg.result === null) {
                    setCaptureKnowAppModal(true)
                }
            }
        });
    }

    function save() {
        setSubmitLoader(true)
        let postJson = { reqId: getUuidv4(), userId: auth.loginId, orgId: auth.activeCompany, dataVal: howToYouLearnSelected }
        console.log("postJson", postJson)
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.csEventSetDomain);
        request.setEvent(GlobalEventCons.updateHowLearnThisAppData);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, async (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn("Invalid Request");
            } else {
                let responseMsg = JSON.parse(response.getMessage());
                if (responseMsg.response === GlobalMsgCons.success) {
                    toast.success("Submitted Successfully.", {
                        position: "top-center",
                        autoClose: 1500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setCaptureKnowAppModal(false)
                }
            }
        });
    }

    return captureKnowAppModal && <Modal
        visible={captureKnowAppModal}
        parentclassName={"community-service-form"}
        closeModal={() => {
            setCaptureKnowAppModal(false)
        }}
        escapeSetup={true}
        size={"modal-lg"}
        heading={" "}
        body={<div style={{ minHeight: "65vh" }}>
            <div className="pb-2" style={{ width: 200, margin: "auto" }}>
                <img src={logoImg} className="img-fluid" />
            </div>

            <div className="my-2">
                <b>How did you learn about this app?</b>
                <div>
                    {
                        howToYouLearn.map((option, index) => {
                            let { value, label, help } = option
                            return <div className="form-check my-2" key={index}>
                                <input className="form-check-input" type="checkbox" value={value} id={"howToYouLearn" + index} checked={howToYouLearnSelected === value} onChange={() => {
                                    setHowToYouLearnSelected(value === howToYouLearnSelected ? "" : value)
                                }} />
                                <label className="form-check-label">
                                    <label style={{ display: "inline" }} className="mb-0" htmlFor={"howToYouLearn" + index}>{label}</label>
                                    <span className="px-2">{help}</span>
                                </label>
                            </div>
                        })
                    }
                </div>
            </div>
            <div className="text-center pt-3 pb-2">
                <button className="btn btn-primary" onClick={() => save()}
                    disabled={submitLoader || howToYouLearnSelected === ""}>
                    {submitLoader && <i className="fa fa-spinner fa-spin"></i>} Done <HelpAudio parentClass={""} audio={"115"} /></button>
            </div>
        </div>} />

}
