import { withRouter } from "react-router-dom";
import { useEffect, useState, } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import GlobalDomainCons from "../../../config/GlobalDomainCons";
import GlobalEventCons from "../../../config/GlobalEventCons";
import MyConfig from "../../../config/MyConfig";
import { useSelector } from 'react-redux';
import PaginationDatatable from "../../../components/PaginationDatatable";




function MyReferFriendList(props) {
    const auth = useSelector(state => state.authReducer);
    let { path } = props

    let [refresh, setRefresh] = useState(false);

    useEffect(() => {

    }, [])



    return <div className="mobile-container">
        <ToastContainer />
        <div className="header">
            <div style={{ display: "flex", flex: 1, padding: 5, flexDirection: "row", fontSize: 18 }}>
                <div style={{ padding: "0 10px" }} onClick={() => {
                    props.history.push(path + "/refer-friend")
                }}>
                    <i className='fas fa-chevron-left' />
                </div>
                <div style={{ flex: 1 }}>
                    <div>{"My Refer Friend List"}</div>
                </div>
            </div>
        </div>
        <div class="p-2 justify-content-center">
            <PaginationDatatable
                grpcClient={props.grpcClient}
                domain={GlobalDomainCons.csEncareSetDomain}
                event={GlobalEventCons.getReferFriendListSetEvent}
                data={[
                    {
                        column: "Answer",
                        cell: (row, key) => {
                            return <div style={{ textTransform: "capitalize" }}>{row.questionAns}</div>
                        }
                    },
                    {
                        column: "Phone number",
                        cell: (row, key) => {
                            return <div>{row.phoneNumber + (row.sentType ? " - " + row.sentType : "")}<i className={`${row.sentType === "whatsapp" ? "fab fa-whatsapp" : row.sentType === "sms" ? "fas fa-sms" : ""} mx-2`} style={{ color: row.sentType === "whatsapp" ? "#28a745" : row.sentType === "sms" ? "#007bff" : "", fontSize: 20 }} /></div>
                        }
                    },
                    {
                        column: "Email Id",
                        cell: (row, key) => {
                            return <div>{row.emailId}</div>
                        }
                    },
                    {
                        column: "Feedback",
                        cell: (row, key) => {
                            return <div>{row.feedback}</div>
                        }
                    }
                ]}
                postJsonInfo={{ userId: auth.loginId, orgId: MyConfig.communityServiceOrgId }}
                refresh={refresh}
            />
        </div>

    </div>;
}

export default withRouter(MyReferFriendList);