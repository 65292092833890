import React, { Fragment } from 'react';
import { getPageText, getValidLabels, getUuidv4, getGrpcClient, checkActiveAdmin, redEscape, getPageEventName, accumulation, commonEventList } from '../../config/Common';
import { connect } from 'react-redux';
import { ZiFetchRequest } from '../../protobuf/generated/ZiFetchService_grpc_web_pb';
import { v4 as uuidv4 } from 'uuid';
import GlobalDomainCons from '../../config/GlobalDomainCons';
import GlobalEventCons from '../../config/GlobalEventCons';
import Modal from '../../components/Modal';
import MyConfig from '../../config/MyConfig';
import { setMultipleAuthData } from '../../redux/actions';
import logoImg from '../../assets/logo-transparent.png'

const pageNameAcc = "morePage"
class CommunityServiceMore extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            calendarChannel: "",
            pageTextNew: null,
            boardMenuText: null,
            itemViewType: "table",
            subItemModelView: false,
            subItemObjList: []
        }
    }


    componentDidMount() {
        let { activeCompanyId, userId } = this.props
        accumulation(pageNameAcc, commonEventList("pageInitiated"), activeCompanyId, userId, this.props.grpcClient)
        this.getCalendarChannel()
        this.init()
    }

    componentDidUpdate(prevProps) {
        if (this.props.theme !== prevProps.theme) {
            let { list, pageTextNew } = this.state;
            var index = list.findIndex(data => data.id === "toggleTheme");

            if (index >= 0) {
                let { theme } = this.props;
                list[index] = {
                    icon: theme === "dark" ? "sun" : "moon",
                    iconColor: theme === "dark" ? "#f5e581" : "#747474",
                    id: "toggleTheme",
                    topLabel: getValidLabels(pageTextNew, 'toggleThemeIconTxt'),
                    sub: [
                        {
                            textLabel: getValidLabels(pageTextNew, 'toggleThemeIconTxt'),
                            routerPush: "",
                            functionCall: () => {
                                this.toggleTheme()
                            }
                        },
                    ]
                }

                this.setState({
                    list
                })
            }
        }
    }

    async logout() {

        let { userId, sessionId } = this.props

        let deviceToken = localStorage.getItem("deviceToken");
        let postJson = { reqId: uuidv4(), userId, deviceToken: deviceToken ? deviceToken : "", sessionId: sessionId ? sessionId : "" };
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.userSetDomain);
        request.setEvent(GlobalEventCons.userSignOutSetEvent);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === 'Invalid Request') {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                console.log("response", responseData)

            }
        });

    }
    init() {
        let itemViewType = localStorage.getItem(MyConfig.project_LocalStorage + "morePageViewType")
        this.setState({
            itemViewType: itemViewType ? itemViewType : "table"
        }, () => {
            this.initPage()
        })
    }

    async getCalendarChannel() {
        let { userId, activeCompanyId } = this.props;
        let postJson = { userId, organizationId: activeCompanyId, reqId: getUuidv4() };
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.companySetDomain);
        request.setEvent(GlobalEventCons.meetingChennalIdSetEvent);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === 'Invalid Request') {
                console.warn('Invalid Request.Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                if (responseData.response) {
                    let channelId = responseData.response;
                    // console.log("channelId", channelId)
                    this.setState({ calendarChannel: channelId })
                }
            }
        });
    }

    initPage = async () => {
        let pageTextNew = await getPageText('users');
        let boardMenuText = await getPageText('boardMenu');
        let { systemLevelRole, activeUserType, activeCompanyId, msgBoxUnreadCount, theme } = this.props

        var lengthOfList = 0;
        var listOfMenus = []

        lengthOfList = lengthOfList + 1
        listOfMenus.push({
            icon: "times-circle",
            iconColor: "#FF5722",
            id: lengthOfList,
            topLabel: "Escape",
            sub: [
                {
                    textLabel: "Escape",
                    functionCall: () => {
                        redEscape()
                    }
                },
            ]
        })

        lengthOfList = lengthOfList + 1
        listOfMenus.push({
            icon: "list",
            iconColor: "#7d6b8f",
            id: lengthOfList,
            topLabel: "Resources",
            sub: [
                {
                    textLabel: "Resources",
                    routerPush: "resources"
                },
            ]
        })



        lengthOfList = lengthOfList + 1
        listOfMenus.push({
            icon: "fa fa-user-plus",
            iconColor: "#51b062",
            id: lengthOfList,
            topLabel: "Refer a Friend",
            sub: [
                {
                    textLabel: "Refer a Friend",
                    routerPush: "refer-friend"
                },
            ]
        })

        lengthOfList = lengthOfList + 1
        listOfMenus.push({
            icon: "fas fa-shield-alt",
            iconColor: "#8f8d6b",
            id: lengthOfList,
            topLabel: "Privacy policy",
            sub: [
                {
                    textLabel: "Privacy policy",
                    routerPush: "privacy-policy"
                },
            ]
        })

        lengthOfList = lengthOfList + 1
        listOfMenus.push({
            icon: "fas fa-shield-alt",
            iconColor: "#B5651D",
            id: lengthOfList,
            topLabel: "Coping Skills",
            sub: [
                {
                    textLabel: "Coping Skills",
                    routerPush: "coping-skills"
                },
            ]
        })

        lengthOfList = lengthOfList + 1
        listOfMenus.push({
            icon: "language",
            iconColor: "#F25278",
            id: lengthOfList,
            topLabel: getValidLabels(pageTextNew, 'switchLanguageSidemenuTxt'),
            sub: [
                {
                    textLabel: getValidLabels(pageTextNew, 'switchLanguageSidemenuTxt'),
                    routerPush: "switch-language"
                },
            ]
        })

        lengthOfList = lengthOfList + 1
        listOfMenus.push({
            icon: "sign-out-alt",
            iconColor: "#42a7cd",
            id: lengthOfList,
            topLabel: getValidLabels(pageTextNew, 'logoutTopMenuTxt'),
            sub: [
                {
                    textLabel: getValidLabels(pageTextNew, 'logoutTopMenuTxt'),
                    functionCall: () => { this.props.logout() }
                },
            ]
        })

        this.setState({ list: listOfMenus, pageTextNew: pageTextNew, boardMenuText: boardMenuText })
    }

    toggleTheme() {
        let { theme } = this.props;
        let tempTheme = theme === "dark" ? "default" : "dark"
        this.props.setMultipleAuthData({
            theme: tempTheme
        })
    }

    redirectFeed() {
        let { feedId } = this.props
        if (!feedId) {
            feedId = "nill"
        }
        this.props.history.push(this.props.path + "/chat/" + feedId + "/feed")
    }

    redirectNotes() {
        this.props.history.push(this.props.path + "/notes")
    }

    renderItem = (item, index) => {
        let { activeCompanyId, userId, activeCompanyOwner, systemLevelRole, activeUserType, theme } = this.props
        var elements = []
        item.sub.map((kval, k) => {
            var data = <div style={{ margin: 10, padding: 10 }} className='moreLabel-bg'>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", cursor: "pointer" }}>
                    <div style={{ flex: 1 }}>
                        <div style={{ color: "#A9A9A9" }} >{kval.textLabel}</div>
                    </div>
                    {
                        (kval.count === "99+" || kval.count > 0) && <div style={{ padding: "0 10px", backgroundColor: "#d02e22", borderRadius: 5, }} className='mx-2'>
                            <div style={{ color: "#fff" }} >{kval.count}</div>
                        </div>
                    }
                    <div><i className='fas fa-chevron-right' /></div>
                </div>

            </div>

            var touch = <div>
                {data}
            </div>;

            if (kval.routerPush || kval.functionCall) {
                touch = <div
                    onClick={() => {
                        this.accumulationCapture(kval.textLabel)
                        if (kval.routerPush) {
                            this.props.history.push(this.props.path + "/" + kval.routerPush)
                        } else if (kval.functionCall) {
                            kval.functionCall()
                        }
                    }}
                >
                    {data}
                </div>;
            }

            /*company based condition */
            let pageDisable = false
            if (kval.isConditionCheck) {
                pageDisable = true
                if (activeCompanyId && (userId === activeCompanyOwner || checkActiveAdmin(activeUserType, systemLevelRole))) {
                    pageDisable = false
                }
            }

            if (kval.isOrgFeed) {
                pageDisable = true
                if (activeCompanyId && userId != activeCompanyOwner) {
                    pageDisable = false
                }
            }

            if (!pageDisable) {
                elements.push(<div key={k.toString()}>
                    {touch}
                </div>)
            }
        })
        return elements.length > 0 && <div key={index}>
            <div style={{ color: theme["text-secondary"], fontWeight: "bold", textAlign: "left", paddingLeft: 10 }}>{item.topLabel}</div>
            {elements}
        </div>
    }

    showSubItems = (subList) => {
        var subListLength = subList.length
        if (subListLength > 1) {
            this.setState({ subItemModelView: true, subItemObjList: subList })
        } else {
            var kval = subList[0]
            if (kval.routerPush) {
                this.props.history.push(this.props.path + "/" + kval.routerPush)
            } else if (kval.functionCall) {
                kval.functionCall()
            }
        }
    }

    onChangeView = () => {
        let { itemViewType } = this.state
        if (itemViewType == "list") {
            itemViewType = "table"
        } else {
            itemViewType = "list"
        }
        this.setState({ itemViewType: itemViewType })
    }

    renderColumns(item, index) {
        let { activeCompanyId, userId, activeCompanyOwner, systemLevelRole, activeUserType } = this.props
        var elements = []

        let count = 0
        item.sub.map((kval, k) => {
            count = kval.count ? kval.count : 0
            var data = <div >
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <div style={{ flex: 1 }}>
                        <div>{kval.textLabel}</div>
                    </div>
                    {
                        kval.count && <div style={{ paddingHorizontal: 10, backgroundColor: "#d02e22", borderRadius: 5 }}>
                            <div style={{ color: "#fff" }} >{kval.count}</div>
                        </div>
                    }

                </div>
            </div>

            var touch = <div>
                {data}
            </div>;

            if (kval.routerPush || kval.functionCall) {
                touch = <div
                    onClick={() => {
                        if (kval.routerPush) {
                            this.props.history.push(this.props.path + "/" + kval.routerPush)
                        }
                        else if (kval.functionCall) {
                            kval.functionCall()
                        }
                    }}
                >
                    {data}
                </div>;
            }
            /*company based condition */
            let pageDisable = false
            if (kval.isConditionCheck) {
                pageDisable = true
                if (activeCompanyId && (userId === activeCompanyOwner || checkActiveAdmin(activeUserType, systemLevelRole))) {
                    pageDisable = false
                }
            }

            if (kval.isOrgFeed) {
                pageDisable = true
                if (activeCompanyId && userId != activeCompanyOwner) {
                    pageDisable = false
                }
            }

            if (!pageDisable) {
                elements.push(<div key={k.toString()}>
                    {touch}
                </div>)
            }
        })

        return elements.length > 0 && <div key={index} onClick={() => {
            this.accumulationCapture(item.topLabel)
            this.showSubItems(item.sub)
        }} style={{ display: "flex", textAlign: "center", flexDirection: "column", width: '33%', alignItems: "center", minHeight: 120, paddingBottom: 10 }}>
            {
                item.icon ? <div style={{ cursor: "pointer", position: "relative", display: "flex", height: 90, width: 90, borderRadius: 10, alignItems: "center", justifyContent: "center" }} className='background-secondary mt-3'>
                    <i className={`fa fa-${item.icon}`} style={{ color: item.iconColor, fontSize: 32 }} />
                    {
                        (item.sub.length === 1 && (count === "99+" || count > 0)) && <div style={{ width: 25, height: 25, lineHeight: "25px", backgroundColor: "#d02e22", borderRadius: "50%", position: "absolute", top: 3, right: 5, display: "flex", justifyContent: "center" }}>
                            <span style={{ color: "#fff", fontSize: 12 }}>{count}</span>
                        </div>
                    }
                </div> : null
            }
            <div>{item.topLabel}</div>
        </div>
    }

    renderSubColumns = (item) => {

        return <div onClick={() => {
            this.setState({
                subItemModelView: false
            }, () => {
                item.routerPush ? this.props.history.push(this.props.path + "/" + item.routerPush) : item.functionCall()
            })
        }
        } style={{ width: '33%', minHeight: 120, paddingBottom: 10, alignItems: "center", display: 'flex', flexDirection: 'column', textAlign: "center" }}>
            {
                item.icon ? <div style={{ height: 90, width: 90, borderRadius: 10, backgroundColor: "#ddd", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <i className={`fa fa-${item.icon}`} style={{ color: item.iconColor, fontSize: 32 }} />
                </div> : <div style={{ height: 90, width: 90, borderRadius: 10, backgroundColor: "#ddd", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <i className={`fa fa-table`} style={{ color: "#000", fontSize: 32 }} />
                </div>
            }
            <div>{item.textLabel}</div>
        </div>
    }
    accumulationCapture(label) {
        let { activeCompanyId, userId } = this.props
        let accumulationText = ""
        switch (label) {
            case "Resources":
                accumulationText = "resourcesIconClicked";
                break;
            case "Escape":
                accumulationText = "escapeIconClicked";
                break;
            case "Refer a Friend":
                accumulationText = "referaFriendIconClicked";
                break;
            case "Privacy policy":
                accumulationText = "privacyPolicyIconClicked";
                break;
            case "Coping Skills":
                accumulationText = "copingSkillsIconClicked";
                break;
            case "Switch Language":
                accumulationText = "switchLanguageIconClicked";
                break;
            case "Log Out":
                accumulationText = "logoutIconClicked";
                break;

            default:
                accumulationText = "";
        }
        if (accumulationText !== "" && accumulationText) {
            accumulation(pageNameAcc, getPageEventName("morePage", accumulationText), activeCompanyId, userId, this.props.grpcClient)
        }
    }
    render() {
        let { list, itemViewType, subItemModelView, subItemObjList } = this.state;
        let { activeCompanyName, loggedEmail, pageText, userId, activeCompanyId } = this.props;
        return <div className="chat-menu mobile-container more-menu">
            <div className="header">
                <div className='align-items-center' style={{ display: "flex", flex: 1, paddingBottom: 5, flexDirection: "row" }}>
                    <div style={{ padding: "0 10px" }}>
                        <div style={{ fontSize: 18 }}>{getValidLabels(pageText, 'moreTxt')}</div>
                    </div>
                    <div className='text-center' style={{ flex: 1 }}>
                        <div style={{ width: 120, margin: "auto" }}>
                            <img src={logoImg} className="img-fluid" />
                        </div>
                        <div style={{ fontSize: 14 }}>
                            {loggedEmail} <br />
                        </div>
                    </div>
                    <div style={{ padding: "0 10px" }}>
                        <div onClick={() => {
                            accumulation(pageNameAcc, getPageEventName("morePage", itemViewType === "list" ? "tableIconClicked" : "listIconClicked"), activeCompanyId, userId, this.props.grpcClient)
                            this.onChangeView()
                        }} style={{ cursor: "pointer" }}>
                            <i className={`fa fa-${itemViewType === "list" ? "table" : "list"}`} />
                        </div>
                    </div>
                </div>
            </div>
            <div className='body more-list'>
                {
                    itemViewType === "table" ? <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap' }}>
                        {
                            list.map((item, index) => {
                                return this.renderColumns(item, index)
                            })
                        }
                    </div> : <div>
                        {
                            list.map((item, index) => {
                                return this.renderItem(item, index)
                            })
                        }
                    </div>
                }
            </div>
            {
                subItemModelView && <Modal
                    visible={subItemModelView}
                    closeModal={() => {
                        this.setState({
                            subItemModelView: false
                        })
                    }}
                    heading={""}
                    body={<React.Fragment>
                        <div className='text-right my-2'>
                            <div className='p-2' onClick={() => {
                                this.setState({
                                    subItemModelView: false
                                })
                            }}>
                                <i className='fa fa-times' />
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap' }}>
                            {
                                subItemObjList.map((item, index) => {
                                    return <React.Fragment key={index}>{this.renderSubColumns(item, index)}</React.Fragment>
                                })
                            }
                        </div>
                    </React.Fragment>
                    }>
                </Modal>
            }
        </div>;
    }
}

const mapStateToProps = (state) => {
    return {
        activeCompanyName: state.authReducer.activeCompanyName,
        systemLevelRole: state.authReducer.systemLevelRole,
        activeUserType: state.authReducer.activeUserType,
        activeCompanyId: state.authReducer.activeCompany,
        activeCompanyOwner: state.authReducer.companyOwnerId,
        userId: state.authReducer.loginId,
        sessionId: state.authReducer.sessionId,
        controlFeature: state.authReducer.controlFeature,
        feedId: state.authReducer.feedId,
        loggedEmail: state.authReducer.loggedEmail,
        theme: state.authReducer.theme,
    }
};

const mapDispatchToProps = {
    setMultipleAuthData
};

export default connect(mapStateToProps, mapDispatchToProps)(CommunityServiceMore);