import React from "react";
import { useState, useEffect } from "react";
import Modal from "./Modal";
import pdfFile from "../assets/pdf.png";
import NotFoundFile from "../assets/NotFound.png";
import { PDFViewer } from "react-view-pdf";
import { getPageText, getValidLabels } from '../config/Common';
import TxtFileView from "./TxtFileView";


function FileView(props) {
    let [previewModal, setPreviewModal] = useState(false);
    let [fileError, setFileError] = useState(false);
    let [pageText, setPageText] = useState({});

    let name = props.name;
    let buildUrl = props.src;
    let fileType = props.type;

    useEffect( () => {
        initPage()
    }, [])
    
    async function initPage(){
        let pageText = await getPageText('board');
        setPageText(pageText)
    }
    function previewContent(fileview) {
        let content = <div></div>;
        if (fileError) {
            content = <div className="file-view" onClick={() => {
                setPreviewModal(true)
            }}>
                <img src={NotFoundFile} className="img-fluid" />
            </div>
        } else {
            let images = ["jpg", "png", "jpeg", "gif"];
            let videos = ["mp4", "3gp", "ogg", "mov", "webm", "wav"]
            let pdf = ["pdf"]
            let txt = ["txt"]
            let audio = ["mp3"]
            let pgpExt = ["pgp"]

            if (images.includes(fileType.toLowerCase())) {
                content = <div className="file-view" onClick={() => {
                    setPreviewModal(true)
                }}>
                    <img src={buildUrl} className="img-fluid" onError={(e) => (
                        setFileError(true)
                    )} />
                </div>
            } else if (videos.includes(fileType.toLowerCase())) {
                content = fileview ? <div style={{height: "75vh"}}><video className="video-responsive" controls onError={(e) => {
                    setFileError(true)
                }}>
                    <source src={buildUrl} />
                </video>
                </div> : <div className="file-view" onClick={() => {
                    setPreviewModal(true)
                }}>
                    <div>
                        <div style={{ fontSize: 32 }}>
                            <i className="fas fa-video"></i>
                        </div>
                        <div>
                            Click to view this Video file.
                        </div>
                    </div>
                </div>
            } else if (audio.includes(fileType.toLowerCase())) {
                content = <div className="file-view" onClick={() => {
                    setPreviewModal(true)
                }}>
                    <audio width="100%" controls onError={(e) => {
                        setFileError(true)
                    }}>
                        <source src={buildUrl} />
                    </audio>
                </div>
            } else if (pdf.includes(fileType.toLowerCase())) {
                content = fileview ? <PDFViewer url={buildUrl} /> : <div className="file-view" onClick={() => {
                    setPreviewModal(true)
                }}>
                    <img src={pdfFile} className="img-fluid" />
                </div>
            } else if (txt.includes(fileType.toLowerCase())) {
                content = fileview ? <div style={{ minHeight: 200 }}>
                    <TxtFileView url={buildUrl} />
                </div> : <div className="file-view" onClick={() => {
                    setPreviewModal(true)
                }}>
                    <div style={{ color: "#fff" }}>
                        <div style={{ fontSize: 32 }}>
                            <i className="far fa-file-word"></i>
                        </div>
                        <div>
                            Click to view this TXT file.
                        </div>
                    </div>
                </div>
            }
            else if (pgpExt.includes(fileType.toLowerCase())) {
                content = fileview ? <div style={{ minHeight: 200 }}>
                    <TxtFileView url={buildUrl} />
                </div> : <div className="file-view" onClick={() => {
                    setPreviewModal(true)
                }}>
                    <div style={{ color: "#fff" }}>
                        <div style={{ fontSize: 32 }}>
                            <i className="far fa-file-word"></i>
                        </div>
                        <div>
                            Click to view this PGP file.
                        </div>
                    </div>
                </div>
            } else {
                content = <div className="file-view text-center" style={{ border: '1px solid #ddd', borderRadius: 10 }} onClick={() => {
                    let downloadUrl = props.downloadUrl;
                    window.open(downloadUrl ? downloadUrl : buildUrl, '_blank').focus();
                }}>
                    <div>
                        <div style={{ fontSize: 32 }}>
                            <i className="far fa-file-alt"></i>
                        </div>
                        <div>
                            Download File
                        </div>
                    </div>
                </div>
            }
        }
        return content;
    }
    return <React.Fragment>
        {
            previewContent(false)
        }
        {
            previewModal && <Modal
                visible={previewModal}
                size={"modal-xl"}
                closeModal={() => setPreviewModal(false)}
                heading={`${getValidLabels(pageText, 'previewHeaderTxt')} ${name !== undefined ? " - " + name : ""}`}
                bodyClassRemove={props.bodyClassRemove}
                body={<div style={{ minHeight: "75vh" }}>
                    <div className="row">
                        <div className="col-12 text-center">
                            {
                                previewContent(true)
                            }
                        </div>
                    </div>

                </div>
                }
            />
        }
    </React.Fragment>;
}

export default FileView;