import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Dropzone from 'react-dropzone';
import Dragplaceholder from '../../../assets/Dragfile.png';
import { ToastContainer, toast } from 'react-toastify';
import MyConstant from '../../../config/MyConstant';
import { ZiFetchRequest } from '../../../protobuf/generated/ZiFetchService_grpc_web_pb';
import { getGrpcClient, getPageText, getValidLabels, getUuidv4, checkMobileView } from '../../../config/Common';
import Card from '../../../components/Card';
import FileView from "../../../components/FileView";
import MyConfig from '../../../config/MyConfig';
import Modal from '../../../components/Modal';
import ReactTooltip from 'react-tooltip';
import countriesJSON from "../../../json/countries.json"
import DropDown from '../../../components/DropDown';
import { setMyProfileData, setMultipleMyProfileData, setMultipleAuthData } from '../../../redux/actions';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import ThemeColor from '../../../components/ThemeColor';
import GlobalMsgCons from '../../../config/GlobalMsgCons';
import GlobalDomainCons from '../../../config/GlobalDomainCons';
import GlobalEventCons from '../../../config/GlobalEventCons';
const pageNameAcc = "myAccountPage"

class MyProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageText: null,
            hoursOption: MyConfig.hourFormate,
        }
    }

    componentDidMount() {
        let { userId, companyId } = this.props;
        this.getCountryList()
        this.getPageText()
        this.getUserProfile(countriesJSON.countries);
        this.getLanguageList()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.languagePreferencemyprofile !== this.props.languagePreferencemyprofile) {
            this.getPageText()
        }
    }

    getCountryList() {
        this.props.setMultipleMyProfileData({ countryOptions: countriesJSON.countries })
    }

    getLanguageList() {
        let postJson = { reqId: getUuidv4(), };
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.uiSetDomain);
        request.setEvent(GlobalEventCons.languagesDataSetEvent);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === "Invalid Request") {
                console.log("Invalid Request. Please try again later")
            } else {
                let json = JSON.parse(response.getMessage());
                let languageOptions = []
                let selectedVal = ""
                if (json && json.uiMap && Object.keys(json.uiMap).length !== 0) {
                    Object.keys(json.uiMap).map((key) => {
                        let lan = json.uiMap[key]
                        var obj = JSON.parse(lan)
                        languageOptions.push({ label: obj["name"], value: key })
                        if (key === "eng") {
                            selectedVal = { label: obj["name"], value: key }
                        }
                    })

                }

                this.props.setMyProfileData("languageOptions", languageOptions)
                // this.props.setMyProfileData("languageSelected", selectedVal)
            }
        });
    }

    stringAppendStar(str, index,) {
        let textString = "*"
        let stringToAdd = textString.repeat(index)
        let replaceBetween = (start, end, what, endString) => {
            return endString.substring(0, start) + what + endString.substring(end);
        }
        let startString = stringToAdd + str.slice(index);
        let endString = startString.slice(0, startString.length - index) + stringToAdd;
        let centerIndex = parseInt(endString.length / 2)
        let remove = replaceBetween(centerIndex, centerIndex + index, stringToAdd, endString)
        return remove;
    }



    getUserProfile(countryOptions) {
        let { userId } = this.props;
        let postJson = { reqId: getUuidv4(), sessionId: '123', userId };
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.userSetDomain);
        request.setEvent(GlobalEventCons.userProfileSetEvent);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                var response = responseData.response;
                let { firstName, lastName, languagePreference, themeColor, profilePhoto, pincode, address, country, hourFormat, phone } = response;
                let documentFiles = [],
                    document_Json = [];
                if (profilePhoto && profilePhoto != "") {
                    let split = profilePhoto.split(".")
                    documentFiles.push({ processType: "profile", fileType: split[1], docsId: split[0] })
                    document_Json.push({ processType: "profile", fileType: split[1], docsId: split[0], fileName: profilePhoto })
                }

                languagePreference = languagePreference ? languagePreference : "eng";
                themeColor = themeColor ? themeColor : "#1e325c";

                let countrySelected = "";
                let findCountryData = countryOptions.filter((val => { return val.value === country }))
                if (findCountryData && findCountryData.length > 0) {
                    countrySelected = findCountryData[0]
                }

                this.props.setMultipleMyProfileData({
                    user_FirstName: firstName,
                    user_LastName: lastName,
                    user_profilePhoto: profilePhoto,
                    documentFiles,
                    document_Json,
                    userBgColor: themeColor,
                    languagePreference,
                    languageSelected: languagePreference,
                    countrySelected,
                    pincode, address,
                    hoursSelected: hourFormat,
                    contactNumber: phone ? phone : ""
                })

            }
        });
    }

    documentOnDrop(newFiles) {
        var documentFiles = [];
        var document_Json = [];

        let file_Name = ""
        for (var i = 0; i < newFiles.length; i++) {
            let file = newFiles[i];
            let filename = file.name;
            let ext = filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
            let uid = uuidv4();

            documentFiles.push(newFiles[i]);
            document_Json.push({
                name: "",
                path: newFiles[i],
                status: 'active',
                progress: '0',
                origFileName: filename,
                fileName: uid + "." + ext,
                docsId: uid,
                processType: "profile",
                fileType: ext
            })
            file_Name = uid + "." + ext
        }
        this.props.setMultipleMyProfileData({
            documentFiles: documentFiles,
            document_Json: document_Json,
            user_profilePhoto: file_Name
        });
    }

    documentOnRemove(loopIndex) {
        var document_Json = this.props.document_Json;
        var documentFiles = this.props.documentFiles;
        document_Json.splice(loopIndex, 1);
        documentFiles.splice(loopIndex, 1);
        this.props.setMultipleMyProfileData({
            document_Json: document_Json,
            documentFiles: documentFiles,
            user_profilePhoto: ""
        });

    }


    async uploadDocumentFiles(documentArray, i) {
        const postFileUpload = new FormData();
        postFileUpload.append('file' + (i + 1), documentArray.path);
        postFileUpload.append('fileName' + (i + 1), documentArray.fileName);
        postFileUpload.append('processType' + (i + 1), documentArray.processType);
        postFileUpload.append('docsId' + (i + 1), documentArray.docsId);
        let { userId } = this.props;
        let result = await fetch(MyConstant.keyList.apiURL + "uploads/" + documentArray.processType + "/" + documentArray.docsId + "/" + userId, {
            method: "POST",
            mode: 'cors',
            redirect: 'follow',
            body: postFileUpload,
        }).then(
            function (response) {
                return response.json();
            }
        ).then(json => {
            return json.response;
        }).catch(error => console.warn(error));

        return result
    }

    async sendPostJson(documentArray, i) {
        var that = this;
        var document_Json = this.props.document_Json;
        document_Json[i].progress = 50;
        that.props.setMultipleMyProfileData({
            document_Json: document_Json
        })
        if (document_Json.length > 0) {
            if (documentArray.path) {
                await that.uploadDocumentFiles(documentArray, i);
            }
            document_Json[i].progress = 100;
            that.props.setMultipleMyProfileData({
                document_Json: document_Json
            })
        }
    }

    async updateDetails() {
        let { user_FirstName, user_LastName, document_Json, userId, documentFiles, user_profilePhoto, languagePreference, languageSelected, userBgColor, countrySelected, pincode, address, hoursSelected, contactNumber } = this.props;
        let { pageText } = this.state;
        let errorFields = {}

        if (!user_FirstName) {
            errorFields.firstName = getValidLabels(pageText, 'enterFirstNameErr_msg')
        }

        if (!user_LastName) {
            errorFields.lastName = getValidLabels(pageText, 'enterLastNameErr_msg')
        }
        if (documentFiles.length === 0) {
            // errorFields.profilePhoto = getValidLabels(pageText, 'chooseImageFilesErr_msg')
        }
        if (!countrySelected) {
            errorFields["country"] = "Please Choose Country";
        }
        if (!pincode) {
            errorFields["pincode"] = "Please Enter Pincdoe";
        }

        this.props.setMultipleMyProfileData({ errorFields })

        if (Object.keys(errorFields).length === 0) {
            this.props.setMultipleMyProfileData({ loaderBtn: true })

            let fileList = {};
            for (var i = 0; i < document_Json.length; i++) {
                await this.sendPostJson(document_Json[i], i);
                let fileDetails = document_Json[i];
                fileList = {
                    origFileName: fileDetails.origFileName,
                    fileName: fileDetails.fileName,
                    docsId: fileDetails.docsId,
                    processType: fileDetails.processType,
                    fileType: fileDetails.fileType
                };

            }
            let postJson = { reqId: getUuidv4(), userId, firstName: user_FirstName, lastName: user_LastName, profilePhoto: Object.keys(fileList).length === 0 ? "" : fileList.fileName, languagePreference: languageSelected, themeColor: userBgColor, country: countrySelected.value.toString(), pincode, address: address ? address : "", hourFormat: hoursSelected, phone: contactNumber };
            const request = new ZiFetchRequest();
            request.setDomain(GlobalDomainCons.userSetDomain);
            request.setEvent(GlobalEventCons.editUserProfileSetEvent);
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
                if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                    console.warn('Invalid Request. Please try again later');
                } else {
                    let responseData = JSON.parse(response.getMessage());
                    localStorage.setItem(MyConfig.project_LocalStorage + "languagePreference", languageSelected);
                    if (responseData.response === GlobalMsgCons.success) {
                        this.props.setMultipleMyProfileData({ loaderBtn: false })
                        toast.success(getValidLabels(pageText, 'updatedSuccess_msg'), {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true
                        });

                        this.props.setFirstName(user_FirstName);
                        this.props.setActiveUserCompanyColor(userBgColor);
                        this.props.setLanguagePreference(languageSelected);
                        this.props.setMultipleAuthData({ phone: contactNumber ? contactNumber : "" })
                        this.getPageText()

                    }
                }
            });
        }

    }
    async getPageText() {
        let pageText = await getPageText('myProfile');
        this.setState({ pageText: pageText })
    }

    async submmitVsCode() {
        let { userId, publicKey, ipAddress, companyId } = this.props;
        let postJson = { userId, orgId: companyId, publicKey: publicKey, allowedIP: ipAddress, reqId: getUuidv4(), };
        let errorIP = {}
        if (!publicKey) {
            errorIP.publicKey = "Please Enter publicKey"
        }
        if (!ipAddress) {
            errorIP.ipAddress = "Please Enter Address"
        }
        else if (ipAddress) {
            let checkValidate = await this.ValidateIPaddress(ipAddress)
            if (!checkValidate) {
                errorIP.ipAddress = "Please Enter Address"
            }
        }
        this.props.setMultipleMyProfileData({ errorIP })
        if (Object.keys(errorIP).length === 0) {
            this.props.setMultipleMyProfileData({ vsCodeLoader: true })
            //userId:String, orgId:String, publicKey: String, allowedIP: String
            const request = new ZiFetchRequest();
            request.setDomain(GlobalDomainCons.vsCodeSetDomain);
            request.setEvent(GlobalEventCons.updateUserSystemConfigSetEvent);
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
                if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                    console.warn('Invalid Request. Please try again later');
                } else {
                    let responseData = JSON.parse(response.getMessage());
                    if (responseData.response === GlobalMsgCons.success) {
                        this.props.setMultipleMyProfileData({ vsCodeLoader: false })
                        this.props.vsCodeReadData()
                        toast.success('My Configuration Updated!', {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true
                        });


                    }
                }
            });
        }
    }

    ValidateIPaddress(ipaddress) {
        const regexExp = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi;
        let test = regexExp.test(ipaddress)
        return test
    }

    async passwordCheck() {
        let { passwordText, askPassworModal, loggedEmail } = this.props;
        let passwordTextError = ""
        let checkPasswordResponse = false



        if (!passwordText) {
            passwordTextError = "Please Enter Password"
        }
        this.props.setMultipleMyProfileData({ passwordTextError })
        if (passwordText) {
            let postJson = { reqId: getUuidv4(), email: loggedEmail, password: passwordText, };
            this.props.setMultipleMyProfileData({ vsCodeLoader: true })
            //userId:String,orgId:String
            const request = new ZiFetchRequest();
            request.setDomain(GlobalDomainCons.manageSetDomain);
            request.setEvent(GlobalEventCons.userSignInSetEvent);
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
                if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                    console.warn('Invalid Request. Please try again later');
                } else {
                    let responseData = JSON.parse(response.getMessage());
                    let responseResult = responseData.response
                    if (responseResult === GlobalMsgCons.success) {
                        checkPasswordResponse = true
                        askPassworModal = false
                    } else {
                        toast.error('Mismatch Password!', {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true
                        });
                    }
                    this.props.setMultipleMyProfileData({ checkPasswordResponse, askPassworModal, vsCodeLoader: false })
                }
            });
        }
    }


    capitalizeFirstLetter(string) {
        let stringAppend = ""
        if (string === "activate") {
            stringAppend = "d"
        }
        return string.charAt(0).toUpperCase() + string.slice(1) + stringAppend;
    }

    setActiveTab(activeTab) {
        this.props.setMultipleMyProfileData({ activeTab: activeTab })
    }

    getContent() {
        let { user_FirstName, user_LastName, documentFiles, editable, errorFields, userBgColor, loaderBtn, userId, countryOptions, countrySelected, pincode, address, languageOptions, languageSelected, hoursSelected, contactNumber, companyId } = this.props;
        let { pageText, hoursOption } = this.state;

        return <React.Fragment>
            <div className='row mx-0'>
                <div className='col-sm-4'>
                    <label>{getValidLabels(pageText, 'firstNameLabelTxt')}</label>
                    <input type={"text"} className={(!editable ? "disable-color" : "") + " form-control "} value={user_FirstName} onChange={(e) => {
                        this.props.setMultipleMyProfileData({ user_FirstName: e.target.value })
                    }}
                        disabled={!editable}
                    />
                    <span style={{ color: "#dc3545", fontSize: 14 }}>{errorFields.firstName} </span>
                </div>
                <div className='col-sm-4'>
                    <label>{getValidLabels(pageText, 'lastNameLabelTxt')}</label>
                    <input type={"text"} className={(!editable ? "disable-color" : "") + " form-control "} value={user_LastName} onChange={(e) => {
                        this.props.setMultipleMyProfileData({ user_LastName: e.target.value })
                    }}
                        disabled={!editable}
                    />
                    <span style={{ color: "#dc3545", fontSize: 14 }}>{errorFields.lastName} </span>
                </div>
                <div className="col-sm-4">
                    <div className="form-group">
                        <label>{getValidLabels(pageText, 'themeColorLabelTxt')}</label>
                        <ThemeColor
                            selectedColor={userBgColor} setColor={(color) => {
                                if (editable) {
                                    this.props.setMultipleMyProfileData({ userBgColor: color })
                                }
                            }} />
                    </div>
                </div>
            </div>


            <div className='row mx-0'>
                <div className='col-sm-4'>
                    <div className="form-group">
                        <label>{getValidLabels(pageText, 'countryLabelTxt')}</label>
                        <DropDown
                            selectedOption={countrySelected}
                            onChange={(e) => this.props.setMultipleMyProfileData({
                                countrySelected: e
                            })}
                            options={countryOptions}
                            isMulti={false}
                            placeholder={getValidLabels(pageText, 'chooseCountry_PhTxt')}
                            error={errorFields['country'] ? true : false}
                            isDisabled={!editable}
                        />
                        {errorFields['country'] && <div className="d-block invalid-feedback">{errorFields['country']}</div>}
                    </div>
                </div>
                <div className='col-sm-4'>
                    <div className="form-group">
                        <label>{getValidLabels(pageText, 'addressLabelTxt')}</label>
                        <input type="text" className={`form-control   ${(!editable ? "disable-color" : "")} ${errorFields['address'] ? "is-invalid" : ""}`} placeholder={getValidLabels(pageText, 'address_PhTxt')} value={address} onChange={(e) => this.props.setMultipleMyProfileData({
                            address: e.target.value
                        })}
                            disabled={!editable}
                        />
                        {errorFields['address'] && <div className="invalid-feedback">{errorFields['address']}</div>}
                    </div>
                </div>
                <div className='col-sm-4'>
                    <div className="form-group">
                        <label>{getValidLabels(pageText, 'pinCodeLabelTxt')}</label>
                        <input type="text" className={`form-control   ${(!editable ? "disable-color" : "")} ${errorFields['pincode'] ? "is-invalid" : ""}`} placeholder={getValidLabels(pageText, 'pinCode_PhTxt')} value={pincode} onChange={(e) => this.props.setMultipleMyProfileData({
                            pincode: e.target.value
                        })}
                            disabled={!editable}
                        />
                        {errorFields['pincode'] && <div className="invalid-feedback">{errorFields['pincode']}</div>}
                    </div>
                </div>
            </div>

            <div className='row mx-0 mt-2'>
                <div className="col-sm-4">
                    <div className="form-group">
                        <label>{getValidLabels(pageText, 'languagePreferenceRadioTxt')}</label>
                        <div>
                            <DropDown
                                options={languageOptions}
                                isSearchable={true}
                                placeholder={getValidLabels(pageText, 'languagePreferenceRadioTxt')}
                                onChange={(e) => {
                                    this.props.setMyProfileData("languageSelected", e.value)
                                }}
                                selectedOption={languageOptions.filter((val => { return val.value === languageSelected }))}
                                isDisabled={!editable}
                                isMulti={false}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-sm-4">
                    <div className="form-group">
                        <label>{"Hour format"}</label>
                        <div>
                            <DropDown
                                options={hoursOption}
                                isSearchable={true}
                                placeholder={"Choose Hours"}
                                onChange={(e) => {
                                    this.props.setMyProfileData("hoursSelected", parseInt(e.value))
                                    this.props.setMultipleAuthData({
                                        "hoursSelected": parseInt(e.value)
                                    })

                                }}
                                selectedOption={hoursOption.filter((val => { return val.value.toString() === hoursSelected.toString() }))}
                                isDisabled={!editable}
                                isMulti={false}
                            />
                        </div>
                    </div>
                </div>
                <div className='col-sm-4'>
                    <div className="form-group">
                        <label>{"Contact Number"}</label>
                        <input type="number" className={`form-control   ${(!editable ? "disable-color" : "")}`} placeholder="Contact Number" value={contactNumber} onChange={(e) => this.props.setMultipleMyProfileData({
                            contactNumber: e.target.value
                        })}
                            disabled={!editable}
                        />
                    </div>
                </div>

                <div className='col-sm-6'>
                    {editable ? <>
                        <label> {getValidLabels(pageText, 'imageLabelTxt')}</label>
                        <div className="dropback" >
                            <Dropzone accept=".png, .jpg, .jpeg," multiple={true}
                                onDrop={(acceptedFiles) => {
                                    this.documentOnDrop(acceptedFiles)
                                }} onDropRejected={(reject) => {
                                    toast.error(getValidLabels(pageText, 'fileFormatErr_msg'), {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true
                                    });

                                }}  >
                                {({ getRootProps, getInputProps }) => (
                                    <section>
                                        <div {...getRootProps({ className: 'dropzone' })}>
                                            <input {...getInputProps()} />
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <img src={Dragplaceholder} className='dragimgph' />
                                                </div>
                                                <div className="col-sm-9">
                                                    <p className='dragtext'>
                                                        {getValidLabels(pageText, 'dragandPlaceFilesPlaceholder')}
                                                        <br />{getValidLabels(pageText, 'fileFormatsPlaceholder')}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                )}
                            </Dropzone>
                        </div>
                        <span style={{ color: "#dc3545", fontSize: 14 }}>{errorFields.profilePhoto} </span>
                    </> : <>
                        <label>{getValidLabels(pageText, 'imageLabelTxt')}</label>
                    </>}
                    <div className='mt-2'>
                        {
                            documentFiles.map((f, loopIndex) => {
                                let filePath = ""
                                let fileName = ""
                                let fileType = ""
                                if (f instanceof File) {
                                    filePath = URL.createObjectURL(f)
                                    fileName = f.name
                                    fileType = fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2);
                                }
                                else {
                                    let buildUrl = MyConstant.keyList.apiURL + "vp?action=" + f.processType + "&key=" + f.docsId + "." + f.fileType + "&id=" + userId;
                                    filePath = buildUrl
                                    fileType = f.fileType
                                }
                                let returnData = <div style={{ width: 100, height: 100, border: '1px solid #ddd', borderRadius: 50, overflow: 'hidden' }}>
                                    <FileView name={getValidLabels(pageText, 'myProfilePictureHeaderTxt')} type={fileType} src={filePath} />
                                </div>
                                return (<div key={loopIndex} className='row'>
                                    <div className='col-3 col-sm-3 col-md-3' >
                                        {returnData}
                                    </div>
                                    <div className='col-2 col-sm-2 col-md-2' >
                                        {editable &&
                                            <i className="fa fa-trash" aria-hidden="true" style={{ position: "absolute", color: "red", top: 10, cursor: "pointer", }}
                                                onClick={() => {
                                                    this.documentOnRemove(loopIndex)
                                                }}
                                            > {getValidLabels(pageText, 'deleteIconTxt')}</i>
                                        }
                                    </div>
                                </div>)
                            })
                        }
                    </div>
                </div>
            </div>
            {editable ? <>
                <div className='row mx-0 my-2'>
                    <div className='col-sm-4' />
                    <div className='col-sm-4' style={{ textAlign: "center" }}>
                        <button className="btn btn-primary" onClick={() => {
                            this.updateDetails()
                        }} >{loaderBtn ? <i className="fas fa-spinner fa-spin"></i> : getValidLabels(pageText, 'updateBtnTxt')}</button>
                    </div>
                    <div className='col-sm-4' />
                </div>
            </> : <></>}
        </React.Fragment>
    }
    render() {
        let { path, user_FirstName, editable, publicKey, ipAddress, errorIP, vsCodeLoader, modalWindowShow, checkPasswordResponse, askPassworModal, passwordText, environmentData, companyId, userId, toggleIcon, toggleIcon2, tutorialModal, activeTab, countryOptions, } = this.props;
        let { pageText } = this.state;

        let { vsCodeMappingData } = this.props
        var environmentValue = ""
        var peerInfoDetails = {}
        if (vsCodeMappingData && Object.keys(vsCodeMappingData).length > 0) {
            let { mappingData } = vsCodeMappingData
            if (mappingData && mappingData != null) {
                let { peerInfo } = mappingData
                if (peerInfo && peerInfo != null) {
                    peerInfoDetails = peerInfo
                }
            }
        }

        return (<React.Fragment>
            <ToastContainer />
            <ReactTooltip place="bottom" type="dark" effect="float" html={true} />
            <div className='mobile-container'>
                <div className="header">
                    <div style={{ display: "flex", flex: 1, padding: 5, flexDirection: "row", fontSize: 18 }}>
                        <div style={{ padding: "0 10px" }} onClick={() => {
                            this.props.history.push(path + "/home/more")
                        }}>
                            <i className='fas fa-chevron-left' />
                        </div>
                        <div style={{ flex: 1 }}>
                            <div>{editable ? getValidLabels(pageText, 'editProfileTitleTxt') : getValidLabels(pageText, 'myProfileTitleTxt')}</div>
                        </div>
                        <div style={{ padding: "0 10px" }}>
                            <button className="btn btn-sm btn-primary" onClick={() => {
                                this.props.setMultipleMyProfileData({ editable: !this.props.editable })
                                if (editable) {
                                    this.getUserProfile(countryOptions)
                                }
                            }}>
                                {editable ? getValidLabels(pageText, 'profileBtnTxt') : getValidLabels(pageText, 'editBtnTxt')}
                            </button>
                        </div>
                    </div>
                </div>
                <div className='body'>
                    {this.getContent()}
                </div>
            </div>
        </React.Fragment>)
    }
}

const mapStateToProps = (state) => {
    return {
        userId: state.authReducer.loginId,
        companyId: state.authReducer.activeCompany,
        loggedEmail: state.authReducer.loggedEmail,
        companyOwnerId: state.myProfileReducer.companyOwnerId,
        user_FirstName: state.myProfileReducer.user_FirstName,
        user_LastName: state.myProfileReducer.user_LastName,
        user_profilePhoto: state.myProfileReducer.user_profilePhoto,
        documentFiles: state.myProfileReducer.documentFiles,
        document_Json: state.myProfileReducer.document_Json,
        errorFields: state.myProfileReducer.errorFields,
        editable: state.myProfileReducer.editable,
        pageText: state.myProfileReducer.pageText,
        workspaceColors: state.myProfileReducer.workspaceColors,
        userBgColor: state.myProfileReducer.userBgColor,
        languageList: state.myProfileReducer.languageList,
        languagePreference: state.myProfileReducer.languagePreference,
        loaderBtn: state.myProfileReducer.loaderBtn,
        vsCodeEdit: state.myProfileReducer.vsCodeEdit,
        publicKey: state.myProfileReducer.publicKey,
        ipAddress: state.myProfileReducer.ipAddress,
        errorIP: state.myProfileReducer.errorIP,
        vsCodeLoader: state.myProfileReducer.vsCodeLoader,
        modalWindowShow: state.myProfileReducer.modalWindowShow,
        checkPasswordResponse: state.myProfileReducer.checkPasswordResponse,
        askPassworModal: state.myProfileReducer.askPassworModal,
        passwordText: state.myProfileReducer.passwordText,
        passwordTextError: state.myProfileReducer.passwordTextError,
        environmentData: state.myProfileReducer.environmentData,
        userSystemConfigDetails: state.myProfileReducer.userSystemConfigDetails,
        toggleIcon: state.myProfileReducer.toggleIcon,
        toggleIcon2: state.myProfileReducer.toggleIcon2,
        tutorialModal: state.myProfileReducer.tutorialModal,
        activeTab: state.myProfileReducer.activeTab,
        address: state.myProfileReducer.address,
        pincode: state.myProfileReducer.pincode,
        countryOptions: state.myProfileReducer.countryOptions,
        countrySelected: state.myProfileReducer.countrySelected,
        languageOptions: state.myProfileReducer.languageOptions,
        languageSelected: state.myProfileReducer.languageSelected,
        hoursSelected: state.myProfileReducer.hoursSelected,
        contactNumber: state.myProfileReducer.contactNumber,
        state,
    };
};

const mapDispatchToProps = {
    setMyProfileData, setMultipleMyProfileData, setMultipleAuthData
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyProfile));