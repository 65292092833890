import { Link, withRouter } from "react-router-dom";
import { useEffect, useState, } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import GlobalDomainCons from "../../../config/GlobalDomainCons";
import GlobalEventCons from "../../../config/GlobalEventCons";
import GlobalMsgCons from "../../../config/GlobalMsgCons";
import MyConfig from "../../../config/MyConfig";
import { useSelector } from 'react-redux';
import { detectMobile, getGrpcClient, getUuidv4, commonEventList, accumulation, getPageEventName } from "../../../config/Common";
import { ZiFetchRequest } from "../../../protobuf/generated/ZiFetchService_grpc_web_pb";
import logoImg from '../../../assets/logo-transparent.png'
import MyConstant from "../../../config/MyConstant";

const pageNameAcc = "referaFriendPage"


function ReferFriend(props) {
    const auth = useSelector(state => state.authReducer);
    let { path } = props
    let [questionAns, setQuestionAns] = useState("");
    let [phoneNumber, setPhoneNumber] = useState("");
    let [emailId, setEmailId] = useState("");
    let [feebackTxt, setFeebackTxt] = useState("");
    let [errorMsg, setErrorMsg] = useState({});
    let [refresh, setRefresh] = useState(false);

    let { loginId, activeCompany, } = auth;

    useEffect(() => {
        accumulation(pageNameAcc, commonEventList("pageInitiated"), activeCompany, loginId, props.grpcClient)
    }, [])



    function resetState() {
        setFeebackTxt("")
        setEmailId("")
        setPhoneNumber("")
        setQuestionAns("")
    }

    function submitPost(submitType, sentType) {
        errorMsg = {}
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        let toastMessage = "Submitted successfully";
        if (submitType === "feedback") {
            if (!feebackTxt) {
                errorMsg.feedback = "Please enter feedback"
            }
        }
        if (submitType === "email") {
            if (emailId.trim() === '') {
                errorMsg["emailId"] = "Please enter email";
            } else if (reg.test(emailId) === false) {
                errorMsg["emailId"] = "Please enter valid email";
            }
        }
        if (submitType === "mobile") {
            if (!phoneNumber) {
                errorMsg["phoneNumber"] = "Please enter phone number";
            }
        }

        setErrorMsg(errorMsg)
        if (Object.keys(errorMsg).length) {
            return false
        }
        let postJson = { reqId: getUuidv4(), userId: auth.loginId, orgId: MyConfig.communityServiceOrgId, questionAns: questionAns, phoneNumber: "", sentType: "", emailId: "", feedback: "" }
        if (submitType === "feedback") {
            postJson.feedback = feebackTxt
        }
        else if (submitType === "email") {
            postJson.emailId = emailId
            postJson.sentType = sentType
        }
        else if (submitType === "mobile") {
            postJson.phoneNumber = phoneNumber
            postJson.sentType = sentType

        }
        console.log("postJson", postJson)
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.csEncareSetDomain);
        request.setEvent(GlobalEventCons.addReferFriendSetEvent);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, async (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn("Invalid Request");
            } else {
                let responseMsg = JSON.parse(response.getMessage());
                if (responseMsg.response === GlobalMsgCons.success) {
                    toast.success(toastMessage, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setRefresh(!refresh)
                    resetState()
                }

            }
        });
    }

    function sendSms() {

    }
    return <div className="mobile-container">
        <ToastContainer />
        <div className="header">
            <div style={{ display: "flex", flex: 1, padding: 5, flexDirection: "row", fontSize: 18 }}>
                <div style={{ padding: "0 10px" }} onClick={() => {
                    accumulation(pageNameAcc, getPageEventName("referaFriendPage", "backAeroIconClicked"), activeCompany, loginId, props.grpcClient)
                    props.history.push(path + "/home/more")
                }}>
                    <i className='fas fa-chevron-left' />
                </div>
                <div style={{ flex: 1 }}>
                    <div>{"Refer a Friend"}</div>
                </div>
            </div>
        </div>
        <div className='body refer-friend'>
            <div className="d-flex justify-content-center">
                <div className='logo py-5'>
                    <Link to={path}>
                        <img src={logoImg} className="img-fluid" />
                    </Link>
                </div>
            </div>
            <div className="d-flex justify-content-center py-5" style={{ gap: 10 }}>
                {
                    detectMobile() && <div className="share-icon">
                        <a target="_blank" href={`sms:?body=Share%20https://encare.ng/`} className="icon">
                            <i className="fas fa-sms"></i>
                        </a>
                    </div>
                }
                <div className="share-icon" onClick={() => { accumulation(pageNameAcc, getPageEventName("referaFriendPage", "mailIconClicked"), activeCompany, loginId, props.grpcClient) }}>
                    <a target="_blank" href={`mailto:?&subject=Encare&body=Share%20https://encare.ng/`} className="icon email">
                        <i className="fas fa-envelope"></i>
                    </a>
                </div>

            </div>
        </div>

    </div>;
}

export default withRouter(ReferFriend);