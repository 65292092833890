import { withRouter } from "react-router-dom";
import HEIF from '../../../assets/resources/HEIF-Full.png'
import wrahp from '../../../assets/resources/wrahp-Full.png'
import humanity from '../../../assets/resources/humanity.jpg'
import felialex from '../../../assets/resources/felialex.png'
import logoImg from '../../../assets/logo-transparent.png'
import React, { useState, useEffect } from "react";
import { accumulation, commonEventList,getPageEventName } from "../../../config/Common";
import { useSelector } from "react-redux";

const pageNameAcc = 'resourcesPage'

function Resources(props) {
    let { path } = props
    const auth = useSelector(state => state.authReducer);
    let { loginId, activeCompany, } = auth;

    useEffect(() => {
        accumulation(pageNameAcc, commonEventList("pageInitiated"), activeCompany, loginId, props.grpcClient)
    }, [])
    return <div className="mobile-container">
        <div className="header">
            <div style={{ display: "flex", flex: 1, padding: 5, flexDirection: "row", fontSize: 18 }}>
                <div style={{ padding: "0 10px" }} onClick={() => {
                    accumulation(pageNameAcc, getPageEventName("resourcesPage", "backAeroIconClicked"),activeCompany, loginId,props.grpcClient)
                    props.history.push(path + "/home/more")
                }}>
                    <i className='fas fa-chevron-left' />
                </div>
                <div style={{ flex: 1 }}>
                    <div>{"Resources"}</div>
                </div>
            </div>
        </div>
        <div className="body">
            <div className="py-5" style={{ width: 200, margin: "auto" }}>
                <img src={logoImg} className="img-fluid" />
            </div>
            <div className="d-flex justify-content-center resources">
                <div className="resource-box">
                    <a href="https://www.havilahempowermentfoundation.org/">
                        <img className="img-fluid" src={HEIF} />
                    </a>
                </div>
                <div className="resource-box">
                    <a href="https://wrahp.com/">
                        <img className="img-fluid" src={wrahp} />
                    </a>
                </div>
                <div className="resource-box">
                    <img className="img-fluid" src={humanity} />
                </div>
                <div className="resource-box">
                    <img className="img-fluid" src={felialex} />
                </div>
            </div>
        </div>
    </div>;
}

export default withRouter(Resources);