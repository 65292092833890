import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';

function ImageView(props) {
    let { url } = props;
    return (
        <PhotoProvider>
            <PhotoView src={url}>
                <img src={url} alt="" />
            </PhotoView>
        </PhotoProvider>
    );
}

export default ImageView;