import React from 'react';
import { getPageText, getValidLabels } from '../config/Common';


export default class Pagination extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageText: null,
            pre: "",
            nxt: ""
        }
    }
    componentDidMount() {
        this.getPageText()
    }
    async getPageText() {
        let pageText = await getPageText('manage');
        let pre = getValidLabels(pageText, 'previousBtnTxt')
        let nxt = getValidLabels(pageText, 'nextBtnTxt')
        this.setState({ pageText: pageText, pre: pre, nxt: nxt })
    }
    render() {
        let { pagination, prev, next, movePage, currentPage, pageText } = this.props;
        let { pre, nxt } = this.state;
        let lastItem = pagination[pagination.length - 1];
        let paginationArr = Array(lastItem).fill(1).map((n, i) => n + i)

        return (
            <React.Fragment>
                <nav>
                    <ul className="pagination justify-content-end my-2">
                        <li className={`page-item ${prev ? "" : "disabled"}`}>
                            <a className="page-link" onClick={() => movePage('prev')}>{pre}</a>
                        </li>
                        {
                            pagination.map((page, index) => {
                                return <li key={index} className={`page-item ${currentPage === page ? "active" : ((page === 0 || page === "...") ? "disabled" : "")}`}><a className="page-link" onClick={() => movePage(page)}>{(page === 0 || page === "...") ? "..." : page}</a></li>
                            })
                        }
                        <li className={`page-item ${next ? "" : "disabled"}`}>
                            <a className="page-link" onClick={() => movePage('next')}>{nxt}</a>
                        </li>
                        <li className={`page-item ml-2`}>
                            {
                                lastItem > 10 && <select className='form-control' value={currentPage} onChange={(e) => {
                                    movePage(parseInt(e.target.value))
                                }}>
                                    {
                                        paginationArr.map(function (x, i) {
                                            return <option value={x} key={i}>{x}</option>;
                                        })
                                    }
                                </select>
                            }
                        </li>
                    </ul>
                </nav>
            </React.Fragment>
        );
    }
}