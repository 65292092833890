import {
  SET_AUTH,
  RESET_AUTH,
  SET_MULTIPLE_AUTH,
  SET_LOGIN,
  RESET_LOGIN,
  SET_REGISTER,
  SET_MULTIPLE_REGISTER,
  RESET_REGISTER,
  SET_FORGOT_PASSWORD,
  SET_MULTIPLE_FORGOT_PASSWORD,
  RESET_FORGOT_PASSWORD,
  SET_CHAT,
  SET_MULTIPLE_CHAT,
  RESET_CHAT,
  SET_CHAT_CHANNEL,
  SET_MULTIPLE_CHAT_CHANNEL,
  RESET_CHAT_CHANNEL,
  SET_THREAD_CHAT,
  SET_MULTIPLE_THREAD_CHAT,
  RESET_THREAD_CHAT,
  SET_MY_PROFILE,
  SET_MULTIPLE_MY_PROFILE,
  RESET_MY_PROFILE,
  RESET_REPORT_HERE,
  SET_MULTIPLE_REPORT_HERE,
  SET_REPORT_HERE,
} from '../constants/actionTypes';

export const setAuthData = (key, value) => ({
  type: SET_AUTH,
  key: key,
  value: value,
});

export const setMultipleAuthData = (data) => ({
  type: SET_MULTIPLE_AUTH,
  data: data,
});

export const reSetAuthData = () => ({
  type: RESET_AUTH,
});

export const setLoginData = (key, value) => ({
  type: SET_LOGIN,
  key: key,
  value: value,
});

export const reSetLoginData = () => ({
  type: RESET_LOGIN,
});

export const setRegisterData = (key, value) => ({
  type: SET_REGISTER,
  key: key,
  value: value,
});

export const setMultipleRegisterData = (data) => ({
  type: SET_MULTIPLE_REGISTER,
  data: data,
});

export const reSetRegisterData = () => ({
  type: RESET_REGISTER,
});

export const setForgotPasswordData = (key, value) => ({
  type: SET_FORGOT_PASSWORD,
  key: key,
  value: value,
});

export const setMultipleForgotPasswordData = (data) => ({
  type: SET_MULTIPLE_FORGOT_PASSWORD,
  data: data,
});

export const reSetForgotPasswordData = () => ({
  type: RESET_FORGOT_PASSWORD,
});

export const setChatData = (key, value) => ({
  type: SET_CHAT,
  key: key,
  value: value,
});

export const setMultipleChatData = (channelId, data) => ({
  type: SET_MULTIPLE_CHAT,
  channelId,
  data,
});

export const reSetChatData = () => ({
  type: RESET_CHAT,
});

export const setChatChannelData = (key, value) => ({
  type: SET_CHAT_CHANNEL,
  key: key,
  value: value,
});

export const setMultipleChatChannelData = (data) => ({
  type: SET_MULTIPLE_CHAT_CHANNEL,
  data,
});

export const reSetChatChannelData = () => ({
  type: RESET_CHAT_CHANNEL,
});

export const setThreadChatData = (key, value) => ({
  type: SET_THREAD_CHAT,
  key: key,
  value: value,
});

export const setMultipleThreadChatData = (data) => ({
  type: SET_MULTIPLE_THREAD_CHAT,
  data: data,
});

export const reSetThreadChatData = () => ({
  type: RESET_THREAD_CHAT,
});

export const setMyProfileData = (key, value) => ({
  type: SET_MY_PROFILE,
  key: key,
  value: value,
});

export const setMultipleMyProfileData = (data) => ({
  type: SET_MULTIPLE_MY_PROFILE,
  data: data,
});

export const reSetMyProfileData = () => ({
  type: RESET_MY_PROFILE,
});

export const setReportHereData = (key, value) => ({
  type: SET_REPORT_HERE,
  key: key,
  value: value,
});

export const setMultipleReportHereData = (data) => ({
  type: SET_MULTIPLE_REPORT_HERE,
  data: data,
});

export const reSetReportHere = () => ({
  type: RESET_REPORT_HERE,
});
