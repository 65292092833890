import { useEffect, useState } from "react";
import { commonEventList, accumulation, getPageEventName } from "../../../config/Common";
import { useSelector } from 'react-redux';

let interval;

const cycleTextArr = [
    "4 Second Inhale",
    "4 Second Wait",
    "4 Second Exhale",
    "4 Second Wait"
]
function ResponseSecond(props) {
    let { path } = props;
    let [cycle, setCycle] = useState(1)
    let [cycleText, setCycleText] = useState(0)
    let [startDisable, setStartDisable] = useState(false)
    let [nextDisable, setNextDisable] = useState(true)
    let [timer, setTimer] = useState(0)
    let [repeatEnable, setRepeatEnable] = useState(false)

    const pageNameAcc = "copingSkillsPage"
    const auth = useSelector(state => state.authReducer);
    let { loginId, activeCompany, } = auth;

    useEffect(() => {
        if (repeatEnable) {
            let trackingData = { ...props.trackingData };
            trackingData[cycle] = "completed";
        }
    }, [repeatEnable])

    useEffect(() => {
        if (timer === 4) {
            if (cycleText < 3) {
                setNextDisable(false)
            } else {
                setRepeatEnable(true)
            }
            clearInterval(interval)
        }
    }, [timer])

    function cycleClass(val) {
        let str = "";
        if (cycle === val) {
            str = "active"
        } else if (cycle > val) {
            str = "completed"
        }
        return str
    }

    function startTimer() {
        setStartDisable(true)
        interval = setInterval(() => {
            countdown();
        }, 1000);
    }

    function countdown() {
        setTimer(c => c + 1)
    }

    function next() {
        setCycleText(c => c + 1);
        setTimer(0);
        startTimer();
        setNextDisable(true)
    }

    function done() {
        props.history.push(path + "/home/more")
    }

    return <div>
        <div className="header-second">
            Coping Skill <br />
            Deep Breathing
        </div>
        <div className="cycle">
            <div className="cycle-header d-flex">
                <div className="cycle-item text-center">
                    <div className={`steps ${cycleClass(1)}`}>1</div>
                </div>
                <div className="cycle-item text-center">
                    <div className={`steps ${cycleClass(2)}`}>2</div>
                </div>
                <div className="cycle-item text-center">
                    <div className={`steps ${cycleClass(3)}`}>3</div>
                </div>
            </div>
        </div>
        <div className="timer">
            <div className="timer-text text-center py-3">
                {cycleTextArr[cycleText]}
            </div>
            <div className="timer-view">
                <div className="round">
                    <div className="value">{timer}</div>
                </div>
            </div>
            <div className="timer-action py-5 text-center">
                <button className="timer-button timer-button-start" disabled={startDisable} onClick={() => {
                    accumulation(pageNameAcc, getPageEventName("copingSkillsPage", "startBtnClicked"), activeCompany, loginId, props.grpcClient)
                    startTimer()
                }}>Start</button>
                <button className="timer-button timer-button-next mx-2" disabled={nextDisable} onClick={() => {
                    accumulation(pageNameAcc, getPageEventName("copingSkillsPage", "nextBtnClicked"), activeCompany, loginId, props.grpcClient)
                    next()
                }}>Next</button>
            </div>
            {
                repeatEnable && <div className="timer-action py-2 text-center">
                    <button className="timer-button timer-button-repeat" onClick={() => {
                        if (cycle === 3) {
                            done()
                        } else {
                            setRepeatEnable(false);
                            setStartDisable(false);
                            setCycle(c => c + 1)
                            setCycleText(0)
                            setTimer(0)
                        }
                    }}>{cycle === 3 ? "Done" : "Repeat"}</button>
                </div>
            }

        </div>
    </div>;
}
export default ResponseSecond;