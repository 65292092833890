import React, { useState } from "react";
import Modal from "../../../../components/Modal";
import { Link } from "react-router-dom";
import Rating from "../../../../components/Rating";
import { ToastContainer, toast } from 'react-toastify';
import MyConfig from "../../../../config/MyConfig";
import GlobalEventCons from "../../../../config/GlobalEventCons";
import GlobalDomainCons from "../../../../config/GlobalDomainCons";
import { ZiFetchRequest } from "../../../../protobuf/generated/ZiFetchService_grpc_web_pb";
import { getGrpcClient, getUuidv4 } from "../../../../config/Common";
import GlobalMsgCons from "../../../../config/GlobalMsgCons";
import { useSelector } from 'react-redux';
import logoImg from '../../../../assets/logo-transparent.png'
import HelpAudio from "../../../../components/HelpAudio";


let howToImprove = [
    { label: "Better connectivity.", value: "Better connectivity.", help: <HelpAudio parentClass={""} audio={"109"} /> },
    { label: "Direct access to community support organization", value: "Direct access to community support organization", help: <HelpAudio parentClass={""} audio={"110"} /> },
    { label: "Direct access to the police", value: "Direct access to the police" , help: <HelpAudio parentClass={""} audio={"111"} />},
    { label: "Other", value: "Other", help: <HelpAudio parentClass={""} audio={"112"} /> },
];
export default function NextStepEvent(props) {
    const auth = useSelector(state => state.authReducer);
    let { eventModal, modalClose, path, requestSendingChannelId, setReportSubmit } = props;
    let [action, setAction] = useState("");
    let [rating, setRating] = useState("5");
    let [howToImproveSelected, setHowToImproveSelected] = useState([]);
    let [other, setOther] = useState("");

    function save() {

        let getMsgId = localStorage.getItem(MyConfig.project_LocalStorage + "reportMessageId");
        getMsgId = getMsgId ? getMsgId : ""
        let postJson = { reqId: getUuidv4(), msgId: getMsgId, chId: requestSendingChannelId, rating, howToImproveSelected, other, userId: auth.loginId, orgId: MyConfig.communityServiceOrgId }
        console.log("postJson", postJson)
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.csEncareSetDomain);
        request.setEvent(GlobalEventCons.addNextStepFromSetEvent);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, async (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn("Invalid Request");
            } else {
                let responseMsg = JSON.parse(response.getMessage());
                if (responseMsg.response === GlobalMsgCons.success) {
                    toast.success("Submitted Successfully.", {
                        position: "top-center",
                        autoClose: 1500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                    modalClose()
                    localStorage.setItem(MyConfig.project_LocalStorage + "reportSubmit", false);
                    setReportSubmit(false)

                    if (path) {
                        props.history.push(path + "/next-step-thanks")
                    }

                }

            }
        });



    }

    function getBody() {
        let str;

        switch (action) {
            case "closestCommunity":
            case "localPolice":
            case "noAction":
                str = <React.Fragment>
                    <div className="text-right pt-2">
                        <button className="btn btn-sm btn-primary" onClick={() => setAction("")}><i className="fa fa-chevron-left" /></button>
                    </div>
                    <div className="text-center py-2">
                        <div className="mail-envelope">
                            <i className="fas fa-envelope"></i>
                        </div>
                        <div>
                            <h4>Thank you for reporting with Encare! <HelpAudio parentClass={""} audio={"106"} /></h4>
                        </div>
                    </div>
                    <div className="my-2">
                        <h6>How would you rate this app? <HelpAudio parentClass={""} audio={"107"} /></h6>
                    </div>
                    <div>
                        <Rating value={rating} setValue={(e) => setRating(e)} />
                    </div>
                    {
                        rating <= 3 && <React.Fragment>
                            <div className="my-2">
                                <div className="form-group">
                                    <h4 style={{ fontSize: 22}}>{"Please tell us how to improve"} <HelpAudio parentClass={""} audio={"108"} /></h4>
                                    <div>
                                        {
                                            howToImprove.map((option, index) => {
                                                let { value, label, help } = option
                                                return <div className="form-check" key={index}>
                                                    <input className="form-check-input" type="checkbox" value={value} id={"howToImprove" + index} checked={howToImproveSelected.includes(value)} onChange={() => {
                                                        let clone = [...howToImproveSelected]
                                                        if (clone.includes(value)) {
                                                            const index = clone.indexOf(value);
                                                            clone.splice(index, 1)
                                                        } else {
                                                            clone.push(value)
                                                        }
                                                        setHowToImproveSelected(clone)
                                                    }} />
                                                    <label className="form-check-label">
                                                        <label style={{ display: "inline"}} className="mb-0" htmlFor={"howToImprove" + index}>{label}</label>
                                                        <span className="px-2">{help}</span>
                                                    </label>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            {
                                howToImproveSelected.includes("Other") && <div className="my-2">
                                    <div className="form-group">
                                        <textarea className={`mb-2 form-control`} rows="4" cols="50" value={other} onChange={(e) => {
                                            setOther(e.target.value.substring(0, 100))

                                        }} />
                                        <span className="float-right"> {other.length} / 100 <HelpAudio parentClass={""} audio={"113"} /></span>
                                    </div>
                                </div>
                            }
                        </React.Fragment>
                    }

                    <div className="text-center pt-3 pb-2">
                        <button className="btn btn-primary" onClick={() => save()}>Done <HelpAudio parentClass={""} audio={"115"} /></button>
                    </div>
                </React.Fragment>
                break;
                break;
            // case "noAction":
            //     str = <React.Fragment>
            //         <div className="text-right py-5">
            //             <button className="btn btn-sm btn-primary" onClick={() => setAction("")}><i className="fa fa-chevron-left" /></button>
            //         </div>
            //         <div className="d-flex justify-content-center py-5"><Link to={path + "/home/chat/" + requestSendingChannelId + "/service"}>Sent to Report Log</Link></div>
            //     </React.Fragment>
            //     break;
            default:
                str = <React.Fragment>
                    <div className="my-2">
                        <h6>Please choose the next step <HelpAudio parentClass={""} audio={"100"} /></h6>
                    </div>
                    <ul>
                        <li onClick={() => { setAction("closestCommunity") }}>
                            <div className="btn-link" >File this report with the closest community organization.  <HelpAudio parentClass={""} audio={"103"} /></div>
                        </li>
                        <li onClick={() => { setAction("localPolice") }}>
                            <div className="btn-link" >File this report with the local police. <HelpAudio parentClass={""} audio={"104"} /></div>
                        </li>
                        <li onClick={() => { setAction("noAction") }}>
                            <div className="btn-link" >File this report here for your records with no further action. <HelpAudio parentClass={""} audio={"105"} /></div>
                        </li>
                    </ul>
                </React.Fragment>
        }

        return str
    }
    return <React.Fragment>
        <ToastContainer />
        {
            eventModal && <Modal
                visible={eventModal}
                parentclassName={"community-service-form"}
                closeModal={() => {
                    modalClose()
                }}
                escapeSetup={true}
                size={"modal-lg"}
                heading={" "}
                body={<div style={{ minHeight: "65vh" }}>
                    <div className="pb-2" style={{ width: 200, margin: "auto" }}>
                        <img src={logoImg} className="img-fluid" />
                    </div>
                    {getBody()}
                </div>} />
        }
    </React.Fragment>;
}
