import React, { Component } from 'react';

export default class ThemeColor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            workspaceColors: ["#1e325c", "#2f3136", "#eeeeee", "#9c27b0", "#2196f3", "#4caf50", "#ff9800", "#607d8b", "#dd4197", "#ee595a"]
        }
    }
    render() {
        let { workspaceColors } = this.state;
        let { selectedColor, defaultColor } = this.props;

        return <div className="workspace-bg">
            {
                workspaceColors.map((color, colorIndex) => {
                    return <div key={colorIndex} className={`color ${color === selectedColor ? "active" : ""}`} onClick={() => this.props.setColor(color)}>
                        <div style={{ backgroundColor: color, padding: 5 }}></div>
                    </div>
                })
            }
        </div>
    }
}