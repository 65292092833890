import React from 'react'
import Confetti from 'react-confetti'
import { withRouter } from 'react-router-dom';
import HelpAudio from '../../../../components/HelpAudio';

function DailyTrackThanks(props) {
    let { path} = props;
    return <div className='daily-track-thanks'>
        <Confetti
            width={window.innerWidth}
            height={window.innerHeight}
        />
        <div className='thanks-box'>
            <div>Thank you for using the Encare Response. <HelpAudio parentClass={""} audio={"114"} /></div>

            <div>
                <button className='btn btn-success my-5' onClick={() => {
                    props.history.push(path)
                }}>Done <HelpAudio parentClass={""} audio={"115"} /></button>
            </div>
        </div>
    </div>
}

export default withRouter(DailyTrackThanks)