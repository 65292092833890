import { withRouter } from "react-router-dom";
import { dateConverter, getInboxIcon } from "../../../config/Common";

function InboxPreview(props) {
    let { msgData } = props.history.location.state;
    let { subject, createdAt, message } = msgData;

    let iconObj = getInboxIcon(subject);
    return <div className="mobile-container">
        <div className="header">
            <div style={{ display: "flex", flex: 1, padding: 5, flexDirection: "row", fontSize: 18 }}>
                <div style={{ padding: "0 10px" }} onClick={() => {
                    props.history.push(props.path + "/inbox")
                }}>
                    <i className='fas fa-chevron-left' />
                </div>
                <div style={{ flex: 1 }}>
                    <div>Inbox Preview</div>
                </div>
            </div>
        </div>
        <div className="body">
            <div className="d-flex border-row">
                <div className="icon px-2 d-flex align-items-center" style={{ fontSize: "1.5rem", backgroundColor: iconObj.color, color: "#fff"}}>
                    {iconObj.icon}
                </div>
                <div className="py-2 px-2">
                    <div><b>{subject}</b></div>
                    <div>{dateConverter(createdAt)}</div>
                </div>
            </div>
            <div className="message m-2" id="inbox-message" style={{ overflow: "auto"}}>
                <div dangerouslySetInnerHTML={{ __html: message }}></div>
            </div>
        </div>
    </div>;
}

export default withRouter(InboxPreview);