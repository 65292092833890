import { SET_LOGIN, RESET_LOGIN } from '../constants/actionTypes';

const initialState = {
  email: "",
  password: "",
  passwordVisible: false,
  errors: {
    email: "",
    password: "",
    country: ""
  },
  loginLoader: false,
  countrySelected: {
    "value": "NG",
    "id": 160,
    "sortname": "NG",
    "label": "Nigeria",
    "phoneCode": 234
  },
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOGIN: {
      return {
        ...state,
        [action.key]: action.value,
      };
    }
    case RESET_LOGIN: {
      return {
        ...state,
        email: "",
        password: "",
        passwordVisible: false,
        errors: {
          email: "",
          password: "",
          country: ""
        },
        loginLoader: false,
        countrySelected: {
          "value": "NG",
          "id": 160,
          "sortname": "NG",
          "label": "Nigeria",
          "phoneCode": 234
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default loginReducer;
