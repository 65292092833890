import React, { useEffect, useState } from "react";

export default function InactivityTracker(props) {
    let { inactivityClose } = props;
    const [isInActive, setIsInActive] = useState(false);

    useEffect(() => {
        let secondsSinceLastActivity = 0;

        let maxInactivity = 60;
        let intervalId = setInterval(function () {
            secondsSinceLastActivity++;
            if (secondsSinceLastActivity > maxInactivity) {
                setIsInActive(true);
                secondsSinceLastActivity = 0;
                clearInterval(intervalId)
            }
        }, 1000);

        function activity() {
            secondsSinceLastActivity = 0;
        }

        let activityEvents = [
            "mousedown",
            "mousemove",
            "keydown",
            "scroll",
            "touchstart",
        ];

        activityEvents.forEach(function (eventName) {
            document.addEventListener(eventName, activity, true);
        });
        return () => {
            activityEvents.forEach(function (eventName) {
                document.addEventListener(eventName, activity);
            });
        };
    }, []);

    useEffect(() => {
        if (isInActive) {
            inactivityClose()
        }
    }, [isInActive]);

    return <React.Fragment></React.Fragment>;
}
