import React, { Suspense, lazy, useEffect, useState } from 'react';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';

import {
  Switch,
  Route,
  withRouter
} from "react-router-dom";
import { useSelector } from "react-redux";
import NoMatch from "./pages/NoPage";
import MyConstant from "./config/MyConstant";
import { createBrowserHistory } from 'history'
import Loader from './components/Loader';
import MyConfig from './config/MyConfig';
import { checkLangFileExistAndDownload, getLanguageData } from './config/MyLangManager';
import UserRoute from './pages/user/UserRoute';
import ReportHere from './pages/user/ReportHere';
import Login from './pages/Login';
import RegisterPin from './pages/RegisterPin';
import ReportHereHome from './pages/user/ReportHereHome';
import DeleteAccount from './pages/DeleteAccount';
import ReportHereLaunch from './pages/user/ReportHereLaunch';
import PrivacyPolicy from './pages/user/common/PrivacyPolicy';
import GlobalEventCons from './config/GlobalEventCons';
import GlobalDomainCons from './config/GlobalDomainCons';
import { getUuidv4, grpcRequest } from './config/Common';

const SwitchLanguage = lazy(() => import('./pages/SwitchLanguage'));
const VerifyAccountEmail = lazy(() => import('./pages/VerifyAccountEmail'));


const {
  ZiFetchService1Client,
  ZiFetchService2Client,
  ZiFetchService3Client,
  ZiFetchService4Client,
  ZiFetchService5Client,
  ZiFetchService6Client,
  ZiFetchService7Client,
  ZiFetchService8Client,
  ZiFetchService9Client,
  ZiFetchService10Client,
  ZiPollingService1Client,
  ZiPollingService2Client,
  ZiPollingService3Client,
  ZiPollingService4Client,
  ZiPollingService5Client
} = require('./protobuf/generated/ZiFetchService_grpc_web_pb');


let grpcClient1 = new ZiFetchService1Client(MyConstant.keyList.grpcURL, {}, {});
let grpcClient2 = new ZiFetchService2Client(MyConstant.keyList.grpcURL, {}, {});
let grpcClient3 = new ZiFetchService3Client(MyConstant.keyList.grpcURL, {}, {});
let grpcClient4 = new ZiFetchService4Client(MyConstant.keyList.grpcURL, {}, {});
let grpcClient5 = new ZiFetchService5Client(MyConstant.keyList.grpcURL, {}, {});
let grpcClient6 = new ZiFetchService6Client(MyConstant.keyList.grpcURL, {}, {});
let grpcClient7 = new ZiFetchService7Client(MyConstant.keyList.grpcURL, {}, {});
let grpcClient8 = new ZiFetchService8Client(MyConstant.keyList.grpcURL, {}, {});
let grpcClient9 = new ZiFetchService9Client(MyConstant.keyList.grpcURL, {}, {});
let grpcClient10 = new ZiFetchService10Client(MyConstant.keyList.grpcURL, {}, {});

let grpcPollingClient1 = new ZiPollingService1Client(MyConstant.keyList.grpcURL, {}, {});
let grpcPollingClient2 = new ZiPollingService2Client(MyConstant.keyList.grpcURL, {}, {});
let grpcPollingClient3 = new ZiPollingService3Client(MyConstant.keyList.grpcURL, {}, {});
let grpcPollingClient4 = new ZiPollingService4Client(MyConstant.keyList.grpcURL, {}, {});
let grpcPollingClient5 = new ZiPollingService5Client(MyConstant.keyList.grpcURL, {}, {});

const searchProto = require('./protobuf/search/ZiFetchService_grpc_web_pb');
let searchGrpcClient1 = new searchProto.ZiFetchService1Client(MyConstant.keyList.searchGrpcURL, {}, {});
let searchGrpcClient2 = new searchProto.ZiFetchService2Client(MyConstant.keyList.searchGrpcURL, {}, {});
let searchGrpcClient3 = new searchProto.ZiFetchService3Client(MyConstant.keyList.searchGrpcURL, {}, {});
let searchGrpcClient4 = new searchProto.ZiFetchService4Client(MyConstant.keyList.searchGrpcURL, {}, {});
let searchGrpcClient5 = new searchProto.ZiFetchService5Client(MyConstant.keyList.searchGrpcURL, {}, {});
let searchGrpcClient6 = new searchProto.ZiFetchService6Client(MyConstant.keyList.searchGrpcURL, {}, {});
let searchGrpcClient7 = new searchProto.ZiFetchService7Client(MyConstant.keyList.searchGrpcURL, {}, {});
let searchGrpcClient8 = new searchProto.ZiFetchService8Client(MyConstant.keyList.searchGrpcURL, {}, {});
let searchGrpcClient9 = new searchProto.ZiFetchService9Client(MyConstant.keyList.searchGrpcURL, {}, {});
let searchGrpcClient10 = new searchProto.ZiFetchService10Client(MyConstant.keyList.searchGrpcURL, {}, {});


const history = createBrowserHistory();

const grpcClient = new Array(grpcClient1, grpcClient2, grpcClient3, grpcClient4, grpcClient5, grpcClient6, grpcClient7, grpcClient8, grpcClient9, grpcClient10);
const grpcPollingClient = new Array(grpcPollingClient1, grpcPollingClient2, grpcPollingClient3, grpcPollingClient4, grpcPollingClient5)
const searchGrpcClient = new Array(searchGrpcClient1, searchGrpcClient2, searchGrpcClient3, searchGrpcClient4, searchGrpcClient5, searchGrpcClient6, searchGrpcClient7, searchGrpcClient8, searchGrpcClient9, searchGrpcClient10);


const tokenKey = MyConfig.tabToken;

function App() {
  const auth = useSelector(state => state.authReducer);
  let { loginId, sessionId } = auth
  const [langLoad, setLangLoad] = useState(false)
  const [langList, setLangList] = useState({})
  let [reportSubmit, setReportSubmit] = useState(false);
  let [tabToken, setTabToken] = useState("");



  useEffect(() => {
    let reportSubmit = localStorage.getItem(MyConfig.project_LocalStorage + "reportSubmit");
    setReportSubmit(reportSubmit === undefined ? false : reportSubmit === "true")
    langInit()

    //Tab session management
    handleTab();
    // Cleanup when component is unmounted or tab is closed
    window.addEventListener("beforeunload", cleanupTab);

    return () => {
      window.removeEventListener("beforeunload", cleanupTab);
      cleanupTab(); // Cleanup when component unmounts
    };
  }, [])

  useEffect(() => {
    if (tabToken && loginId) {
      let postJson = {
        userId: loginId,
        sessionId: sessionId,
        tabId: tabToken
      }
      console.log("Test", postJson)
      grpcRequest(grpcClient, GlobalDomainCons.userSetDomain, GlobalEventCons.addSessionTab, postJson)
    }
  }, [tabToken, loginId])

  const handleTab = () => {
    let token = sessionStorage.getItem(tokenKey);
    if (!token) {
      token = getUuidv4();
      sessionStorage.setItem(tokenKey, token);
    }

    const existingTabs = JSON.parse(localStorage.getItem("openTabs") || "[]");

    if (existingTabs.includes(token)) {
      token = getUuidv4();
      sessionStorage.setItem(tokenKey, token);
    }

    setTabToken(token)
    existingTabs.push(token);
    localStorage.setItem("openTabs", JSON.stringify(existingTabs));
  };

  const cleanupTab = () => {
    const token = sessionStorage.getItem(tokenKey);
    if (token) {
      if (loginId) {
        let postJson = {
          userId: loginId,
          sessionId: sessionId,
          tabId: token
        }
        grpcRequest(grpcClient, GlobalDomainCons.userSetDomain, GlobalEventCons.removeSessionTab, postJson)
      }
      const existingTabs = JSON.parse(localStorage.getItem("openTabs") || "[]");
      const updatedTabs = existingTabs.filter((t) => t !== token);
      localStorage.setItem("openTabs", JSON.stringify(updatedTabs));
    }
  };

  async function langInit() {
    let langCode = localStorage.getItem(MyConfig.project_LocalStorage + "languagePreference");
    if (!langCode) {
      localStorage.setItem(MyConfig.project_LocalStorage + "languagePreference", 'eng')
    }
    langCode = langCode ? langCode : "eng"
    let langList = await getLanguageData()
    if (langCode !== "eng") {
      await checkLangFileExistAndDownload("eng", langList["eng"])
      setLangList(langList)
    }

    let response = await checkLangFileExistAndDownload(langCode, langList[langCode]);
    if (response) {
      setLangLoad(true)
    }
  }

  return <div className='teqit-container'>
    <div className="teqit-container-body">
      <span className={`powered-by-text`}>Powered by Teqbahn</span>
      {
        MyConstant.keyList.demoWorkspace === "true" && <div className='demo-flag'>Demo</div>
      }
      <Suspense fallback={<Loader />}>
        {
          langLoad ? <Switch>
            <Route exact path={MyConstant.keyList.projectUrl + "/"}>
              <ReportHere reportSubmit={reportSubmit} setReportSubmit={setReportSubmit} fromHomePage={true} grpcClient={grpcClient} history={history} />
              {/* <ReportHereLaunch history={history} /> */}
            </Route>
            <Route exact path={MyConstant.keyList.projectUrl + "/login"}>
              <Login fromRoute={true} grpcClient={grpcClient} history={history} />
            </Route>
            <Route exact path={MyConstant.keyList.projectUrl + "/register"}>
              <RegisterPin fromRoute={true} grpcClient={grpcClient} history={history} />
            </Route>
            <Route exact path={MyConstant.keyList.projectUrl + "/switch-language"}>
              <SwitchLanguage grpcClient={grpcClient} history={history} />
            </Route>
            <Route exact path={MyConstant.keyList.projectUrl + "/verify-account/:userId/:otp/:email"} >
              <VerifyAccountEmail grpcClient={grpcClient} history={history} />
            </Route>
            <Route path={MyConstant.keyList.projectUrl + "/community-service/:companyId"}>
              <UserRoute reportSubmit={reportSubmit} setReportSubmit={setReportSubmit} grpcClient={grpcClient} grpcPollingClient={grpcPollingClient} searchGrpcClient={searchGrpcClient} history={history} />
            </Route>

            <Route exact path={MyConstant.keyList.projectUrl + "/delete-account"}>
              <DeleteAccount grpcClient={grpcClient} />
            </Route>
            <Route exact path={MyConstant.keyList.projectUrl + "/privacy-policy"}>
              <PrivacyPolicy path={MyConstant.keyList.projectUrl} grpcClient={grpcClient} />
            </Route>
            <Route path="*">
              <NoMatch />
            </Route>
          </Switch> : <Loader />
        }
      </Suspense>
    </div>
  </div>
}

export default withRouter(App);
