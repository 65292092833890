import React from 'react';
import logoImg from '../assets/logo-transparent.png'
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
import { Link, withRouter } from 'react-router-dom';
import MyConstant from '../config/MyConstant';
import { ToastContainer, toast } from 'react-toastify';
import { getGrpcClient, getPageText, getValidLabels, getUuidv4, getAppInfo, userFromTeqitMeDomain } from '../config/Common';
import MyConfig from '../config/MyConfig';
import {
    setLoginData,
    setMultipleAuthData,
    reSetAuthData,
} from '../redux/actions';
import { connect } from 'react-redux';
import GlobalMsgCons from '../config/GlobalMsgCons';
import GlobalDomainCons from '../config/GlobalDomainCons';
import GlobalEventCons from '../config/GlobalEventCons';
import Modal from '../components/Modal';
import DropDown from '../components/DropDown';
import countriesJSON from "../json/countries.json"


class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageText: null,
            verifyAccountModal: false,
            loginType: "email"
        }
    }
    componentDidMount() {
        let loginType = localStorage.getItem(MyConfig.project_LocalStorage + "loginType")

        this.setState({
            loginType: loginType ? loginType : "email"
        })
        this.reSetReduxData();
        this.getPageText()
    }

    reSetReduxData() {
        this.props.setLoginData("password", "")
        this.props.reSetAuthData();
    }

    resetPageData() {

    }

    async setUserTimeLocation(userId) {
        if (userId) {
            //userId: String, timezoneInfo: String,timezoneType:String
            let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
            let postJson = {
                reqId: getUuidv4(),
                userId: userId,
                timezoneInfo: timeZone,
                timezoneType: "dynamic",
                isForced: false
            };
            // console.log("postJson", postJson)
            const request = new ZiFetchRequest();
            request.setDomain(GlobalDomainCons.userSetDomain);
            request.setEvent(GlobalEventCons.setUserTimeZoneInfoSetEvent);
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
                if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                    console.warn('Invalid Request. Please try again later');
                } else {
                    // let responseData = JSON.parse(response.getMessage());
                    // console.log("setUserZone!", responseData)
                }
            });
        }
    }

    loginCheck() {
        let { email, password, countrySelected } = this.props;
        let { pageText, loginType } = this.state;
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        let passwordReg = /^(?!.* ).{4,15}$/;
        let errors = {};
        if (email.trim() === '') {
            errors["email"] = loginType === "email" ? getValidLabels(pageText, 'pleaseEnterEmailErr_msg') : getValidLabels(pageText, 'enterPhoneErrMsg');
        } else if (loginType === "email" && reg.test(email) === false) {
            errors["email"] = getValidLabels(pageText, 'enterValidEmailErr_msg');
        }

        if (loginType === "phone" && countrySelected === "") {
            errors["country"] = getValidLabels(pageText, 'selectCountryErrMsg');
        }

        if (password.trim() === '') {
            errors["password"] = getValidLabels(pageText, 'pleaseEnterPasswordErr_msg');
        } else if (passwordReg.test(password) === false) {
            errors["password"] = getValidLabels(pageText, 'enterMin4CharactersErr_msg');
        }

        if (Object.keys(errors).length > 0) {
            this.props.setLoginData("errors", errors)
        } else {
            this.props.setLoginData("errors", {
                email: "",
                password: "",
                country: ""
            })
            this.props.setLoginData("loginLoader", true);

            let deviceInfo = getAppInfo()
            let postJson = { reqId: getUuidv4(), email: email.toLowerCase(), password, deviceInfo };

            if (loginType === "phone") {
                postJson.country = countrySelected.value;
                postJson.phone = email.toLowerCase()
            } else if(loginType === "pin") {
                postJson.userName = email;
            }

            console.log("postJson", postJson)

            let event = GlobalEventCons.userSignInViaMobileNumber
            if(loginType === "email") {
                event = GlobalEventCons.userSignInSetEvent
            } else if(loginType === "pin") {
                event = GlobalEventCons.userSignInViaPin
            }

            const request = new ZiFetchRequest();
            request.setDomain(GlobalDomainCons.manageSetDomain);
            request.setEvent(event);
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
                if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                    console.warn('Invalid Request. Please try again later');
                } else {
                    let responseData = JSON.parse(response.getMessage());
                    var response = responseData.response;
                    if (response === GlobalMsgCons.success) {
                        this.props.setLoginData("loginLoader", false)
                        if (responseData.status === GlobalMsgCons.unVerified) {
                            this.setState({
                                verifyAccountModal: true
                            })
                        } else {
                            this.props.setMultipleAuthData({
                                isLoggedIn: '1',
                                loginId: responseData.id,
                                firstName: responseData.firstName,
                                lastName: responseData.lastName,
                                profilePhoto: responseData.profilePhoto,
                                sessionId: responseData.sessionId,
                                loggedEmail: email,
                                userThemeColor: responseData.themeColor,
                                languagePreference: response.languagePreference ? response.languagePreference : "eng",
                                activeStatus: responseData.status,
                                hoursSelected: responseData.hourFormat ? responseData.hourFormat : 12,
                                phone: responseData.phone ? responseData.phone : ""
                            })


                            localStorage.setItem(MyConfig.project_LocalStorage + "languagePreference", response.languagePreference ? response.languagePreference : "eng");
                            localStorage.setItem(MyConfig.project_LocalStorage + "_userLogin", true);
                            this.setUserTimeLocation(responseData.id)

                            if(this.props.fromRoute) {
                                this.props.history.push(MyConstant.keyList.projectUrl + `/community-service/` + MyConfig.communityServiceOrgId);
                            } else {
                                this.props.backToForm()
                            }
                        }
                    } else if (response === GlobalMsgCons.passwordMismatch) {
                        this.props.setLoginData("loginLoader", false)
                        toast.error(getValidLabels(pageText, 'passwordNotMatchErr_msg'), {
                            position: "top-center",
                            autoClose: 1500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else if (response === GlobalMsgCons.notExist) {
                        this.props.setLoginData("loginLoader", false)
                        toast.error(loginType === "email" ? getValidLabels(pageText, 'emailNotExistErr_msg') : "Phone not exist.", {
                            position: "top-center",
                            autoClose: 1500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                    else if (response === GlobalMsgCons.inActiveAccount) {
                        this.props.setLoginData("loginLoader", false)
                        toast.error("Account has been deactivated, please contact admin.", {
                            position: "top-center",
                            autoClose: 1500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                }
            });
        }
    };

    resendEmail() {
        let { email } = this.props;
        let postJson = {
            reqId: getUuidv4(),
            verifyMethod: "email",
            mobileNumber: "",
            email: email.toLowerCase(),
            countryCode: "",
            emailSend: true,
            smsSend: false,
            requestFrom: MyConfig.requestFrom
        };
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.manageSetDomain);
        request.setEvent(GlobalEventCons.userTriggerVerificationSetEvent);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                var response = responseData.responseMessage;
                if (response === GlobalMsgCons.success) {
                    this.setState({
                        verifyAccountModal: false
                    })

                    toast.success("The verification email has been sent successfully.", {
                        position: "top-center",
                        autoClose: 1500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }
        });
    }

    async getPageText() {
        let pageText = await getPageText('login');
        console.log("pageText", pageText);
        if (Object.keys(pageText).length > 0) {
            this.setState({ pageText: pageText })
        } else {
            window.location.reload()
        }
    }
    render() {
        let { pageText, verifyAccountModal, loginType } = this.state;
        let { email, password, errors, passwordVisible, loginLoader, countrySelected, fromRoute } = this.props;

        return <React.Fragment>
            <ToastContainer />
            {
                <div className={`mobile-view-login ${fromRoute ? "" : "modal-setup"}`}>
                    <div className='logo py-5'>
                        <img src={logoImg} className="img-fluid" />
                    </div>
                    <div className='pt-5 text-center'>
                        {getValidLabels(pageText, 'newHereTxt')}<br />
                        <b><div onClick={() => {
                            if(this.props.setFormType) {
                                this.props.setFormType("register")
                            } else {
                                this.props.history.push(MyConstant.keyList.projectUrl+"/register")
                            }
                        }} className="link-primary fw-bolder px-2">{getValidLabels(pageText, 'createAccountLinkTxt')}</div></b>
                    </div>
                    <form className='login pb-5'>
                        <div className="mt-3">
                            <div>
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="updateStatus1" name="updateStatus" className="custom-control-input" checked={loginType === "email"} onChange={() => {
                                        this.setState({
                                            loginType: "email"
                                        })
                                        this.props.setLoginData("errors", {
                                            email: "",
                                            password: "",
                                            country: ""
                                        })
                                        localStorage.setItem(MyConfig.project_LocalStorage + "loginType", "email")
                                    }} />
                                    <label className="custom-control-label" htmlFor="updateStatus1">{getValidLabels(pageText, 'emailSignInTxt')}</label>
                                </div>
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="updateStatus2" name="updateStatus" className="custom-control-input" checked={loginType === "phone"} onChange={() => {
                                        this.setState({
                                            loginType: "phone"
                                        })
                                        this.props.setLoginData("errors", {
                                            email: "",
                                            password: "",
                                            country: ""
                                        })
                                        localStorage.setItem(MyConfig.project_LocalStorage + "loginType", "phone")
                                    }} />
                                    <label className="custom-control-label" htmlFor="updateStatus2">{getValidLabels(pageText, 'phoneSignInTxt')}</label>
                                </div>
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="updateStatus3" name="updateStatus" className="custom-control-input" checked={loginType === "pin"} onChange={() => {
                                        this.setState({
                                            loginType: "pin"
                                        })
                                        this.props.setLoginData("errors", {
                                            email: "",
                                            password: "",
                                            country: ""
                                        })
                                        localStorage.setItem(MyConfig.project_LocalStorage + "loginType", "pin")
                                    }} />
                                    <label className="custom-control-label" htmlFor="updateStatus3">PIN SignIn</label>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-3'>
                            {
                                loginType === "phone" && <React.Fragment>
                                    <div className='col-5'>
                                        <div className="form-group mb-4">
                                            <div className="row">
                                                <div className="col-6">
                                                    <label className="form-label m-none fw-bolder" >{getValidLabels(pageText, 'countryLabelTxt')}</label>
                                                </div>
                                            </div>

                                            <div className="col-12 register-select p-0">
                                                <DropDown
                                                    options={countriesJSON.countries}
                                                    isSearchable={true}
                                                    placeholder={getValidLabels(pageText, 'selectCountryPhTxt')}
                                                    onChange={(e) => {
                                                        this.props.setLoginData("countrySelected", e)
                                                    }}
                                                    selectedOption={countrySelected}
                                                    isDisabled={false}
                                                    isMulti={false}
                                                    error={errors['country'] ? true : false}
                                                />
                                                {
                                                    errors['country'] && <div className="d-block invalid-feedback">{errors['country']}</div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            }
                            <div className={`col-${loginType === "phone" ? "7" : "12"}`}>
                                <div className="form-group mb-3">
                                    <label className="form-label fw-bolder">{loginType === "email" ? getValidLabels(pageText, 'emailLabelTxt') : (loginType === "phone" ? "Phone" : "User ID")}</label>
                                    <input type={loginType === "email" ? "email" : "text"} value={email} className={`form-control form-control-solid form-control-lg ${errors['email'] ? "is-invalid" : ""}`} placeholder={loginType === "email" ? getValidLabels(pageText, 'emailPlaceholderTxt') : (loginType === "phone" ? "Phone" : "User ID")} autoComplete="new-password"
                                        onChange={(e) => {
                                            this.props.setLoginData("email", e.target.value)
                                        }} />
                                    {
                                        errors['email'] && <div className="invalid-feedback">{errors['email']}</div>
                                    }
                                </div>

                            </div>
                        </div>

                        <div className="form-group mb-3">
                            <div className="row">
                                <div className="col-6">
                                    <label className="form-label fw-bolder" >{loginType === "pin" ? "PIN" : getValidLabels(pageText, 'passwordLabelTxt')}</label>
                                </div>
                            </div>
                            <div className="input-group">
                                <input type={passwordVisible ? "text" : "password"} value={password} className={`form-control form-control-solid form-control-lg ${errors['password'] ? "is-invalid" : ""}`} placeholder={loginType === "pin" ? "PIN" : getValidLabels(pageText, 'passwordPlaceholderTxt')} autoComplete="new-password"
                                    onChange={(e) => {
                                        this.props.setLoginData("password", e.target.value)
                                    }} />
                                <div className="input-group-append" onClick={() => {
                                    this.props.setLoginData("passwordVisible", !passwordVisible)
                                }}>
                                    <span className="input-group-text border-0 cursor-pointer"><i className={`fa ${passwordVisible ? "fa-eye-slash" : "fa-eye"}`} /></span>
                                </div>
                                {
                                    errors['password'] && <div className="invalid-feedback">{errors['password']}</div>
                                }
                            </div>
                        </div>
                        <button type="button" className="btn btn-lg btn-theme btn-block" disabled={loginLoader} onClick={() => {
                            this.loginCheck()

                        }}>
                            {loginLoader && <i className="fa fa-spinner fa-spin"></i>}
                            <span className={`${loginLoader && ("pl-1")}`}>{getValidLabels(pageText, 'signinBtnTxt')}</span>
                        </button>
                        {
                            this.props.backToFormWithAutoRegister && <button type="button" className="btn btn-lg btn-primary btn-block" disabled={loginLoader} onClick={() => {
                                this.props.backToFormWithAutoRegister()
                            }}>
                                <span className={`${loginLoader && ("pl-1")}`}>{"Done without SignIn"}</span>
                            </button>
                        }
                        
                    </form>
                </div>
            }
            {
                verifyAccountModal && <Modal
                    visible={verifyAccountModal}
                    closeModal={() => {
                        this.setState({
                            verifyAccountModal: false
                        })
                    }}
                    heading={"Verify Account"}
                    body={<React.Fragment>
                        Please check your email and confirm your account by clicking the verification link. <br />
                        If you missed it check your <b>spam</b> or click below to resend your verification email.

                        <div className='mt-5 mb-2'>
                            <button type="button" className="btn btn-lg btn-theme btn-block" onClick={() => {
                                this.resendEmail()
                            }}>
                                Resend Email
                            </button>
                        </div>
                    </React.Fragment>}
                />
            }
        </React.Fragment>;
    }
}


const mapStateToProps = (state) => {
    return {
        email: state.loginReducer.email,
        password: state.loginReducer.password,
        passwordVisible: state.loginReducer.passwordVisible,
        errors: state.loginReducer.errors,
        loginLoader: state.loginReducer.loginLoader,
        countrySelected: state.loginReducer.countrySelected,
        state,
    };
};

const mapDispatchToProps = {
    setLoginData, setMultipleAuthData, reSetAuthData,
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));