import { useSelector } from "react-redux";
import { aiChatAgentDetails, esStatusdesign, getCaseId, getDateWithFormat, getOnlyDateWithFormat, getValidLabels, parseJsonBotReponse, surveyTimingRun, urlify, getTimeFormate, getReportName, getPageEventName, accumulation } from "../config/Common";
import MyConfig from "../config/MyConfig";
import React from "react";
import GlobalMsgCons from "../config/GlobalMsgCons";
import Typewriter from "./Typewriter";
import UpdateStatus from "../pages/communityService/UpdateStatus";
import ReportsFileView from "../pages/communityService/components/ReportsFileView";
import Card from "../components/Card"
import openmrsLogo from '../assets/logo/openmrsLogo.png'
import communityIcon from '../assets/community/community.png'
import copsIcon from '../assets/community/cops.png'
import { ToastContainer, toast } from 'react-toastify';
import MyConstant from "../config/MyConstant";

let days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
let month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

export default function ChatMessage(props) {
    let { loggedEmail, openApps, channelType, sendCommunityServiceEvent, requestReceivingChannelId, scrollBottom, newMessage, userChatHistory, ival, callStatus, controlFeature, joinCall, declineCall, pageText, updateMeetingDetails, joinMeetingCall, chatFileView, setMultipleChatData, channelId, replyChatSurveyQuestion, getChatSurveyReplyDetails } = props;
    const auth = useSelector(state => state.authReducer);
    let { loginId, activeCompany } = auth;
    const pageNameAcc = "reportLogPage"

    function getCommunityServiceFormValue(formType, formValue, userId) {
        let str = formValue;
        if (formType === "emoji") {
            let emojiSelected = MyConfig.communityServiceEmojiOption.filter(option => option.value === formValue);
            if (emojiSelected.length > 0) {
                str = <div className="d-flex" style={{ flexWrap: "wrap", gap: 10 }}>
                    <div><img src={emojiSelected[0].img} alt={emojiSelected[0].label} style={{ width: 50 }} /></div>
                    <div className="my-auto badge badge-success">{emojiSelected[0].label}</div>
                </div>
            }
        } else if (formType === "voice-record") {
            formValue = JSON.parse(formValue);

            if (Object.keys(formValue).length === 0) {
                str = "No file found."
            } else {
                let buildUrl = MyConstant.keyList.apiURL + "vp?action=" + formValue.processType + "&key=" + formValue.fileName + "&id=" + userId;
                str = <audio width="100%" controls>
                    <source src={buildUrl} />
                </audio>
            }
        }

        return str;
    }

    function reportSentLocalCommunity() {
        if (window.confirm("File this report with the closest community organization.")) {
            toast.success("Sent.", {
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    function reportSentLocalPolice() {
        if (window.confirm("File this report with the local police.")) {
            toast.success("Sent.", {
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    function getContent() {
        let message;
        let { chatMessage, csMessageMetaInfo } = userChatHistory[ival]
        let userName = userChatHistory[ival].name

        let { editedAt, messageType, deviceInfo, csEventData, openMrsData, healthcareInfo } = chatMessage;
        deviceInfo = deviceInfo ? JSON.parse(deviceInfo) : ""

        if (typeof messageType !== "undefined" && messageType === "call") {
            let callData = chatMessage.callData;
            let { joinedPeople, declinedPeople } = callData
            declinedPeople = declinedPeople ? declinedPeople : []
            message = <div className="call-details" id={ival}>
                <div className="call-info">
                    {controlFeature.audioEnable && <div className={`call-icon ${callData.callStatus}`}>
                        <i className="fa fa-phone"></i>
                    </div>}
                    <div className="call-info-message">
                        <b>{callStatus(callData.callStatus, chatMessage)}</b><br />
                        <small> {callData.endAt > 0 ? "Ended at " + getDateWithFormat(callData.endAt, auth.activeCompanyDetails) : (callData.startAt > 0 ? "Started at " + getDateWithFormat(callData.startAt, auth.activeCompanyDetails) : "")}</small>
                    </div>
                    <div className="call-info-button">
                        {
                            (callData.callStatus === "" || callData.callStatus === "call-started") && <React.Fragment>
                                {
                                    (!joinedPeople.includes(auth.loginId) && !declinedPeople.includes(auth.loginId)) ? <div>
                                        <button onClick={() => {
                                            joinCall(chatMessage)
                                        }} className="btn btn-success">Accept
                                        </button>
                                        <button onClick={() => {
                                            declineCall(chatMessage)
                                        }} className="btn btn-danger ml-2">Decline
                                        </button>
                                    </div> : <button onClick={() => {
                                        joinCall(chatMessage)
                                    }} className="btn btn-success">Join</button>
                                }
                            </React.Fragment>
                        }
                    </div>
                </div>
                <div className="user-info">
                    <div className="user-icon">
                    </div>
                    <div className="users-count">
                        {callData.joinedPeople.length} {getValidLabels(pageText, 'peopleJoinedTxt')}
                    </div>
                </div>
            </div>
        } else if (typeof messageType !== "undefined" && (messageType === "calender" || messageType === "meeting" || messageType === "meetingNotification")) {
            let { meetingsData, id } = chatMessage;
            let startTime = new Date(meetingsData.startTime);
            message = <div className="calendar-details" id={ival}>
                <div className="date-details">
                    <div className="month">{month[startTime.getMonth()]}</div>
                    <div className="date-info">
                        <div className="date">{startTime.getDate()}</div>
                        <div className="day">{days[startTime.getDay()]}</div>
                    </div>
                </div>
                <div className={`message-details ${meetingsData.status}`}>
                    <div className="title">{meetingsData.title}</div>
                    <div className="desc" dangerouslySetInnerHTML={{ __html: meetingsData.description }}></div>
                    <div className="separator" />
                    <div className="message">
                        <div className="meeting-message">{chatMessage.message}</div>
                        <div className="sub-message my-2">
                            <b>When</b> : {getDateWithFormat(meetingsData.startTime, auth.activeCompanyDetails) + " - " + getDateWithFormat(meetingsData.endTime, auth.activeCompanyDetails)}
                        </div>
                    </div>
                </div>
                {
                    messageType === "meeting" && <div className="update-details">
                        <div className={`button ${(meetingsData.acceptedMembers && meetingsData.acceptedMembers.includes(auth.loginId)) ? "accepted" : ""}`} onClick={() => {
                            if (!meetingsData.acceptedMembers || !meetingsData.acceptedMembers.includes(loginId)) {
                                updateMeetingDetails(meetingsData.id, id, "accept")
                            }
                        }}>{getValidLabels(pageText, 'acceptBtnTxt')}</div>
                        <div className={`button ${(meetingsData.rejectedMembers && meetingsData.rejectedMembers.includes(auth.loginId)) ? "rejected" : ""}`} onClick={() => {
                            if (!meetingsData.rejectedMembers || !meetingsData.rejectedMembers.includes(loginId)) {
                                updateMeetingDetails(meetingsData.id, id, "reject")
                            }
                        }}>{getValidLabels(pageText, 'rejectBtnTxt')}</div>
                    </div>
                }
                {
                    (messageType === "meeting-notification") && <div className="update-details">
                        <button onClick={() => {
                            if (new Date().getTime() < meetingsData.endTime) {
                                joinMeetingCall(chatMessage)
                            }
                        }} className={`btn ${new Date().getTime() < meetingsData.endTime ? "btn-success" : "btn-secondary disabled"}`}>{getValidLabels(pageText, 'joinBtnTxt')}</button>
                    </div>
                }

            </div>
        } else if (typeof chatMessage.fileList != "undefined" && chatMessage.fileList != null && chatMessage.fileList.length > 0) {
            message = <div id={ival}>
                <div className="">
                    <div dangerouslySetInnerHTML={{ __html: urlify(chatMessage.message) }} />
                    {
                        editedAt && <div className="edited">
                            (edited)
                            <div className="time">{getDateWithFormat(editedAt, auth.activeCompanyDetails)}</div>
                        </div>
                    }
                    {
                        messageType === GlobalMsgCons.messageTypeSharedChat && <div className="edited">
                            (shared)
                        </div>
                    }
                </div>
                {chatFileView(ival)}
            </div>;
        } else if (chatMessage.objUrl !== undefined && chatMessage.objUrl) {
            message = <div id={ival}>
                <div className="">
                    <div dangerouslySetInnerHTML={{ __html: urlify(chatMessage.message) }} />
                    {
                        editedAt && <div className="edited">
                            (edited)
                            <div className="time">{getDateWithFormat(editedAt, auth.activeCompanyDetails)}</div>
                        </div>
                    }
                    {
                        messageType === GlobalMsgCons.messageTypeSharedChat && <div className="edited">
                            (shared)
                        </div>
                    }
                </div>
                <div style={{ cursor: "pointer" }} onClick={() => {
                    setMultipleChatData(channelId, {
                        buildUrl: chatMessage.objUrl,
                        previewModal: true
                    })
                }}>
                    <img src={chatMessage.objUrl} />
                </div>
            </div>
        }
        else if (typeof messageType !== "undefined" && (messageType === GlobalMsgCons.surveyText && chatMessage.chatSurveyData)) {
            let chatDuration = 0;
            let currentTiming = new Date().getTime();
            let surveyCreatedTiming = parseInt(chatMessage.createdAt);
            let showSurveyDuration = 0
            let checkSurveyEnd = false;
            let timeRunShow = "00:00:00"
            if (typeof (chatMessage.chatSurveyData.duration) !== "undefined") {
                chatDuration = chatMessage.chatSurveyData.duration;
                let d = Math.floor(chatDuration / (3600 * 24));
                let h = Math.floor(chatDuration % (3600 * 24) / 3600);
                let m = Math.floor(chatDuration % 3600 / 60);
                showSurveyDuration = ("0" + d).slice(-2) + ":" + ("0" + h).slice(-2) + ":" + ("0" + m).slice(-2);
                let subtractTime = (currentTiming - surveyCreatedTiming)
                if (subtractTime >= (chatMessage.chatSurveyData.duration * 1000)) {
                    checkSurveyEnd = true
                }
                if (!checkSurveyEnd) {
                    timeRunShow = surveyTimingRun(chatDuration - Math.floor((subtractTime) / 1000))
                }
            }
            let maxValues = 0;
            let createObj = {};
            let attendSurvey = chatMessage.chatSurveyData.responseData;
            if (attendSurvey && Object.keys(attendSurvey).length > 0) {
                Object.keys(attendSurvey).map((s, si) => {
                    if (attendSurvey[s] && Object.keys(attendSurvey[s]).length > 0) {
                        createObj[s] = Object.keys(attendSurvey[s]).length
                    }

                })
                maxValues = Object.values(createObj).reduce((r, s) => r > s ? r : s, 0);
            }


            message = <div id={ival} className={`chat-survey-box ${(checkSurveyEnd ? "survey-end" : "")}`}>
                <div className="" >
                    {showSurveyDuration && <div style={{ display: "flex", flexDirection: "row" }}>
                        {checkSurveyEnd &&
                            <div style={{ flex: 1 }}>
                                <label className="survey-closed-txt mr-1">
                                    Survey Closed
                                </label>
                            </div>
                        }
                        <div style={{ flex: 1 }}>
                            <span className="mr-1">
                                <label>Survey Duration</label>
                            </span>
                            <span className="ml-1">{timeRunShow}</span>
                        </div>
                    </div>}

                    <div className="d-inline-block mb-2" dangerouslySetInnerHTML={{ __html: chatMessage.chatSurveyData.question }} />

                    <div className="chat-survey-progress" >
                        {
                            Object.keys(chatMessage.chatSurveyData.optionMap).map((jval, j) => {
                                let checkedUserIds = false;
                                let countCheckBox = 0;
                                let calData = 0;
                                let optdata = chatMessage.chatSurveyData.optionMap;
                                if (typeof (chatMessage.chatSurveyData.responseData) !== "undefined" && chatMessage.chatSurveyData.responseData[jval] && Object.keys(chatMessage.chatSurveyData.responseData[jval]).length > 0) {
                                    checkedUserIds = chatMessage.chatSurveyData.responseData[jval][auth.userId]

                                    countCheckBox = Object.values(chatMessage.chatSurveyData.responseData[jval]).length;
                                    calData = Math.round((Object.values(chatMessage.chatSurveyData.responseData[jval]).length / maxValues * 100)).toString()

                                }
                                return <React.Fragment key={j}>
                                    <div>
                                        <label className="chat-survey-container">
                                            <div className="d-inline-block" dangerouslySetInnerHTML={{ __html: optdata[jval] }} />
                                            <input type={"checkbox"} checked={checkedUserIds}
                                                onChange={() => {
                                                    replyChatSurveyQuestion(chatMessage.id, jval, chatMessage)
                                                }}
                                                disabled={checkSurveyEnd}
                                            />
                                            <span className={"checkmark " + (chatMessage.chatSurveyData.surveyType === "single" ? "" : "check-box")}></span>
                                        </label>
                                    </div>
                                    {<div style={{ display: "flex", marginBottom: 10, alignItems: "center" }}>
                                        <div className="chat-survey-count"
                                            onClick={(e) => { getChatSurveyReplyDetails(chatMessage.id, channelId, chatMessage.chatSurveyData.question, optdata, userName) }}
                                        >
                                            {countCheckBox}
                                        </div>

                                        <div className="progress" style={{ flex: 1 }}>
                                            <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow={calData.toString()} aria-valuemin="0" aria-valuemax="100" style={{ width: calData.toString() + "%", }}>
                                                {/* {(calData.toString() + "%"} */}
                                            </div>
                                        </div>

                                    </div>}
                                </React.Fragment>
                            })
                        }
                        <button className="btn btn-sm btn-info" onClick={(e) => { getChatSurveyReplyDetails(chatMessage.id, channelId, chatMessage.chatSurveyData.question, chatMessage.chatSurveyData.optionMap,) }}><p style={{ fontSize: "12px" }}>View Survey</p></button>
                    </div>
                </div>
            </div>
        } else if (messageType === GlobalMsgCons.botResponse) {
            let botMessage = chatMessage.message
            let botData = parseJsonBotReponse(botMessage, { request: "", response: "" })
            if (botData.request) {
                message = <div className="bot-chat">
                    <div className="request">
                        <div dangerouslySetInnerHTML={{ __html: urlify(botData.request) }} />
                        <div className="agent-box">
                            Type of LLM - {aiChatAgentDetails(chatMessage.aiAgentType)}
                        </div>
                    </div>
                    <div className="response">
                        {
                            newMessage.includes(ival) && !botData.response.includes(GlobalMsgCons.botResponseDefault) ? <Typewriter scrollBottom={() => scrollBottom()} text={botData.response} delay={50} /> : botData.response
                        }
                    </div>
                </div>
            } else {
                message = <div className="bot-chat-wrong">
                    Something went wrong with this response.
                </div>
            }
        } else if (csEventData && (messageType === GlobalMsgCons.messageTypeCSEventRequest || messageType === GlobalMsgCons.messageTypeCSEventResponse || messageType === GlobalMsgCons.messageTypeCSEventAutomaticResponse || messageType === GlobalMsgCons.messageTypeCSEventLnscResponse)) {
            let { eventCaseNo, data, receivedAt, status, assignedUserId, lnscStatus, caseStatus } = csEventData;
            let assignedUserName = ""
            if (assignedUserId === "admin") {
                assignedUserName = "Admin"
            } else if (csMessageMetaInfo) {
                assignedUserName = csMessageMetaInfo.assignedUserName
            }
            let caseStatusStr = caseStatus
            if(caseStatus) {
                let filterCaseStatus = MyConfig.caseStatusOption.filter(option => option.value === caseStatus)
                if(filterCaseStatus.length) {
                    caseStatusStr = filterCaseStatus[0].label
                }
            }

            let { id, serviceType, requestName, levelOfUrgency, requsterInfo, requestByMemberType, communityMemberInfo, listOfQuestion, listOfQuestionsResponse, createdBy, contactPersonInfo, summary, formType, formValue, subServiceType, fileList, submitType } = data;
            let { name, phone, email, gender, genderCategory, latitude, longitude, address, pinCode, phoneCode, ageRange, reachedHere, cityOrState } = requsterInfo;
            let { contactInfo, relationshipToSurvivor, survivorDistance, phoneCode: communityMemberPhoneCode, email: communityMemberEmail, address: communityMemberAddress, reachedHere: cmReachedHere } = communityMemberInfo;
            let answers = listOfQuestionsResponse.answer;
            let formTypeSelected = MyConfig.communityServiceFormOption.filter(option => option.value === formType)
            let prioritySelected = MyConfig.communityServicePriority.filter(option => option.value === levelOfUrgency.toString())
            let genderTypeLabel = MyConfig.csEventGenderCategory.filter(option => option.value === genderCategory)

            let msgText = chatMessage.message
            if (msgText && msgText !== "") {
                var values = msgText.split('<br>');
                if (Array.isArray(values)) {
                    msgText = msgText.replace(values[0], `<span style='background-color:#ed760e; padding: 6px 10px;border-radius: 5px;font-size: 16px;color: #fff;}'>${values[0]}</span><br>`)
                    msgText = msgText.replace(values[1], `<span style='background-color: blue; padding: 6px 10px;border-radius: 5px;font-size: 16px;color: #fff;margin-top:5px;}'>${values[1]}</span><br><br>`)
                }
                msgText = msgText.replace(`${getCaseId(eventCaseNo)}`, `<span style='background-color: #036268; padding: 3px 10px;margin-left:3px;border-radius: 10px;font-size: 13px;color: #fff;}'>${getCaseId(eventCaseNo)}</span>`);
            }


            message = <>
                {(messageType === GlobalMsgCons.messageTypeCSEventAutomaticResponse && msgText) && <div className="d-inline-block mb-1 mt-2" dangerouslySetInnerHTML={{
                    __html: urlify(msgText)
                }}
                />}
                <div className="es-event">
                    <div className="px-2 d-flex justify-content-end" style={{ gap: "10px 25px" }}>
                        <div onClick={() => {
                            accumulation(pageNameAcc, getPageEventName("reportLogPage", "reportClosestCommunityOrgIconClicked"), activeCompany, loginId, props.grpcClient)
                            reportSentLocalCommunity()
                        }} className="cursor-pointer" style={{ width: 40 }}>
                            <img src={communityIcon} className="img-fluid" alt={"Community"} />
                        </div>
                        <div onClick={() => {
                            accumulation(pageNameAcc, getPageEventName("reportLogPage", "reportLocalPoliceIconClicked"), activeCompany, loginId, props.grpcClient)
                            reportSentLocalPolice()
                        }} className="cursor-pointer" style={{ width: 40 }}>
                            <img src={copsIcon} className="img-fluid" alt={"Cops"} />
                        </div>
                    </div>
                    {
                        (formType && formType !== "form") && <div className="form-details d-flex">
                            <div>
                                <b>Form Type</b>
                                <div>{formTypeSelected.length > 0 ? formTypeSelected[0].label : "-"}</div>
                            </div>
                            <div>
                                <b>Form Value</b>
                                <div>{getCommunityServiceFormValue(formType, formValue, createdBy)}</div>
                            </div>
                        </div>
                    }
                    <div className="requester-info ">
                        <div className="d-flex">
                            <div>
                                <b>Case Number</b>
                                <div><span className="case-number">{getCaseId(eventCaseNo)}</span></div>
                            </div>
                            <div>
                                <b>Received At</b>
                                <div>{getDateWithFormat(receivedAt, auth.activeCompanyDetails)}</div>
                            </div>
                        </div>

                        <div className="d-flex mt-2">
                            <div>
                                <b>Case Status</b>
                                <div className="first-letter-caps">
                                    <span className={`case-status ${caseStatus && esStatusdesign(caseStatus)}`}>{caseStatus ? caseStatusStr : "-"}</span>
                                </div>
                            </div>
                            <div>
                                <b>Report Status</b>
                                <div className="text-capitalize">
                                    <span className={`case-status ${esStatusdesign(status)}`}>{status}</span>
                                </div>
                                {
                                    assignedUserName && <div className="my-2">
                                        <b className="text-capitalize">{assignedUserName}</b>{" updated as "}<b className="text-capitalize">{status}</b>
                                    </div>
                                }

                                {
                                    ((typeof (chatMessage.metaStatus) === "undefined" || (chatMessage.metaStatus && chatMessage.metaStatus !== GlobalMsgCons.msgExpired)) && ((assignedUserId && assignedUserId === auth.loginId || assignedUserId === GlobalMsgCons.type_admin || assignedUserId === "") && (auth.activeUserType === GlobalMsgCons.roleTypeCbo || auth.activeUserType === GlobalMsgCons.roleTypeAdmin || auth.activeUserType === GlobalMsgCons.roleTypeCommunityServiceAdmin) && channelId === requestReceivingChannelId)) && <UpdateStatus
                                        csEventData={csEventData}
                                        sendCommunityServiceEvent={(data, type) => {
                                            sendCommunityServiceEvent(data, type, chatMessage.id)
                                        }}
                                        grpcClient={props.grpcClient}
                                    />
                                }
                            </div>
                            <div>
                                <b>LNSC Status</b>
                                <div className="first-letter-caps">
                                    <span className={`case-status ${lnscStatus && esStatusdesign(lnscStatus)}`}>{lnscStatus ? lnscStatus : ""}</span>
                                </div>
                                {
                                    ((typeof (chatMessage.metaStatus) === "undefined" || (chatMessage.metaStatus && chatMessage.metaStatus !== GlobalMsgCons.msgExpired)) && (auth.activeUserType === GlobalMsgCons.roleTypeLNSC) && channelId === requestReceivingChannelId) && <UpdateStatus
                                        csEventData={csEventData}
                                        sendCommunityServiceEvent={(data, type) => {
                                            sendCommunityServiceEvent(data, type, chatMessage.id)
                                        }}
                                        grpcClient={props.grpcClient}
                                    />
                                }
                            </div>
                        </div>
                        <div className="d-flex mt-2">
                            <div>
                                <b>History</b>
                                <div>
                                    <button className="btn btn-sm btn-success" onClick={() => {
                                        accumulation(pageNameAcc, getPageEventName("reportLogPage", "viewHistoryBtnClicked"), activeCompany, loginId, props.grpcClient)
                                        props.communityMemberViewHistory(eventCaseNo)
                                    }}>View History</button>
                                </div>
                            </div>
                            <div>
                                <b>Files</b>
                                <div>
                                    {
                                        fileList && <ReportsFileView fileList={fileList} createdBy={createdBy}
                                            grpcClient={props.grpcClient}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    {(assignedUserId && assignedUserId === loginId && status && status.toLowerCase() === "pending") && <>
                        <div className="event-details d-flex">
                            <div>
                                <b className="nearest-text">You are near of this survivor</b>
                            </div>
                        </div></>}

                    {contactPersonInfo && Object.keys(contactPersonInfo).length > 0 ? <>
                        <div className="requester-info">
                            <h5 >Contact information</h5>
                            <div className="d-flex">
                                <div>
                                    <b>Name</b>
                                    <div>{contactPersonInfo.name}</div>
                                </div>
                                <div>
                                    <b>Email</b>
                                    <div>{contactPersonInfo.email}</div>
                                </div>
                                <div>
                                    <b>Address</b>
                                    <div>{contactPersonInfo.address}</div>
                                </div>

                            </div>

                            <div className="d-flex">
                                <div>
                                    <b>Phone</b>
                                    <div>{contactPersonInfo.phone}</div>
                                </div>
                            </div>
                        </div>

                    </> : ""}

                    {summary ? <div className="requester-info">
                        <div className="d-flex">
                            <div>
                                <b>Summary</b>
                                <div dangerouslySetInnerHTML={{ __html: summary }}></div>
                            </div>
                        </div>
                    </div> : null}

                    <div className="requester-info">
                        <div className="d-flex">
                            <div>
                                <b>Type of Violence</b>
                                <div>{serviceType}</div>
                            </div>
                            <div>
                                <b>Report Name</b>
                                <div>{<span className={`report-name ${prioritySelected.length > 0 ? prioritySelected[0].label.toLowerCase() : ""}`}>{requestName ? requestName : getReportName(eventCaseNo, serviceType, receivedAt)}</span>}</div>
                            </div>
                            {(channelType !== "communityServiceRequestSendingChannel") &&
                                <div>
                                    <b>Level of urgency</b>
                                    <div>{prioritySelected.length > 0 ? prioritySelected[0].label : "-"}</div>
                                </div>
                            }
                        </div>
                        {
                            submitType && <div className="d-flex">
                                {/* <div>
                                    <b>Type of Sub Violence</b>
                                    <div>{subServiceType ? subServiceType : "-"}</div>
                                </div> */}
                                <div>
                                    <b>Submit Type</b>
                                    <div className="text-capitalize">{submitType}</div>
                                </div>

                            </div>
                        }

                    </div>
                    <div className="requester-info">
                        <h5>Survivor Info</h5>
                        <div className="d-flex">
                            <div>
                                <b>Name</b>
                                <div>{name}</div>
                            </div>
                            <div>
                                <b>Phone</b>
                                <div>
                                    {phone ? (phoneCode ? "(" + phoneCode + ")" : "") + phone : "-"} <br />
                                    {reachedHere && "Reach here: " + reachedHere}
                                </div>
                            </div>
                            <div>
                                <b>Email</b>
                                <div>{email ? email : "-"}</div>
                            </div>
                        </div>
                        <div className="d-flex">
                            <div>
                                <b>Gender</b>
                                <div>{`${gender} ${genderTypeLabel.length >0 ? "(" + genderTypeLabel[0].label + ")" : ""}`}</div>
                            </div>
                            {/* <div>
                                <b>Age Group</b>
                                <div>{ageRange ? ageRange : "-"}</div>
                            </div> */}
                            <div>
                                <b>Address</b>
                                <div>{address ? address : "-"}</div>
                            </div>
                            <div></div>
                        </div>
                        <div className="d-flex">
                            <div>
                                <b>City/State</b>
                                <div>{cityOrState ? cityOrState : "-"}</div>
                            </div>
                            <div>
                                <b>Latitude</b>
                                <div>{latitude}</div>
                            </div>
                            <div>
                                <b>Longitude</b>
                                <div>{longitude}</div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="community-member-info">
                        <h5>Community Member Info</h5>
                        <div className="d-flex">
                            <div>
                                <b>Name</b>
                                <div>{communityMemberInfo.name ? communityMemberInfo.name : "-"}</div>
                            </div>
                            <div>
                                <b>Phone</b>
                                <div>
                                    {contactInfo ? (communityMemberPhoneCode ? "(" + communityMemberPhoneCode + ")" : "") + contactInfo : "-"}
                                    <br />
                                    {cmReachedHere && "Reach here: " + cmReachedHere}
                                </div>
                            </div>
                            <div>
                                <b>Relationship To Survivor</b>
                                <div>{relationshipToSurvivor ? relationshipToSurvivor : "-"}</div>
                            </div>
                        </div>
                        <div className="d-flex">
                            <div>
                                <b>How close are you to the survivor?</b>
                                <div>{survivorDistance ? survivorDistance : "-"}</div>
                            </div>
                            <div>
                                <b>Address</b>
                                <div>{communityMemberAddress ? communityMemberAddress : "-"}</div>
                            </div>
                            <div>
                                <b>Email</b>
                                <div>{communityMemberEmail ? communityMemberEmail : "-"}</div>
                            </div>
                        </div>
                    </div> */}
                    {
                        listOfQuestion.questions.length > 0 && <div className="questions">
                            {
                                listOfQuestion.questions.map((row) => {
                                    let { id, question } = row;
                                    return <div className="question" key={id}>
                                        <b>{question}</b>
                                        {
                                            answers[id] ? <div dangerouslySetInnerHTML={{ __html: answers[id] }}></div> : <div>-</div>
                                        }
                                    </div>
                                })
                            }
                        </div>
                    }
                </div>
            </>
        }
        else if (messageType === GlobalMsgCons.messageTypeHealthcareBotRequest && healthcareInfo) {
            let { conditionNotes, location, patientRefNo, prompt } = healthcareInfo;
            message = <div className="hs-event">
                <div className="d-flex px-2">
                    <div>
                        <b>TP Id</b>
                        <div>{patientRefNo}</div>
                    </div>
                    <div>
                        <b>Location</b>
                        <div>{location}</div>
                    </div>
                    <div>
                        <b>Notes</b>
                        <div>{conditionNotes}</div>
                    </div>
                </div>
                <div className="d-flex px-2 pt-2">
                    <div>
                        <b>Prompt</b>
                        <div>{prompt}</div>
                    </div>
                </div>
            </div>
        }
        else if (messageType === GlobalMsgCons.messageTypeOpenMrsResponse && openMrsData) {
            let { typeOfData, response } = JSON.parse(chatMessage.message)

            if (openMrsData.intent && openMrsData.intent === "addMedications" && openMrsData.apiType === "debezium") {
                response = JSON.parse(response)
                let { drug, patient, drug_order, orders } = response
                let { duration, quantity, dose, num_refills } = drug_order
                let { order_reason } = orders
                let { name, strength } = drug
                let { family_name, given_name, middle_name, person_identifier } = patient
                message = <div id={ival} className="openmrs mb-1">
                    <Card
                        title={<span className="openmrs">
                            <b>Medications Added <span className="openmrs-bdge" >({openMrsData.apiType})</span></b>
                            <img src={openmrsLogo} className="img-fluid ml-2" style={{ maxWidth: 90 }} />
                        </span>}
                        options={""}
                        bodyClassName={"openmrs-body"}
                        themeColor={"openmrs-theme"}
                    >
                        <b>Patient Info :</b>
                        <div >Name  - {given_name ? given_name : ""} {middle_name ? middle_name : ""} {family_name ? family_name : ""}</div>
                        <div>OpenMRS ID - {person_identifier}</div>
                        <br />
                        <b>Medications :</b>
                        <div>{name ? name : ""} - {strength ? strength : ""}</div>
                        <div>DOSE {dose} tablet - for {duration} days - {num_refills}</div>
                        <div className="mb-1">INDICATION {order_reason} - QUANTITY {quantity} Tablet</div>
                    </Card>
                    {/* <div className="openmrs-txt">{JSON.stringify(response)}</div> */}
                </div>
            }
            else if (typeOfData === "text") {
                message = <div id={ival} className="openmrs">
                    <div className="openmrs-txt">{response}</div>
                </div>
            } else if (response) {
                response = JSON.parse(response)
                let { results } = response;
                let { intent, requestDataJson } = openMrsData
                let { v, } = JSON.parse(requestDataJson)
                let dataPush = [];
                if (Array.isArray(results)) {
                    results.map((idd, idi) => {
                        let { display, person, } = idd;
                        let personalInfoShow = "";
                        if (intent && intent === "createAppointment") {
                            dataPush.push(<div key={idi + "_" + messageType}>
                                <div className="requester-info">
                                    <Card
                                        title={<span>
                                            <img src={openmrsLogo} className="img-fluid" style={{ maxWidth: 90 }} />
                                        </span>}
                                        options={""}
                                        bodyClassName={"openmrs-body"}
                                        themeColor={"openmrs-theme"}
                                    >
                                        <div> Appointment created successfully  </div>
                                    </Card>
                                    <br />
                                </div>
                            </div>)
                        }
                        else if (intent && intent === "searchPatient") {
                            if (person) {
                                try {
                                    let { birthdate, gender, age, addresses, names } = person
                                    if (v === "full") {
                                        let nameInfoShow = "";
                                        let addressShow = "";
                                        if (names) {
                                            let { givenName, middleName, familyName, } = names[0]
                                            nameInfoShow = <div className="d-flex">
                                                {givenName ? <div className="mb-2">
                                                    <b>Given Name</b>
                                                    <div> {givenName}</div>
                                                </div> : <div>{"-"}</div>}
                                                {middleName ? <div className="mb-2">
                                                    <b>Middle Name</b>
                                                    <div> {middleName}</div>
                                                </div> : <div>{"-"}</div>}
                                                {familyName ? <div className="mb-2">
                                                    <b>Family Name</b>
                                                    <div> {familyName}</div>
                                                </div> : <div>{"-"}</div>}
                                            </div>
                                        }
                                        if (addresses) {
                                            let { cityVillage, stateProvince, countyDistrict, } = addresses[0];
                                            addressShow = <div className="d-flex">
                                                {cityVillage ? <div className="mb-2">
                                                    <b>City Village</b>
                                                    <div> {cityVillage}</div>
                                                </div> : <div>{"-"}</div>}
                                                {stateProvince ? <div className="mb-2">
                                                    <b>State Province</b>
                                                    <div> {stateProvince}</div>
                                                </div> : <div>{"-"}</div>}
                                                {countyDistrict ? <div className="mb-2">
                                                    <b>County District</b>
                                                    <div> {countyDistrict}</div>
                                                </div> : <div>{"-"}</div>}
                                            </div>
                                        }
                                        personalInfoShow = <>
                                            <div className="d-flex">
                                                {gender ? <div className="mb-2">
                                                    <b>Gender</b>
                                                    <div> {gender}</div>
                                                </div> : <div>{"-"}</div>}
                                                {age ? <div className="mb-2">
                                                    <b>Age</b>
                                                    <div> {age}</div>
                                                </div> : <div>{"-"}</div>}
                                                {birthdate ? <div className="mb-2">
                                                    <b>Birth date</b>
                                                    <div> {getOnlyDateWithFormat(new Date(birthdate).getTime(), auth.activeCompanyDetails)}</div>
                                                </div> : <div>{"-"}</div>}
                                            </div>

                                            {nameInfoShow}
                                            {addressShow}

                                        </>
                                    } else if (v === "default") {
                                        let { birthdate, gender, age, } = person
                                        personalInfoShow = <>
                                            <div className="d-flex">
                                                {gender ? <div className="mb-2">
                                                    <b>Gender</b>
                                                    <div> {gender}</div>
                                                </div> : <div>{"-"}</div>}
                                                {age ? <div className="mb-2">
                                                    <b>Age</b>
                                                    <div> {age}</div>
                                                </div> : <div>{"-"}</div>}
                                                {birthdate ? <div className="mb-2">
                                                    <b>Birth date</b>
                                                    <div> {getOnlyDateWithFormat(new Date(birthdate).getTime(), auth.activeCompanyDetails)}</div>
                                                </div> : <div>{"-"}</div>}
                                            </div></>

                                    }
                                }
                                catch (err) {
                                    // console.log("err",err)
                                }
                            }
                        }

                        dataPush.push(<div key={idi + "_" + messageType}>
                            <div className="requester-info">
                                {display ? <b>{display}</b> : "-"}
                                {personalInfoShow}
                            </div>
                        </div>)

                    })

                } else if (response.display) {
                    dataPush = <div className="requester-info">
                        <div className="d-flex">
                            {/* {JSON.stringify(userChatHistory[ival])}<br /> */}
                            <Card
                                title={<img src={openmrsLogo} className="img-fluid" style={{ maxWidth: 90 }} />}
                                options={""}
                                bodyClassName={"openmrs-body"}
                                themeColor={"openmrs-theme"}
                            >
                                <div>
                                    {!(MyConfig.openMRSDoctoreMessageSendEmailidObj.hasOwnProperty(loggedEmail)) ? "Message sent to  doctor regarding appointment for patient " : "Message arrived from admin regarding Appointment  creation for "}
                                    <span>"{response.display}"</span>
                                </div>
                                <br />
                                {(MyConfig.openMRSDoctoreMessageSendEmailidObj.hasOwnProperty(loggedEmail)) && <div className="text-start mb-3">
                                    <button className="btn btn-sm btn-success" onClick={() => {
                                        setMultipleChatData(channelId, {
                                            createAppoimentModal: true,
                                            openMRSPatientUid: response.uuid
                                        });

                                    }}>Create appointment</button>
                                </div>}
                            </Card>
                        </div>

                    </div>
                } else if (response.entry) {
                    var entryObj = response.entry
                    if (Array.isArray(entryObj)) {
                        entryObj.map((entryData, entryDatai) => {
                            let { resource } = entryData;
                            if (resource) {
                                let { text } = resource
                                if (text) {
                                    if (text.status == "generated") {
                                        dataPush = <div className="requester-info">

                                            <div className="d-flex">
                                                <div>
                                                    <div className="desc" dangerouslySetInnerHTML={{ __html: text.div }}></div>
                                                </div>
                                            </div>
                                        </div>


                                    }
                                }
                            }
                        })
                    }
                    //     dataPush = <div className="requester-info">
                    //     <div className="d-flex">
                    //         <div>
                    //             <div>{JSON.stringify(response.entry)}</div>
                    //         </div>
                    //     </div>
                    // </div>
                }
                else if (response.error && response.error.message) {
                    let errorMessage = response.error.message;
                    let showMessage = errorMessage.slice(1, -1);
                    if (showMessage.includes("birthdate on") && showMessage.includes("Error converting date")) {
                        dataPush = <div className="requester-info">
                            <div>
                                <div>
                                    <span>Birthdate - </span>
                                    <span style={{ color: "red" }}>Error Converting Date</span>
                                </div>
                                <div>
                                    <span>Correct format (ISO8601 Long): yyyy-MM-dd'T'HH:mm:ss.SSSZ </span>
                                    <span style={{ color: "red" }}>Invalid format: ""</span>
                                </div>
                            </div>
                        </div>
                    } else {
                        dataPush = <div className="requester-info">
                            <div className="d-flex">
                                <div style={{ color: "red", fontSize: 15, fontWeight: "bold" }}>{showMessage}</div>
                            </div>
                        </div>
                    }
                }
                else if (response && Object.keys(response).length && response.appointmentNumber) {
                    let { intent } = openMrsData;
                    if (intent === "createAppointment") {
                        dataPush = <div className="openmrs-response">
                            <div className="">
                                <Card
                                    title={<span>
                                        <img src={openmrsLogo} className="img-fluid" style={{ maxWidth: 90 }} />
                                    </span>}
                                    options={""}
                                    bodyClassName={"openmrs-body"}
                                    themeColor={"openmrs-theme"}
                                >
                                    <div> Appointment created successfully  </div>
                                    <br />
                                    {response.patient && <button className="btn btn-sm btn-success" onClick={() => {
                                        let openMRSURL = MyConfig.openMRSPatientFullUrl + response.patient.uuid + MyConfig.openMRSAppointmentUrl
                                        let openMRSUrl = {
                                            "title": "OpenMRS",
                                            "description": "",
                                            "appsUrl": openMRSURL,
                                            "color": "#4e567d",
                                            "logo": "",
                                            "id": "OpenMRS_" + auth.activeCompany,
                                            "iframeUrl": openMRSURL,
                                        }
                                        openApps("OpenMRS", openMRSUrl)
                                    }}>View appointment</button>}
                                </Card>
                            </div>
                        </div>
                    }

                }
                else if ((intent && (intent === "endVisit" || intent === "createVisit"))) {
                    let { response } = JSON.parse(chatMessage.message)
                    response = JSON.parse(response)
                    let { patient, visit, location } = response
                    let { person_identifier, family_name, middle_name, given_name } = patient
                    let { name } = location
                    let { date_started, date_stopped, visit_type_name } = visit
                    dataPush = <div className="openmrs-response">

                        <div className="">
                            <Card
                                title={<span className="openmrs">
                                    <b>{openMrsData.context} <span className="openmrs-bdge" >({openMrsData.apiType})</span></b>
                                    <img src={openmrsLogo} className="img-fluid ml-2" style={{ maxWidth: 90 }} />
                                </span>}
                                options={""}
                                bodyClassName={"openmrs-body"}
                                themeColor={"openmrs-theme"}
                            >
                                <b>Patient Info :</b>
                                <div >Name  - {given_name ? given_name : ""} {middle_name ? middle_name : ""} {family_name ? family_name : ""}</div>
                                <div>OpenMRS ID - {person_identifier}</div>
                                <br />
                                <div><b>Location : </b> {name ? name : ""}</div>
                                <br />
                                <div><b>Visit : </b> {visit_type_name ? visit_type_name : ""}</div>

                                <div>{date_started ? <> Start : {getDateWithFormat(date_started, auth.activeCompanyDetails)} </> : null}</div>
                                <div>{date_stopped ? <> End : {getDateWithFormat(date_stopped, auth.activeCompanyDetails)} </> : null}</div>

                            </Card>
                        </div>
                    </div>

                }
                else if ((intent && (intent === "newEncounter"))) {
                    let { response } = JSON.parse(chatMessage.message)
                    response = JSON.parse(response)
                    let { patient, visit, location, obs, encounter } = response
                    let { person_identifier, family_name, middle_name, given_name } = patient
                    let { name } = location
                    let { date_started, date_stopped, visit_type_name } = visit
                    let { encounter_datetime, encounter_type_name } = encounter
                    dataPush = <div className="openmrs-response">

                        <div className="">
                            <Card
                                title={<span className="openmrs">
                                    <b>{openMrsData.context} <span className="openmrs-bdge" >({openMrsData.apiType})</span></b>
                                    <img src={openmrsLogo} className="img-fluid ml-2" style={{ maxWidth: 90 }} />
                                </span>}
                                options={""}
                                bodyClassName={"openmrs-body"}
                                themeColor={"openmrs-theme"}
                            >
                                <b>Patient Info :</b>
                                <div >Name  - {given_name ? given_name : ""} {middle_name ? middle_name : ""} {family_name ? family_name : ""}</div>
                                <div>OpenMRS ID - {person_identifier}</div>
                                <br />
                                <div><b>Location : </b> {name ? name : ""}</div>
                                <br />
                                <div><b>Visit : </b> {visit_type_name ? visit_type_name : ""}</div>

                                <div>{date_started ? <> Start : {getDateWithFormat(date_started, auth.activeCompanyDetails)} </> : null}</div>
                                <div>{date_stopped ? <> End : {getDateWithFormat(date_stopped, auth.activeCompanyDetails)} </> : null}</div>
                                <br />
                                <b>Encounter :</b>
                                <div>Type :  {encounter_type_name}</div>
                                <div>Date : {getTimeFormate(parseInt(encounter_datetime))}</div>

                                <br />
                                <b>Observation :</b>
                                {Object.keys(obs).length && Object.keys(obs).map((obskey) => {
                                    //obs_datetime
                                    let { concept_name, value_text, value_numeric } = obs[obskey]
                                    return <div>{concept_name} : {value_numeric ? value_numeric : value_text ? value_text : ""}</div>
                                })}


                                {/* {JSON.stringify(response)} */}

                            </Card>
                        </div>
                    </div>

                }
                else if (intent && intent === "addAllergy") {
                    let { response } = JSON.parse(chatMessage.message)
                    response = JSON.parse(response)
                    let { patient, allergy, allergy_reaction } = response
                    let { person_identifier, family_name, middle_name, given_name } = patient
                    let { allergen_type, allergen, severity, comments, date_created } = allergy
                    dataPush = <div className="openmrs-response">
                        <div className="">
                            <Card
                                title={<span className="openmrs">
                                    <b>{openMrsData.context} <span className="openmrs-bdge" >({openMrsData.apiType})</span></b>
                                    <img src={openmrsLogo} className="img-fluid ml-2" style={{ maxWidth: 90 }} />
                                </span>}
                                options={""}
                                bodyClassName={"openmrs-body"}
                                themeColor={"openmrs-theme"}
                            >
                                <b>Patient Info :</b>
                                <div >Name  - {given_name ? given_name : ""} {middle_name ? middle_name : ""} {family_name ? family_name : ""}</div>
                                <div>OpenMRS ID - {person_identifier}</div>
                                <br />

                                <b>Allergies :</b>
                                <div >Allergen Type  - {allergen_type} </div>
                                <div >Allergen  - {allergen} </div>
                                <div >Comments  - {comments} </div>
                                <div >Severity  - {severity} </div>
                                <div >Created  - {getTimeFormate(parseInt(date_created))} </div>
                                <br />


                                <b>Reaction :</b>
                                {Object.keys(allergy_reaction).length && Object.keys(allergy_reaction).map((alkey) => {
                                    let { reaction_name, reaction_non_coded } = allergy_reaction[alkey]
                                    return <div> {reaction_name}{reaction_non_coded ? " - " + reaction_non_coded : ""}</div>
                                })}

                            </Card>
                        </div>
                    </div>

                }
                else if (intent && intent === "addConditions") {
                    let { response } = JSON.parse(chatMessage.message)
                    response = JSON.parse(response)
                    let { allergy, patient } = response
                    let { condition_name, clinical_status, date_created, onset_date } = allergy
                    let { person_identifier, family_name, middle_name, given_name } = patient
                    dataPush = <div className="openmrs-response">
                        <div className="">
                            <Card
                                title={<span className="openmrs">
                                    <b>{openMrsData.context} <span className="openmrs-bdge" >({openMrsData.apiType})</span></b>
                                    <img src={openmrsLogo} className="img-fluid ml-2" style={{ maxWidth: 90 }} />
                                </span>}
                                options={""}
                                bodyClassName={"openmrs-body"}
                                themeColor={"openmrs-theme"}
                            >
                                <b>Patient Info :</b>
                                <div >Name  - {given_name ? given_name : ""} {middle_name ? middle_name : ""} {family_name ? family_name : ""}</div>
                                <div>OpenMRS ID - {person_identifier}</div>
                                <br />
                                <b>Conditions :</b>
                                <div>Condition Name  - {condition_name} </div>
                                <div>Status  - {clinical_status} </div>
                                <div>Created  - {getTimeFormate(parseInt(date_created))} </div>
                                <div>Date of onset - {getTimeFormate(parseInt(onset_date))} </div>
                            </Card>
                        </div>
                    </div>

                }
                message = <div id={ival} className="">
                    <div>{dataPush}</div>
                    {/* <div>{JSON.stringify(results)}</div> */}
                </div>
            }


        }
        else if (messageType === GlobalMsgCons.messageTypeOpenMrsRequest && openMrsData) {
            let { intent, requestDataJson, apiType } = openMrsData
            if (intent && intent === "searchPatient") {
                let { limit, startIndex, v, q, identifier, includeDead, searchType, } = JSON.parse(requestDataJson)
                message = <div id={ival} className="">
                    <div className="requester-info">
                        <Card
                            title={<span>
                                <b>Search Patient Info ({apiType})</b>
                                <img src={openmrsLogo} className="img-fluid ml-2" style={{ maxWidth: 90 }} />
                            </span>}
                            options={""}
                            bodyClassName={"openmrs-body"}
                            themeColor={"openmrs-theme"}
                        >
                            {
                                apiType === "fhir2" ? <div className="d-flex">
                                    <div className="mb-2">
                                        <b>Identifier</b>
                                        <div>{identifier}</div>
                                    </div>
                                </div> : <React.Fragment>
                                    <div className="d-flex">
                                        <div className="mb-2">
                                            <b>Limit</b>
                                            <div>{limit}</div>
                                        </div>
                                        <div className="mb-2">
                                            <b>Start index</b>
                                            <div>{startIndex}</div>
                                        </div>
                                        <div className="mb-2">
                                            <b>V</b>
                                            <div>{v}</div>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="mb-2">
                                            <b>Q</b>
                                            <div>{q}</div>
                                        </div>
                                        <div className="mb-2">
                                            <b>Identifier</b>
                                            <div>{identifier}</div>
                                        </div>
                                        <div className="mb-2">
                                            <b>Include dead</b>
                                            <div>{includeDead}</div>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="mb-2">
                                            <b>search Type</b>
                                            <div>{searchType}</div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            }
                        </Card>
                    </div>

                </div>
            } else if (intent && intent === "createPatient") {
                let { person, } = JSON.parse(requestDataJson)
                let { names, gender, birthdate, addresses } = person;
                let { givenName, middleName, familyName } = names[0]
                let { country, stateProvince, countyDistrict, address1, cityVillage } = addresses[0]

                message = <div id={ival} className="requester-info">
                    <Card
                        title={<span>
                            <b>Create Patient Info</b>
                            <img src={openmrsLogo} className="img-fluid ml-2" style={{ maxWidth: 90 }} />
                        </span>}
                        options={""}
                        bodyClassName={"openmrs-body"}
                        themeColor={"openmrs-theme"}
                    >
                        <div className="d-flex">
                            <div className="mb-2">
                                <b>First Name</b>
                                <div>{givenName}</div>
                            </div>
                            <div className="mb-2">
                                <b>Middle Name</b>
                                <div>{middleName}</div>
                            </div>
                            <div className="mb-2">
                                <b>Family Name</b>
                                <div>{familyName}</div>
                            </div>
                        </div>
                        <div className="d-flex">
                            <div className="mb-2">
                                <b>Sex</b>
                                <div>{gender}</div>
                            </div>
                            <div className="mb-2">
                                <b>Date of Birth</b>
                                <div>{birthdate}</div>
                            </div>
                            <div className="mb-2">
                                <b>Country</b>
                                <div>{country}</div>
                            </div>
                        </div>
                        <div className="d-flex">
                            <div className="mb-2">
                                <b>Province</b>
                                <div>{stateProvince}</div>
                            </div>
                            <div>
                                <b>impl.commune</b>
                                <div>{address1}</div>
                            </div>
                            <div className="mb-2">
                                <b>District</b>
                                <div>{countyDistrict}</div>
                            </div>
                        </div>

                        <div className="d-flex">
                            <div className="mb-2">
                                <b>Village</b>
                                <div>{cityVillage}</div>
                            </div>
                        </div>

                    </Card>

                    {/* <div>{JSON.stringify(openMrsData)}</div> */}
                </div>
            }
            else if (intent && intent === "createAppointment") {
                message = <div id={ival} className="openmrs-request">
                    <div className="requester-info">
                        <div className=""> Create Appointment request initialized  </div>
                    </div>
                </div>
            }
        }
        else {
            message = <div id={ival} className="">
                <div className="d-inline-block" dangerouslySetInnerHTML={{
                    __html: urlify(chatMessage.message
                    )
                }}
                />
                {
                    editedAt && <div className="edited">
                        (edited)
                        <div className="time">{getDateWithFormat(editedAt, auth.activeCompanyDetails)}</div>
                    </div>
                }
                {
                    messageType === GlobalMsgCons.messageTypeSharedChat && <div className="edited">
                        (shared)
                    </div>
                }
            </div>
        }

        return message;
    }
    return <React.Fragment>
        <ToastContainer />
        {
            getContent()
        }
    </React.Fragment>;
}
