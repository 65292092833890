import React, { useEffect, useState, Suspense, lazy, useRef } from 'react';
import {
    Switch,
    Route,
    useRouteMatch,
    withRouter,
    useParams
} from "react-router-dom";
import Home from "./Home";
import { getAppInfo, getGrpcClient, getGrpcPollingClient, getPageText, getTabToken, getUuidv4, redEscape } from "../../config/Common";
import { useSelector, useDispatch } from "react-redux";
import { reSetAuthData, reSetReportHere, setMultipleAuthData } from "../../redux/actions";
import GlobalDomainCons from "../../config/GlobalDomainCons";
import GlobalEventCons from "../../config/GlobalEventCons";
import GlobalMsgCons from "../../config/GlobalMsgCons";
import { ZiFetchRequest } from "../../protobuf/generated/ZiFetchService_grpc_web_pb";
import Loader from "../../components/Loader";
import MyConfig from "../../config/MyConfig";
import MyConstant from "../../config/MyConstant";
import SwitchLanguage from '../SwitchLanguage';
import MyProfile from './common/MyProfile';
import InboxPreview from './common/InboxPreview';
import PrivacyPolicy from './common/PrivacyPolicy';
import CopingSkills from './common/CopingSkills';
import ReferFriend from './common/ReferFriend';
import Resources from './common/Resources';
import NextStepThanks from './common/component/NextStepThanks';
import MyReferFriendList from './common/MyReferFriendList';
import InactivityTracker from '../../components/InactivityTracker';
import HelpAudio from '../../components/HelpAudio';



const InboxList = lazy(() => import('./common/InboxList'))

let intervalUserPolling;
let globalStream;
let intervalPolling;
function UserRoute(props) {
    let { path } = useRouteMatch();
    const { companyId } = useParams()
    path = path.replace(":companyId", companyId);
    let [languagePreference, setLanguagePreference] = useState("");
    let [chatMenuText, setChatMenuText] = useState("");
    let [activeDocsId, setActiveDocsId] = useState("");
    let [pinTabInfo, setPinTabInfo] = useState({
        id: "",
        type: ""
    });
    const [activeCompanyColor, setActiveCompanyColor] = useState("");
    let [contextHelp, setContextHelp] = useState("");
    let [allChannelList, setAllChannelList] = useState({});
    let [channelList, setChannelList] = useState({});
    let [firstName, setFirstName] = useState("");
    let [activeUserCompanyColor, setActiveUserCompanyColor] = useState("");
    let [activeUserType, setActiveUserType] = useState("");
    let [msgBoxUnreadCount, setMsgBoxUnreadCount] = useState(0);
    let [activeCompanyId, setActiveCompanyId] = useState("");
    let [systemLevelRole, setSystemLevelRole] = useState(GlobalMsgCons.systemLevelRoleMember);
    let [updateModal, setUpdateModal] = useState(false);
    let [memberJoinUpdated, setMemberJoinUpdated] = useState(false);
    let [memberDeactiveUpdated, setMemberDeactiveUpdated] = useState(false)
    let [taskChannelUpdated, setTaskChannelUpdated] = useState(false)
    let [notesChannelUpdated, setNotesChannelUpdated] = useState(false)
    let [inboxUpdated, setInboxUpdated] = useState(false)
    let [hideUnhideChannels, setHideUnhideChannels] = useState(false);
    let [dayEntryDateUpdate, setDayEntryDateUpdate] = useState("");
    let [dayEntryUpdate, setDayEntryUpdate] = useState(false);
    let [refreshCompanyDetails, setRefreshCompanyDetails] = useState(false);
    let [aiChannelId, setAiChannelId] = useState("");
    let [companyType, setCompanyType] = useState("");
    let [accessVerify, setAccessVerify] = useState(false);
    let [accessVerifyLoader, setAccessVerifyLoader] = useState(true);
    let [channelId, setChannelId] = useState("");
    let [pollChannel, setPollChannel] = useState({});
    let [pollCall, setPollCall] = useState({});
    let [pollUser, setPollUser] = useState({});
    let [pollChannelUnreadCount, setPollChannelUnreadCount] = useState({});
    let [pollBoardChangesStatus, setPollBoardChangesStatus] = useState({});
    const [privateSupportChannelId, setPrivateSupportChannelId] = useState("");
    const [communitySupportChannelId, setCommunitySupportChannelId] = useState("");
    const [communityChannelUnreadCount, setCommunityChannelUnreadCount] = useState(0);
    const [privateChannelUnreadCount, setPrivateChannelUnreadCount] = useState(0);
    const [carePlanAssistantChannelId, setCarePlanAssistantChannelId] = useState("");
    const [carePlanAssistantChannelUnreadCount, setCarePlanAssistantChannelUnreadCount] = useState(0);


    const [requestSendingChannelId, setRequestSendingChannelId] = useState("");
    const [requestSendingChannelUnreadCount, setRequestSendingChannelUnreadCount] = useState(0);
    const [requestReceivingChannelId, setRequestReceivingChannelId] = useState("");
    const [requestReceivingChannelUnreadCount, setRequestReceivingChannelUnreadCount] = useState(0);

    const auth = useSelector(state => state.authReducer);
    const reportHere = useSelector(state => state.reportHereReducer);

    let { requesterReached } = reportHere

    const dispatch = useDispatch();
    let activeCompanyIdRef = useRef("");
    let channelIdRef = useRef("");
    let messageIdRef = useRef("");
    let boardIdRef = useRef("");
    let memberListRef = useRef([]);
    let pollDeviceInfo = useRef({});
    let pollChannelUnreadCountRef = useRef({});


    useEffect(() => {
        pollDeviceInfo.current = getAppInfo()
        init();

        window.addEventListener('beforeunload', logOutApi);
        return () => {
            document.removeEventListener('beforeunload', logOutApi)
            clearInterval(intervalUserPolling)
            clearTimeout(intervalPolling)
        }
    }, [])

    useEffect(() => {
        if (companyId !== "login" && companyId !== undefined) {
            if (auth.loginId) {
                let loginId = auth.loginId;
                activeCompanyIdRef.current = companyId
                dispatch(setMultipleAuthData({
                    activeCompany: companyId
                }))
                getCompanyDetails(loginId, companyId);
                setActiveCompanyId(companyId);
                pollSetup();
            } else {
                props.history.replace(MyConstant.keyList.projectUrl)
            }
        }
    }, [companyId])

    useEffect(() => {
        if (requesterReached) {

        }

    }, [requesterReached])

    useEffect(() => {
        if (memberDeactiveUpdated) {
            dispatch(setMultipleAuthData({
                activeCompany: "",
                activeCompanyName: "",
                activeCompanyColor: "",
                activeCompanyDetails: "",
                activeCompanyLogo: "",
                feedId: ""
            }));

            setActiveCompanyId("");
            setMemberDeactiveUpdated(false)
            props.history.push(MyConstant.keyList.projectUrl);
        }
    }, [memberDeactiveUpdated]);

    useEffect(() => {
        channelIdRef.current = channelId
    }, [channelId])

    async function init() {
        let chatMenuText = await getPageText('chatMenu');
        setChatMenuText(chatMenuText)
    }

    function freqUserPoll() {
        intervalPolling = setTimeout(() => {
            pollSetup()
        }, 1000);
    }

    function setMessageId(id) {
        messageIdRef.current = id
    }

    function pollSetup() {
        let { sessionId, loginId } = auth;
        let postJson = {
            rId: getUuidv4(),
            uId: loginId,
            sId: sessionId,
            stId: getTabToken(),
            orgType: GlobalMsgCons.orgTypeCompany
        };

        if (activeCompanyIdRef.current) {
            postJson.oId = activeCompanyIdRef.current
        }

        if (channelIdRef.current) {
            postJson.cId = channelIdRef.current
        }

        if (messageIdRef.current) {
            postJson.mId = messageIdRef.current
        }

        if (boardIdRef.current) {
            postJson.bId = boardIdRef.current
        }

        if (memberListRef.current.length) {
            postJson.memberList = memberListRef.current
        }

        if (pollDeviceInfo.current) {
            postJson.deviceInfo = pollDeviceInfo.current
        }

        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.pollSetDomain);
        request.setEvent(GlobalEventCons.globalPoll);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcPollingClient(props.grpcPollingClient).pollZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request.Please try again later');
                freqUserPoll();
            } else {
                freqUserPoll();

                let responseData = JSON.parse(response.getMessage());
                let { userSession, userPoll, user, channel, call, getChannelUnReadCountList, getBoardChangesStatus, areThereMessagesWaiting } = responseData;

                if (userSession) {
                    validateUserSession(JSON.parse(userSession));
                }
                if (userPoll) {
                    userPollFrequent(JSON.parse(userPoll))
                }
                if (channel) {
                    setPollChannel(JSON.parse(channel))
                }
                if (call) {
                    setPollCall(JSON.parse(call))
                }
                if (user) {
                    setPollUser(JSON.parse(user))
                }
                if (getChannelUnReadCountList && pollChannelUnreadCountRef.current !== getChannelUnReadCountList) {
                    pollChannelUnreadCountRef.current = getChannelUnReadCountList;
                    setPollChannelUnreadCount(JSON.parse(getChannelUnReadCountList))
                }
                if (getBoardChangesStatus) {
                    setPollBoardChangesStatus(JSON.parse(getBoardChangesStatus))
                }

                if (areThereMessagesWaiting) {
                    let { listOfMessageTypesWaiting } = JSON.parse(areThereMessagesWaiting);
                    if (listOfMessageTypesWaiting !== undefined) {
                        notifyUsersChanges(listOfMessageTypesWaiting)
                    }
                }
            }
        });
    }

    function validateUserSession(responseData) {
        let { listOfMessageTypesWaiting } = responseData;
        if (listOfMessageTypesWaiting !== undefined) {
            notifyUsersChanges(listOfMessageTypesWaiting)
        }
    }

    function userPollFrequent(responseData) {
        let { isValidSession, unreadInboxCount } = responseData;
        if (!isValidSession) {
            logOutApi()
        }
        setMsgBoxUnreadCount(unreadInboxCount)
    }

    async function logOutApi() {
        let loginId = auth.loginId;
        let deviceToken = auth.deviceToken;
        var sessionId = auth.sessionId;
        dispatch(reSetAuthData())
        dispatch(reSetReportHere())
        props.history.push(MyConstant.keyList.projectUrl);
        if (props.setReportSubmit) {
            props.setReportSubmit(false)
        }
        localStorage.removeItem(MyConfig.project_LocalStorage + "_userLogin");
        localStorage.removeItem(MyConfig.project_LocalStorage + "reportSubmit");

        let postJson = { reqId: getUuidv4(), userId: loginId, deviceToken: deviceToken ? deviceToken : "", sessionId: sessionId ? sessionId : "" };
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.userSetDomain);
        request.setEvent(GlobalEventCons.userSignOutSetEvent);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                console.log("responseData logout")
            }
        });

    }


    function getCompanyDetails(userId, organizationId) {
        let postJson = { reqId: getUuidv4(), userId, organizationId };
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.companySetDomain);
        request.setEvent(GlobalEventCons.organizationSetEvent);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                response = JSON.parse(response.getMessage());
                if (response && response.organization) {
                    let { organizationName, organizationType, themeColor, feedChannelId, communitySupportChannelId, requestFrom } = response.organization;
                    let { carePlanAssistantChannelId, csRequestSendChannelId, csRequestReceivingChannelId, privateSupportChannelId, openMrsChannelId } = response

                    setPrivateSupportChannelId(privateSupportChannelId ? privateSupportChannelId : "")
                    setCommunitySupportChannelId(communitySupportChannelId ? communitySupportChannelId : "")

                    setRequestSendingChannelId(csRequestSendChannelId ? csRequestSendChannelId : "")
                    setRequestReceivingChannelId(csRequestReceivingChannelId ? csRequestReceivingChannelId : "")

                    setCarePlanAssistantChannelId(carePlanAssistantChannelId ? carePlanAssistantChannelId : "")

                    let controlFeature = {
                        audioEnable: true,
                        videoEnable: true,
                        qatEnable: true,
                        expensesEnable: true,
                        orgFeedbackEnable: true,
                        vsCodeEnable: true,
                        teqreqEnable: true,
                        dailyReportEnable: true,
                        appsEnable: false,
                        notesTeqitAiEnable: false,
                        userLastAccessEnable: false,
                        communityOrganization: false,
                        openMrsEnable: false
                    }
                    let controlFeatureDetails = response.controlFeaturesForOrganization;
                    if (controlFeatureDetails && Object.keys(controlFeatureDetails).length > 0) {
                        let { audioEnable, videoEnable } = controlFeatureDetails;
                        controlFeature.audioEnable = audioEnable;
                        controlFeature.videoEnable = videoEnable;
                        if (typeof (controlFeatureDetails.qat) !== "undefined") {
                            controlFeature.qatEnable = controlFeatureDetails.qat;
                        }
                        if (typeof (controlFeatureDetails.expenses) !== "undefined") {
                            controlFeature.expensesEnable = controlFeatureDetails.expenses;
                        }
                        if (typeof (controlFeatureDetails.orgFeedback) !== "undefined") {
                            controlFeature.orgFeedbackEnable = controlFeatureDetails.orgFeedback
                        }
                        if (typeof (controlFeatureDetails.teqreq) !== "undefined") {
                            controlFeature.teqreqEnable = controlFeatureDetails.teqreq
                        }
                        if (typeof (controlFeatureDetails.vsCode) !== "undefined") {
                            controlFeature.vsCodeEnable = controlFeatureDetails.vsCode
                        }
                        if (typeof (controlFeatureDetails.dailyReport) !== "undefined") {
                            controlFeature.dailyReportEnable = controlFeatureDetails.dailyReport
                        }
                        if (typeof (controlFeatureDetails.apps) !== "undefined") {
                            controlFeature.appsEnable = controlFeatureDetails.apps
                        }
                        if (typeof (controlFeatureDetails.notesTeqitAi) !== "undefined") {
                            controlFeature.notesTeqitAiEnable = controlFeatureDetails.notesTeqitAi
                        }
                        if (typeof (controlFeatureDetails.aiFeedChannel) !== "undefined") {
                            controlFeature.aiFeedChannelEnable = controlFeatureDetails.aiFeedChannel
                        }
                        if (typeof (controlFeatureDetails.userLastAccess) !== "undefined") {
                            controlFeature.userLastAccessEnable = controlFeatureDetails.userLastAccess
                        }
                        if (typeof (controlFeatureDetails.communityOrganization) !== "undefined") {
                            controlFeature.communityOrganization = controlFeatureDetails.communityOrganization
                        }
                        if (typeof (controlFeatureDetails.openMrs) !== "undefined") {
                            controlFeature.openMrsEnable = controlFeatureDetails.openMrs
                        }
                    }


                    if (response.aiFeedChannelId && response.aiFeedChannelId !== "") {
                        setAiChannelId(response.aiFeedChannelId)
                    }

                    let roleType = response.roleType;
                    let systemLevelRole = response.systemLevelRoleType

                    if (roleType === GlobalMsgCons.roleTypeMemberAdmin) {
                        roleType = "MemberAdmin"
                    } else if (roleType === GlobalMsgCons.roleTypeMember) {
                        roleType = "Member"
                    }
                    response.userId = "";

                    let companyOwnerId = response.organization.userId;
                    let activeUserType = "";
                    if (userId === companyOwnerId) {
                        activeUserType = "Admin"
                        setActiveUserType(activeUserType);

                    } else {
                        activeUserType = roleType;
                        setActiveUserType(activeUserType);

                    }
                    setSystemLevelRole(systemLevelRole);
                    setCompanyType(organizationType)

                    setActiveCompanyColor(themeColor)
                    dispatch(setMultipleAuthData({
                        activeCompanyName: organizationName,
                        activeCompanyColor: themeColor,
                        activeCompanyDetails: JSON.stringify(response.organization),
                        feedId: feedChannelId,
                        activeUserType,
                        systemLevelRole,
                        controlFeature,
                        openMrsChannelId: openMrsChannelId ? openMrsChannelId : "",
                        requestFrom
                    }))

                    setAccessVerify(true)
                } else {
                    setAccessVerify(false)
                    setAccessVerifyLoader(false)
                }
            }
        });
    }

    function setupChannelList(type, list) {
        channelList[type] = list;
        list.map((channel) => {
            allChannelList[channel.id] = channel.name
        })
        setAllChannelList(allChannelList)
        setChannelList(channelList)
    }


    function askAlertModal(itemNewArr, item) {
        let askMessage = false
        itemNewArr.map((e => {
            let splitmsg = e.split("-");
            if (splitmsg.length > 0) {
                splitmsg.map((e) => {
                    if (e.toLowerCase() === "web" && item === "BuildRelease") {
                        askMessage = true;
                    }
                })
            }
        }))
        if (askMessage) {
            setUpdateModal(askMessage)
        }
    }

    function notifyUsersChanges(listOfMessageTypesWaiting) {
        listOfMessageTypesWaiting.map((item) => {

            let itemArr = []
            if (item.includes("::")) {
                itemArr = item.split("::");
                item = itemArr[0]

                /*build release alert */
                if (itemArr.length > 0) {
                    if (!updateModal) {
                        askAlertModal(itemArr, item)
                    }

                }
                /*build release alert */
            }

            switch (item) {
                case "MemberJoin":
                    setMemberJoinUpdated(current => !current)
                    break;
                case "MemberDeactive":
                    setMemberDeactiveUpdated(true)
                    break;
                case "TaskChannel":
                    setTaskChannelUpdated(current => !current)
                    break;
                case "Notes":
                    setNotesChannelUpdated(current => !current)
                    break;
                case "InboxMsgArrived":
                    setInboxUpdated(current => !current)
                    break;
                case "ControlFeature":
                case "SystemLevelRoleType":
                    getCompanyDetails(auth.loginId, companyId)
                    break;
                case "sessionDeactivated":
                case "UserDeleted":
                    dispatch(reSetAuthData())
                    localStorage.removeItem(MyConfig.project_LocalStorage + "_userLogin");
                    // localStorage.removeItem(MyConfig.project_LocalStorage + "languagePreference");
                    setActiveCompanyId("")
                    props.history.push(MyConstant.keyList.projectUrl);
                    break;
                case "ChannelVisibility":
                    setHideUnhideChannels(hideUnhideChannels => !hideUnhideChannels)
                    break;
                case "OrganizationActive":
                case "OrganizationDeactive":
                    getCompanyDetails(auth.loginId, companyId)
                    setRefreshCompanyDetails(refreshCompanyDetails => !refreshCompanyDetails)
                    if (itemArr.length > 0 && itemArr.includes(companyId)) {
                        setActiveCompanyId("");
                        props.history.push(path + "/switch-account")
                    }
                    break;
            }
        })
    }

    function getThemeName() {
        let activeColor = activeUserCompanyColor ? activeUserCompanyColor : activeCompanyColor;
        let returnTheme = "";

        if (auth.theme === "dark") {
            returnTheme = "theme-dark"
        } else {
            switch (activeColor) {
                case "#2f3136":
                    returnTheme = "theme-black"
                    break;
                case "#eeeeee":
                    returnTheme = "theme-white"
                    break;
                case "#9c27b0":
                    returnTheme = "theme-purple"
                    break;
                case "#2196f3":
                    returnTheme = "theme-blue"
                    break;
                case "#4caf50":
                    returnTheme = "theme-green"
                    break;
                case "#ff9800":
                    returnTheme = "theme-orange"
                    break;
                case "#607d8b":
                    returnTheme = "theme-gray"
                    break;
                case "#ee595a":
                    returnTheme = "theme-dark-orange"
                    break;
                case "#dd4197":
                    returnTheme = "theme-pink"
                    break;
            }
        }
        return returnTheme;
    }

    function inactivityClose() {
        redEscape()
    }

    function useOnPageLeave(handler) {
    
        useEffect(() => {
          window.onbeforeunload = () => handler();
      
          window.addEventListener('beforeunload', (event) => {
            handler();
          });
      
          return () => {
            handler();
            document.removeEventListener('beforeunload', handler);
          };
        });
    };

    return <div className={`mobile-view ${getThemeName()}`}>
        {
            requesterReached === "No" && <InactivityTracker inactivityClose={inactivityClose}/>
        }
        
        <div className="escape-common-button" onClick={() => redEscape()}><i className="fas fa-exclamation-triangle"></i> Escape <HelpAudio parentClass={""} audio={"5_12"} /></div>
        <Suspense fallback={<Loader />}>
            <Switch>

                {
                    accessVerify ? <React.Fragment>
                        <Route exact path={`${path}`}>
                            <Home
                                privateSupportChannelId={privateSupportChannelId}
                                communitySupportChannelId={communitySupportChannelId}
                                carePlanAssistantChannelId={carePlanAssistantChannelId}
                                requestSendingChannelId={requestSendingChannelId}
                                requestReceivingChannelId={requestReceivingChannelId}
                                privateChannelUnreadCount={privateChannelUnreadCount}
                                communityChannelUnreadCount={communityChannelUnreadCount}
                                requestSendingChannelUnreadCount={requestSendingChannelUnreadCount}
                                requestReceivingChannelUnreadCount={requestReceivingChannelUnreadCount}
                                pollChannelUnreadCount={pollChannelUnreadCount}
                                companyType={companyType}
                                aiChannelId={aiChannelId}
                                dayEntryUpdate={dayEntryUpdate}
                                dayEntryDateUpdate={dayEntryDateUpdate}
                                hideUnhideChannels={hideUnhideChannels}
                                taskChannelUpdated={taskChannelUpdated}
                                msgBoxUnreadCount={msgBoxUnreadCount}
                                setupChannelList={setupChannelList}
                                logout={logOutApi}
                                setChannelId={setChannelId}
                                pollChannel={pollChannel}
                                {...props} pageText={chatMenuText} path={path} languagePreference={languagePreference} />
                        </Route>
                        <Route path={`${path}/home`}>
                            <Home
                                privateSupportChannelId={privateSupportChannelId}
                                communitySupportChannelId={communitySupportChannelId}
                                carePlanAssistantChannelId={carePlanAssistantChannelId}
                                requestSendingChannelId={requestSendingChannelId}
                                requestReceivingChannelId={requestReceivingChannelId}
                                privateChannelUnreadCount={privateChannelUnreadCount}
                                communityChannelUnreadCount={communityChannelUnreadCount}
                                requestSendingChannelUnreadCount={requestSendingChannelUnreadCount}
                                requestReceivingChannelUnreadCount={requestReceivingChannelUnreadCount}
                                pollChannelUnreadCount={pollChannelUnreadCount}
                                pollChannel={pollChannel}
                                companyType={companyType}
                                setChannelId={setChannelId}
                                aiChannelId={aiChannelId}
                                dayEntryDateUpdate={dayEntryDateUpdate}
                                hideUnhideChannels={hideUnhideChannels}
                                taskChannelUpdated={taskChannelUpdated}
                                msgBoxUnreadCount={msgBoxUnreadCount}
                                setupChannelList={setupChannelList}
                                logout={logOutApi} {...props} pageText={chatMenuText} path={path} languagePreference={languagePreference} />
                        </Route>
                        <Route exact path={`${path}/inbox`}>
                            <InboxList path={path} inboxUpdated={inboxUpdated} grpcClient={props.grpcClient} history={props.history} companyType={companyType} languagePreference={languagePreference} contextHelp={contextHelp} setContextHelp={setContextHelp} msgBoxUnreadCount={msgBoxUnreadCount} />
                        </Route>
                        <Route exact path={`${path}/inbox-preview`}>
                            <InboxPreview {...props} path={path} languagePreference={languagePreference} />
                        </Route>
                        <Route exact path={`${path}/myprofile`}>
                            <MyProfile {...props} path={path} languagePreference={languagePreference} setFirstName={setFirstName} setActiveUserCompanyColor={setActiveUserCompanyColor} setLanguagePreference={setLanguagePreference} />
                        </Route>
                        <Route exact path={`${path}/switch-language`}>
                            <SwitchLanguage {...props} path={path} languagePreference={languagePreference} />
                        </Route>
                        <Route exact path={`${path}/privacy-policy`}>
                            <PrivacyPolicy {...props} path={path} languagePreference={languagePreference} />
                        </Route>
                        <Route exact path={`${path}/coping-skills`}>
                            <CopingSkills {...props} path={path} languagePreference={languagePreference} />
                        </Route>
                        <Route exact path={`${path}/refer-friend`}>
                            <ReferFriend {...props} path={path} languagePreference={languagePreference} grpcClient={props.grpcClient} />
                        </Route>
                        <Route exact path={`${path}/resources`}>
                            <Resources {...props} path={path} languagePreference={languagePreference} />
                        </Route>
                        <Route exact path={`${path}/next-step-thanks`}>
                            <NextStepThanks {...props} path={path} languagePreference={languagePreference} grpcClient={props.grpcClient} />
                        </Route>
                        <Route exact path={`${path}/my-refer-friend-list`}>
                            <MyReferFriendList {...props} path={path} languagePreference={languagePreference} grpcClient={props.grpcClient} />
                        </Route>

                    </React.Fragment> : <React.Fragment>
                        {
                            accessVerifyLoader ? <Loader /> : <div className='no-access d-flex align-items-center justify-content-center h-100'>
                                No access rights were granted for this workspace. Please check your login details.
                            </div>
                        }
                    </React.Fragment>
                }

            </Switch>
        </Suspense>
    </div>;
}

export default withRouter(UserRoute);