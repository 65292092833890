import React, { useEffect, useState } from "react";
import CommunityServiceEvent from "./common/component/CommunityServiceEvent";
import { ToastContainer, toast } from 'react-toastify';
import { coversionED, getAppInfo, getDateWithFormat, getGrpcClient, getUuidv4, getValidLabels, readEncareStoreData, removeEncareStoreData } from "../../config/Common";
import GlobalMsgCons from "../../config/GlobalMsgCons";
import { ZiFetchRequest } from "../../protobuf/generated/ZiFetchService_grpc_web_pb";
import GlobalDomainCons from "../../config/GlobalDomainCons";
import GlobalEventCons from "../../config/GlobalEventCons";
import { useDispatch, useSelector } from 'react-redux';
import MyConstant from "../../config/MyConstant";
import MyConfig from "../../config/MyConfig";
import { Link, withRouter } from "react-router-dom";
import NextStepEvent from "./common/component/NextStepEvent";
import logoImg from '../../assets/logo-transparent.png'
import { reSetAuthData, setMultipleReportHereData } from "../../redux/actions";
import HelpAudio from "../../components/HelpAudio";
import Modal from "../../components/Modal";
import CaptureKnowApp from "../../components/CaptureKnowApp";

function ReportHere(props) {
    const dispatch = useDispatch();

    let { requestSendingChannelId, grpcClient, fromHomePage, reportSubmit, setReportSubmit } = props
    let [communityServiceEvent, setCommunityServiceEvent] = useState(false);
    let [nextStepEvent, setNextStepEvent] = useState(false);
    let [submitTime, setSubmitTime] = useState("");
    let [previewModal, setPreviewModal] = useState(false);
    let [email, setEmail] = useState("");
    let [feedback, setFeedback] = useState("");
    let [errors, setErrors] = useState({});
    let [submitLoader, setSubmitLoader] = useState(false)

    const auth = useSelector(state => state.authReducer);
    const reportHere = useSelector(state => state.reportHereReducer);
    let { loginId, firstName, activeCompany, activeCompanyDetails } = auth;
    let { generatedPin } = reportHere;

    useEffect(() => {
        if (loginId) {
            props.history.replace(MyConstant.keyList.projectUrl + `/community-service/` + MyConfig.communityServiceOrgId);
        }
        let submitTime = localStorage.getItem(MyConfig.project_LocalStorage + "reportSubmitTime")
        setSubmitTime(submitTime ? parseInt(submitTime) : "")
    }, [])

    useEffect(() => {
        window.addEventListener('online', () => onlineAlert());

        return () => {
            window.removeEventListener('online', () => {
                console.log("removed")
            });
        }
    }, []);

    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            let errors = checkValidation();
            setErrors(errors)
        }
    }, [email])

    function onlineAlert() {
        sentPreviousClosedData()
    }

    function sentPreviousClosedData() {
        let loginId = localStorage.getItem(MyConfig.project_LocalStorage + "tempId")
        let tempSessionId = localStorage.getItem(MyConfig.project_LocalStorage + "tempSessionId")

        if (tempSessionId && loginId) {
            let csEventData = readEncareStoreData(loginId, "TempCSEventData", MyConfig.communityServiceOrgId, tempSessionId)
            if (csEventData) {
                let createdAt = new Date();
                let deviceInfo = getAppInfo();
                let messageId = getUuidv4();

                let { data } = csEventData
                let { fromChannelId } = data
                let postJson = {
                    reqId: getUuidv4(),
                    messageId,
                    channelId: fromChannelId,
                    orgId: activeCompany,
                    senderId: loginId,
                    message: "",
                    fileList: [],
                    sendAt: createdAt.getTime(),
                    objUrl: "",
                    messageType: GlobalMsgCons.messageTypeCSEventRequest,
                    deviceInfo,
                    aiAgentType: "",
                    userType: GlobalMsgCons.type_user,
                    csEventData
                };

                postJson = coversionED("encrypt", postJson).toString()
                let request = new ZiFetchRequest();
                request.setDomain(GlobalDomainCons.chatSetDomain);
                request.setEvent(GlobalEventCons.sendMsgASyncSetEvent);
                request.setMessage(postJson);
                request.setDatetime(createdAt.toString());
                getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
                    if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                        console.warn('Invalid Request. Please try again later');
                    } else {
                        let responseData = response.getMessage();
                        responseData = coversionED("decrypt", responseData)
                        if (responseData === GlobalMsgCons.success) {
                            localStorage.removeItem(MyConfig.project_LocalStorage + "tempId")
                            localStorage.removeItem(MyConfig.project_LocalStorage + "tempSessionId")
                            removeEncareStoreData(loginId, "TempCSEventData", MyConfig.communityServiceOrgId, tempSessionId)
                        }
                    }
                })
            }
        }
    }

    function sendCommunityServiceEvent(csEventData, messageType, channelId, requesterReached) {
        let createdAt = new Date();
        let deviceInfo = getAppInfo();
        let messageId = getUuidv4();

        let { data } = csEventData
        let { submitType } = data

        if (submitType !== "") {
            dispatch(reSetAuthData())
            localStorage.removeItem(MyConfig.project_LocalStorage + "_userLogin");
        }

        dispatch(setMultipleReportHereData({
            requesterReached
        }))
        let postJson = {
            reqId: getUuidv4(),
            messageId,
            channelId: requestSendingChannelId ? requestSendingChannelId : channelId,
            orgId: activeCompany,
            senderId: loginId,
            message: "",
            fileList: [],
            sendAt: createdAt.getTime(),
            objUrl: "",
            messageType,
            deviceInfo,
            aiAgentType: "",
            userType: GlobalMsgCons.type_user,
            csEventData
        };

        postJson = coversionED("encrypt", postJson).toString()
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.sendMsgASyncSetEvent);
        request.setMessage(postJson);
        request.setDatetime(createdAt.toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = response.getMessage();
                responseData = coversionED("decrypt", responseData)
                if (responseData === GlobalMsgCons.success) {
                    toast.success("Report Done.", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                    let tempSessionId = localStorage.getItem(MyConfig.project_LocalStorage + "tempSessionId")
                    if (tempSessionId) {
                        localStorage.removeItem(MyConfig.project_LocalStorage + "tempId")
                        localStorage.removeItem(MyConfig.project_LocalStorage + "tempSessionId")
                        removeEncareStoreData(loginId, "TempCSEventData", MyConfig.communityServiceOrgId, tempSessionId)
                    }

                    if (submitType === "") {
                        let submitTime = createdAt.getTime()
                        localStorage.setItem(MyConfig.project_LocalStorage + "reportSubmit", true);
                        localStorage.setItem(MyConfig.project_LocalStorage + "reportSubmitTime", submitTime);
                        localStorage.setItem(MyConfig.project_LocalStorage + "reportMessageId", messageId);

                        setSubmitTime(submitTime ? parseInt(submitTime) : "")
                        setReportSubmit(true)
                    }

                    if (fromHomePage) {
                        setTimeout(() => {
                            props.history.replace(MyConstant.keyList.projectUrl + `/community-service/` + MyConfig.communityServiceOrgId);
                        }, 3000)
                    }
                }
            }
        });
    }

    function getContent() {
        return <div className="report-here-page">
            <div className="p-3 text-center">
                Just text "enc" to 34461 to make a report.<br/>
                Available on the MTN network. <HelpAudio parentClass={""} audio={"117"} />
            </div>
            <div className={`red-panic-button ${reportSubmit ? "submit" : ""}`} onClick={() => {
                if (!reportSubmit) {
                    setCommunityServiceEvent(true)
                }
            }}>
                {
                    reportSubmit ? <React.Fragment>
                        <div className="mt-2" style={{ fontSize: 48 }}><i className="fas fa-check"></i></div>
                        <HelpAudio parentClass={"report-here-home-help"} audio={"98"} />
                        <div className="my-2">Report Sent</div>
                        {submitTime && <div className="mb-2" style={{ fontSize: 12 }}>{getDateWithFormat(submitTime, activeCompanyDetails, 12)}</div>}
                    </React.Fragment> : <React.Fragment>
                        <HelpAudio parentClass={"report-here-home-help"} audio={"1_22_23"} />
                        <div>Report Here</div>
                    </React.Fragment>
                }
            </div>
            {
                reportSubmit ? <React.Fragment>
                    <div className={`red-panic-button next-steps`} onClick={() => {
                        setNextStepEvent(true)
                    }}>
                        <HelpAudio parentClass={"report-here-home-help"} audio={"99"} />
                        Next Steps
                    </div>
                    {
                        generatedPin && <div>
                            <div className="display-pin mx-auto">
                                Name : {firstName} <br/>
                                PIN : {generatedPin}
                            </div>
                            <div className="text-center my-2">Please save this name and pin code to view and manage your reports. <HelpAudio parentClass={""} audio={"102"} /></div>
                        </div>
                    }
                </React.Fragment>
                    : <div className="bottom-alert">
                        This carbon sensitive web app offers quick and safe reporting.  <br />
                        If you would like to know more about smartphone apps please <HelpAudio parentClass={""} audio={"116"} /> <span className="click-here-review c-pointer" onClick={() => setPreviewModal(true)}>click here</span> <HelpAudio parentClass={""} audio={"118"} />.
                    </div>
            }


        </div>
    }

    function checkValidation() {
        let errors = {}
        if (email.trim() === "") {
            errors['email'] = "Please enter email/phone."
        }

        return errors
    }

    function save() {
        let errors = checkValidation();
        setErrors(errors)

        if (Object.keys(errors).length === 0) {
            setSubmitLoader(true)

            let postJson = {
                reqId: getUuidv4(),
                email,
                phone: "",
                feedback,
                projectCode: "encare"
            };
            console.log("postJson", postJson)
            let request = new ZiFetchRequest();
            request.setDomain(GlobalDomainCons.userSetDomain);
            request.setEvent(GlobalEventCons.addPublicUserFeedbackData);
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
                if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                    console.warn('Invalid Request. Please try again later');
                } else {
                    let responseData = JSON.parse(response.getMessage());
                    console.log("responseData", responseData)
                    if (responseData.response === GlobalMsgCons.success) {
                        setPreviewModal(false)
                        setSubmitLoader(false)
                        toast.success("Thanks for your feedback.", {
                            position: "top-center",
                            autoClose: 1500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }

                }
            });
        }

    }
    return <React.Fragment>

        {
            fromHomePage ? <React.Fragment>
                <div style={{ width: 200, margin: "auto", paddingTop: 25, position: "relative" }}>
                    <img src={logoImg} className="img-fluid" /> <HelpAudio parentClass={"encare-home-help"} audio={"2_7_13"} />
                </div>
                <div className='d-flex justify-content-center align-items-center' style={{ height: "calc(100vh - 93px)", position: "relative" }}>
                    {getContent()}
                </div>
            </React.Fragment> : <React.Fragment>
                <div style={{ width: 200, margin: "auto", paddingTop: 25, position: "relative" }}>
                    <img src={logoImg} className="img-fluid" /> <HelpAudio parentClass={"encare-home-help"} audio={"2_7_13"} />
                </div>
                {getContent()}
            </React.Fragment>
        }

        <ToastContainer />
        {
            communityServiceEvent && <CommunityServiceEvent
                eventModal={communityServiceEvent}
                modalClose={(redirect) => {
                    setCommunityServiceEvent(false)
                    if (loginId && redirect) {
                        props.history.replace(MyConstant.keyList.projectUrl + `/community-service/` + MyConfig.communityServiceOrgId);
                    }
                }}
                sendCommunityServiceEvent={sendCommunityServiceEvent}
                grpcClient={grpcClient}
                fromChannelId={requestSendingChannelId}
            />
        }
        {
            reportSubmit && <CaptureKnowApp grpcClient={grpcClient}/>
        }

        {
            nextStepEvent && <NextStepEvent
                eventModal={nextStepEvent}
                modalClose={() => {
                    setNextStepEvent(false)
                }}
                grpcClient={grpcClient}
                path={props.path}
                requestSendingChannelId={props.requestSendingChannelId}
                setReportSubmit={setReportSubmit}
                history={props.history}
            />
        }

        {
            previewModal && <Modal
                visible={previewModal}
                closeModal={() => {
                    setErrors({})
                    setPreviewModal(false)
                }}
                heading={""}
                body={<React.Fragment>
                    Welcome to Encare! Report any case of violence quickly and safely on Encare.
                    Our team is all about making it easy to report so we spent time developing smartphone apps in case you wanted to download one.
                    We found several safety and privacy concerns with smartphone apps including the fact that others (the perpetrator) could easily see the app downloaded and stored on your phone - making it unsafe.
                    Additionally, we are a carbon sensitive technology company and we help reduce the global digital carbon footprint which currently produces more carbon than the entire aviation industry!!
                    So, please know that we are thoughtful and intentional in how we support you.
                    Please write to us at <a href="mailto:contact@teqbahn.com">contact@teqbahn.com</a>.
                    We would LOVE to hear from you.
                    {/* <div className="my-2">
                        <div className="form-group">
                            <label>{"Email/Phone"} <span className='required'>*</span></label>
                            <input type="text" value={email} className={`form-control ${errors["email"] ? "is-invalid" : ""}`} placeholder={"Please enter email/phone"} onChange={(e) => setEmail(e.target.value)} />
                            {
                                errors["email"] && <div className="invalid-feedback">{errors["email"]}</div>
                            }
                        </div>
                    </div>
                    <div className="my-2">
                        <div className="form-group">
                            <label>{"Feedback"}</label>
                            <textarea value={feedback} className={`form-control ${errors["feedback"] ? "is-invalid" : ""}`} placeholder={"Please enter feedback"} onChange={(e) => setFeedback(e.target.value)} />
                            {
                                errors["feedback"] && <div className="invalid-feedback">{errors["feedback"]}</div>
                            }
                        </div>
                    </div> */}
                    <div className='d-flex my-2'>
                        <div className="text-center" style={{ flex: 1 }}>
                            <button type="button" className="btn btn-sm btn-secondary" onClick={() => {
                                setPreviewModal(false)
                                setErrors({})
                            }}>
                                Close
                            </button>
                        </div>
                        {/* <div className="text-right" style={{ flex: 1 }}>
                            <button type="button" className="btn btn-sm btn-theme" onClick={() => {
                                save()
                            }} disabled={submitLoader}>
                                {submitLoader && <i className="fa fa-spinner fa-spin"></i>}
                                <span className={`${submitLoader && ("pl-1")}`}>
                                    {"Done"}
                                </span>
                            </button>
                        </div> */}
                    </div>
                </React.Fragment>}
            />
        }
    </React.Fragment>;
}

export default withRouter(ReportHere)
