import React from "react";
import { ZiFetchRequest } from "../../../protobuf/generated/ZiFetchService_grpc_web_pb";
import { v4 as uuidv4 } from 'uuid';
import MyConstant from "../../../config/MyConstant";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Picker from 'emoji-picker-react';
import { Link, withRouter } from 'react-router-dom';
import CustomEditor from "../../../components/CustomEditor";
import CustomEditorMinimized from "../../../components/CustomEditorMinimized";
import Modal from "../../../components/Modal";
import { PDFViewer } from 'react-view-pdf';
import Recorder from "../../../components/Recorder";
import GifPicker from "../../../components/GifPicker";
import { getDateWithFormat, getGrpcClient, getPageText, getValidLabels, getUuidv4, capitalize, getAppInfo, checkActiveAdmin, getValidTooltip, getTimeFormate, surveyTimingRun, getMyIp, startDayValidation, parseJsonBotReponse, aiChatAgentDetails, coversionED, getCaseId, commonEventList, accumulation, getPageEventName, getTabToken } from "../../../config/Common";
import { setMultipleChatData } from '../../../redux/actions';
import { connect } from 'react-redux';
import GlobalDomainCons from '../../../config/GlobalDomainCons';
import GlobalEventCons from '../../../config/GlobalEventCons';
import GlobalMsgCons from "../../../config/GlobalMsgCons";
import ReactTooltip from 'react-tooltip';
import DropDown from '../../../components/DropDown';
import MyConfig from "../../../config/MyConfig";
import VoiceToText from "../../../components/VoiceToText";
import ProcessAudioToText from "../common/component/ProcessAudioToText";
import ChatOptionsModal from "./ChatOptionsModal";
import CommunityServiceEvent from "../common/component/CommunityServiceEvent";
import ChatMessage from "../../../components/ChatMessage";
import ImageView from "../../../components/ImageView";
import logoImg from '../../../assets/logo-transparent.png'


let intervalId;
let intervalChannelId;
let activeStatusIntervalId;
let lastMessage;
let alive = true

let days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
let month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
let color = {
    "A": "#673ab7",
    "B": "#3f51b5",
    "C": "#2196f3",
    "D": "#00bcd4",
    "E": "#009688",
    "F": "#4caf50",
    "G": "#8bc34a",
    "H": "#cddc39",
    "I": "#ffeb3b",
    "J": "#ffc107",
    "K": "#ff9800",
    "L": "#795548",
    "M": "#673ab7",
    "N": "#3f51b5",
    "O": "#2196f3",
    "P": "#00bcd4",
    "Q": "#009688",
    "R": "#4caf50",
    "S": "#8bc34a",
    "T": "#cddc39",
    "U": "#ffeb3b",
    "V": "#ffc107",
    "W": "#ff9800",
    "X": "#795548",
    "Y": "#9e9e9e",
    "Z": "#9e9e9e",
}

const agentTypeOptions = MyConfig.agentTypeOptions;
const patientOptions = MyConfig.patientOptions
const pageNameAcc = "reportLogPage"
let longPressEvent;
class Chat extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageText: null,
            typingUserList: {},
            messageEmojiPicker: false,
            messageIdEmojiPicker: "",
            messageEmojiPickerPosition: {},
            emojiHoverView: "",
            searchText: "",
            fileDrop: false,
            readMore: false,
            readMoreId: "",
            processAudioToText: "",
            resultAudioToText: {},
            newMessage: [],
            communityServiceEvent: false,
            chatPageType: "",
            callButtonLoader: false,
            refresh: false,
            groupChannelChatUploadProcessType: "channelImage",
        }
        this.saveAudio = this.saveAudio.bind(this);
        this.processAudioToText = this.processAudioToText.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.scrollBottom = this.scrollBottom.bind(this);
        this.messageEnd = React.createRef();
        this.sendCommunityServiceEvent = this.sendCommunityServiceEvent.bind(this);
    }

    componentDidMount() {
        let { userId, companyId } = this.props
        accumulation(pageNameAcc, commonEventList("pageInitiated"), companyId, userId, this.props.grpcClient)
        alive = true;
        window.addEventListener("click", this.handleClickOutside);
        this.getPageText()
        ReactTooltip.rebuild();

        let { channelId, feed } = this.props.match.params;
        let currentChatText = localStorage.getItem("currentChatText_" + channelId);
        let message = currentChatText ? currentChatText : "";
        this.props.setMultipleChatData(channelId, {
            channelId,
            message,
            isFeedPage: feed ? true : false,
            isSupportPage: feed === "support" ? true : false,
            isCommunityServicePage: feed === "service" ? true : false,
        })

        this.setState({
            chatPageType: feed
        })
        if (channelId && channelId !== "nill") {
            if (this.props.history.location.state) {
                let { meetingCall } = this.props.history.location.state;
                if (meetingCall) {
                    this.props.history.replace({ state: {} })
                    this.call()
                }
            }
            if (this.props.setChannelId) {
                this.props.setChannelId(channelId)
            }
            this.init(channelId);
            let _this = this;
            _this.updateChannelUnReadCountIsEmpty(channelId);
        }
        this.surveyOptionSet()
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            let { channelId, feed } = this.props.match.params;
            if (this.props.setChannelId) {
                this.props.setChannelId(channelId)
            }

            let currentChatText = localStorage.getItem("currentChatText_" + channelId);
            let message = currentChatText ? currentChatText : "";

            this.props.setMultipleChatData(channelId, {
                channelId,
                message,
                changeText: message,
                editMessageId: "",
                isFeedPage: feed ? true : false,
                isSupportPage: feed === "support" ? true : false,
                isCommunityServicePage: feed === "service" ? true : false,
            });
            this.setState({
                typingUserList: {},
                chatPageType: feed
            })

            this.updateChannelUnReadCountIsEmpty(channelId);
            this.init(channelId);
        }
        if (prevProps.languagePreference !== this.props.languagePreference) {
            this.getPageText()
        }

        if (prevProps.pollChannel !== this.props.pollChannel) {
            this.getChannelMessagesFrequent(this.props.pollChannel)
        }

        ReactTooltip.rebuild();
    }

    componentWillUnmount() {
        if (this.props.setChannelId) {
            this.props.setChannelId("")
        }
        window.removeEventListener("click", this.handleClickOutside);
        clearInterval(intervalId);
        clearInterval(intervalChannelId);
        clearTimeout(activeStatusIntervalId);
        alive = false;
    }

    handleScroll(event) {
        let { channelId } = this.props;
        const e = event.target;
        // let positionY = e.scrollHeight - (e.scrollTop + e.clientHeight)
        this.props.setMultipleChatData(channelId, {
            scrollPosition: e.scrollTop,
        })
    }

    saveAudio(type, blob) {
        let uid = uuidv4();
        let audioFile = new File([blob], "record_" + uid + ".mp3")
        let fileUrl = URL.createObjectURL(audioFile);
        let file = audioFile;
        let filename = audioFile.name;
        let ext = filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
        let fileObj = {
            file: file,
            origFileName: filename,
            fileUrl: fileUrl,
            fileName: uid + "." + ext,
            docsId: uid,
            processType: "chat-record",
            fileType: ext,
        };

        let fileList = [];
        let inc = 0;
        fileList[inc] = fileObj;
        fileList[inc].uploadProcess = "pending";
        let { channelId } = this.props;
        this.props.setMultipleChatData(channelId, {
            fileList,
            recordingModal: false
        })
        inc++;
    }

    processAudioToText(type, blob) {
        let uid = uuidv4();
        let file = "",
            ext = "";
        if (type === "record") {
            let audioFile = new File([blob], "record_" + uid + ".mp3")
            file = audioFile;
            let filename = audioFile.name;
            ext = filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
        } else {
            file = blob.path;
            ext = blob.fileType
        }
        let fileObj = {
            file: file,
            fileName: uid + "." + ext,
            docsId: uid,
            processType: "chat-record",
        };

        this.audioToTextUpload(fileObj)
    }


    audioToTextUpload(dataJson) {
        let { userId } = this.props;
        this.setState({
            processAudioToText: "process"
        }, () => {
            const postFileUpload = new FormData();
            postFileUpload.append('file', dataJson.file);
            postFileUpload.append('fileName', dataJson.fileName);
            postFileUpload.append('processType', dataJson.processType);
            postFileUpload.append('docsId', dataJson.docsId);
            postFileUpload.append('entryId', dataJson.docsId);
            postFileUpload.append('userId', userId);
            fetch(MyConstant.keyList.aiUrl + "voice-to-text/file", {
                method: "POST",
                mode: 'cors',
                redirect: 'follow',
                body: postFileUpload,
            })
                .then((response) => response.json())
                .then((result) => {
                    this.setState({
                        processAudioToText: "completed",
                        resultAudioToText: result
                    })
                    console.log('Success:', result);
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        })
    }

    init(channelId) {
        let { channelKeys, getChannelChatsFetched, getChannelDetailsFetched, getChannelMembersFetched, scrollPosition } = this.props;
        if (!channelKeys.includes(channelId)) {
            this.getChannelChats(channelId, 1, true, {});
            this.getChannelDetails(channelId);
        } else {
            if (!getChannelChatsFetched) {
                this.getChannelChats(channelId, 1, true, {});
            }
            if (!getChannelDetailsFetched) {
                this.getChannelDetails(channelId);
            }
            this.scrollToPosition(scrollPosition);
        }
        this.getChannelChatsNew(channelId, 1, "MsgArrived");
        clearTimeout(activeStatusIntervalId);
    }

    getChannelMessagesFrequent(responseData) {
        let { listOfMessageTypesWaiting } = responseData
        if (listOfMessageTypesWaiting !== undefined) {
            let { channelId } = this.props;
            listOfMessageTypesWaiting.map((item) => {
                if (item === "MsgArrived" || item === "MsgEdited" || item === "MsgDeleted" || item === "ChannelMemberUpdated") {
                    this.getChannelChatsNew(channelId, 1, item === "MsgArrived");

                    if (item === "MsgArrived") {
                        this.updateChannelUnReadCountIsEmpty(channelId)
                    }
                }
                if (item === "MsgTyping") {
                    this.getChatTypingInfo(channelId)
                }
                if (item === "ChannelMemberUpdated") {
                    this.getChannelMembers(channelId)
                }
            })
        }
    }

    getChannelDetails = (channelId) => {
        let { loginId, firstName } = this.props;
        let { groupChannelChatUploadProcessType } = this.state;
        let postJson = { reqId: getUuidv4(), channelId, memberId: loginId };
        postJson = coversionED("encrypt", postJson).toString()
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.channelDetailsSetEvent);
        request.setMessage(postJson);
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = response.getMessage();
                responseData = coversionED("decrypt", responseData)
                responseData = JSON.parse(responseData)
                let result = responseData.result;
                let { hostMemberId, image, channelId } = result;
                let existChannelMemberIds = result.membersUserIds;
                existChannelMemberIds.push(result.createdByUserId)
                let currentChannelName = result.channelName.replace(firstName + ",", "").replace(/,\s*$/, "");
                currentChannelName = currentChannelName.replace("aiFeed", "AI Channel")
                let channelMuteStatus = result.channelMuteStatus ? result.channelMuteStatus : "unmute";
                let selectedMessageSound = result.channelSound ? result.channelSound : "sound1";
                let channelHeaderDocumentFiles = [],
                    channelHeaderDocument_Json = [];
                let buildUrl = "";
                // console.log("-->", image)
                if (image && image != "") {
                    let split = image.split(".")
                    channelHeaderDocumentFiles.push({ processType: groupChannelChatUploadProcessType, fileType: split[1], docsId: split[0] })
                    channelHeaderDocument_Json.push({ processType: groupChannelChatUploadProcessType, fileType: split[1], docsId: split[0], fileName: image })
                    buildUrl = MyConstant.keyList.apiURL + "vp?action=" + groupChannelChatUploadProcessType + "&key=" + channelId + "." + split[1] + "&id=" + channelId + "&time=" + new Date().getTime();
                }
                this.props.setMultipleChatData(channelId, {
                    currentChannelName,
                    channelType: result.channelType,
                    channelMuteStatus,
                    selectedMessageSound,
                    deactivatedMembersIds: result.deactivatedMembersIds ? result.deactivatedMembersIds : [],
                    existChannelMemberIds,
                    getChannelDetailsFetched: true,
                    hostMemberId,
                    channelHeaderDocumentFiles, channelHeaderDocument_Json, headerChannelImg: buildUrl
                })
            }
        });
    }

    updateChannelUnReadCountIsEmpty(channelId) {
        let { loginId, companyId } = this.props;
        let postJson = { reqId: getUuidv4(), userId: loginId, channelId, orgId: companyId };
        postJson = coversionED("encrypt", postJson).toString()
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.updateChannelUnReadCountIsEmptySE);
        request.setMessage(postJson);
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                // console.log("Updated")
            }
        });
    }

    getChatTypingInfo(channelId) {
        let { userId } = this.props;
        let postJson = { reqId: getUuidv4(), channelId };
        postJson = coversionED("encrypt", postJson).toString()
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.getChatTypingInfo);
        request.setMessage(postJson);
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = response.getMessage();
                responseData = coversionED("decrypt", responseData)
                responseData = JSON.parse(responseData)
                let result = responseData.result;
                let list = [];
                if (Object.keys(result).length > 0) {
                    Object.keys(result).map((id) => {
                        if (userId !== id) {
                            list.push(capitalize(result[id]))
                        }
                    })
                }
                this.setState({
                    typingUserList: list
                })
            }
        });
    }

    getChannelChatsNew(channelId, noOfPage, scrollBottom) {
        let { loginId, sessionId } = this.props;
        let { newMessage } = this.state;
        let postJson = { reqId: getUuidv4(), sessionId: sessionId, channelId, userId: loginId, pageLimit: 25, noOfPage: noOfPage,sessionTabId: getTabToken() };
        postJson = coversionED("encrypt", postJson).toString()
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.channelChatViaListenerSetEvent);
        request.setMessage(postJson);
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let { userChatHistory, deleteMessageId } = this.props;
                let responseData = response.getMessage();
                responseData = coversionED("decrypt", responseData)
                responseData = JSON.parse(responseData)
                if (responseData.channelId === this.props.channelId) {
                    let totalMsgCount = responseData.totalResult
                    let result = userChatHistory;
                    let deletedMsg = false

                    let { deletedIdList, newMsgs, editedMsgs } = responseData
                    if (deleteMessageId.length > 0) {
                        let chat_his = {}
                        Object.keys(result).map((key) => {
                            if (!deleteMessageId.includes(result[key].id)) {
                                chat_his[key] = { ...result[key] }
                            }
                        })
                        result = chat_his
                    }

                    if (newMsgs.length > 0 && !deletedMsg) {
                        for (let i = 0; i < newMsgs.length; i++) {
                            let ival = newMsgs[i]
                            result[ival.chatMessage.createdAt] = ival;
                            if (result[ival.chatMessage.sendAt] && ival.chatMessage.createdAt !== ival.chatMessage.sendAt) {
                                delete result[ival.chatMessage.sendAt]
                            }
                            newMessage.push(ival.chatMessage.createdAt.toString())
                        }
                    }


                    if (deletedIdList.length > 0) {
                        let chatHistory = {};
                        for (let i = 0; i < deletedIdList.length; i++) {
                            let msgId = deletedIdList[i];
                            Object.keys(result).map((key) => {
                                if (result[key].chatMessage.id === msgId) {
                                    deleteMessageId.push(msgId)
                                    deletedMsg = true
                                } else {
                                    chatHistory[key] = { ...result[key] }
                                }
                            })
                        }
                        result = chatHistory
                        totalMsgCount = Object.keys(result).length
                    }

                    if (editedMsgs.length > 0 && !deletedMsg) {
                        for (let i = 0; i < editedMsgs.length; i++) {
                            let ival = editedMsgs[i]
                            result[ival.chatMessage.createdAt] = ival;
                        }
                    }

                    this.props.setMultipleChatData(channelId, {
                        userChatHistory: result,
                        currentResult: totalMsgCount,
                        scrollBottom: false,
                        deleteMessageId,
                    })

                    if (newMessage.length > 0) {
                        this.setState({
                            newMessage
                        })
                    }
                    if (newMsgs.length > 0) {
                        this.scrollBottom();
                    }
                }
            }

        });
    }

    getChannelChats(channelId, noOfPage, scrollBottom, userChatHistory) {
        let { loginId } = this.props
        let postJson = { reqId: getUuidv4(), channelId, pageLimit: 25, noOfPage: noOfPage, memberId: loginId };
        postJson = coversionED("encrypt", postJson).toString()
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.getChannelChatsSetEvent);
        request.setMessage(postJson);
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = response.getMessage();
                responseData = coversionED("decrypt", responseData)
                responseData = JSON.parse(responseData)
                let result = userChatHistory;
                responseData.result.map((ival, i) => {
                    result[ival.chatMessage.sendAt] = ival;
                });

                this.setState({
                    refresh: false
                })
                this.props.setMultipleChatData(channelId, {
                    userChatHistory: result,
                    currentResult: responseData.totalResult,
                    getChannelChatsFetched: true
                })

                if (scrollBottom) {
                    this.scrollBottom();
                } else if (Object.keys(result)[((noOfPage - 1) * 25)]) {
                    //focus div
                    document.getElementById(Object.keys(result)[((noOfPage - 1) * 25)]).scrollIntoView();
                }
            }
        });
    }

    async editChannelSubmit(event, gifURL) {
        event.preventDefault();
        let { companyId, channelId, fileList, loginId, editMessageText, editMessageId, userChatHistory, editMessageDetails, setEmptyText } = this.props;

        if ((editMessageText.trim() !== "") || fileList.length > 0 || gifURL !== "") {
            for (let i = 0; i < fileList.length; i++) {
                await this.fileUpload(i, fileList[i]);
            }

            if (gifURL !== "") {
                editMessageText = "";
                fileList = [];
            }

            editMessageDetails.message = editMessageText;
            editMessageDetails.fileList = fileList;
            userChatHistory[editMessageDetails.sendAt].chatMessage = editMessageDetails
            this.props.setMultipleChatData(channelId, {
                editMessageId: "",
                editMessageText: "",
                editMessageDetails: {},
                userChatHistory,
                setEmptyText: !setEmptyText,
                emojiPicker: false,
                fileList: [],
                gifURL: ""
            })

            if (startDayValidation(companyId, channelId, editMessageText)) {
                this.startEndDay("startDay")
            }

            let postJson = {
                reqId: getUuidv4(),
                messageId: editMessageId,
                channelId,
                senderId: loginId,
                message: editMessageText,
                editType: "chat",
                callData: "",
                fileList: fileList,
                objUrl: gifURL,
                deviceInfo: getAppInfo(),
                userType: GlobalMsgCons.type_user
            };
            postJson = coversionED("encrypt", postJson).toString()
            let request = new ZiFetchRequest();
            request.setDomain(GlobalDomainCons.chatSetDomain);
            request.setEvent(GlobalEventCons.editChannelChatSetEvent);
            request.setMessage(postJson);
            request.setDatetime(new Date().toString());
            getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
                if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                    console.warn('Invalid Request. Please try again later');
                } else {
                    let responseData = response.getMessage();
                    responseData = coversionED("decrypt", responseData)
                    responseData = JSON.parse(responseData)
                    if (responseData.response === GlobalMsgCons.success) {
                        console.log("updated.")
                    }
                }
            });
        }
    }

    scrollBottom() {
        setTimeout(() => {
            if (this.messageEnd) {
                this.messageEnd.scrollTop = this.messageEnd.scrollHeight;
            }
        }, 50)
    }

    scrollToPosition(position) {
        this.messageEnd.scrollTop = position;
    }

    handleClickOutside(e) {
        let { messageEmojiPicker } = this.state;
        if (messageEmojiPicker && !e.target.closest(".emoji-message") && !(e.target.className.includes("emoji-from-picker") || e.target.closest(".emoji-from-picker"))) {
            this.setState({
                messageEmojiPicker: false
            })
        }
    }

    setChatMessage(e) {
        let { channelId } = this.props;
        localStorage.setItem("currentChatText_" + channelId, e)
        this.props.setMultipleChatData(channelId, {
            message: e
        });
    }

    edit(data) {
        let details = JSON.parse(JSON.stringify(data));
        let { surveyData, surveyModal, surveyAction, channelId, chatSurveyTimeData } = this.props;
        if (details.messageType === GlobalMsgCons.surveyText) {
            surveyData.surveyQuestion = details.chatSurveyData.question;
            surveyData.surveyOption = details.chatSurveyData.optionMap;
            surveyData.surveyType = details.chatSurveyData.surveyType;
            let chatSurveyDuration = 0;
            if (typeof (details.chatSurveyData.duration) !== "undefined") {
                chatSurveyDuration = parseInt(details.chatSurveyData.duration);
            }
            surveyData.totalSeconds = chatSurveyDuration;
            surveyModal = true;
            surveyAction = "edit";
            let seconds = chatSurveyDuration;
            var d = Math.floor(seconds / (3600 * 24));
            var h = Math.floor(seconds % (3600 * 24) / 3600);
            var m = Math.floor(seconds % 3600 / 60);
            // var s = Math.floor(seconds % 60);
            // console.log("d-->", d, "-->h", h, '-->m' + m)
            chatSurveyTimeData.daySelected = parseInt(d)
            chatSurveyTimeData.hourSelected = parseInt(h)
            chatSurveyTimeData.minuteSelected = parseInt(m)

        }

        this.props.setMultipleChatData(channelId, {
            editMessageId: details.id,
            editMessageDetails: details,
            editMessageText: details.message,
            changeText: details.message,
            fileList: details.fileList ? details.fileList : [],
            editBlink: true,
            surveyModal,
            surveyData,
            surveyAction,
            chatSurveyTimeData
        });

        setTimeout(() => {
            this.props.setMultipleChatData(channelId, {
                editBlink: false
            })
        }, 3000, channelId)
    }

    async retryChatMessage(messageData) {
        let { channelId, userChatHistory, loginId, firstName, profilePhoto, companyId } = this.props;
        let { chatMessage } = messageData;
        let { sendAt, message, fileList, objUrl } = chatMessage

        delete userChatHistory[sendAt];
        let createdAt = new Date();

        let deviceInfo = getAppInfo();
        let messageId = getUuidv4();
        let tempMessage = {
            chatMessage: {
                id: messageId,
                userId: loginId,
                message,
                sendAt: createdAt.getTime(),
                createdAt: createdAt.getTime(),
                messageType: GlobalMsgCons.messageTypeChat,
                fileList,
                objUrl,
                deviceInfo
            },
            name: firstName,
            userPhoto: profilePhoto,
            tempMessage: true,
            tempMessageStatus: "loading"
        }
        userChatHistory[createdAt.getTime()] = tempMessage

        this.props.setMultipleChatData(channelId, {
            userChatHistory
        })
        this.scrollBottom()

        for (let i = 0; i < fileList.length; i++) {
            await this.fileUpload(i, fileList[i]);
        }

        let postJson = {
            reqId: getUuidv4(),
            messageId,
            channelId,
            orgId: companyId,
            senderId: loginId,
            message,
            fileList,
            sendAt: createdAt.getTime(),
            objUrl,
            messageType: GlobalMsgCons.messageTypeChat,
            deviceInfo,
            userType: GlobalMsgCons.type_user
        };
        postJson = coversionED("encrypt", postJson).toString()
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.sendMsgASyncSetEvent);
        request.setMessage(postJson);
        request.setDatetime(createdAt.toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
                userChatHistory[createdAt.getTime()].tempMessageStatus = "failed"

                this.props.setMultipleChatData(channelId, {
                    userChatHistory
                });
            } else {
                let responseData = response.getMessage();
                responseData = coversionED("decrypt", responseData)
                if (responseData === GlobalMsgCons.success) {

                }
            }
        });
    }

    async addChannelChat(event, gifURL) {
        event.preventDefault();
        let { selectedAgent, loginId, channelId, message, fileList, setEmptyText, companyId, firstName, profilePhoto, userChatHistory } = this.props;
        let msg = message.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, '')
        if ((msg.trim() !== "") || fileList.length > 0 || gifURL !== "") {
            if (startDayValidation(companyId, channelId, msg)) {
                this.startEndDay("startDay")
            }

            let createdAt = new Date();
            let deviceInfo = getAppInfo();
            let messageId = getUuidv4();

            let tempMessage = {
                chatMessage: {
                    id: messageId,
                    userId: loginId,
                    message,
                    sendAt: createdAt.getTime(),
                    createdAt: createdAt.getTime(),
                    messageType: selectedAgent.value ? GlobalMsgCons.botRequest : GlobalMsgCons.messageTypeChat,
                    fileList: fileList,
                    objUrl: gifURL,
                    deviceInfo,
                    aiAgentType: selectedAgent.value
                },
                name: firstName,
                userPhoto: profilePhoto,
                tempMessage: true,
                tempMessageStatus: "loading"
            }
            userChatHistory[createdAt.getTime()] = tempMessage
            localStorage.removeItem("currentChatText_" + channelId)
            await this.props.setMultipleChatData(channelId, {
                message: "",
                fileList: [],
                emojiPicker: false,
                setEmptyText: !setEmptyText,
                scrollBottom: true,
                gifURL: "",
                userChatHistory,
                selectedAgent: { label: "Select Agent", value: "" }
            })
            this.scrollBottom()

            for (let i = 0; i < fileList.length; i++) {
                await this.fileUpload(i, fileList[i]);
            }

            let postJson = {
                reqId: getUuidv4(),
                messageId,
                channelId,
                orgId: companyId,
                senderId: loginId,
                message,
                fileList: fileList,
                sendAt: createdAt.getTime(),
                objUrl: gifURL,
                messageType: selectedAgent.value ? GlobalMsgCons.botRequest : GlobalMsgCons.messageTypeChat,
                deviceInfo,
                aiAgentType: selectedAgent.value,
                userType: GlobalMsgCons.type_user
            };

            console.log("postJson", postJson)
            postJson = coversionED("encrypt", postJson).toString()
            let request = new ZiFetchRequest();
            request.setDomain(GlobalDomainCons.chatSetDomain);
            request.setEvent(GlobalEventCons.sendMsgASyncSetEvent);
            request.setMessage(postJson);
            request.setDatetime(createdAt.toString());
            getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
                if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                    console.warn('Invalid Request. Please try again later');
                    userChatHistory[createdAt.getTime()].tempMessageStatus = "failed"

                    this.props.setMultipleChatData(channelId, {
                        userChatHistory
                    });
                } else {
                    let responseData = response.getMessage();
                    responseData = coversionED("decrypt", responseData)
                    if (responseData === GlobalMsgCons.success) {

                    }
                }
            });
        }
    }

    async fileChange(files) {
        let { pageText } = this.state;
        let { channelId, fileList } = this.props;
        let length = files.length;
        let validation = true;
        if (length > 0) {
            if (fileList.length <= 4) {
                let inc = fileList.length;
                for (var i = 0; i < length; i++) {
                    let fileUrl = URL.createObjectURL(files[i]);
                    let file = files[i];
                    let filename = file.name;
                    let valid = this.isValidFileExt(filename);
                    if (!valid) {
                        validation = false;
                    }
                    let ext = filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
                    let uid = uuidv4();
                    let fileObj = {
                        file: file,
                        origFileName: filename,
                        fileUrl: fileUrl,
                        fileName: uid + "." + ext,
                        docsId: uid,
                        processType: "chat",
                        fileType: ext
                    };
                    if (validation) {
                        fileList[inc] = fileObj;
                        fileList[inc].uploadProcess = "pending";

                        this.props.setMultipleChatData(channelId, {
                            fileList
                        })
                        inc++;
                    } else {
                        toast.error(getValidLabels(pageText, 'fileNotValidErr_msg'), {
                            position: "top-center",
                            autoClose: 1500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                }
            } else {

                toast.error("Maximum 5 files can be uploaded", {
                    position: "top-center",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }

        }
    }

    fileUpload(i, dataJson) {
        let { loginId } = this.props;
        const postFileUpload = new FormData();
        postFileUpload.append('file' + (i + 1), dataJson.file);
        postFileUpload.append('fileName' + (i + 1), dataJson.fileName);
        postFileUpload.append('processType' + (i + 1), dataJson.processType);
        postFileUpload.append('docsId' + (i + 1), dataJson.docsId);
        fetch(MyConstant.keyList.apiURL + "uploads/" + dataJson.processType + "/" + dataJson.docsId + "/" + loginId, {
            method: "POST",
            mode: 'cors',
            redirect: 'follow',
            body: postFileUpload,
        }).then(
            function (response) {
                return response;
            }
        ).then(json => {
            return json.response;
        }).catch(error => console.warn(error));
    }
    isValidFileExt(name) {
        name = name.toLowerCase()
        const ext = ['.jpg', '.jpeg', '.bmp', '.gif', '.png', '.svg', '.csv', '.pdf', '.xls', '.xlsx', '.mp3', '.mp4', '.3gp', '.ogg', '.mov', ".zip", ".rar", ".7zip", ".wav"];
        console.log("name", name)
        return ext.some(el => name.endsWith(el));
    }

    onEmojiClick(event, emojiObject) {
        let { insertTextToggle, channelId } = this.props
        this.props.setMultipleChatData(channelId, {
            insertTextToggle: !insertTextToggle,
            insertText: emojiObject.emoji
        })
    }

    call() {
        let { channelId, loginId, companyId, path } = this.props;
        let roomId = "Teqit" + this.roomIdCreate();
        let createdAt = new Date();
        let messageId = getUuidv4();
        let deviceInfo = getAppInfo();
        let postJson = {
            reqId: getUuidv4(),
            messageId,
            channelId,
            orgId: companyId,
            senderId: loginId,
            message: "",
            fileList: [],
            sendAt: createdAt.getTime(),
            objUrl: "",
            messageType: "call",
            deviceInfo,
            callData: {
                startAt: 0,
                callStatus: "",
                endAt: 0,
                joinedPeople: [loginId],
                roomId,
                metaInfo: ""
            },
            userType: GlobalMsgCons.type_user
        };

        this.setState({
            callButtonLoader: true
        }, () => {
            this.props.setCallWindowData({
                channelId,
                roomId,
                messageId,
                calltype: "call"
            })
        })

        postJson = coversionED("encrypt", postJson).toString()
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.sendMsgASyncSetEvent);
        request.setMessage(postJson);
        request.setDatetime(createdAt.toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {

                let responseData = response.getMessage();
                responseData = coversionED("decrypt", responseData)
                if (responseData === GlobalMsgCons.success) {
                    // let url = path + "/call/" + channelId + "/" + roomId + "/" + messageId;
                    // this.props.history.push(url)
                    // window.open(url, "_blank")

                    this.setState({
                        callButtonLoader: false
                    })
                }
            }
        });

    }

    roomIdCreate = function () {
        var uniqueId = uuidv4();
        uniqueId = uniqueId.split(/([-])/g);
        let newval = ""
        uniqueId.map((val) => {
            if (val != "-") {
                newval += val
            }
        })
        newval = newval.toString().substring(1, 11);
        return newval
    }

    humanDateFormat(createdAt) {
        let { activeCompanyDetails, hoursSelected } = this.props;
        return getDateWithFormat(createdAt, activeCompanyDetails, hoursSelected)
    }


    loadMore() {
        let { channelId, noOfPage, userChatHistory } = this.props;
        this.props.setMultipleChatData(channelId, {
            noOfPage: noOfPage + 1
        })
        this.getChannelChats(channelId, (noOfPage + 1), false, userChatHistory);
    }


    previewContent(buildUrl) {
        let content;
        if (buildUrl !== undefined) {
            var re = /(?:\.([^.]+))?$/;
            let url = new URL(buildUrl)
            let params = new URLSearchParams(url.search);
            let fileType = re.exec(params.get('key'))[1];

            console.log("buildUrl", buildUrl)
            if (fileType) {
                let images = ["jpg", "png", "jpeg", "gif"];
                let videos = ["mp4", "3gp", "ogg", "mov"]
                let pdf = ["pdf"]
                let txt = ["txt"]
                let audio = ["mp3", "wav"]
                let fileContent = "";
                if (images.includes(fileType.toLowerCase())) {
                    fileContent = <img src={buildUrl} className="img-fluid" />
                } else if (videos.includes(fileType.toLowerCase())) {
                    fileContent = <video width="100%" controls>
                        <source src={buildUrl} />
                    </video>
                } else if (pdf.includes(fileType.toLowerCase())) {
                    fileContent = <PDFViewer url={buildUrl} />
                } else if (txt.includes(fileType.toLowerCase())) {
                    fileContent = <iframe frameborder="0" style={{ height: "75vh" }} src={buildUrl} height="75vh" width="100%" />
                } else if (audio.includes(fileType.toLowerCase())) {
                    fileContent = <audio width="100%" controls>
                        <source src={buildUrl} />
                    </audio>
                } else {
                    fileContent = <div>
                        <div className="text-right">
                            <button className="btn btn-secondary" onClick={() => this.downloadUrl(buildUrl)} >
                                <i className="fa fa-download"></i>
                            </button>
                        </div>
                        Not able to preview.
                    </div>
                }

                content = <React.Fragment>
                    {/* <div className="text-right">
                        <button className="btn btn-secondary" onClick={() => this.downloadUrl(buildUrl)} >
                            <i className="fa fa-download"></i>
                        </button>
                    </div> */}
                    <div style={{ textAlign: "center" }} >
                        {fileContent}
                    </div>
                </React.Fragment>
            }
        }

        return content;
    }

    downloadUrl(buildUrl) {
        var link = document.createElement("a");
        link.download = "feedback.xls";
        link.href = buildUrl;
        link.click();
    }


    fileIcon(buildUrl, fileType) {
        let images = ["jpg", "png"];
        let videos = ["mp4", "3gp", "ogg", "mov"]
        let pdf = ["pdf"]
        let txt = ["txt"]
        let fileContent = "";
        if (images.includes(fileType.toLowerCase())) {
            fileContent = <img src={buildUrl} className="img-fluid" />
        } else if (videos.includes(fileType.toLowerCase())) {
            fileContent = <i className="fa fa-video" />
        } else if (pdf.includes(fileType.toLowerCase())) {
            fileContent = <i className="fa fa-file-pdf" />
        } else if (txt.includes(fileType.toLowerCase())) {
            fileContent = <i className="fa fa-file-alt" />
        } else {
            fileContent = <i className="fa fa-file" />
        }

        return fileContent;
    }

    previewBlobContent() {
        let { previewBlob } = this.props;
        let { filePath, fileType } = previewBlob;

        let images = ["jpg", "png", "jpeg", "gif"];
        let videos = ["mp4", "3gp", "ogg", "mov"]
        let pdf = ["pdf"]
        let txt = ["txt"]
        let audio = ["mp3", "wav"]
        let excel = ["csv", "xls", "xlsx"]
        let fileContent = "";
        if (images.includes(fileType.toLowerCase())) {
            fileContent = <img src={filePath} className="img-fluid" />
        } else if (videos.includes(fileType.toLowerCase())) {
            fileContent = <video width="100%" controls>
                <source src={filePath} />
            </video>
        } else if (pdf.includes(fileType.toLowerCase())) {
            fileContent = <PDFViewer url={filePath} />
        } else if (txt.includes(fileType.toLowerCase())) {
            fileContent = <iframe frameborder="0" style={{ height: "75vh" }} src={filePath} height="75vh" width="100%" />
        } else if (audio.includes(fileType.toLowerCase())) {
            fileContent = <audio width="100%" controls>
                <source src={filePath} />
            </audio>
        }

        let content = <div style={{ textAlign: "center" }} >
            {fileContent}
        </div>

        return content;
    }

    fileDelete(e, i) {
        e.stopPropagation();
        let { fileList } = this.props;
        fileList.splice(i, 1);

        let { channelId } = this.props;
        this.props.setMultipleChatData(channelId, {
            fileList
        })
    }

    toggleInfo() {
        let { channelId, path } = this.props;
        this.props.history.push(path + "/chat-channel-view/" + channelId);
    }

    chatFileView(ival) {
        let { userChatHistory, channelId } = this.props;
        let message = userChatHistory[ival].chatMessage;
        let tempMessage = userChatHistory[ival].tempMessage;
        tempMessage = tempMessage !== undefined ? tempMessage : false;
        let fileList = message.fileList;

        let images = ["jpg", "png", "jpeg", "gif"];
        let videos = ["mp4", "3gp", "ogg", "mov"]
        let pdf = ["pdf"]
        let txt = ["txt"]
        let audio = ["mp3", "wav"]
        let contentDiv = "";
        let showRowType = false;

        contentDiv = fileList.map((fileMap, fi) => {
            showRowType = false;
            let buildUrl = MyConstant.keyList.apiURL + "vp?action=" + fileMap.processType + "&key=" + fileMap.fileName + "&id=" + message.userId;
            if (tempMessage) {
                buildUrl = fileMap.fileUrl;
            }

            let downlodFileName = fileMap.origFileName;
            let downLoadUrl = MyConstant.keyList.apiURL + `download/${downlodFileName}?action=` + fileMap.processType + "&key=" + fileMap.fileName + "&id=" + message.userId;
            let fileType = fileMap.fileType;
            let preview = { filePath: buildUrl, fileType }
            let content = <div className="message-file">
                <div className="file" onClick={() => {
                    this.props.setMultipleChatData(channelId, {
                        buildUrl,
                        previewModal: true,
                        previewBlob: preview,
                        previewType: tempMessage ? "blob" : "",
                    })
                }}>
                    <i className="fa fa-file"></i>
                    {fileMap.processType === "chat-record" ? "" : fileMap.origFileName}
                </div>
            </div>;

            if (images.includes(fileType.toLowerCase())) {
                showRowType = true;
                content = <div className=" chat-file-box-wrapper col-3 " style={{ display: "contents" }}>
                    <div className="chat-file-box p-3" >
                        <ImageView url={buildUrl} />
                    </div>
                    {!tempMessage && <div className="download-url">
                        <a href={downLoadUrl} download className="files-down">
                            <i className="fa fa-download "></i>
                        </a>
                    </div>
                    }
                </div>
            } else if (videos.includes(fileType.toLowerCase())) {
                content = <div className="d-flex chat-file-box-wrapper">
                    <div className="chat-file-box" onClick={() => {
                        this.props.setMultipleChatData(channelId, {
                            buildUrl,
                            previewModal: true,
                            previewBlob: preview,
                            previewType: tempMessage ? "blob" : "",
                        })
                    }}>
                        <video width="100%" controls style={{ width: "80%" }}>
                            <source src={buildUrl} />
                        </video>
                    </div>
                    {!tempMessage && <div className="download-url">
                        <a href={downLoadUrl} download className="files-down">
                            <i className="fa fa-download "></i>
                        </a>
                    </div>
                    }
                </div>
            } else if (audio.includes(fileType.toLowerCase())) {
                content = <div className="d-flex chat-file-box-wrapper">
                    <div>
                        <audio width="100%" controls onClick={() => {
                            this.props.setMultipleChatData(channelId, {
                                buildUrl,
                                previewModal: true,
                                previewBlob: preview,
                                previewType: tempMessage ? "blob" : "",
                            })
                        }}>
                            <source src={buildUrl} />
                        </audio>
                    </div>
                    {!tempMessage && <div className="download-url">
                        <a href={downLoadUrl} download className="files-down">
                            <i className="fa fa-download "></i>
                        </a>
                    </div>
                    }
                </div>
            } else if (pdf.includes(fileType.toLowerCase())) {
                content = <div className="d-flex chat-file-box-wrapper">
                    <div className="chat-file-box" >
                        <div className="file-info d-flex" onClick={() => {
                            this.props.setMultipleChatData(channelId, {
                                buildUrl,
                                previewModal: true,
                                previewBlob: preview,
                                previewType: tempMessage ? "blob" : "",
                            })
                        }} >
                            <div className="icon" >
                                <i className="fa fa-file-pdf"></i>
                            </div>
                            <div className="text">
                                {fileMap.origFileName}
                            </div>
                        </div>
                    </div>
                    {!tempMessage && <div className="download-url">
                        <a href={downLoadUrl} download className="files-down">
                            <i className="fa fa-download "></i>
                        </a>
                    </div>
                    }
                </div>
            }
            return content
        })
        let returnDiv = contentDiv
        if (showRowType) {
            returnDiv = <div className="row">
                {contentDiv}
            </div>
        }


        return returnDiv;
    }

    chatEditFileView(file) {
        let { loginId } = this.props;
        let buildUrl = MyConstant.keyList.apiURL + "vp?action=" + file.processType + "&key=" + file.fileName + "&id=" + loginId;
        let fileType = file.fileType;
        return this.fileIcon(buildUrl, fileType);
    }

    deleteChatMessage(messageId, key) {
        let { channelId, loginId, userChatHistory } = this.props
        delete userChatHistory[key];

        this.props.setMultipleChatData(channelId, {
            editMessageId: "",
            userChatHistory
        })

        //messageId: String, channelId: String, senderId: String
        let postJson = { reqId: getUuidv4(), messageId: messageId, channelId: channelId, senderId: loginId }
        postJson = coversionED("encrypt", postJson).toString()
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.deleteChannelChatSetEvent);
        request.setMessage(postJson);
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = response.getMessage();
                responseData = coversionED("decrypt", responseData)
                responseData = JSON.parse(responseData)

                if (responseData.response === GlobalMsgCons.success) {
                    console.log("deleted!")
                }
            }
        });

    }

    joinCall(data) {
        let { id, callData } = data;
        let { channelId, path } = this.props;
        let { roomId } = callData;
        // let url = path + "/join-call/" + channelId + "/" + roomId + "/" + id;
        // window.open(url, "_blank")
        // this.props.history.push(url)
        this.props.setCallWindowData({
            channelId,
            roomId,
            messageId: id,
            calltype: "join"
        })
    }

    declineCall(data) {
        let { id } = data;
        let { loginId, channelId } = this.props;
        this.updateCallStatus(loginId, channelId, id, "reject-call")
    }

    updateCallStatus(senderId, channelId, messageId, status) {
        let postJson = { reqId: getUuidv4(), messageId, channelId, memberId: senderId, status };
        postJson = coversionED("encrypt", postJson).toString()
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.updateCallStatusSetEvent);
        request.setMessage(postJson);
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {

            }
        });
    }

    updateEmoJiReactionForMsg(msgId, emojiId) {
        let { loginId, channelId, companyId } = this.props;
        let postJson = { reqId: getUuidv4(), channelId, msgId, userId: loginId, orgId: companyId, emojiId };
        postJson = coversionED("encrypt", postJson).toString()
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.updateEmoJiReactionForMsg);
        request.setMessage(postJson);
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {

            }
        });
    }

    joinMeetingCall(data) {
        let { meetingsData } = data;
        let meetingId = meetingsData.id;
        let { path } = this.props

        let url = path + "/join-meeting-call/" + meetingId;
        // window.open(url, "_blank")
        this.props.history.push(url)
    }
    async getPageText() {
        let pageText = await getPageText('chat');
        this.setState({ pageText: pageText })
    }


    callStatus(status, data) {
        let { loginId } = this.props;
        let str = "";
        switch (status) {
            case "call-started":
                str = "Call Started."
                break;
            case "line-busy":
                if (data.userId === loginId) {
                    let name = "Receiver";
                    // Todo name display
                    str = name + " is on another call."
                } else {
                    str = "Missed call."
                }
                break;
            case "call-ended":
                str = "Call Ended."
                break;
            case "call-declined":
                str = "Call Declined."
                break;
            case "missed-call":
                str = "Missed call."
                break;
            default:
                str = "Calling.";
                break;
        }

        return str;
    }



    toggleNotification(channelMuteStatus, selectedMessageSound) {
        let { channelId, loginId } = this.props;
        let postJson = { reqId: getUuidv4(), channelId, memberId: loginId, muteStatus: channelMuteStatus, sound: selectedMessageSound };
        postJson = coversionED("encrypt", postJson).toString()
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.updateChannelNotificationSoundStatusSE);
        request.setMessage(postJson);
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {

            }
        });

    }

    timeStampToDate = (d) => {
        let day, month, year
        let separator = "/";
        month = '' + (d.getMonth() + 1);
        day = '' + d.getDate();
        year = d.getFullYear();
        let returnStr = ("0" + day).slice(-2) + separator + ("0" + month).slice(-2) + separator + year;
        return returnStr
    }




    convertStatus(status) {
        let returnMsg = ""
        switch (status) {
            case GlobalMsgCons.status_available:
                returnMsg = <div className="mx-2" style={{ width: 10, height: 10, borderRadius: 5, backgroundColor: "#28a745", display: "inline-block" }}>
                </div>
                break;
            case GlobalMsgCons.status_onCall:
                returnMsg = <div className="mx-2" style={{ width: 10, height: 10, borderRadius: 5, backgroundColor: "red", display: "inline-block" }}>
                </div>
                break;
            case GlobalMsgCons.status_notOnline:
            case GlobalMsgCons.status_notAvailable:
                returnMsg = <div className="mx-2" style={{ width: 10, height: 10, borderRadius: 5, backgroundColor: "grey", display: "inline-block" }}>
                </div>
                break;

            case GlobalMsgCons.status_steppedAway:
                returnMsg = <div className="mx-2" style={{ width: 10, height: 10, borderRadius: 10 / 2, backgroundColor: "orange", display: "inline-flex", alignItems: "center", justifyContent: "center" }}>
                    {/* <i class="fa fa-bolt" style={{ fontSize: 10, position: "absolute", color: "#fff" }} aria-hidden="true"></i> */}
                </div>
                break;

            default:
                returnMsg = "";
                break;
        }
        return returnMsg;
    }
    convertCallStatus(status) {
        let returnMsg = "";
        switch (status) {
            case GlobalMsgCons.status_onCall:
                returnMsg = <div className="mx-2" style={{ display: "inline-flex", alignItems: "center", justifyContent: "center", color: "#3a7c5e" }}>
                    <i className="fa fa-phone"></i>
                </div>
                break;
            default:
                returnMsg = "";
                break;
        }
        return returnMsg;
    }


    checkUserDetailsDisplay(chatMessage, index, type) {
        let { channelType } = this.props
        let nameDisplay = false
        if (lastMessage !== undefined && (type !== "live" || index > 0)) {
            let diff = chatMessage.sendAt - lastMessage.sendAt
            if (lastMessage.userId === chatMessage.userId && diff < 60000 && lastMessage.deviceInfo === chatMessage.deviceInfo) {
                nameDisplay = false
            } else {
                nameDisplay = true
            }
        } else {
            nameDisplay = true
        }

        if (["communityServiceRequestSendingChannel", "communityServiceRequestReceivingChannel"].includes(channelType)) {
            nameDisplay = true
        }

        lastMessage = chatMessage

        return nameDisplay;
    }

    updateChatTypingInfo(event) {
        let { userId, companyId, firstName, channelId } = this.props;
        let postJson = { reqId: getUuidv4(), channelId, userId, orgId: companyId, userName: firstName, actionType: event };
        postJson = coversionED("encrypt", postJson).toString()
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.updateChatTypingInfo);
        request.setMessage(postJson);
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, async (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {

            }
        });
    }

    search() {
        let { searchText } = this.state;
        let { path, channelId } = this.props
        let { feed } = this.props.match.params;
        let url = path + "/search-chat/" + channelId;

        if (feed) {
            url += "/" + feed
        }
        this.props.history.push(url, {
            searchText
        })
    }

    openThread(message) {
        let { path, channelId } = this.props;
        this.props.history.push(path + "/chat-thread/" + channelId, {
            threadData: {
                message,
                channelId
            },
        });
    }


    async surveyValidation() {
        let { surveyData, surveyAction, channelId, chatSurveyTimeData } = this.props;
        let errorObj = {};
        let optionsValidate = false;
        errorObj.surveyOption = {};

        if (surveyData.surveyQuestion == "") {
            optionsValidate = true;
            errorObj.surveyQuestion = "Plese enter question";
        }

        if (chatSurveyTimeData.hourSelected === 0 && chatSurveyTimeData.minuteSelected === 0 && chatSurveyTimeData.daySelected === 0) {
            errorObj.duration = "Please enter duration!";
            optionsValidate = true;
        }
        let getTimer = await this.timeConversion();
        surveyData.totalSeconds = getTimer

        Object.keys(surveyData.surveyOption).map((ival, i) => {
            let objLabel = surveyData.surveyOption[ival];
            if (objLabel === "") {
                optionsValidate = true;
                errorObj.surveyOption[ival] = "Plese enter option";
            }
        })


        await this.props.setMultipleChatData(channelId, {
            errorSurveyData: errorObj, surveyData
        });


        if (!optionsValidate) {
            if (surveyAction === "edit") {
                this.editSurveySubmit()
            }
            else {
                this.chatSurveyPost()
            }
        }
    }
    async chatSurveyPost() {
        let { loginId, channelId, message, companyId, firstName, profilePhoto, surveyData, userChatHistory, chatSurveyTimeData } = this.props;

        let createdAt = new Date();
        let deviceInfo = getAppInfo()
        let messageId = getUuidv4();
        let tempMessage = {
            chatMessage: {
                id: messageId,
                userId: loginId,
                message,
                sendAt: createdAt.getTime(),
                createdAt: createdAt.getTime(),
                messageType: GlobalMsgCons.surveyText,
                fileList: [],
                objUrl: "",
                chatSurveyData: {
                    question: surveyData.surveyQuestion,
                    optionMap: surveyData.surveyOption,
                    surveyType: surveyData.surveyType,
                    duration: surveyData.totalSeconds
                },
                deviceInfo
            },
            name: firstName,
            userPhoto: profilePhoto
        }
        userChatHistory[createdAt.getTime()] = tempMessage

        await this.props.setMultipleChatData(channelId, {
            userChatHistory,
            surveyModal: false,
            surveyData: {
                surveyQuestion: "",
                surveyOption: { 1: "", 2: "" },
                surveyType: "single"
            },
            surveyAction: "add",
            chatSurveyTimeData: {
                ...chatSurveyTimeData, daySelected: 0, hourSelected: 0, minuteSelected: 30,
            }
        })
        this.scrollBottom();
        let postJson = {
            reqId: getUuidv4(),
            messageId,
            channelId,
            orgId: companyId,
            senderId: loginId,
            message: "",
            fileList: [],
            sendAt: createdAt.getTime(),
            objUrl: "",
            messageType: GlobalMsgCons.surveyText,
            deviceInfo,
            chatSurveyData: {
                question: surveyData.surveyQuestion,
                optionMap: surveyData.surveyOption,
                surveyType: surveyData.surveyType,
                duration: surveyData.totalSeconds
            },
            userType: GlobalMsgCons.type_user
        };
        console.log("--->", postJson)
        postJson = coversionED("encrypt", postJson).toString()
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.sendMsgASyncSetEvent);
        request.setMessage(postJson);
        request.setDatetime(createdAt.toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, async (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = response.getMessage();
                responseData = coversionED("decrypt", responseData)
                if (responseData === GlobalMsgCons.success) {

                }
            }
        });

    }

    replyChatSurveyQuestion(msgId, optionId, chatMessage) {
        let { userId, companyId, channelId, userChatHistory } = this.props;

        let { surveyType, responseData } = chatMessage.chatSurveyData;

        responseData = responseData !== undefined ? responseData : {}

        if (surveyType === "single" && responseData[optionId] !== undefined) {
            Object.keys(responseData).map((loopOptionId) => {
                if (optionId !== loopOptionId) {
                    if (responseData[loopOptionId][userId] !== undefined) {
                        delete responseData[loopOptionId][userId]
                    }
                }
            })
        }

        if (responseData[optionId] !== undefined && responseData[optionId][userId] !== undefined) {
            delete responseData[optionId][userId]
        } else {
            if (responseData[optionId] === undefined) {
                responseData[optionId] = {}
            }
            responseData[optionId][userId] = {
                userId,
                createdAt: ""
            }
        }
        chatMessage.chatSurveyData.responseData = responseData

        console.log("chatMessage", chatMessage)
        userChatHistory[chatMessage.sendAt].chatMessage = chatMessage
        this.props.setMultipleChatData(channelId, {
            userChatHistory
        })

        let postJson = { reqId: getUuidv4(), channelId, msgId, userId, orgId: companyId, optionId };
        postJson = coversionED("encrypt", postJson).toString()
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.updateChatSurveyReplySetEvent);
        request.setMessage(postJson);
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, async (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {

            }
        });
    }

    async editSurveySubmit() {
        let { channelId, fileList, loginId, editMessageText, editMessageId, userChatHistory, editMessageDetails, setEmptyText, surveyData, chatSurveyTimeData } = this.props;
        let postJson = {
            reqId: getUuidv4(),
            messageId: editMessageId,
            channelId,
            senderId: loginId,
            message: "",
            editType: GlobalMsgCons.surveyText,
            fileList: fileList,
            objUrl: "",
            messageType: GlobalMsgCons.surveyText,
            deviceInfo: getAppInfo(),
            chatSurveyData: {
                question: surveyData.surveyQuestion,
                optionMap: surveyData.surveyOption,
                surveyType: surveyData.surveyType,
                duration: surveyData.totalSeconds,
            },
            userType: GlobalMsgCons.type_user
        };
        postJson = coversionED("encrypt", postJson).toString()
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.editChannelChatSetEvent);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = response.getMessage();
                responseData = coversionED("decrypt", responseData)
                responseData = JSON.parse(responseData)
                if (responseData.response === GlobalMsgCons.success) {
                    editMessageDetails.message = editMessageText;
                    editMessageDetails.fileList = fileList;
                    userChatHistory[editMessageDetails.sendAt].chatMessage = editMessageDetails
                    this.props.setMultipleChatData(channelId, {
                        editMessageId: "",
                        editMessageText: "",
                        editMessageDetails: {},
                        userChatHistory,
                        setEmptyText: !setEmptyText,
                        emojiPicker: false,
                        fileList: [],
                        gifURL: "",
                        surveyModal: false,
                        surveyData: {
                            surveyQuestion: "",
                            surveyOption: { 1: "", 2: "" },
                            surveyType: "single"
                        },
                        surveyAction: "add",
                        chatSurveyTimeData: {
                            ...chatSurveyTimeData, daySelected: 0, hourSelected: 0, minuteSelected: 30,
                        }
                    })
                }
            }
        });

    }
    timeReturn(createdAt) {
        let { activeCompanyDetails, hoursSelected } = this.props;
        return getTimeFormate(createdAt, activeCompanyDetails, hoursSelected)
    }
    getChatSurveyReplyDetails(msgId, channelId, surveyQues, optdata) {
        let { surveyModalData, surveyQuestionStr } = this.props
        let postJson = { reqId: getUuidv4(), channelId, msgId };
        console.log("postJson", postJson)
        postJson = coversionED("encrypt", postJson).toString()
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.chatSurveyReplyDetailsSetEvent);
        request.setMessage(postJson);
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, async (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = response.getMessage();
                responseData = coversionED("decrypt", responseData)
                responseData = JSON.parse(responseData)
                let result = responseData.result
                let sortData = result;
                sortData = Object.keys(sortData)
                    .map(function (k) { return { key: k, value: sortData[k] }; })
                    .sort(function (a, b) { return b.value.length - a.value.length; });
                this.props.setMultipleChatData(channelId, { surveyPollModal: true, surveyModalData: sortData, surveyQuestionStr: surveyQues, surveyModalOptData: optdata })
            }
        });
    }
    surveyOptionSet() {
        let { chatSurveyTimeData } = this.props;
        let { channelId, } = this.props.match.params;
        let dayOptions = [];
        for (let i = 0; i <= 31; i++) {
            let label = i.toString().padStart(2, "0");
            dayOptions.push({ label: label, value: i })
        }
        let hourOption = [];
        for (let h = 0; h < 25; h++) {
            let label = h.toString().padStart(2, "0");
            hourOption.push({ label: label, value: h })
        }
        let minuteOption = [];
        for (let m = 0; m < 61; m++) {
            let label = m.toString().padStart(2, "0");
            minuteOption.push({ label: label, value: m })
        }
        chatSurveyTimeData.dayOptions = dayOptions
        chatSurveyTimeData.minuteOption = minuteOption
        chatSurveyTimeData.hourOption = hourOption
        this.props.setMultipleChatData(channelId, { chatSurveyTimeData })

    }
    timeConversion() {
        let { chatSurveyTimeData } = this.props
        let { daySelected, minuteSelected, hourSelected, } = chatSurveyTimeData
        let convDuration = 0;
        if (minuteSelected > 0 && hourSelected === 0 && daySelected == 0) {
            convDuration = 60 * minuteSelected;
        }
        if (hourSelected > 0 && minuteSelected === 0 && daySelected === 0) {
            convDuration = 60 * hourSelected * 60;
        }
        if (daySelected > 0 && minuteSelected === 0 && hourSelected === 0) {
            convDuration = 60 * daySelected * 60 * 24;
        }
        if (minuteSelected > 0 && hourSelected > 0 && daySelected === 0) {
            convDuration = (60 * hourSelected * 24 * 60) + 60 * minuteSelected;
        }
        if (minuteSelected > 0 && hourSelected > 0 && daySelected > 0) {
            convDuration = (60 * daySelected * 60 * 24) + (60 * hourSelected * 60) + (60 * minuteSelected);
        }
        if (daySelected > 0 && hourSelected > 0 && minuteSelected === 0) {
            convDuration = (60 * daySelected * 60 * 24) + (60 * hourSelected * 60);
        }
        if (daySelected > 0 && minuteSelected > 0 && hourSelected === 0) {
            convDuration = (60 * daySelected * 60 * 24) + (60 * minuteSelected);
        }
        return convDuration
    }

    async startEndDay(actionType) {
        let { companyId, userId } = this.props
        var today = new Date();
        var getCurrentMonth = (today.getMonth() + 1).toString().padStart(2, "0");
        var dateStr = today.getFullYear() + "-" + getCurrentMonth + "-" + today.getDate().toString().padStart(2, "0");

        let ip = await getMyIp()
        let ipInfo = { actionType, ip, }
        let postJson = { reqId: getUuidv4(), memberId: userId, orgId: companyId, ipInfo: JSON.stringify(ipInfo), dateStr }
        const request = new ZiFetchRequest();
        request.setDomain("user");
        request.setEvent("updateUserIpHistory");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
            }
        });

    }

    handleLongPress(selectedMessage, chatMessage) {
        let _this = this;
        let { channelId } = this.props;
        longPressEvent = setTimeout(() => {
            _this.props.setMultipleChatData(channelId, {
                selectedMessage,
                selectedChatMessage: chatMessage,
                optionsModal: true
            })
        }, 1000);
    }

    handleLongRelease() {
        clearTimeout(longPressEvent);
    }

    patientPost(openMrsData, messageType) {
        let { loginId, channelId, message, fileList, companyId, userChatHistory, setEmptyText, profilePhoto, firstName } = this.props;
        let createdAt = new Date();
        let deviceInfo = getAppInfo();
        let messageId = getUuidv4();

        let tempMessage = {
            chatMessage: {
                id: messageId,
                userId: loginId,
                message,
                sendAt: createdAt.getTime(),
                createdAt: createdAt.getTime(),
                messageType,
                fileList: fileList,
                objUrl: "",
                deviceInfo,
                aiAgentType: "",
                openMrsData
            },
            name: firstName,
            userPhoto: profilePhoto,
            tempMessage: true,
            tempMessageStatus: "loading"
        }
        userChatHistory[createdAt.getTime()] = tempMessage
        localStorage.removeItem("currentChatText_" + channelId)
        this.props.setMultipleChatData(channelId, {
            message: "",
            fileList: [],
            emojiPicker: false,
            setEmptyText: !setEmptyText,
            scrollBottom: true,
            gifURL: "",
            userChatHistory,
            selectedPatient: { label: "OpenMRS", value: "" },
            patientBuildJson: { ...MyConfig.patientBuildJson },
            searchPatientBuildJson: { ...MyConfig.searchPatientBuildJson },
        })
        this.scrollBottom()

        let postJson = {
            reqId: getUuidv4(),
            messageId,
            channelId,
            orgId: companyId,
            senderId: loginId,
            message,
            fileList: fileList,
            sendAt: createdAt.getTime(),
            objUrl: "",
            messageType,
            deviceInfo,
            aiAgentType: "",
            userType: GlobalMsgCons.type_user,
            openMrsData
        };

        console.log("postJson", postJson)
        postJson = coversionED("encrypt", postJson).toString()
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.sendMsgASyncSetEvent);
        request.setMessage(postJson);
        request.setDatetime(createdAt.toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
                userChatHistory[createdAt.getTime()].tempMessageStatus = "failed"

                this.props.setMultipleChatData(channelId, {
                    userChatHistory
                });
            } else {
                let responseData = response.getMessage();
                responseData = coversionED("decrypt", responseData)
                if (responseData === GlobalMsgCons.success) {
                    userChatHistory[createdAt.getTime()].tempMessageStatus = ""

                    this.props.setMultipleChatData(channelId, {
                        userChatHistory
                    });
                }
            }
        });
    }

    sendCommunityServiceEvent(csEventData, messageType) {
        let { loginId, channelId, message, fileList, companyId, userChatHistory, setEmptyText, profilePhoto, firstName } = this.props;
        let createdAt = new Date();
        let deviceInfo = getAppInfo();
        let messageId = getUuidv4();

        let tempMessage = {
            chatMessage: {
                id: messageId,
                userId: loginId,
                message,
                sendAt: createdAt.getTime(),
                createdAt: createdAt.getTime(),
                messageType,
                fileList: fileList,
                objUrl: "",
                deviceInfo,
                aiAgentType: "",
                csEventData
            },
            name: firstName,
            userPhoto: profilePhoto,
            tempMessage: true,
            tempMessageStatus: "loading"
        }
        userChatHistory[createdAt.getTime()] = tempMessage
        localStorage.removeItem("currentChatText_" + channelId)
        this.props.setMultipleChatData(channelId, {
            message: "",
            fileList: [],
            emojiPicker: false,
            setEmptyText: !setEmptyText,
            scrollBottom: true,
            gifURL: "",
            userChatHistory,
            selectedAgent: { label: "Select Agent", value: "" }
        })
        this.scrollBottom()

        let postJson = {
            reqId: getUuidv4(),
            messageId,
            channelId,
            orgId: companyId,
            senderId: loginId,
            message,
            fileList: fileList,
            sendAt: createdAt.getTime(),
            objUrl: "",
            messageType,
            deviceInfo,
            aiAgentType: "",
            userType: GlobalMsgCons.type_user,
            csEventData
        };

        console.log("postJson", postJson)
        postJson = coversionED("encrypt", postJson).toString()
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.chatSetDomain);
        request.setEvent(GlobalEventCons.sendMsgASyncSetEvent);
        request.setMessage(postJson);
        request.setDatetime(createdAt.toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
                userChatHistory[createdAt.getTime()].tempMessageStatus = "failed"

                this.props.setMultipleChatData(channelId, {
                    userChatHistory
                });
            } else {
                let responseData = response.getMessage();
                responseData = coversionED("decrypt", responseData)
                if (responseData === GlobalMsgCons.success) {
                    userChatHistory[createdAt.getTime()].tempMessageStatus = ""

                    this.props.setMultipleChatData(channelId, {
                        userChatHistory
                    });
                }
            }
        });
    }

    refresh() {
        this.setState({
            refresh: true
        }, () => {
            setTimeout(() => {
                let { channelId, userChatHistory } = this.props;
                this.getChannelChats(channelId, 1, true, userChatHistory);
            }, 500)
        })
    }

    getCommunityHistoryData(caseNo) {
        let { companyId, channelId, activeUserType } = this.props;
        let postJson = { reqId: getUuidv4(), eventId: caseNo, orgId: companyId };
        let request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.csEventSetDomain);
        request.setEvent(GlobalEventCons.eventLogs);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(this.props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                console.log("responseData", responseData)
                let { statusLogs, lnscStatusLogs } = responseData;
                if (Array.isArray(statusLogs) || Array.isArray(lnscStatusLogs)) {
                    this.props.setMultipleChatData(channelId, {
                        communityHistoryData: { statusLogs: statusLogs.reverse(), lnscStatusLogs: lnscStatusLogs },
                        activeUpdateStatusTab: "status"
                    });
                } else {
                    this.props.setMultipleChatData(channelId, {
                        communityHistoryData: { statusLogs: [], lnscStatusLogs: [] },
                        activeUpdateStatusTab: "status"
                    });
                }
            }
        });

    }

    returnUpdateHistory(data) {
        let { activeUserType } = this.props;
        let filtered = data;
        if (activeUserType === GlobalMsgCons.roleTypeCommunityMember || activeUserType === GlobalMsgCons.roleTypeSurvivor) {
            filtered = data.filter((item) => item.status !== "declined");
        }

        return filtered.length === 0 ? <div style={{ display: "flex", justifyContent: "center", width: "100%" }}> No History</div> : <ul className="timeline">
            {
                filtered.map((history, index) => {
                    let { updatedByUserName, updatedByEmailId } = history
                    //updatedByEmailId
                    return <li key={index} className={history.status.toLowerCase()}>
                        <span>{this.humanDateFormat(history.createdAt)}</span>
                        <br />
                        <span className="p-2 text-capitalize">{updatedByUserName}</span>
                        <br />
                        <span className="p-1">
                            <b className="p-1">Status :</b>
                            <span className="text-capitalize">{history.status}</span>
                        </span>
                    </li>
                })
            }
        </ul>

    }
    channelImgEdit() {
        let { channelId } = this.props;

        this.props.history.push(this.props.path + "/channel-img/" + channelId,
            { channelId })
    }

    filterReceiveChannel(data) {
        let { userChatHistory, channelType, filterList } = this.props;
        let eventData = []
        let filterData = data
        if (channelType === "communityServiceRequestSendingChannel" && filterList === "showLatest") {
            filterData = data.sort().reverse().filter((item) => {
                let validation = false
                let { chatMessage } = userChatHistory[item];
                let { csEventData } = chatMessage

                if (csEventData) {
                    let { eventCaseNo } = csEventData
                    if (!eventData.includes(eventCaseNo)) {
                        eventData.push(eventCaseNo)
                        validation = true
                    } else {
                        validation = false
                    }
                } else {
                    validation = true
                }
                return validation
            })
        }
        return filterData
    }

    render() {
        let { filterList, hostMemberId, userId, isSupportPage, requestReceivingChannelId, selectedChatMessage, optionsModal, agentDropdown, selectedAgent, chatEnable, voiceToTextModal, voiceToTextFileType, activeWindow, activeUserType, systemLevelRole, message, editBlink, gifURL, loginId, userChatHistory, fileList, emojiPicker, gifPicker, currentChannelName, currentResult, previewModal, buildUrl, insertText, insertTextToggle, recording, previewType, recordingModal, selectedMessage, editMessageId, editMessageText, setEmptyText, changeText, channelId, isFeedPage, path, deactivatedMembersIds, controlFeature, surveyModal, surveyData, errorSurveyData, surveyAction, surveyPollModal, surveyModalData, surveyQuestionStr, surveyModalOptData, chatSurveyTimeData, patientDropdown, patientBuildJson, searchPatientBuildJson, selectedPatient, loggedEmail, isCommunityServicePage, channelType, communityHistoryModal, communityCaseNo, activeUpdateStatusTab, communityHistoryData, headerChannelImg, channelHeaderDocumentFiles, channelImageBtnLoader, companyId } = this.props;
        let { refresh, callButtonLoader, chatPageType, communityServiceEvent, pageText, typingUserList, messageEmojiPicker, messageEmojiPickerPosition, messageIdEmojiPicker, emojiHoverView, processAudioToText, resultAudioToText, newMessage } = this.state
        let _this = this;
        var dateArray = []

        return <div className="main-content chat-page">
            <ToastContainer />
            <form className="chat-form" onSubmit={(e) => {
                if (editMessageId) {
                    this.editChannelSubmit(e, gifURL)
                } else {
                    this.addChannelChat(e, gifURL)
                }
            }} >
                <div className={`chat-window`}>
                    <React.Fragment>
                        <div className="chat-header" style={{ backgroundColor: "#ccf3f6" }}>
                            <div style={{ margin: "2px 10px" }}>
                                <img src={logoImg} className="img-fluid" style={{ width: 150 }} />
                            </div>
                            <div className="text-right pr-2" style={{ flex:1}}>
                                <div className="btn-group">
                                    <input type="radio" className="btn-check" name="btnradio" id="btnradio1" autoComplete="off" checked={filterList === "showLatest"} onChange={() => {
                                        this.props.setMultipleChatData(channelId, {
                                            filterList: "showLatest"
                                        })
                                    }} />
                                    <label className="btn btn-outline-primary" htmlFor="btnradio1">Show Latest</label>

                                    <input type="radio" className="btn-check" name="btnradio" id="btnradio2" autoComplete="off" checked={filterList === "showAll"} onChange={() => {
                                        this.props.setMultipleChatData(channelId, {
                                            filterList: "showAll"
                                        })
                                    }} />
                                    <label className="btn btn-outline-primary" htmlFor="btnradio2">Show All</label>
                                </div>
                            </div>
                        </div>
                        {
                            channelId && channelId !== "nill" && <div className="chat-body">
                                <div className="chat-body-message" ref={(el) => { this.messageEnd = el; }} onScroll={(e) => this.handleScroll(e)}>
                                    {
                                        (currentResult > 0 && currentResult !== Object.keys(userChatHistory).length) ? <div className="load-more-message" onClick={() => this.loadMore()}><span>{getValidLabels(pageText, 'loadMoreMessagesBtnTxt')}</span></div> : null
                                    }
                                    {
                                        userChatHistory && this.filterReceiveChannel(Object.keys(userChatHistory)).sort().map(function (ival, i) {
                                            var showLine = false
                                            var datetime = _this.timeStampToDate(new Date(parseInt(ival)))
                                            if (!dateArray.includes(datetime)) {
                                                dateArray.push(datetime)
                                                showLine = true
                                            }
                                            var dateView = datetime
                                            var datetime_2 = _this.timeStampToDate(new Date())

                                            if (datetime_2 === datetime) {
                                                dateView = "Today"
                                            }

                                            let message = <ChatMessage
                                                channelType={channelType}
                                                channelId={channelId}
                                                loggedEmail={loggedEmail}
                                                requestReceivingChannelId={requestReceivingChannelId}
                                                newMessage={newMessage}
                                                controlFeature={controlFeature}
                                                grpcClient={_this.props.grpcClient}
                                                pageText={pageText}
                                                userChatHistory={userChatHistory}
                                                ival={ival}
                                                callStatus={(callStatus, message) => _this.callStatus(callStatus, message)}
                                                joinCall={(chatMessage) => _this.joinCall(chatMessage)}
                                                declineCall={(chatMessage) => _this.declineCall(chatMessage)}
                                                updateMeetingDetails={(meetingId, messageId, status) => _this.updateMeetingDetails(meetingId, messageId, status)}
                                                joinMeetingCall={(chatMessage) => _this.joinMeetingCall(chatMessage)}
                                                chatFileView={(ival) => _this.chatFileView(ival)}
                                                replyChatSurveyQuestion={(id, jval, chatMessage) => _this.replyChatSurveyQuestion(id, jval, chatMessage)}
                                                getChatSurveyReplyDetails={(id, channelId, question, optdata, userName) => _this.getChatSurveyReplyDetails(id, channelId, question, optdata, userName)}
                                                scrollBottom={() => _this.scrollBottom()}
                                                getCommunityServiceFormValue={(formType, formValue, createdBy) => _this.getCommunityServiceFormValue(formType, formValue, createdBy)}
                                                sendCommunityServiceEvent={(data, type, id) => _this.sendCommunityServiceEvent(data, type, id)}
                                                openApps={(name, url) => _this.props.openApps(name, url)}
                                                setMultipleChatData={(channelId, value) => {
                                                    _this.props.setMultipleChatData(channelId, value)
                                                }}
                                                communityMemberViewHistory={(caseNo) => {
                                                    _this.props.setMultipleChatData(channelId, {
                                                        communityHistoryModal: true,
                                                        communityCaseNo: caseNo
                                                    })
                                                    _this.getCommunityHistoryData(caseNo)

                                                }}
                                            />
                                            let { chatMessage, tempMessageStatus, } = userChatHistory[ival];
                                            let userName = userChatHistory[ival].name
                                            let emojiReactionWithMemberNameData = userChatHistory[ival].emojiReactionWithMemberNameData;
                                            let { messageType, threadsCount, deviceInfo, chatSurveyData } = chatMessage;
                                            deviceInfo = deviceInfo ? JSON.parse(deviceInfo) : ""

                                            let profilePhoto = userChatHistory[ival].userPhoto
                                            let userProfilePhoto = ""
                                            if (profilePhoto) {
                                                let split = profilePhoto.split(".")
                                                userProfilePhoto = MyConstant.keyList.apiURL + "vp?action=profile&key=" + split[0] + "." + split[1] + "&id=" + chatMessage.userId
                                            }

                                            let emojiList = [];
                                            if (emojiReactionWithMemberNameData) {
                                                Object.keys(emojiReactionWithMemberNameData).map((emoji) => {
                                                    if (emojiReactionWithMemberNameData[emoji].length > 0) {
                                                        emojiList.push({
                                                            icon: emoji,
                                                            count: emojiReactionWithMemberNameData[emoji].length,
                                                            name: emojiReactionWithMemberNameData[emoji]
                                                        })
                                                    }
                                                })
                                            }

                                            let checkUserDetailsDisplay = _this.checkUserDetailsDisplay(chatMessage, i, "live")

                                            return <div key={i} id={ival}>
                                                {showLine && <div className="" style={{ padding: 2, marginBottom: 5, marginTop: 5 }}>
                                                    <div className="date-bg-line" style={{}} >
                                                        <span className="date-bg-color">{dateView}</span>
                                                    </div>
                                                </div>}
                                                <div key={ival} className={`message-container p-1 ${chatMessage.userId === loginId ? "receiver" : "sender"}`}
                                                    style={{ display: "block" }}
                                                    onTouchStart={() => {
                                                        if ((typeof messageType === "undefined" || (messageType !== "call" && messageType !== "calender" && messageType !== "meeting" && messageType !== "meeting-notification"))) {
                                                            _this.handleLongPress(chatMessage.id, userChatHistory[ival])
                                                        }
                                                    }}
                                                    onTouchEnd={() => {
                                                        if (!messageEmojiPicker) {
                                                            _this.handleLongRelease()
                                                        }
                                                    }}>

                                                    <div className="d-flex">
                                                        {
                                                            checkUserDetailsDisplay ? <div className="user-icon">
                                                                {
                                                                    (userProfilePhoto && userProfilePhoto != "") ? <img className="name-bg" src={userProfilePhoto} alt="no img" /> : <div className="name-bg" style={{ backgroundColor: color[userName !== "" ? userName[0].toUpperCase() : "A"] }}>{userName !== "" ? userName[0].toUpperCase() : ""}</div>
                                                                }
                                                            </div> : <>
                                                                <div className="empty-user-icon">
                                                                </div>
                                                                {/* <span className="time-show">{_this.timeReturn(chatMessage.createdAt)}</span> */}
                                                            </>
                                                        }
                                                        <div className="user-details">
                                                            <div className="user">
                                                                {
                                                                    checkUserDetailsDisplay && <div className="mx-2 mb-2">
                                                                        <React.Fragment>
                                                                            <b className="mr-2 uppercase">{userName}</b>

                                                                            {
                                                                                (checkActiveAdmin(activeUserType, systemLevelRole) && deviceInfo) && <span className="device-info">{deviceInfo.appPlatform}</span>
                                                                            }
                                                                            {
                                                                                tempMessageStatus === "loading" && <span className="spinner-border spinner-border-sm text-primary" role="status">
                                                                                    <span className="sr-only">Loading...</span>
                                                                                </span>
                                                                            }
                                                                            {
                                                                                tempMessageStatus === "failed" && <span style={{ color: "#E91E63" }}>
                                                                                    <i className="fas fa-exclamation-triangle"></i>
                                                                                </span>
                                                                            }
                                                                        </React.Fragment>
                                                                        <div className="time-info">{_this.humanDateFormat(chatMessage.createdAt)}</div>
                                                                    </div>
                                                                }
                                                                {
                                                                    typeof (chatSurveyData) !== "undefined" &&
                                                                    <span className="p-2">
                                                                        <i className="fas fa-poll-h"></i>
                                                                    </span>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="message-box">
                                                        {
                                                            message
                                                        }
                                                        {
                                                            messageType === GlobalMsgCons.botRequest && <div className="agent-box">
                                                                Agent - {chatMessage.aiAgentType} <br />
                                                                Type of LLM - {aiChatAgentDetails(chatMessage.aiAgentType)}
                                                            </div>
                                                        }
                                                        {
                                                            threadsCount > 0 && <div onClick={() => _this.openThread(userChatHistory[ival])} className="thread-count">{`${threadsCount} ${threadsCount > 1 ? "replies" : "reply"}`}</div>
                                                        }
                                                        {
                                                            emojiList.length > 0 && <div className="emoji-reaction mt-1">
                                                                {
                                                                    emojiList.map((item, index) => {
                                                                        return <div key={index} className="emoji-reaction-item" onClick={() => _this.updateEmoJiReactionForMsg(chatMessage.id, item.icon)}
                                                                            onMouseEnter={(e) => {
                                                                                let rect = e.currentTarget.getBoundingClientRect()
                                                                                let clientX = rect.left + rect.width / 2;
                                                                                let clientY = rect.top + rect.height + 5;
                                                                                _this.setState({
                                                                                    emojiHoverView: { id: chatMessage.id, icon: item.icon, clientX, clientY }
                                                                                })
                                                                            }}
                                                                            onMouseLeave={() => {
                                                                                _this.setState({
                                                                                    emojiHoverView: ""
                                                                                })
                                                                            }}>
                                                                            <div className="icon">{String.fromCodePoint("0x" + item.icon)}</div>
                                                                            <div className="count">{item.count}</div>
                                                                            {
                                                                                (chatMessage.id === emojiHoverView.id && item.icon === emojiHoverView.icon) && <div className="names" style={{ position: "fixed", top: emojiHoverView.clientY, left: emojiHoverView.clientX }}>{item.name.join(", ")}</div>
                                                                            }

                                                                        </div>
                                                                    })
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>

                            </div>}

                        <React.Fragment>
                            {
                                (isCommunityServicePage && channelType === "communityServiceRequestSendingChannel") ? <div className="report-here-button" onClick={() => {
                                    this.setState({
                                        communityServiceEvent: true
                                    })
                                }}>
                                    {getValidLabels(pageText, 'reportHereBtnTxt')}
                                </div> : <React.Fragment>
                                    {
                                        channelId && channelId !== "nill" && channelType !== "communityServiceRequestReceivingChannel" && !deactivatedMembersIds.includes(this.props.userId) && chatEnable && <div className="chat-input-container">
                                            <div className="chat-input-container-element">

                                                {
                                                    emojiPicker && <div className="chat-smiley">
                                                        <Picker
                                                            pickerStyle={{ width: '100%', height: 300 }}
                                                            disableSkinTonePicker={true}
                                                            onEmojiClick={(event, emojiObject) => this.onEmojiClick(event, emojiObject)}
                                                        />
                                                    </div>
                                                }
                                                {
                                                    gifPicker && <div className="chat-gif">
                                                        <GifPicker select={(e, url) => {
                                                            this.props.setMultipleChatData(channelId, {
                                                                gifPicker: false,
                                                                gifURL: url
                                                            })
                                                            if (editMessageId) {
                                                                this.editChannelSubmit(e, url)
                                                            } else {
                                                                this.addChannelChat(e, url)
                                                            }
                                                        }} />
                                                    </div>
                                                }
                                                <div className="">
                                                    <CustomEditorMinimized
                                                        editorFocused={(e) => {
                                                            this.props.setMultipleChatData(channelId, {
                                                                emojiPicker: false,
                                                                gifPicker: false,
                                                                surveyModal: false,
                                                            })
                                                        }}
                                                        editorClassName={editBlink ? "editor-blink" : ""}
                                                        pagename={"chat"}
                                                        updateChatTypingInfo={(event) => this.updateChatTypingInfo(event)}
                                                        insertText={insertText}
                                                        insertTextToggle={insertTextToggle}
                                                        setEmptyText={setEmptyText}
                                                        text={editMessageId ? editMessageText : message}
                                                        changeText={changeText}
                                                        textOnchange={(value) => {
                                                            {
                                                                if (editMessageId) {
                                                                    this.props.setMultipleChatData(channelId, {
                                                                        editMessageText: value
                                                                    })
                                                                } else {
                                                                    this.setChatMessage(value)
                                                                }
                                                            }
                                                        }}
                                                        submit={(e) => {
                                                            if (editMessageId) {
                                                                this.editChannelSubmit(e, gifURL)
                                                            } else {
                                                                this.addChannelChat(e, gifURL)
                                                            }
                                                        }}
                                                        channelId={channelId}
                                                    />
                                                </div>
                                                {
                                                    fileList.length > 0 && <div className="chat-files">
                                                        <div className="row mx-0">
                                                            {
                                                                fileList.map((file, i) => {
                                                                    return file.file instanceof File ? <div className="col-2" key={i}>
                                                                        <div className="chat-file-holder" title={file.origFileName} onClick={() => {
                                                                            let preview = { filePath: file.fileUrl, fileType: file.fileType }
                                                                            _this.props.setMultipleChatData(channelId, {
                                                                                previewBlob: preview,
                                                                                previewType: "blob",
                                                                                previewModal: true
                                                                            })
                                                                        }}>
                                                                            {file.uploadProcess === "pending" && <div className="spin-delete" onClick={(e) => {
                                                                                this.fileDelete(e, i)
                                                                            }}><i className="fa fa-times" /></div>}
                                                                            {file.uploadProcess === "processing" && <div className="spin-loader"></div>}
                                                                            {
                                                                                this.fileIcon(file.fileUrl, file.fileType)
                                                                            }
                                                                            {
                                                                                <span>{file.processType === "chat-record" ? "Record" : file.origFileName}</span>
                                                                            }
                                                                        </div>
                                                                    </div> : <div className="col-2" key={i}>
                                                                        <div className="chat-file-holder">
                                                                            <div className="spin-delete" onClick={(e) => {
                                                                                this.fileDelete(e, i)
                                                                            }} ><i className="fa fa-times" /></div>
                                                                            {_this.chatEditFileView(file)}
                                                                        </div>
                                                                    </div>
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                                <div className="chat-input-extra">
                                                    <div style={{ display: 'flex', flex: 1, flexWrap: 'wrap' }}>
                                                        <div className="icon" data-tip={getValidTooltip(pageText, 'attchmentIconTxt')}>
                                                            <input className="file-input-hidden" type="file" accept="image/*,video/*,.csv,application/pdf,application/vnd.ms-excel,.zip,.rar,.7zip,.wav" onChange={(e) => this.fileChange(e.target.files)} />
                                                            <i className="fa fa-plus" ></i>
                                                        </div>
                                                        <div className={`icon ${recording ? "record" : ""}`} onClick={() => {
                                                            this.props.setMultipleChatData(channelId, {
                                                                recordingModal: true,
                                                                surveyModal: false,
                                                            })
                                                        }} data-tip={getValidTooltip(pageText, 'audioRecIconTxt')}>
                                                            <i className="fa fa-microphone"></i>
                                                        </div>
                                                        <div className={`icon ${emojiPicker ? "active" : ""}`} onClick={() => {
                                                            this.props.setMultipleChatData(channelId, {
                                                                gifPicker: false,
                                                                emojiPicker: !emojiPicker,
                                                                surveyModal: false,
                                                            })
                                                        }} data-tip={getValidTooltip(pageText, 'smileIconTxt')}>
                                                            <i className="fa fa-smile"></i>
                                                        </div>
                                                        <div className={`icon ${gifPicker ? "active" : ""}`} style={{ fontWeight: 'bold' }} onClick={() => {
                                                            this.props.setMultipleChatData(channelId, {
                                                                emojiPicker: false,
                                                                gifPicker: !gifPicker,
                                                                surveyModal: false,
                                                            })
                                                        }} data-tip={getValidTooltip(pageText, 'gifIconTxt')}>
                                                            {getValidLabels(pageText, 'gIFLabelTxt')}
                                                        </div>

                                                        {
                                                            !isCommunityServicePage && <div className={`icon ${surveyModal ? "active" : ""}`} onClick={async () => {
                                                                await this.props.setMultipleChatData(channelId, {
                                                                    surveyModal: true,
                                                                    gifPicker: false,
                                                                    emojiPicker: false,

                                                                })
                                                            }} data-tip={"Create survey"}>
                                                                <i className="fas fa-poll-h"></i>
                                                            </div>
                                                        }
                                                        <div className={`icon ${voiceToTextModal ? "active" : ""}`} onClick={() => {
                                                            this.setState({
                                                                processAudioToText: ""
                                                            }, async () => {
                                                                await this.props.setMultipleChatData(channelId, {
                                                                    voiceToTextModal: true,
                                                                    gifPicker: false,
                                                                    emojiPicker: false,
                                                                })
                                                            })
                                                        }} data-tip={"Audio To Text"}>
                                                            <i className="fas fa-file-audio"></i>
                                                        </div>
                                                        {
                                                            (chatPageType !== "openmrschannel" && controlFeature.aiFeedChannelEnable) && <div className={`icon agent-modal ${agentDropdown ? "active" : ""}`} onClick={() => {
                                                                this.props.setMultipleChatData(channelId, {
                                                                    agentDropdown: !agentDropdown,
                                                                })
                                                            }}>
                                                                <i className="fas fa-robot"></i> {selectedAgent.label}
                                                                {
                                                                    selectedAgent.value !== "" && <span className="remove-agent" onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        this.props.setMultipleChatData(channelId, {
                                                                            selectedAgent: { label: "Select Agent", value: "" },
                                                                        })

                                                                    }}><i className="fas fa-times"></i></span>
                                                                }
                                                                <div className="agent-modal-select">
                                                                    {
                                                                        agentTypeOptions.map((agent, index) => {
                                                                            return <div key={index} className="modal-data" onClick={() => {
                                                                                this.props.setMultipleChatData(channelId, {
                                                                                    selectedAgent: agent,
                                                                                })
                                                                            }}>
                                                                                {agent.label}
                                                                            </div>
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="d-flex">
                                                        <button className="message-submit-button reset" type="button" onClick={() => {
                                                            this.props.setMultipleChatData(channelId, {
                                                                editMessageId: "",
                                                                editMessageText: "",
                                                                editMessageDetails: {},
                                                                userChatHistory,
                                                                setEmptyText: !setEmptyText,
                                                                emojiPicker: false,
                                                                fileList: [],
                                                                gifURL: "",
                                                                changeText: "",
                                                                selectedAgent: { label: "Select Agent", value: "" }
                                                            })
                                                        }} data-tip={getValidTooltip(pageText, 'resetTypeIconTxt')}><i className="fa fa-times"></i></button>
                                                        <button className="message-submit-button" type="submit" data-tip={getValidTooltip(pageText, 'sendIconTxt')}><i className="fa fa-paper-plane"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        chatEnable && <div className="typing">
                                            {
                                                typingUserList.length > 0 && <div data-tip={typingUserList.join(" and ") + getValidTooltip(pageText, 'isTypingTxt')}>{typingUserList.join(" and ")} {getValidLabels(pageText, 'isTypingTxt')}...</div>
                                            }
                                        </div>
                                    }
                                </React.Fragment>
                            }
                        </React.Fragment>
                    </React.Fragment>
                    {channelId && channelId === "nill" && <p style={{ margin: "auto", fontSize: 30, fontWeight: "bold" }}> {getValidLabels(pageText, 'feedIdMissingTxt')}</p>}
                </div>
            </form>

            {
                communityServiceEvent && <CommunityServiceEvent
                    eventModal={communityServiceEvent}
                    modalClose={() => {
                        this.setState({
                            communityServiceEvent: false
                        })
                    }}
                    sendCommunityServiceEvent={this.sendCommunityServiceEvent}
                    grpcClient={this.props.grpcClient}
                    fromChannelId={channelId}
                />
            }

            {
                optionsModal && <ChatOptionsModal
                    loginId={loginId}
                    pageText={pageText}
                    closeModal={() => {
                        this.props.setMultipleChatData(channelId, {
                            optionsModal: false
                        })
                    }}
                    openThread={(selectedChatMessage) => {
                        this.openThread(selectedChatMessage)
                        this.props.setMultipleChatData(channelId, {
                            optionsModal: false
                        })
                    }}
                    setShareMessage={(selectedChatMessage) => {
                        this.props.setShareMessage(selectedChatMessage)
                        this.props.setMultipleChatData(channelId, {
                            optionsModal: false
                        })
                    }}
                    updateEmoJiReactionForMsg={(id, emoji) => {
                        this.updateEmoJiReactionForMsg(id, emoji)
                        this.props.setMultipleChatData(channelId, {
                            optionsModal: false
                        })
                    }}
                    retryChatMessage={(selectedChatMessage) => {
                        this.retryChatMessage(selectedChatMessage)
                        this.props.setMultipleChatData(channelId, {
                            optionsModal: false
                        })
                    }}
                    edit={(chatMessage) => {
                        this.edit(chatMessage)
                        this.props.setMultipleChatData(channelId, {
                            optionsModal: false
                        })
                    }}
                    deleteChatMessage={(id, val) => {
                        this.deleteChatMessage(id, val)
                        this.props.setMultipleChatData(channelId, {
                            optionsModal: false
                        })
                    }}
                    selectedChatMessage={selectedChatMessage}
                    optionsModal={optionsModal} />
            }

            {
                messageEmojiPicker && <div className="emoji-message" style={{ top: messageEmojiPickerPosition.clientY, left: messageEmojiPickerPosition.clientX }}>
                    <Picker
                        pickerStyle={{ width: 300, height: 300 }}
                        disableSkinTonePicker={true}
                        onEmojiClick={(event, emojiObject) => {
                            _this.setState({
                                messageEmojiPicker: false
                            }, () => {
                                _this.updateEmoJiReactionForMsg(messageIdEmojiPicker, emojiObject.unified)
                            })
                        }}
                    />
                </div>
            }

            {
                previewModal && <Modal
                    visible={previewModal}
                    size={"modal-xl"}
                    closeModal={() => this.props.setMultipleChatData(channelId, {
                        previewModal: false,
                        viewModal: true,
                        previewBlob: {},
                        previewType: "",
                    })}
                    heading={getValidLabels(pageText, 'previewHeaderTxt')}
                    body={<div style={{ minHeight: "75vh" }}>
                        <div className="row">
                            <div className="col-1">
                            </div>
                            <div className="col-10">
                                {
                                    previewType === "blob" ? this.previewBlobContent() : this.previewContent(buildUrl)
                                }
                            </div>
                            <div className="col-1">
                            </div>
                        </div>

                    </div>
                    }
                />
            }

            {
                recordingModal && <Modal
                    visible={recordingModal}
                    closeModal={() => this.props.setMultipleChatData(channelId, {
                        recordingModal: false
                    })}
                    heading={getValidLabels(pageText, 'recordHeaderTxt')}
                    body={
                        <Recorder
                            saveAudio={this.saveAudio} />
                    }
                />
            }
            {
                voiceToTextModal && <Modal
                    visible={voiceToTextModal}
                    closeModal={() => {
                        this.props.setMultipleChatData(channelId, {
                            voiceToTextModal: false
                        })
                    }}
                    heading={"Audio to Text"}
                    body={<div>
                        {
                            processAudioToText ? <ProcessAudioToText
                                processAudioToText={processAudioToText}
                                resultAudioToText={resultAudioToText}
                                addTextToChat={(message) => {
                                    console.log("message", message)
                                    this.props.setMultipleChatData(channelId, {
                                        changeText: message,
                                        voiceToTextModal: false
                                    })
                                }} /> : <React.Fragment>
                                <div className="tabs">
                                    <div className={`tab ${voiceToTextFileType === "record" ? "active" : ""}`} onClick={() => this.props.setMultipleChatData(channelId, {
                                        voiceToTextFileType: "record"
                                    })
                                    }>
                                        Record
                                    </div>
                                    <div className={`tab ${voiceToTextFileType === "file" ? "active" : ""}`} onClick={() => this.props.setMultipleChatData(channelId, {
                                        voiceToTextFileType: "file"
                                    })
                                    }>
                                        File
                                    </div>
                                </div>
                                {
                                    voiceToTextFileType === "record" ? <Recorder autoStart={true} maxTime={10000} saveAudio={this.processAudioToText} /> : <VoiceToText saveAudio={this.processAudioToText} />
                                }
                            </React.Fragment>
                        }
                    </div>
                    }
                />
            }


            {
                surveyModal && <Modal
                    visible={surveyModal}
                    size={"modal-xl"}
                    closeModal={() => this.props.setMultipleChatData(channelId, {
                        surveyModal: false,
                        surveyData: {
                            surveyQuestion: "",
                            surveyOption: { 1: "", 2: "" },
                            surveyType: "single"
                        },
                        surveyAction: "add",
                        editMessageId: "",
                        editMessageDetails: {},
                        editMessageText: "",
                        chatSurveyTimeData: {
                            ...chatSurveyTimeData, daySelected: 0, hourSelected: 0, minuteSelected: 30,
                        }
                    })}
                    heading={surveyAction === "add" ? "Create" : "Edit" + " survey"}
                    body={<div style={{ minHeight: "75vh" }}>

                        {errorSurveyData['duration'] && errorSurveyData['duration'] !== "" && <div style={{ color: "red", fontSize: 12 }}>{errorSurveyData['duration']}</div>}
                        <div className="row">
                            <div className="col-sm-4">
                                <label>Survey Type</label>
                                <DropDown
                                    options={MyConfig.surveyOptions}
                                    isSearchable={true}
                                    placeholder={"Survey Type"}
                                    onChange={(e) => {
                                        surveyData.surveyType = e.value;
                                        this.props.setMultipleChatData(channelId, {
                                            surveyData
                                        })
                                    }}
                                    selectedOption={MyConfig.surveyOptions.filter((val => { return val.value.toString() === surveyData.surveyType.toString() }))}
                                    isMulti={false}
                                />

                                <div className="mt-3" style={{ display: "flex", flexDirection: "row" }}>
                                    <label > How long should the survey be open?</label><br />
                                </div>
                                <div className='mb-3' style={{ display: "flex", flexDirection: "row" }}>
                                    <div style={{ flex: 1, padding: 5 }}>
                                        <label >Day</label>
                                        <DropDown
                                            selectedOption={chatSurveyTimeData.dayOptions.filter((e) => { return e.value === chatSurveyTimeData.daySelected })}
                                            onChange={(e) => {
                                                chatSurveyTimeData.daySelected = e.value
                                                this.props.setMultipleChatData(channelId, { daySelected: e.value })
                                            }}
                                            options={chatSurveyTimeData.dayOptions}
                                            isDisabled={surveyAction === "edit"}
                                        />
                                    </div>
                                    <div style={{ flex: 1, padding: 5 }}>
                                        <label >Hour</label>
                                        <DropDown
                                            selectedOption={chatSurveyTimeData.hourOption.filter((e) => { return e.value === chatSurveyTimeData.hourSelected })}
                                            onChange={(e) => {
                                                chatSurveyTimeData.hourSelected = e.value
                                                this.props.setMultipleChatData(channelId, { chatSurveyTimeData })
                                            }}
                                            options={chatSurveyTimeData.hourOption}
                                            isDisabled={surveyAction === "edit"}
                                        />
                                    </div>
                                    <div style={{ flex: 1, padding: 5 }}>
                                        <label >Minute</label>
                                        <DropDown
                                            selectedOption={chatSurveyTimeData.minuteOption.filter((e) => { return e.value === chatSurveyTimeData.minuteSelected })}
                                            onChange={(e) => {
                                                chatSurveyTimeData.minuteSelected = e.value
                                                this.props.setMultipleChatData(channelId, { chatSurveyTimeData })
                                            }}
                                            options={chatSurveyTimeData.minuteOption}
                                            isDisabled={surveyAction === "edit"}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-8">
                                <label>Question</label>
                                <div className="form-group linebr">
                                    <CustomEditor
                                        title={"Question"}
                                        text={surveyData.surveyQuestion}
                                        textOnchange={(e) => {
                                            surveyData.surveyQuestion = e;
                                            this.props.setMultipleChatData(channelId, {
                                                surveyData
                                            })
                                        }}
                                    />
                                    {errorSurveyData['surveyQuestion'] && errorSurveyData['surveyQuestion'] !== "" && <div style={{ color: "red", fontSize: 12 }}>{errorSurveyData['surveyQuestion']}</div>}
                                </div>
                            </div>
                        </div>

                        <div className="row mt-3">
                            {Object.keys(surveyData.surveyOption).map((sId, i) => {
                                let iop = surveyData.surveyOption[sId];
                                return <>
                                    <div className="col-sm-4" key={i}>
                                        <label>Option - {i + 1}</label>
                                        {Object.keys(surveyData.surveyOption).length > 2 && <span style={{ color: "red", float: "right", cursor: "pointer" }} onClick={async () => {
                                            let filterOptions = surveyData.surveyOption;
                                            let newObj = {};
                                            let currentValues = Object.keys(filterOptions);
                                            currentValues = currentValues.filter((e) => { return sId !== e })
                                            Promise.all(
                                                currentValues.map((jval, j) => {
                                                    let objValue = filterOptions[jval];
                                                    newObj[j + 1] = objValue;
                                                })
                                            )
                                            surveyData.surveyOption = newObj;
                                            await this.props.setMultipleChatData(channelId, {
                                                surveyData
                                            })
                                        }}>
                                            <i className="fa fa-trash" aria-hidden="true"></i>
                                        </span>}

                                        <div className="form-group linebr">
                                            <CustomEditor
                                                title={"Question"}
                                                text={iop}
                                                textOnchange={(e) => {
                                                    surveyData.surveyOption[sId] = e;
                                                    this.props.setMultipleChatData(channelId, {
                                                        surveyData
                                                    })
                                                }}
                                                changeText={iop}
                                            />
                                            {errorSurveyData['surveyOption'] && errorSurveyData['surveyOption'][sId] && errorSurveyData['surveyOption'][sId] && errorSurveyData['surveyOption'][sId] !== "" && <div style={{ color: "red", fontSize: 12 }}>{errorSurveyData['surveyOption'][sId]}</div>}
                                        </div>
                                    </div></>
                            })
                            }
                        </div>
                        <div className="row mt-3">
                            <div className="col-sm-4">
                            </div>
                            <div className="col-sm-2 p-2">
                                <button className="btn btn-secondary" onClick={() => {
                                    surveyData.surveyOption[Object.keys(surveyData.surveyOption).length + 1] = "";
                                    this.props.setMultipleChatData(channelId, {
                                        surveyData
                                    })
                                }} >
                                    Add option
                                </button>
                            </div>
                            <div className="col-sm-2 p-2">
                                <button className="btn btn-primary" onClick={async () => {
                                    this.surveyValidation()
                                }} >
                                    {surveyAction == "add" ? "Create" : "Update"}

                                </button>
                            </div>
                        </div>
                    </div>
                    }
                />
            }
            {
                surveyPollModal && <Modal
                    visible={surveyPollModal}
                    size={"modal-md"}
                    closeModal={() => this.props.setMultipleChatData(channelId, {
                        surveyPollModal: false,
                    })}
                    heading={"Survey details"}
                    body={<div style={{ minHeight: "75vh" }}>
                        <h5 dangerouslySetInnerHTML={{ __html: surveyQuestionStr }} />
                        {surveyModalData && surveyModalData.map((data) => {
                            let userSurveyData = data.value
                            return <><div className="row mt-2">
                                <div className="surveyModal">
                                    <div className="col-sm-9">
                                        <div className="optionSurveyMd mt-3 mx-2" dangerouslySetInnerHTML={{ __html: surveyModalOptData[data.key] }} />
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="optionSurveyMd mt-1" style={{ float: "right" }}>
                                            {userSurveyData.length} {userSurveyData.length > 1 ? "Votes" : "Vote"}
                                        </div>
                                    </div>
                                </div>
                            </div>

                                {userSurveyData && userSurveyData.map((dataArr, j) => {
                                    return <div className="row">
                                        <div className="nameSurveybody mx-2">
                                            <div className="logoSurvey">
                                                <div className="userLogoSurvey">
                                                    {dataArr.firstName[0]}
                                                </div>
                                            </div>
                                            <div className="mx-1 mt-1 nameSurvey" ><p className="mx-2 mt-3">{dataArr.firstName}<br />
                                                <span style={{ fontSize: "12px" }}>{getTimeFormate(dataArr.createdAt)}</span>
                                            </p>
                                            </div>
                                            {/* <div className="mx-2 emailSurvey"><p className="mx-3 mt-3">{dataArr.email}</p>
                                            </div> */}
                                        </div>
                                    </div>
                                })
                                }
                            </>
                        })}
                    </div>
                    }
                />
            }

            {
                communityHistoryModal && <Modal
                    visible={communityHistoryModal}
                    closeModal={() => this.props.setMultipleChatData(channelId, {
                        communityHistoryModal: false,
                        activeUpdateStatusTab: "status"
                    })}
                    heading={"Case Number - " + getCaseId(communityCaseNo)}
                    body={<div className="">
                        <div className="btn-group flex-wrap p-3">
                            <input type="radio" className="p-2 btn-check" name="btnradio" id={"srcbtnradioStatus"} autoComplete="off" checked={activeUpdateStatusTab === "status"} onChange={() => {
                                accumulation(pageNameAcc, getPageEventName("reportLogPage", "statusTabClicked"), companyId, loginId, this.props.grpcClient)
                                this.props.setMultipleChatData(channelId, {
                                    activeUpdateStatusTab: "status"
                                })

                            }} />
                            <label className={`btn btn${activeUpdateStatusTab === "status" ? "" : "-outline"}-primary`} htmlFor={"srcbtnradioStatus"}>{"Status"}</label>

                            <input type="radio" className="p-2 btn-check" name="btnradio" id={"srcbtnradioLnscStatus"} autoComplete="off" checked={activeUpdateStatusTab === "lnscstatus"} onChange={() => {
                                accumulation(pageNameAcc, getPageEventName("reportLogPage", "lNSCStatusTabClicked"), companyId, loginId, this.props.grpcClient)
                                this.props.setMultipleChatData(channelId, {
                                    activeUpdateStatusTab: "lnscstatus"
                                })
                            }} />
                            <label className={`btn btn${activeUpdateStatusTab === "lnscstatus" ? "" : "-outline"}-primary`} htmlFor={"srcbtnradioLnscStatus"}>{"LNSC Status"}</label>
                        </div>


                        {this.returnUpdateHistory(activeUpdateStatusTab === "status" ? communityHistoryData.statusLogs : communityHistoryData.lnscStatusLogs)}
                    </div>}

                />
            }
        </div >
    }
}


const mapStateToProps = (state, ownProps) => {
    let { channelId, feed } = ownProps.match.params;
    let chatReducerData = state.chatReducer[channelId];
    return {
        userId: state.authReducer.loginId,
        loginId: state.authReducer.loginId,
        profilePhoto: state.authReducer.profilePhoto,
        sessionId: state.authReducer.sessionId,
        companyId: state.authReducer.activeCompany,
        activeUserType: state.authReducer.activeUserType,
        systemLevelRole: state.authReducer.systemLevelRole,
        activeCompanyDetails: state.authReducer.activeCompanyDetails,
        firstName: state.authReducer.firstName,
        hoursSelected: state.authReducer.hoursSelected,
        controlFeature: state.authReducer.controlFeature,
        channelKeys: Object.keys(state.chatReducer),
        loggedEmail: state.authReducer.loggedEmail,
        currentChannelName: (chatReducerData && chatReducerData.currentChannelName !== undefined) ? chatReducerData.currentChannelName : "",
        channelType: (chatReducerData && chatReducerData.channelType !== undefined) ? chatReducerData.channelType : "",
        channelId: (chatReducerData && chatReducerData.channelId !== undefined) ? chatReducerData.channelId : "",
        channelMembers: (chatReducerData && chatReducerData.channelMembers !== undefined) ? chatReducerData.channelMembers : [],
        userChatHistory: (chatReducerData && chatReducerData.userChatHistory !== undefined) ? chatReducerData.userChatHistory : {},
        currentResult: (chatReducerData && chatReducerData.currentResult !== undefined) ? chatReducerData.currentResult : 0,
        message: (chatReducerData && chatReducerData.message !== undefined) ? chatReducerData.message : "",
        fileList: (chatReducerData && chatReducerData.fileList !== undefined) ? chatReducerData.fileList : [],
        noOfPage: (chatReducerData && chatReducerData.noOfPage !== undefined) ? chatReducerData.noOfPage : 1,
        emojiPicker: (chatReducerData && chatReducerData.emojiPicker !== undefined) ? chatReducerData.emojiPicker : false,
        gifPicker: (chatReducerData && chatReducerData.gifPicker !== undefined) ? chatReducerData.gifPicker : false,
        buildUrl: (chatReducerData && chatReducerData.buildUrl !== undefined) ? chatReducerData.buildUrl : "",
        insertTextToggle: (chatReducerData && chatReducerData.insertTextToggle !== undefined) ? chatReducerData.insertTextToggle : false,
        setEmptyText: (chatReducerData && chatReducerData.setEmptyText !== undefined) ? chatReducerData.setEmptyText : false,
        insertText: (chatReducerData && chatReducerData.insertText !== undefined) ? chatReducerData.insertText : "",
        recording: (chatReducerData && chatReducerData.recording !== undefined) ? chatReducerData.recording : false,
        recordingModal: (chatReducerData && chatReducerData.recordingModal !== undefined) ? chatReducerData.recordingModal : false,
        previewBlob: (chatReducerData && chatReducerData.previewBlob !== undefined) ? chatReducerData.previewBlob : {},
        previewType: (chatReducerData && chatReducerData.previewType !== undefined) ? chatReducerData.previewType : "",
        selectedMessage: (chatReducerData && chatReducerData.selectedMessage !== undefined) ? chatReducerData.selectedMessage : "",
        editMessageId: (chatReducerData && chatReducerData.editMessageId !== undefined) ? chatReducerData.editMessageId : "",
        editMessageDetails: (chatReducerData && chatReducerData.editMessageDetails !== undefined) ? chatReducerData.editMessageDetails : {},
        editMessageText: (chatReducerData && chatReducerData.editMessageText !== undefined) ? chatReducerData.editMessageText : "",
        infoShow: (chatReducerData && chatReducerData.infoShow !== undefined) ? chatReducerData.infoShow : true,
        isRender: (chatReducerData && chatReducerData.isRender !== undefined) ? chatReducerData.isRender : false,
        scrollBottom: (chatReducerData && chatReducerData.scrollBottom !== undefined) ? chatReducerData.scrollBottom : true,
        gifURL: (chatReducerData && chatReducerData.gifURL !== undefined) ? chatReducerData.gifURL : "",
        deleteMessageId: (chatReducerData && chatReducerData.deleteMessageId !== undefined) ? chatReducerData.deleteMessageId : [],
        channelMembersDetails: (chatReducerData && chatReducerData.channelMembersDetails !== undefined) ? chatReducerData.channelMembersDetails : {},
        isFeedPage: (chatReducerData && chatReducerData.isFeedPage !== undefined) ? chatReducerData.isFeedPage : false,
        isSupportPage: (chatReducerData && chatReducerData.isSupportPage !== undefined) ? chatReducerData.isSupportPage : false,
        isCommunityServicePage: (chatReducerData && chatReducerData.isCommunityServicePage !== undefined) ? chatReducerData.isCommunityServicePage : false,
        pageText: (chatReducerData && chatReducerData.pageText !== undefined) ? chatReducerData.pageText : null,
        addPeopleModal: (chatReducerData && chatReducerData.addPeopleModal !== undefined) ? chatReducerData.addPeopleModal : false,
        channelUser: (chatReducerData && chatReducerData.channelUser !== undefined) ? chatReducerData.channelUser : [],
        userListOption: (chatReducerData && chatReducerData.userListOption !== undefined) ? chatReducerData.userListOption : [],
        errors: (chatReducerData && chatReducerData.errors !== undefined) ? chatReducerData.errors : {},
        excludeMembers: (chatReducerData && chatReducerData.excludeMembers !== undefined) ? chatReducerData.excludeMembers : [],
        channelMuteStatus: (chatReducerData && chatReducerData.channelMuteStatus !== undefined) ? chatReducerData.channelMuteStatus : "unmute",
        messageSound: (chatReducerData && chatReducerData.messageSound !== undefined) ? chatReducerData.messageSound : MyConfig.messageSound,
        selectedMessageSound: (chatReducerData && chatReducerData.selectedMessageSound !== undefined) ? chatReducerData.selectedMessageSound : "sound1",
        deactivatedMembersIds: (chatReducerData && chatReducerData.deactivatedMembersId !== undefined) ? chatReducerData.deactivatedMembersIds : [],
        previewModal: (chatReducerData && chatReducerData.previewModal !== undefined) ? chatReducerData.previewModal : false,
        changeText: (chatReducerData && chatReducerData.changeText !== undefined) ? chatReducerData.changeText : "",
        editBlink: (chatReducerData && chatReducerData.editBlink !== undefined) ? chatReducerData.editBlink : false,
        existChannelMemberIds: (chatReducerData && chatReducerData.existChannelMemberIds !== undefined) ? chatReducerData.existChannelMemberIds : [],
        paginateData: (chatReducerData && chatReducerData.paginateData !== undefined) ? chatReducerData.paginateData : { "pagination": [], "noOfPage": 1, "prev": false, "next": false },
        surveyModal: (chatReducerData && chatReducerData.surveyModal !== undefined) ? chatReducerData.surveyModal : false,
        surveyData: (chatReducerData && chatReducerData.surveyData !== undefined) ? chatReducerData.surveyData : { "surveyQuestion": "", "surveyOption": { "1": "", "2": "" }, "surveyType": "single", "totalSeconds": 0, },
        errorSurveyData: (chatReducerData && chatReducerData.errorSurveyData !== undefined) ? chatReducerData.errorSurveyData : {},
        channelMembersTotalCount: (chatReducerData && chatReducerData.channelMembersTotalCount !== undefined) ? chatReducerData.channelMembersTotalCount : 0,
        surveyAction: (chatReducerData && chatReducerData.surveyAction !== undefined) ? chatReducerData.surveyAction : "add",
        voiceToTextModal: (chatReducerData && chatReducerData.voiceToTextModal !== undefined) ? chatReducerData.voiceToTextModal : false,
        voiceToTextFileType: (chatReducerData && chatReducerData.voiceToTextFileType !== undefined) ? chatReducerData.voiceToTextFileType : "record",
        getChannelChatsFetched: (chatReducerData && chatReducerData.getChannelChatsFetched !== undefined) ? chatReducerData.getChannelChatsFetched : false,
        getChannelDetailsFetched: (chatReducerData && chatReducerData.getChannelDetailsFetched !== undefined) ? chatReducerData.getChannelDetailsFetched : false,
        getChannelMembersFetched: (chatReducerData && chatReducerData.getChannelMembersFetched !== undefined) ? chatReducerData.getChannelMembersFetched : false,
        surveyPollModal: (chatReducerData && chatReducerData.surveyPollModal !== undefined) ? chatReducerData.surveyPollModal : false,
        surveyModalData: (chatReducerData && chatReducerData.surveyModalData !== undefined) ? chatReducerData.surveyModalData : {},
        surveyQuestionStr: (chatReducerData && chatReducerData.surveyQuestionStr !== undefined) ? chatReducerData.surveyQuestionStr : "",
        surveyModalOptData: (chatReducerData && chatReducerData.surveyModalOptData) ? chatReducerData.surveyModalOptData : {},
        scrollPosition: (chatReducerData && chatReducerData.scrollPosition) ? chatReducerData.scrollPosition : 0,
        selectedAgent: (chatReducerData && chatReducerData.selectedAgent) ? chatReducerData.selectedAgent : { label: "Select Agent", value: "" },
        agentDropdown: (chatReducerData && chatReducerData.agentDropdown) ? chatReducerData.agentDropdown : false,
        optionsModal: (chatReducerData && chatReducerData.optionsModal) ? chatReducerData.optionsModal : false,
        selectedChatMessage: (chatReducerData && chatReducerData.selectedChatMessage) ? chatReducerData.selectedChatMessage : {},
        chatSurveyTimeData: (chatReducerData && chatReducerData.chatSurveyTimeData) ? chatReducerData.chatSurveyTimeData : {
            dayOptions: [],
            daySelected: 0,
            hourOption: [],
            hourSelected: 0,
            minuteOption: [],
            minuteSelected: 30,
        },
        searchPatientBuildJson: (chatReducerData && chatReducerData.searchPatientBuildJson) ? chatReducerData.searchPatientBuildJson : { ...MyConfig.searchPatientBuildJson },
        selectedPatient: (chatReducerData && chatReducerData.selectedPatient) ? chatReducerData.selectedPatient : { label: "OpenMRS", value: "" },
        patientDropdown: (chatReducerData && chatReducerData.patientDropdown) ? chatReducerData.patientDropdown : false,
        patientBuildJson: (chatReducerData && chatReducerData.patientBuildJson) ? chatReducerData.patientBuildJson : { ...MyConfig.patientBuildJson },
        hostMemberId: (chatReducerData && chatReducerData.hostMemberId) ? chatReducerData.hostMemberId : [],
        communityHistoryModal: (chatReducerData && chatReducerData.communityHistoryModal) ? chatReducerData.communityHistoryModal : false,
        communityHistoryData: (chatReducerData && chatReducerData.communityHistoryData) ? chatReducerData.communityHistoryData : { statusLogs: [], lnscStatusLogs: [] },
        communityCaseNo: (chatReducerData && chatReducerData.communityCaseNo) ? chatReducerData.communityCaseNo : [],
        activeUpdateStatusTab: (chatReducerData && chatReducerData.activeUpdateStatusTab) ? chatReducerData.activeUpdateStatusTab : "",
        headerChannelImg: (chatReducerData && chatReducerData.headerChannelImg !== undefined) ? chatReducerData.headerChannelImg : "",
        channelHeaderDocument_Json: (chatReducerData && chatReducerData.channelHeaderDocument_Json !== undefined) ? chatReducerData.channelHeaderDocument_Json : [],
        channelHeaderDocumentFiles: (chatReducerData && chatReducerData.channelHeaderDocumentFiles !== undefined) ? chatReducerData.channelHeaderDocumentFiles : [],
        channelImageBtnLoader: (chatReducerData && chatReducerData.channelImageBtnLoader) ? chatReducerData.channelImageBtnLoader : false,
        filterList: (chatReducerData && chatReducerData.filterList) ? chatReducerData.filterList : "showLatest",
        state,
    };
};

const mapDispatchToProps = {
    setMultipleChatData
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Chat));