import React from "react";
import { withRouter } from "react-router-dom";
import logoImg from '../../assets/logo-transparent.png'

function ReportHereLaunch(props) {

    return <React.Fragment>
        <div style={{ width: 200, margin: "auto", paddingTop: 25 }}>
            <img src={logoImg} className="img-fluid" />
        </div>
        <div className='d-flex justify-content-center align-items-center' style={{ height: "calc(100vh - 93px)" }}>
            <div className="report-here-page">
                <div className="text-center" style={{ color: "#962e7f"}}>
                    <h4>Getting ready for launch</h4>
                </div>
                <div className={`red-panic-button`} style={{ background: "linear-gradient(112.1deg, #9E9E9E 11.4%, #7b7070 70.2%)"}}>
                    <div>Report Here</div>
                </div>

                <div className="text-center" style={{ color: "#962e7f"}}>
                    <h4>See you soon!</h4>
                </div>
            </div>
        </div>
    </React.Fragment>;
}

export default withRouter(ReportHereLaunch)
