import { combineReducers } from 'redux';

import authReducer from './authReducer';
import loginReducer from './loginReducer';
import registerReducer from './registerReducer';
import forgotPasswordReducer from './forgotPasswordReducer';
import chatReducer from './chatReducer';
import chatChannelReducer from './chatChannelReducer';
import chatThreadReducer from './chatThreadReducer';
import myProfileReducer from './myProfileReducer';
import reportHereReducer from './reportHereReducer';



const rootReducer = combineReducers({
  authReducer,
  loginReducer,
  registerReducer,
  forgotPasswordReducer,
  chatReducer,
  chatChannelReducer,
  chatThreadReducer,
  myProfileReducer,
  reportHereReducer
});

export default rootReducer;
